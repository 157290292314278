<template>
  <div>
    <v-expansion-panels accordion class="expansion-panel" v-if="isSignedIn">
      <v-expansion-panel v-for="(category, i) in categories" :key="i">
        <v-expansion-panel-header>
          {{ category.label }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <template v-for="(submenu, index) in category.layer">
            <template v-if="category.type === 'radio'">
              <v-radio-group
                v-model="mapType"
                dense
                :key="index"
                @change="mapTypeChanged"
              >
                <v-radio
                  :label="submenu.label"
                  :value="submenu.value"
                ></v-radio>
              </v-radio-group>
            </template>
            <template v-else>
              <v-checkbox
                :key="index"
                :label="submenu.label"
                :value="submenu.value"
                v-model="selectedFilterValue"
                @change="filterValueChanged"
              ></v-checkbox>
              <CustomRadius
                v-show="selectedFilterValue.includes(submenu.value)"
                :key="'customRadius' + index"
                :submenu="submenu"
                class="mx-1 mt-2 mb-5"
              ></CustomRadius>
            </template>
          </template>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <div class="text-body-1 px-3" v-else>
      Loggen Sie sich mit dem Mitarbeiter Konto ein, um mehr zu erfahren.
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CustomRadius from "@/components/app/CustomRadius.vue";

export default {
  components: {
    CustomRadius,
  },

  data() {
    return {
      mapType: "roadmap",
      selectedFilterValue: [],
    };
  },

  methods: {
    mapTypeChanged() {
      this.$emit("mapTypeChangeEvent", this.mapType);
    },

    filterValueChanged() {
      this.setSelectedFilterValue(this.selectedFilterValue);
    },

    ...mapActions(["setSelectedFilterValue", "initSelectedFilterState"]),
  },

  computed: {
    ...mapGetters({
      categories: "categories",
      filterValueGetter: "selectedFilterValue",
      isSignedIn: "isSignedIn",
    }),
  },

  mounted() {
    this.initSelectedFilterState();
    this.selectedFilterValue = this.filterValueGetter;
  },
};
</script>

<style>
</style>
