<template>
  <v-container fluid grid-list-sm>
    <form @submit.prevent="updateFirebase">
      <v-card v-if="$store.state.auth.isAuthenticating">
          <v-banner  v-if="$store.state.zbl.isOnline == false" single-line >
              <v-icon slot="icon" color="warning" size="36">mdi-wifi-strength-alert-outline</v-icon>
              Offline-Modus!
          </v-banner>
          <v-card-title><v-icon>mdi-calendar</v-icon>&nbsp;ZBL - Tagesbericht vom {{$store.state.zbl.tb.datumanzeige}} </v-card-title>
          <v-card-subtitle>letzte Aktualisierung: {{$store.state.zbl.lastChange}}</v-card-subtitle>
          <v-card-text>
              <v-layout  class="tagesbericht-form" row wrap >
                  <v-flex>
                      <v-btn icon @click.native="TBlastDay">
                          <v-icon >mdi-arrow-left</v-icon>
                          Vortag
                      </v-btn>
                  </v-flex>
                  <v-flex class="text-xs-right">
                      <v-btn icon @click.native="TBAktDay">
                          aktueller Tag
                          <v-icon >mdi-arrow-right</v-icon>
                      </v-btn>
                  </v-flex>
                  <v-tabs
                      v-model="tabbox"
                      show-arrows
                      icons-and-text
                  >
                      <v-tabs-slider color="pink lighten-4"></v-tabs-slider>
                      <v-tab href="#tabbox-1">
                          Tagesbericht
                          <v-icon>mdi-message-text-outline</v-icon>
                      </v-tab>
                      <v-tab href="#tabbox-2">
                          Fahrtausfall
                          <v-icon>mdi-bus-side</v-icon>
                      </v-tab>
                      <v-tab href="#tabbox-3">
                          Taxi/Sammeltaxi
                          <v-icon>mdi-train-car</v-icon>
                      </v-tab>
                      <v-tab href="#tabbox-4"  @click="getSessionname()">
                          An die Verkehrsabt.
                          <v-icon>mdi-train-car</v-icon>
                      </v-tab>
                      <v-tab href="#tabbox-5">
                          Einsatz u. Abzug von Bussen
                          <v-icon>mdi-train-car</v-icon>
                      </v-tab>
                      <v-tab href="#tabbox-6">
                          Ausgewechselte Wagen
                          <v-icon>mdi-train-car</v-icon>
                      </v-tab>
                      <v-tab href="#tabbox-7">
                          Ausgabe VDT
                          <v-icon>mdi-card-account-details-outline</v-icon>
                      </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tabbox" width='100%'>
<!--********** Tab Tagesbericht *******************************************************************************************-->
                      <v-tab-item :key="1" :value="'tabbox-1'" width='100%'>
<!--********** Platz 1 *******************************************************************************************-->
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Platz 1</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewPlatz1Form(0)" :disabled="formreadonly">
                                  <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(p1, pindex) in $store.state.zbl.tb.platz1" :key="p1.dienst">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card  tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.VMList"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="p1.dienst" 
                                                      v-on:change="fieldUpdate('ZBL', pindex)"
                                                      label="Dienst" data-vv-name="p1.dienst" prepend-icon="mdi-account-outline" return-object
                                                      :readonly="formreadonly"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.VMList"
                                                      item-text='name'
                                                      item-value='Personnel_nr'
                                                      v-model="p1.name" 
                                                      v-on:change="fieldUpdate('ZBL', pindex)"
                                                      :readonly="formreadonly"
                                                      label="Verkehrsmeister" data-vv-name="p1.name" prepend-icon="mdi-account-outline" return-object
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'von'"
                                                      v-bind:field="p1.von" 
                                                      v-bind:modal="p1.modal1"
                                                      v-bind:i1="pindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'p1von'"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'bis'"
                                                      v-bind:field="p1.bis" 
                                                      v-bind:modal="p1.modal2"
                                                      v-bind:i1="pindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'p1bis'"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="4">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Bemerkung"
                                                          placeholder="Bemerkung" v-model="p1.bemerkung"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('ZBL', pindex)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delForm(0, pindex, 0, 'platz1')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
<!--********** Platz 2 *******************************************************************************************-->
                          <div heigth='1px'></div>
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title >Platz 2</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewPlatz2Form(0)" :disabled="formreadonly">
                                  <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(p2, pindex) in $store.state.zbl.tb.platz2" :key="p2.dienst">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card  tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.VMList"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="p2.dienst" 
                                                      label="Dienst" 
                                                      v-on:change="fieldUpdate('ZBL', pindex)"
                                                      :readonly="formreadonly"
                                                      data-vv-name="p2.dienst" prepend-icon="mdi-account-outline" return-object
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.VMList"
                                                      item-text='name'
                                                      item-value='Personnel_nr'
                                                      v-model="p2.name" 
                                                      label="Verkehrsmeister" 
                                                      v-on:change="fieldUpdate('ZBL', pindex)"
                                                      :readonly="formreadonly"
                                                      data-vv-name="p2.name" prepend-icon="mdi-account-outline" return-object
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'von'"
                                                      v-bind:field="p2.von" 
                                                      v-bind:modal="p2.modal1"
                                                      v-bind:i1="pindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'p2von'"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'bis'"
                                                      v-bind:field="p2.bis" 
                                                      v-bind:modal="p2.modal2"
                                                      v-bind:i1="pindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'p2bis'"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="4">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Bemerkung"
                                                          placeholder="Bemerkung" v-model="p2.bemerkung"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('ZBL', pindex)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delForm(0, pindex, 0, 'platz2')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
<!--********** Wetterlage *******************************************************************************************-->
                          <div heigth='1px'></div>
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Wetterlage</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewWetterForm(0)" :disabled="formreadonly">
                                  <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-textarea
                                  rows="1"
                                  prepend-icon="mdi-weather-cloudy"
                                  clearable
                                  auto-grow
                                  clear-icon="mdi-cancel"
                                  placeholder="Wetterlage in Stichworten" v-model="$store.state.zbl.tb.wetterlage"
                              ></v-textarea>
                              <v-row v-for="(w, windex) in $store.state.zbl.tb.wetter" :key="w.zeit">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="wetterzeit"
                                                      v-model="w.zeit" 
                                                      label="Zeit" data-vv-name="w.zeit" prepend-icon="mdi-clock-outline"
                                                      v-on:change="fieldUpdate('ZBL', windex)"
                                                      :readonly="formreadonly"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-text-field 
                                                      v-model.number="w.temperatur" 
                                                      label="Temperatur" 
                                                      type="number"
                                                      prepend-icon="mdi-temperature-celsius"
                                                      :readonly="formreadonly"
                                                      v-on:change="fieldUpdate('ZBL', windex)"
                                                  >
                                                  </v-text-field>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-text-field 
                                                      v-model.number="w.hydro" 
                                                      label="Hydro in %" 
                                                      type="number"
                                                      :rules="hydrorule"
                                                      prepend-icon="mdi-water"
                                                      :readonly="formreadonly"
                                                      v-on:change="fieldUpdate('ZBL', windex)"
                                                  >
                                                  </v-text-field>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="5">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Wetterlage"
                                                          placeholder="Wetterlage" v-model="w.wetterlage"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('ZBL', windex)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delForm(0, windex, 0, 'wetter')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
<!--********** Bericht über den Betriebsablauf *******************************************************************************************-->
                          <div heigth='1px'></div>
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Bericht &uuml;ber den Betriebsablauf</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewBetriebsablaufForm(0)" :disabled="formreadonly">
                                  <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(b, bindex) in $store.state.zbl.tb.betriebsablauf" :key="b.dienst">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.dayroster.roster"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="b.dienst" 
                                                      v-on:change="fieldUpdate('ZBL', bindex)"
                                                      :readonly="formreadonly"
                                                      label="Dienst" data-vv-name="b.dienst" prepend-icon="mdi-account-outline" return-object
                                                  >
                                                  </v-autocomplete>
                                                  <!--<v-text-field prepend-icon="mdi-numeric-1-box-multiple-outline" label="Dienst" :rules="rules" v-model="b.dienst"></v-text-field>-->
                                              </v-card>
                                          </v-col>
                                          <v-col sm="9">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Bericht"
                                                          placeholder="Bericht" v-model="b.bericht"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('ZBL', bindex)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delForm(0, bindex, 0, 'betriebsablauf')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
<!--********** Anzahl der... *******************************************************************************************-->
                          <div heigth='1px'></div>
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Anzahl der ...</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewAnzahlForm(0)" :disabled="formreadonly">
                                  <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(a, aindex) in $store.state.zbl.tb.anzahl" :key="a.dienst">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.dayroster.roster"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="a.dienst" 
                                                      v-on:change="fieldUpdate('ZBL', aindex)"
                                                      :readonly="formreadonly"
                                                      label="Dienst" data-vv-name="a.dienst" prepend-icon="mdi-account-outline" return-object
                                                  >
                                                  </v-autocomplete>
                                                  <!--<v-text-field prepend-icon="mdi-numeric-1-box-multiple-outline" label="Dienst" :rules="rules" v-model="p.dienst"></v-text-field>-->
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-text-field 
                                                      v-model.number="a.anzahlunfall" 
                                                      label="gemeldeten Unfälle eigener Fahrzeuge" 
                                                      type="number"
                                                      :rules="anzahlrule"
                                                      :readonly="formreadonly"
                                                      prepend-icon="mdi-bus"
                                                      v-on:change="fieldUpdate('ZBL', aindex)"
                                                  >
                                                  </v-text-field>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-text-field 
                                                      v-model.number="a.anzahlpersonal" 
                                                      label="Störungen durch fehlendes Personal" 
                                                      type="number"
                                                      :rules="anzahlrule"
                                                      :readonly="formreadonly"
                                                      prepend-icon="mdi-account-outline"
                                                      v-on:change="fieldUpdate('ZBL', aindex)"
                                                  >
                                                  </v-text-field>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-text-field 
                                                      v-model.number="a.anzahlausfall" 
                                                      label="Wagenausfälle" 
                                                      type="number"
                                                      :rules="anzahlrule"
                                                      :readonly="formreadonly"
                                                      prepend-icon="mdi-minus"
                                                      v-on:change="fieldUpdate('ZBL', aindex)"
                                                  >
                                                  </v-text-field>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-text-field 
                                                      v-model.number="a.anzahlverspaetung" 
                                                      label="Verspätungen über 5 Min" 
                                                      type="number"
                                                      :rules="anzahlrule"
                                                      :readonly="formreadonly"
                                                      prepend-icon="mdi-clock-fast"
                                                      v-on:change="fieldUpdate('ZBL', aindex)"
                                                  >
                                                  </v-text-field>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delForm(0, aindex, 0, 'anzahl')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
<!--********** Meldungen an die Polizei *******************************************************************************************-->
                          <div heigth='1px'></div>
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Meldungen an die Polizei</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewPolizeiForm(0)" :disabled="formreadonly">
                                  <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(p, pindex) in $store.state.zbl.tb.polizei" :key="p.dienst">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.dayroster.roster"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="p.dienst" 
                                                      v-on:change="fieldUpdate('ZBL', pindex)"
                                                      :readonly="formreadonly"
                                                      label="Dienst" data-vv-name="p.dienst" prepend-icon="mdi-account-outline" return-object
                                                  >
                                                  </v-autocomplete>
                                                  <!--<v-text-field prepend-icon="mdi-numeric-1-box-multiple-outline" label="Dienst" :rules="rules" v-model="p.dienst"></v-text-field>-->
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit'"
                                                      v-bind:field="p.zeit" 
                                                      v-bind:modal="p.modal"
                                                      v-bind:i1="pindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'polizeizeit'"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="8">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Meldung"
                                                          placeholder="Meldung" v-model="p.meldung"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('ZBL', pindex)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delForm(0, pindex, 0, 'polizei')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
<!--********** Mängel und Störungen im Funkbetrieb und an sonstigen Einrichtungen der ZBL. *******************************************************************************************-->
                          <div heigth='1px'></div>
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Mängel und Störungen im Funkbetrieb und an sonstigen Einrichtungen der ZBL.</v-toolbar-title>
                              </v-toolbar>
                              <v-row>
                                  <v-col md="12">
                                      <v-card tile flat>
                                          <v-textarea
                                              rows="1"
                                              prepend-icon="mdi-message-alert"
                                              clearable
                                              auto-grow
                                              clear-icon="mdi-cancel"
                                              placeholder="Mängel und Störungen" v-model="$store.state.zbl.tb.zblstoerung"
                                              :readonly="formreadonly"
                                              v-on:change="fieldUpdate('ZBL', pindex)"
                                          ></v-textarea>
                                      </v-card>
                                  </v-col>
                              </v-row>
                          </v-card>
<!--********** Streudienst Einsätze *******************************************************************************************-->
                          <div heigth='1px'></div>
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Streudienst Eins&auml;tze</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewStreudienstForm(0)" :disabled="formreadonly">
                                  <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(s, sindex) in $store.state.zbl.tb.streudienst" :key="s.zeit">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit'"
                                                      v-bind:field="s.zeit" 
                                                      v-bind:modal="s.modal"
                                                      v-bind:i1="sindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'streudienstzeit'"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="9">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-map"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Streckenabschnitt"
                                                          placeholder="Streckenabschnitt" v-model="s.strecke"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('ZBL', sindex)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delForm(0, sindex, 0, 'streudienst')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
<!--********** Meldungen auf besonderem Vordruck *******************************************************************************************-->
                          <div heigth='1px'></div>
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Meldungen auf besonderem Vordruck</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewMeldungForm(0)" :disabled="formreadonly">
                                  <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(m, mindex) in $store.state.zbl.tb.meldung" :key="m.dienst">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.dayroster.roster"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="m.dienst" 
                                                      v-on:change="fieldUpdate('ZBL', mindex)"
                                                      label="Dienst" data-vv-name="m.dienst" prepend-icon="mdi-account-outline" return-object
                                                      :readonly="formreadonly"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="9">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Meldung"
                                                          placeholder="Meldung Inhaltsangabe in Stichworte" v-model="m.meldung"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('ZBL', mindex)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delForm(0, mindex, 0, 'meldung')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
<!--********** Tagesbericht zu AnGe senden *******************************************************************************************-->
<!--                            <v-alert
                          v-model="TBalert"
                          border="left"
                          type="error"
                          close-text="Close Alert"
                          dark
                          dismissible
                          >
                              Der aktuelle Tagesbericht vom {{ aktdate }} kann noch nicht versendet werden!
                          </v-alert>
                          <div heigth='1px'></div>
                          <v-card width='100%'>
                              <v-layout>
                                  <v-flex d-flex xs12 sm12 child-flex>
                                      <v-layout row wrap>
                                          <v-flex d-flex xs12 sm12 child-flex>
                                              <v-btn icon color="green" v-if="TBsend" :disabled="sessionlogin!='A002536@ITVV.ORG'" v-on:click="sendtoange()">
                                              <v-icon>mdi-send</v-icon> Absenden
                                              </v-btn>
                                          </v-flex>
                                      </v-layout>
                                  </v-flex>
                              </v-layout>
                          </v-card>-->
                      </v-tab-item>
<!--********** Tab Fahrtausfall *******************************************************************************************-->
                      <v-tab-item :key="2" :value="'tabbox-2'" width='100%' >
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Meldung &uuml;ber Fahrtausfall</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewFahrtausfallForm(0)" :disabled="formreadonly">
                                      <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(f, findex) in $store.state.zbl.fahrtausfall" :key="f.id">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.busline.itemdata"
                                                      :rules="rules"
                                                      item-text='line'
                                                      item-value='line'
                                                      v-model="f.linie" 
                                                      v-on:change="fieldUpdate('FAHRTAUSFALL', findex, f.id)"
                                                      :readonly="formreadonly"
                                                      label="Linie" data-vv-name="f.linie" prepend-icon="mdi-account-outline"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.dayroster.roster"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="f.dienst" 
                                                      v-on:change="fieldUpdate('FAHRTAUSFALL', findex, f.id)"
                                                      :readonly="formreadonly"
                                                      label="Dienst" data-vv-name="f.dienst" prepend-icon="mdi-account-outline"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit von'"
                                                      v-bind:field="f.zeit" 
                                                      v-bind:modal="f.modalvon"
                                                      v-bind:i1="findex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'favon'"
                                                      v-bind:collection="'fahrtausfall'"
                                                      v-bind:dataid="f.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit bis'"
                                                      v-bind:field="f.zeitbis" 
                                                      v-bind:modal="f.modalbis"
                                                      v-bind:i1="findex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'fabis'"
                                                      v-bind:collection="'fahrtausfall'"
                                                      v-bind:dataid="f.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Fahrt von"
                                                          placeholder="Fahrt von" v-model="f.fahrtvon"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('FAHRTAUSFALL', findex, f.id)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Fahrt nach"
                                                          placeholder="Fahrt nach" v-model="f.fahrtnach"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('FAHRTAUSFALL', findex, f.id)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-select
                                                      v-model="f.grund"
                                                      :items= "['Wagenausfall', 'Personalmangel', 'Straßensperrung', 'Verspätung', 'Unfall', 'Glatteis', 'Schnee', 'Sonstiges']"
                                                      :menu-props="{ maxHeight: '400' }"
                                                      label="Fahrtausfall Grund"
                                                      prepend-icon="mdi-tooltip-text-outline"
                                                      hint="Bitte den Grund auswählen"
                                                      persistent-hint
                                                      v-on:change="fieldUpdate('FAHRTAUSFALL', findex, f.id)"
                                                      :readonly="formreadonly"
                                                  ></v-select>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Bemerkung"
                                                          placeholder="Bemerkung" v-model="f.bemerkung"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('FAHRTAUSFALL', findex, f.id)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delSubForm(f.id, 'fahrtausfall')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                              <v-toolbar flat>
                                  <v-toolbar-title>Meldung &uuml;ber Fahrtausfall</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewFahrtausfallForm(0)" :disabled="formreadonly">
                                      <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                          </v-card>
                      </v-tab-item>
<!--********** Tab Taxibestellung *******************************************************************************************-->
                      <v-tab-item :key="3" :value="'tabbox-3'" width='100%' >
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Meldung &uuml;ber Taxi-Bestellungen</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewTaxibestellungForm(0)" :disabled="formreadonly">
                                      <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(t, tindex) in $store.state.zbl.taxibestellung" :key="t.id">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.dayroster.roster"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="t.dienst" 
                                                      v-on:change="fieldUpdate('TAXI', tindex, t.id)"
                                                      :readonly="formreadonly"
                                                      label="Dienst" data-vv-name="t.dienst" prepend-icon="mdi-account-outline"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card  tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit'"
                                                      v-bind:field="t.zeit" 
                                                      v-bind:modal="t.modal1"
                                                      v-bind:i1="tindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'taxizeit'"
                                                      v-bind:collection="'taxibestellung'"
                                                      v-bind:dataid="t.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.busstation.itemdata"
                                                      item-text='text'
                                                      item-value='text'
                                                      v-model="t.haltestelle" 
                                                      v-on:change="fieldUpdate('TAXI', tindex, t.id)"
                                                      :readonly="formreadonly"
                                                      label="Haltestelle" data-vv-name="t.haltestelle" prepend-icon="mdi-bus-stop-uncovered" 
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-select
                                                      v-model="t.ort"
                                                      :items= "['Mönchengladbach 02161/13013', 
                                                                  'Rheydt 02166/41010', 
                                                                  'Korschenbroich 02161/64646', 
                                                                  'Viersen 02162/13000', 
                                                                  'Schwalmtal 02163/2222', 
                                                                  'Brüggen 02163/5644', 
                                                                  'Wegberg 02434/7676',
                                                                  'Willich 02154/3000' ]"
                                                      :menu-props="{ maxHeight: '400' }"
                                                      label="Ort"
                                                      prepend-icon="mdi-city"
                                                      hint="Bitte den Ort ausw&auml;hlen"
                                                      persistent-hint
                                                      v-on:change="fieldUpdate('TAXI', tindex, t.id)"
                                                      :readonly="formreadonly"
                                                  ></v-select>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Ankunft'"
                                                      v-bind:field="t.ankunft" 
                                                      v-bind:modal="t.modal2"
                                                      v-bind:i1="tindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'taxiankunftzeit'"
                                                      v-bind:collection="'taxibestellung'"
                                                      v-bind:dataid="t.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          clearable
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Bemerkung"
                                                          placeholder="Bemerkung" v-model="t.bemerkung"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('TAXI', tindex, t.id)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delSubForm(t.id, 'taxibestellung')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                              <v-toolbar flat>
                                  <v-toolbar-title>Anruf Sammel Taxi</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewSammelTaxibestellungForm(0)" :disabled="formreadonly">
                                      <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(s, sindex) in $store.state.zbl.sammeltaxi" :key="s.id">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Abfahrt'"
                                                      v-bind:field="s.abfahrt" 
                                                      v-bind:modal="s.modal1"
                                                      v-bind:i1="sindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'sammeltaxiabfahrtzeit'"
                                                      v-bind:collection="'sammeltaxi'"
                                                      v-bind:dataid="s.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.busstation.itemdata"
                                                      item-text='text'
                                                      item-value='text'
                                                      v-model="s.einstieg" 
                                                      v-on:change="fieldUpdate('SAMMEL', sindex, s.id)"
                                                      :readonly="formreadonly"
                                                      label="Einstieg" data-vv-name="s.einstieg" prepend-icon="mdi-bus-stop-uncovered" 
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.busstation.itemdata"
                                                      item-text='text'
                                                      item-value='text'
                                                      v-model="s.ausstieg" 
                                                      v-on:change="fieldUpdate('SAMMEL', sindex, s.id)"
                                                      :readonly="formreadonly"
                                                      label="Ausstieg" data-vv-name="s.ausstieg" prepend-icon="mdi-bus-stop-uncovered" 
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-text-field 
                                                      v-model.number="s.personen" 
                                                      label="Personen" 
                                                      type="number"
                                                      :rules="anzahlrule"
                                                      prepend-icon="mdi-human-queue"
                                                      :readonly="formreadonly"
                                                      v-on:change="fieldUpdate('SAMMEL', sindex, s.id)"
                                                  >
                                              </v-text-field>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit'"
                                                      v-bind:field="s.bestellzeit" 
                                                      v-bind:modal="s.modal2"
                                                      v-bind:i1="sindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'sammeltaxibestellzeit'"
                                                      v-bind:collection="'sammeltaxi'"
                                                      v-bind:dataid="s.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.VMList"
                                                      item-text='name'
                                                      item-value='Personnel_nr'
                                                      v-model="s.bestellperson" 
                                                      v-on:change="fieldUpdate('SAMMEL', sindex, s.id)"
                                                      :readonly="formreadonly"
                                                      label="Verkehrsmeister" data-vv-name="s.bestellperson" prepend-icon="mdi-human-edit" return-object
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delSubForm(s.id, 'sammeltaxi')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
                      </v-tab-item>


<!--********** Meldung an die Verkehrsabteilung *******************************************************************************************-->
                      <v-tab-item :key="4" :value="'tabbox-4'" width='100%'>
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Meldung an die Verkehrsabteilung</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewMeldungVerkehrsabteilungForm(0)" :disabled="formreadonly">
                                      <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(v, vindex) in $store.state.zbl.meldungverkehrsabteilung" :key="v.id">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.busline.itemdata"
                                                      :rules="rules"
                                                      item-text='line'
                                                      item-value='line'
                                                      v-model="v.linie" 
                                                      :readonly="v.meister != $store.state.auth.user.displayName || formreadonly == true"
                                                      v-on:change="fieldUpdate('MELDUNG', vindex, v.id)"
                                                      label="Linie" data-vv-name="v.linie" prepend-icon="mdi-account-outline"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.FahrerList"
                                                      :rules="rules"
                                                      item-text='nd'
                                                      item-value='nd'
                                                      v-model="v.dienst" 
                                                      :readonly="v.meister != $store.state.auth.user.displayName || formreadonly == true"
                                                      v-on:change="fieldUpdate('MELDUNG', vindex, v.id)"
                                                      label="Dienst" data-vv-name="v.dienst" prepend-icon="mdi-account-outline"  return-object
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.KomList"
                                                      :rules="rules"
                                                      item-text='text'
                                                      item-value='kom'
                                                      v-model="v.kom" 
                                                      :readonly="v.meister != $store.state.auth.user.displayName || formreadonly == true"
                                                      v-on:change="fieldUpdate('MELDUNG', vindex, v.id)"
                                                      label="Kom" data-vv-name="v.kom" prepend-icon="mdi-bus-side"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-select
                                                      v-model="v.grund"
                                                      :items= "meldungverkehrsabteilung"
                                                      :menu-props="{ maxHeight: '400' }"
                                                      label="Grund"
                                                      prepend-icon="mdi-tooltip-text-outline"
                                                      hint="Bitte den Grund ausw&auml;hlen"
                                                      persistent-hint
                                                      :readonly="v.meister != $store.state.auth.user.displayName || formreadonly == true"
                                                      v-on:change="fieldUpdate('MELDUNG', vindex, v.id)"
                                                  ></v-select>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit'"
                                                      v-bind:field="v.zeit" 
                                                      v-bind:modal="v.modal"
                                                      v-bind:i1="vindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="v.meister != $store.state.auth.user.displayName || formreadonly"
                                                      v-bind:typ="'meldungverkehrsabteilungzeit'"
                                                      v-bind:collection="'meldungverkehrsabteilung'"
                                                      v-bind:dataid="v.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col sm="6" offset-md="2">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                      prepend-icon="mdi-message-text-outline"
                                                      :clearable="v.meister == $store.state.auth.user.displayName"
                                                      auto-grow
                                                      clear-icon="mdi-cancel"
                                                      label="Bemerkung"
                                                      :readonly="v.meister != $store.state.auth.user.displayName || formreadonly == true"
                                                      placeholder="Bemerkung" v-model="v.bemerkung"
                                                      v-on:change="fieldUpdate('MELDUNG', vindex, v.id)"
                                                  ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-text-field
                                                  v-model="v.meister"
                                                  label="Name"
                                                  prepend-icon="mdi-clock-outline"
                                                  readonly
                                              ></v-text-field>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" :disabled="v.meister != $store.state.auth.user.displayName" @click.native="delSubForm(v.id, 'meldungverkehrsabteilung')">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                              <v-btn icon light @click.native="addNewMeldungVerkehrsabteilungForm(0)" :disabled="formreadonly">
                                  <v-icon color="grey darken-2">mdi-plus</v-icon>
                              </v-btn>
                          </v-card>
                      </v-tab-item>
<!--**********  Einsatz und abzug von Bussen *******************************************************************************************-->
                      <v-tab-item :key="5" :value="'tabbox-5'" width='100%' >
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Meldung &uuml;ber au&szlig;erplanm&auml;&szlig;igen Einsatz und Abzug von Gelenkbussen</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewEuAvonGelenkbusForm(0)" :disabled="formreadonly">
                                      <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(g, gindex) in $store.state.zbl.euavongelenkbus" :key="g.id">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.KomList"
                                                      :rules="rules"
                                                      item-text='text'
                                                      item-value='kom'
                                                      v-model="g.kom" 
                                                      v-on:change="fieldUpdate('GELENKBUS', gindex, g.id)"
                                                      :readonly="formreadonly"
                                                      label="Kom" data-vv-name="g.kom" prepend-icon="mdi-bus-side"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.FahrerList"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="g.dienst" 
                                                      v-on:change="fieldUpdate('GELENKBUS', gindex, g.id)"
                                                      :readonly="formreadonly"
                                                      label="Dienst" data-vv-name="g.dienst" prepend-icon="mdi-account-outline"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'von'"
                                                      v-bind:field="g.von" 
                                                      v-bind:modal="g.modal1"
                                                      v-bind:i1="gindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'euavongelenkbusvon'"
                                                      v-bind:collection="'euavongelenkbus'"
                                                      v-bind:dataid="g.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'bis'"
                                                      v-bind:field="g.bis" 
                                                      v-bind:modal="g.modal2"
                                                      v-bind:i1="gindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'euavongelenkbusbis'"
                                                      v-bind:collection="'euavongelenkbus'"
                                                      v-bind:dataid="g.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-select
                                                      v-model="g.typ"
                                                      :items= "['Einsatz', 'Abzug']"
                                                      :menu-props="{ maxHeight: '400' }"
                                                      label="Einsatz / Abzug"
                                                      prepend-icon="mdi-tooltip-text-outline"
                                                      hint="Bitte ausw&auml;hlen"
                                                      persistent-hint
                                                      v-on:change="fieldUpdate('GELENKBUS', gindex, g.id)"
                                                      :readonly="formreadonly"
                                                  ></v-select>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delSubForm(g.id, 'euavongelenkbus')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                              <v-toolbar flat>
                                  <v-toolbar-title>Zus&auml;tzliche au&szlig;erplanm&auml;&szlig;ige Einsatzwagen</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewZuAuEinsatzwagenForm(0)" :disabled="formreadonly">
                                      <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(e, eindex) in $store.state.zbl.zuaueinsatzwagen" :key="e.id">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.busline.itemdata"
                                                      item-text='line'
                                                      item-value='line'
                                                      v-model="e.linie" 
                                                      v-on:change="fieldUpdate('WAGENZUS', eindex, e.id)"
                                                      :readonly="formreadonly"
                                                      label="Linie" data-vv-name="e.linie" prepend-icon="mdi-account-outline"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit'"
                                                      v-bind:field="e.zeit" 
                                                      v-bind:modal="e.modal"
                                                      v-bind:i1="eindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'zuaueinsatzwagenzeit'"
                                                      v-bind:collection="'zuaueinsatzwagen'"
                                                      v-bind:dataid="e.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="4">
                                              <v-card tile flat>
                                                  <v-text-field
                                                      v-model="e.fahrtvon"
                                                      label="Fahrt von"
                                                      prepend-icon="mdi-tooltip-text-outline"
                                                      :readonly="formreadonly"
                                                      v-on:change="fieldUpdate('WAGENZUS', eindex, e.id)"
                                                  ></v-text-field>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="4">
                                              <v-card tile flat>
                                                  <v-text-field
                                                      v-model="e.fahrtnach"
                                                      label="Fahrt nach"
                                                      prepend-icon="mdi-tooltip-text-outline"
                                                      :readonly="formreadonly"
                                                      v-on:change="fieldUpdate('WAGENZUS', eindex, e.id)"
                                                  ></v-text-field>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col sm="2" offset-sm="1">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.KomList"
                                                      :rules="rules"
                                                      item-text='text'
                                                      item-value='kom'
                                                      v-model="e.kom" 
                                                      v-on:change="fieldUpdate('WAGENZUS', eindex, e.id)"
                                                      :readonly="formreadonly"
                                                      label="Wagen" data-vv-name="e.kom" prepend-icon="mdi-bus-side"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.FahrerList"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="e.dienst" 
                                                      v-on:change="fieldUpdate('WAGENZUS', eindex, e.id)"
                                                      :readonly="formreadonly"
                                                      label="Dienst" data-vv-name="e.dienst" prepend-icon="mdi-account-outline"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.FahrerList"
                                                      item-text='name'
                                                      item-value='Personnel_nr'
                                                      v-model="e.fahrer" 
                                                      v-on:change="fieldUpdate('WAGENZUS', eindex, e.id)"
                                                      :readonly="formreadonly"
                                                      label="Fahrer" data-vv-name="e.fahrer" prepend-icon="mdi-account-outline" return-object
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-select
                                                      v-model="e.grund"
                                                      :items= "zuaueinsatzwagen"
                                                      :menu-props="{ maxHeight: '400' }"
                                                      label="Grund"
                                                      prepend-icon="mdi-tooltip-text-outline"
                                                      hint="Bitte ausw&auml;hlen"
                                                      v-on:change="fieldUpdate('WAGENZUS', eindex, e.id)"
                                                      :readonly="formreadonly"
                                                      persistent-hint
                                                  ></v-select>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delSubForm(e.id, 'zuaueinsatzwagen')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
                      </v-tab-item>
<!--********** Ausgewechselte Wagen *******************************************************************************************-->
                      <v-tab-item :key="6" :value="'tabbox-6'" width='100%' >
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Ausgewechselte Wagen</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewAusgwechselteWagenForm(0)" :disabled="formreadonly">
                                      <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(a, aindex) in $store.state.zbl.ausgewechseltewagen" :key="a.id">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-text class="headline mb-1">{{aindex+1}}.</v-card-text>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.KomList"
                                                      :rules="rules"
                                                      item-text='text'
                                                      item-value='kom'
                                                      v-model="a.kom" 
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                      :readonly="formreadonly"
                                                      label="Wagen" data-vv-name="a.kom" prepend-icon="mdi-bus-side"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.FahrerList"
                                                      :rules="rules"
                                                      item-text='Service_nr'
                                                      item-value='Service_nr'
                                                      v-model="a.dienst" 
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                      :readonly="formreadonly"
                                                      label="Dienst" data-vv-name="a.dienst" prepend-icon="mdi-account-outline"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.FahrerList"
                                                      item-text='name'
                                                      item-value='Personnel_nr'
                                                      v-model="a.fahrer" 
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                      :readonly="formreadonly"
                                                      label="Fahrer" data-vv-name="a.fahrer" prepend-icon="mdi-account-outline" return-object
                                                  >
                                                  </v-autocomplete>
                                                  <!--<v-text-field prepend-icon="mdi-clock-outline" label="Verkehrsmeister" v-model="platz1.name"></v-text-field>-->
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.state.busline.itemdata"
                                                      :rules="rules"
                                                      item-text='line'
                                                      item-value='line'
                                                      v-model="a.linie" 
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                      :readonly="formreadonly"
                                                      label="Linie" data-vv-name="a.linie" prepend-icon="mdi-account-outline"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit'"
                                                      v-bind:field="a.zeit" 
                                                      v-bind:modal="a.modal"
                                                      v-bind:i1="aindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'wagenwechselzeit'"
                                                      v-bind:collection="'ausgewechseltewagen'"
                                                      v-bind:dataid="a.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col sm="2" offset-md="1">
                                              <v-card tile flat>
                                                  <v-select
                                                      v-model="a.ausfall"
                                                      :items= "ausfallart"
                                                      :menu-props="{ maxHeight: '400' }"
                                                      label="Art"
                                                      prepend-icon="mdi-tooltip-text-outline"
                                                      hint="Bitte die Art ausw&auml;len"
                                                      persistent-hint
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                      :readonly="formreadonly"
                                                  ></v-select>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.KomList"
                                                      :rules="rules"
                                                      item-text='text'
                                                      item-value='kom'
                                                      v-model="a.ekom" 
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                      :readonly="formreadonly"
                                                      label="Ersatz Wagen" data-vv-name="a.ekom" prepend-icon="mdi-bus-side"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="3">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.FahrerList"
                                                      item-text='name'
                                                      item-value='Personnel_nr'
                                                      v-model="a.efahrer" 
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                      :readonly="formreadonly"
                                                      label="Ersatz Fahrer" data-vv-name="a.efahrer" prepend-icon="mdi-account-outline" return-object
                                                  >
                                                  </v-autocomplete>
                                                  <!--<v-text-field prepend-icon="mdi-clock-outline" label="Verkehrsmeister" v-model="platz1.name"></v-text-field>-->
                                              </v-card>
                                          </v-col>
                                          <v-col sm="4">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                      prepend-icon="mdi-message-text-outline"
                                                      auto-grow
                                                      clear-icon="mdi-cancel"
                                                      label="Grund der Auswechslung"
                                                      v-model="a.grund"
                                                      :readonly="formreadonly"
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                  ></v-textarea>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col sm="3" offset-md="1">
                                              <v-card tile flat>
                                                  <v-select
                                                      v-model="a.kennziffer"
                                                      :items= "kennziffern"
                                                      :menu-props="{ maxHeight: '400' }"
                                                      label="Kennziffer"
                                                      prepend-icon="mdi-tooltip-text-outline"
                                                      hint=""
                                                      persistent-hint
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                      :readonly="formreadonly"
                                                  ></v-select>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="7">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                      prepend-icon="mdi-message-text-outline"
                                                      auto-grow
                                                      clear-icon="mdi-cancel"
                                                      label="Erleuterungen"
                                                      v-model="a.erleuterung"
                                                      :readonly="formreadonly"
                                                      v-on:change="fieldUpdate('WAGENAUS', aindex, a.id)"
                                                  ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delSubForm(a.id, 'ausgewechseltewagen')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
                      </v-tab-item>
<!--********** Ausgabe Reseve VDT *******************************************************************************************-->
                      <v-tab-item :key="7" :value="'tabbox-7'" width='100%' >
                          <v-card width='100%'>
                              <v-toolbar flat>
                                  <v-toolbar-title>Ausgabe VDT</v-toolbar-title>
                                  <v-spacer></v-spacer>
                                  <v-btn icon light @click.native="addNewGeraetwechselForm(0)" :disabled="formreadonly">
                                      <v-icon color="grey darken-2">mdi-plus</v-icon>
                                  </v-btn>
                              </v-toolbar>
                              <v-row v-for="(g, gindex) in $store.state.zbl.geraetwechsel" :key="g.id">
                                  <v-col md="12">
                                      <v-row>
                                          <v-col sm="2">
                                              <v-card tile flat>
                                                  <timepicker 
                                                      v-bind:label="'Zeit'"
                                                      v-bind:field="g.zeit" 
                                                      v-bind:modal="g.modal"
                                                      v-bind:i1="gindex"
                                                      v-bind:i2=0
                                                      v-bind:i3=0
                                                      v-bind:ro="formreadonly"
                                                      v-bind:typ="'geraetwechselzeit'"
                                                      v-bind:collection="'geraetwechsel'"
                                                      v-bind:dataid="g.id"
                                                  ></timepicker>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="5">
                                              <v-card tile flat>
                                                  <v-text-field 
                                                      v-model="g.altgeraet"
                                                      label="VDT-Nr" 
                                                      prepend-icon="mdi-application-export"
                                                      :readonly="formreadonly"
                                                      v-on:change="fieldUpdate('GERAETE', gindex, g.id)"
                                                  >
                                                  </v-text-field>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="5">
                                              <v-card tile flat>
                                                  <v-autocomplete
                                                      :items="$store.getters.FahrerList"
                                                      :rules="rules"
                                                      item-text='pnd'
                                                      item-value='Personnel_nr'
                                                      v-model="g.dienst" 
                                                      label="Persnr / Fahrer / Dienst" 
                                                      data-vv-name="g.dienst" 
                                                      prepend-icon="mdi-account-outline"  return-object
                                                      v-on:change="fieldUpdate('GERAETE', gindex, g.id)"
                                                      :readonly="formreadonly"
                                                  >
                                                  </v-autocomplete>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                      <v-row>
                                          <v-col sm="10" offset-md="1">
                                              <v-card tile flat>
                                                  <v-textarea
                                                      rows="1"
                                                          prepend-icon="mdi-message-text-outline"
                                                          auto-grow
                                                          clear-icon="mdi-cancel"
                                                          label="Grund der Ausgabe"
                                                          placeholder="Grund der Ausgabe" v-model="g.stoerung"
                                                          :readonly="formreadonly"
                                                          v-on:change="fieldUpdate('GERAETE', gindex, g.id)"
                                                      ></v-textarea>
                                              </v-card>
                                          </v-col>
                                          <v-col sm="1">
                                              <v-card tile flat>
                                                  <v-card-actions class="justify-center">
                                                      <v-btn icon color="green" @click.native="delSubForm(g.id, 'geraetwechsel')" :disabled="formreadonly">
                                                          <v-icon>mdi-delete-outline</v-icon>
                                                      </v-btn>
                                                  </v-card-actions>
                                              </v-card>
                                          </v-col>
                                      </v-row>
                                  </v-col>
                              </v-row>
                          </v-card>
                      </v-tab-item>
                  </v-tabs-items>
              </v-layout>
          </v-card-text>
      </v-card>
    </form>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import { debounce } from 'debounce';
import timepicker from "@/components/app/ZBLTimePicker";

export default {
    name: "Form ZBL-Tagesbericht",
    props: ['readonly'],

    data() {
        return {
            isLoading: false,
            isTruncated: this.$vuetify.breakpoint.mobile,
            date: null,
            tabbox: null,
            VMs: [],
            formreadonly: false,
            wetterzeit: ["04:00","08:00","12:00","16:00","20:00","00:00"],
            ausfallart: ["verspätete Fahrt","ausgefallene Fahrt"],
            kennziffern: ["1 Unfall","2 Verschleiss","3 Konstr. Fehler","4 Materialfehler","5 Bedienungsfehler","6 Einbaufehler","7 Unterhaltung"],
            zuaueinsatzwagen: ["Überbesetzung","Ausfall Gelenkzug","Wagenausfall","sonstige Störung"],
            meldungverkehrsabteilung: ['Einweisungen', 'verspätete Dienstaufnahme', 'Dienstvorlage fehlt', 'Ablösung vom Dienst',
                                    'Krankmeldung ', 'Umleitung nicht beachtet', 'falsche Ausfahrtsstrecke', 'Planstrecke verlassen',
                                    'Haltestelle nicht bedient', 'falsche Haltestelle bedient', 'Verständigungsprobleme',
                                    'Einfahrtskontrolle', 'Fehlendes Fahrpersonal', 'Telefonieren während der Fahrt',
                                    'Nichtbeachten der Dienstvorlage', 'Fahrerlaubnis nicht mitgeführt/abgelaufen','Dienstliches Fehlverhalten Personal',
                                    'Stellungnahme Dienstliches Fehlverhalten', 'Gesprächsvermerk Personal', 'Dienstbesetzung', 'Sonstiges'],
        };
    },
    created: function () {
        this.$store.dispatch("attachVehiclesOnSnapshot");
    },
    computed: {
        
    },
    watch: {
        "$store.state.auth.isAuthenticating": {
            handler: function (isAuthenticating) {
                if(this.readonly === 'true') {
                    this.formreadonly = true;
                } else {
                    this.formreadonly = false;
                }
                if (isAuthenticating) {
                    if(this.date == null) {
                        this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
                        //this.date = DateTime.now().setLocale('de').plus({ days: -1 }).toFormat("yyyy-MM-dd");
                    }
                    this.$store.dispatch("attachZBLDayReport", { date: this.date} ).then(() => {
                        this.$store.dispatch("attachDayrosterAll", { date: this.date} )
                        this.$store.dispatch("attachBusLineOnSnapshotoffline", {  data: null } );
                        this.$store.dispatch("attachBusStationOnSnapshotoffline");
                    });
                }
            },
            immediate: true,
        },
    },
    methods: {
        async updateFirebase(e, index, id) {
            switch(e) {
                case 'ZBL': {
                    this.$store.dispatch("updateZBLDayReport", { docid: this.date + "_1"} );
                    break;
                }
                case 'FAHRTAUSFALL': {
                    this.$store.dispatch("updateZBLDaySubReport", { docid: this.date + "_1", 
                                                                    collection: "fahrtausfall",
                                                                    subdocid: id,
                                                                    index: index,
                                                                    date: this.date } );
                    break;
                }
                case 'TAXI': {
                    this.$store.dispatch("updateZBLDaySubReport", { docid: this.date + "_1", 
                                                                    collection: "taxibestellung",
                                                                    subdocid: id,
                                                                    index: index,
                                                                    date: this.date } );
                    break;
                }
                case 'SAMMEL': {
                    this.$store.dispatch("updateZBLDaySubReport", { docid: this.date + "_1", 
                                                                    collection: "sammeltaxi",
                                                                    subdocid: id,
                                                                    index: index,
                                                                    date: this.date } );
                    break;
                }
                case 'MELDUNG': {
                    this.$store.dispatch("updateZBLDaySubReport", { docid: this.date + "_1", 
                                                                    collection: "meldungverkehrsabteilung",
                                                                    subdocid: id,
                                                                    index: index,
                                                                    date: this.date } );
                    break;
                }
                case 'GELENKBUS': {
                    this.$store.dispatch("updateZBLDaySubReport", { docid: this.date + "_1", 
                                                                    collection: "euavongelenkbus",
                                                                    subdocid: id,
                                                                    index: index,
                                                                    date: this.date } );
                    break;
                }
                case 'WAGENZUS': {
                    this.$store.dispatch("updateZBLDaySubReport", { docid: this.date + "_1", 
                                                                    collection: "zuaueinsatzwagen",
                                                                    subdocid: id,
                                                                    index: index,
                                                                    date: this.date } );
                    break;
                }
                case 'WAGENAUS': {
                    this.$store.dispatch("updateZBLDaySubReport", { docid: this.date + "_1", 
                                                                    collection: "ausgewechseltewagen",
                                                                    subdocid: id,
                                                                    index: index,
                                                                    date: this.date } );
                    break;
                }
                case 'GERAETE': {
                    this.$store.dispatch("updateZBLDaySubReport", { docid: this.date + "_1", 
                                                                    collection: "geraetwechsel",
                                                                    subdocid: id,
                                                                    index: index,
                                                                    date: this.date } );
                    break;
                }
            } 
        },

        fieldUpdate(e, index, id) {
            console.log("Update:", e, index, id)
            this.debouncedUpdate(e, index, id);
        },
    
        debouncedUpdate: debounce(function(e, index, id) {
            if( this.formreadonly == false) {
                this.updateFirebase(e, index, id)
            }
        }, 70), //500

        async deleteFirebase(e, id) {
//            console.log("Delete:", e, id)
            this.$store.dispatch("deleteZBLDaySubReport", { docid: this.date + "_1", 
                                                            collection: e,
                                                            subdocid: id } );
        },

        debouncedDelete: debounce(function(e, id) {
            this.deleteFirebase(e, id)
        }, 500),

        TBlastDay() {
            console.log("Vortag");
            if (this.$store.state.auth.isAuthenticating) {
                this.$store.dispatch("detachZBLDayReportOnSnapshot");
                this.$store.dispatch("detachDayrosterAll");
                this.$store.dispatch("detachBusLineOnSnapshot");
                this.date = DateTime.fromFormat(this.date, "yyyy-MM-dd").setLocale('de').plus({ days: -1 }).toFormat("yyyy-MM-dd");
                this.$store.dispatch("attachZBLDayReport", { date: this.date} ).then(() => {
                    this.$store.dispatch("attachDayrosterAll", { date: this.date} )
                    this.$store.dispatch("attachBusLineOnSnapshotoffline", {  data: null } );
                    this.$store.dispatch("attachBusStationOnSnapshotoffline");
                });
            }
        },
         TBAktDay() {
            if (this.$store.state.auth.isAuthenticating) {
                this.$store.dispatch("detachZBLDayReportOnSnapshot");
                this.$store.dispatch("detachDayrosterAll");
                this.$store.dispatch("detachBusLineOnSnapshot");
                this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
                this.$store.dispatch("attachZBLDayReport", { date: this.date} ).then(() => {
                    this.$store.dispatch("attachDayrosterAll", { date: this.date} )
                    this.$store.dispatch("attachBusLineOnSnapshotoffline", {  data: null } );
                    this.$store.dispatch("attachBusStationOnSnapshotoffline");
                });
            }
        },

        getSessionname() {
            if(this.$store.state.zbl.meldungverkehrsabteilung[0].meister === "") {
                this.$store.state.zbl.meldungverkehrsabteilung[0].meister = this.$store.state.auth.user.displayName;
            }
        },

        addNewPlatz1Form: function(index) {
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'platz1', data: {
                dienst: "",
                name: "",
                modal1: false,
                modal2: false,
                von: null,
                bis: null,
                bemerkung: ""
            }})
            this.debouncedUpdate('ZBL', 0);
        },
        addNewPlatz2Form: function(index) {
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'platz2', data: {
                dienst: "",
                name: "",
                modal1: false,
                modal2: false,
                von: null,
                bis: null,
                bemerkung: ""
            }})
            this.debouncedUpdate('ZBL', 0);
        },
        addNewWetterForm: function(index) {
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'wetter', data: {
                zeit: "",
                modal: false,
                temperatur: 0,
                hydro: 0,
                wetterlage: ""
            }})
            this.debouncedUpdate('ZBL', 0);
        },
        addNewBetriebsablaufForm: function(index) {
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'betriebsablauf', data: {
                dienst: "",
                bericht: ""
            }})
            this.debouncedUpdate('ZBL', 0);
        },
        addNewAnzahlForm: function(index) {
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'anzahl', data: {
                dienst: "",
                anzahlunfall: 0,
                anzahlpersonal: 0,
                anzahlausfall: 0,
                anzahlverspaetung: 0
            }})
            this.debouncedUpdate('ZBL', 0);
        },
        addNewPolizeiForm: function(index) {
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'polizei', data: {
                zeit: null,
                dienst: "",
                meldung: "",
                modal: false
            }})
            this.debouncedUpdate('ZBL', 0);
        },
        addNewStreudienstForm: function(index) {
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'streudienst', data: {
                zeit: null,
                strecke: "",
                modal: false
            }})
            this.debouncedUpdate('ZBL', 0);
        },
        addNewMeldungForm: function(index) {
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'meldung', data: {
                dienst: "",
                meldung: ""
            }})
            this.debouncedUpdate('ZBL', 0);
        },

        addNewFahrtausfallForm: function(index) {
            const _id = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'fahrtausfall', data: {
                id: _id,
                linie: "",
                dienst: "",
                zeit: null,
                zeitbis: null,
                fahrtvon: "",
                fahrtnach: "",
                grund: "",
                bemerkung: ""
            }})
            this.debouncedUpdate("FAHRTAUSFALL", 'NEW', _id);
        },
        addNewTaxibestellungForm: function(index) {
            const _id = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'taxibestellung', data: {
                id: _id,
                dienst: "",
                zeit: null,
                haltestelle: "",
                ankunft: null,
                ort: "",
                bemerkung: ""
            }})
            this.debouncedUpdate("TAXI", 'NEW', _id);
        },
        addNewSammelTaxibestellungForm: function(index) {
            const _id = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'sammeltaxi', data: {
                id: _id,
                abfahrt: null,
                einstieg: "",
                ausstieg: "",
                personen: 0,
                bestellzeit: null,
                bestellperson: ""
            }})
            this.debouncedUpdate("SAMMEL", 'NEW', _id);
        },
        addNewMeldungVerkehrsabteilungForm: function(index) {
            const _id = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'meldungverkehrsabteilung', data: {
                linie: "",
                dienst: "",
                zeit: null,
                fahrer: "",
                kom: "",
                grund: "",
                bemerkung: "",
                meister: this.$store.state.auth.user.displayName,
            }})
            this.debouncedUpdate("MELDUNG", 'NEW', _id);
        },
        addNewEuAvonGelenkbusForm: function(index) {
            const _id = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'euavongelenkbus', data: {
                id: _id,
                kom: "",
                dienst: "",
                modal1: false,
                modal2: false,
                von: null,
                bis: null,
                typ: "",
            }})
            this.debouncedUpdate("MELDUNG", 'NEW', _id);
        },
        addNewZuAuEinsatzwagenForm: function(index) {
            const _id = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'zuaueinsatzwagen', data: {
                id: _id,
                linie: "",
                zeit: null,
                fahrtvon: "",
                fahrtnach: "",
                kom: "",
                dienst: "",
                fahrer: "",
                grund: "",
                modal1: false,
            }})
            this.debouncedUpdate("MELDUNG", 'NEW', _id);
        },
        addNewAusgwechselteWagenForm: function(index) {
            const _id = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'ausgewechseltewagen', data: {
                id: _id,
                lfdnr: 0,
                kom: "",
                dienst: "",
                linie: "",
                fahrer: "",
                zeit: null,
                ort: "",
                ausfall: "",
                ekom: "",
                efahrer: "",
                grund: "",
                kennziffer: "",
                erleuterung: "",
                modal1: false,
            }})
            this.debouncedUpdate("MELDUNG", 'NEW', _id);
        },
        addNewGeraetwechselForm:function(index) {
            const _id = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
            this.$store.commit('addNewFormZBLReport', {index1: index, index2: 0, index3: 0, typ: 'geraetwechsel', data: {
                id: _id,
                typ: "",
                zeit: null,
                neugeraet: "",
                altgeraet: "",
                position:"",
                fahrer: "",
                dienst: "",
                kom: "",
                vermerk: "",
                stoerung: "",
                modal: false,
            }})
            this.debouncedUpdate("MELDUNG", 'NEW', _id);
        },
        delForm: function(index1, index2, index3, typ) {
            this.$store.commit('delFormZBLReport', {index1: index1, index2: index2, index3: index3, typ: typ});
             this.debounced('ZBL', 0);
        },
        delSubForm: function(id, typ) {
            //this.$store.commit('delFormZBLReport', {index1: index1, index2: index2, index3: index3, typ: typ});
            this.debouncedDelete(typ, id);
        },
    },
    components: {
        timepicker,
    },
}

</script>

<style>
    .v-tabs-items {
        width:100%;
    }
    /* Hide Calendar/Clock Icon In Chrome */
    input[type="time"]::-webkit-calendar-picker-indicator { background: none; display:none;}
</style>