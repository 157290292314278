<template>
  <v-container fluid>
    <v-toolbar flat>
      <v-toolbar-title>
        <v-switch
          v-model="showMapView"
          label="zur Kartenansicht wechseln"
          class="mt-5"
        >
        </v-switch>
      </v-toolbar-title>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">
            Are you sure you want to delete this item?
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeDelete">
              Cancel
            </v-btn>
            <v-btn color="blue darken-1" text @click="deleteItemConfirm">
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>

    <GmapMap
      :center="centerCoordinate"
      :zoom="18"
      :options="{
        mapTypeControl: false,
        streetViewControl: false,
      }"
      map-type-id="satellite"
      class="map"
      v-if="showMapView"
      style="width: 100%; height: 775px"
    >
      <GmapMarker
        v-for="(obj, index) in $store.getters.busLocationMarkers"
        :key="'Marker' + index"
        :position="obj.koordinaten"
        :clickable="true"
        :draggable="false"
        @click="handleMarkerClicked(obj)"
      ></GmapMarker>
      <GmapInfoWindow
        :options="infoWindowOptions"
        :position="infoWindowPosition"
        :opened="infoWindowOpened"
        @closeclick="handleInfoWindowClosed"
      >
        <v-card>
          <v-card-text>
            <span
              v-for="(obj, index) in selectedObject.content"
              :key="obj.wagennummer"
            >
              <template v-if="index == 0">{{ obj.wagennummer }} </template>
              <template v-else>- {{ obj.wagennummer }} </template>
            </span>
          </v-card-text>
        </v-card>
      </GmapInfoWindow>
    </GmapMap>

    <v-data-table
      :headers="headers"
      :items="$store.getters.busLocationTable"
      v-else
    >
      <!-- <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      showMapView: false,
      dialogDelete: false,
      centerCoordinate: { lat: 51.184527163181166, lng: 6.449758375657727 },
      headers: [
        {
          text: "Wagennummer",
          align: "start",
          value: "wagennummer",
        },
        { text: "Ort", value: "ort" },
        { text: "Koordinaten", value: "koordinaten" },
        { text: "Typ", value: "typ" },
        { text: "", value: "actions", sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        wagennummer: "",
        ort: "",
        gps: [0, 0],
      },
      defaultItem: {
        wagennummer: "",
        ort: "",
        gps: [0, 0],
      },
      selectedObject: {
        content: [],
      },
      infoWindowOpened: false,
      infoWindowOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },

    showMapView(val) {
      localStorage.setItem("showMapView", val);
    },
  },

  computed: {
    infoWindowPosition() {
      if (this.selectedObject) {
        return this.selectedObject.koordinaten;
      }
      return this.centerCoordinate;
    },
  },

  methods: {
    // editItem(item) {
    //   this.editedIndex = this.desserts.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialog = true;
    // },

    // deleteItem(item) {
    //   this.editedIndex = this.desserts.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // deleteItemConfirm() {
    //   this.desserts.splice(this.editedIndex, 1);
    //   this.closeDelete();
    // },

    handleMarkerClicked(obj) {
      this.selectedObject = obj;
      this.infoWindowOpened = true;
    },

    handleInfoWindowClosed() {
      this.selectedObject = { content: [] };
      this.infoWindowOpened = false;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        console.log("UPDATE ITEM", this.editedItem);
        // Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        // this.desserts.push(this.editedItem);
      }
      this.close();
    },
  },

  created() {
    this.$store.dispatch("attachBusLocationOnSnapshotoffline");
    this.$store.dispatch("attachBusLocationOptionsOnSnapshotoffline");

    if (localStorage.getItem("showMapView")) {
      this.showMapView =
        localStorage.getItem("showMapView") == "true" ? true : false;
    }
  },
};
</script>

<style scoped>
.map {
  width: 100%;
  height: 100%;
}
</style>
