<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>Meldeliste anlegen</v-card-title>
      <v-card-text>
        <v-select
            v-model="docid"
            :items= "docs"
            :menu-props="{ maxHeight: '400' }"
            label="Meldeliste"
            prepend-icon="mdi-tooltip-text-outline"
            hint=""
            persistent-hint
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primaryLight"
          text
          @click="cancleMeldeliste"
          value="CANCELAUSFAHRTMELDEN"
        >
          Abbrechen
        </v-btn>
        <v-btn color="primaryLight" text @click="addbusexitlist">
          Löschen
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  //import firebase from 'firebase/app';
  
  export default {
    data() {
      return {
        isLoading: false,
        dialogVisible: false,
        meldeliste: '',
        docid: '',
        docs: [],
      };
    },
  
    computed: {},
  
    watch: {
      active() {
        this.dialogVisible = true;
        this.busexitlists.forEach(element => {
          this.docs.push(element.docid);
        });
        console.log("Daten: ", this.docs, this.busexitlists);
      },
    },
  
    methods: {
      addbusexitlist() {
        if (this.docid !== '') {
          this.$store.dispatch("deleteBusexitlist", { docid: this.docid });
          this.docid = '';
          this.docs = [];
          this.dialogVisible = false;
        }
      },
      cancleMeldeliste() {
        this.docid = '';
        this.docs = [];
        this.dialogVisible = false;
      },
    },
  
    created() {},
  
    props: {
      active: Number,
      busexitlists: {
        type: Array,
        default: function () {
            return [];
        },
        },
    },
  };
  </script>
  