import { initializeApp } from "firebase/app"; //getApp
import { CACHE_SIZE_UNLIMITED, enableIndexedDbPersistence, getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { persistentLocalCache, persistentMultipleTabManager, enablePersistence } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
import { getAuth, OAuthProvider, onAuthStateChanged, getRedirectResult, signInWithRedirect } from "firebase/auth";

function getFirebaseConf(configKey)
{
  return process.env['VUE_APP_FIREBASE_CONF_' + configKey.toUpperCase()]
}

const firebaseConfig = {
  projectId: getFirebaseConf("project_id"),
  appId: getFirebaseConf("app_id"),
  databaseURL: getFirebaseConf("db_url"),
  storageBucket: getFirebaseConf("storage_bucket"),
  //locationId: "europe-west",
  apiKey: getFirebaseConf("api_key"),
  authDomain: getFirebaseConf("auth_domain"),
  messagingSenderId: getFirebaseConf("msg_sender_id"),
  measurementId: getFirebaseConf("measurement_id"),
  cacheSizeBytes: CACHE_SIZE_UNLIMITED,
  synchronizeTabs: true,
  localCache: persistentLocalCache(/*settings*/{tabManager: persistentMultipleTabManager()}),
  enablePersistence: true,
};

const app = initializeApp(firebaseConfig)  //const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const provider = new OAuthProvider('microsoft.com');
provider.setCustomParameters({
  tenant: '494aa4dd-1c7b-453f-88b1-513bbe6982d0'
});
//provider.addScope('mail.read');
//provider.addScope('calendars.read');

const auth = getAuth(app)

//signInWithRedirect(auth, provider)

const functions = getFunctions(app, 'europe-west1');

let _mode = process.env.VUE_APP_ENV;
console.log("Use Mode: " + _mode + " / " + process.env.VUE_APP_FIREBASE_CONF_PROJECT_ID);
if (process.env.VUE_APP_ENV === 'dev') {
	console.log("Use Emulator");
//  functions.useEmulator(process.env.VUE_APP_EMULATOR_FUNCTIONS_HOST, process.env.VUE_APP_EMULATOR_FUNCTIONS_PORT);
//  firestore.useEmulator(process.env.VUE_APP_EMULATOR_FIRESTORE_HOST, process.env.VUE_APP_EMULATOR_FIRESTORE_PORT);
  auth.useEmulator('http://localhost:9099/');
  connectFunctionsEmulator(functions, process.env.VUE_APP_EMULATOR_FUNCTIONS_HOST, process.env.VUE_APP_EMULATOR_FUNCTIONS_PORT);
  connectFirestoreEmulator(firestore, process.env.VUE_APP_EMULATOR_FIRESTORE_HOST, process.env.VUE_APP_EMULATOR_FIRESTORE_PORT);
} else {
/*  firestore.settings({
    cacheSizeBytes: CACHE_SIZE_UNLIMITED,
    merge: true
  })*/
}
//https://firebase.google.com/docs/firestore/manage-data/enable-offline#web-modular-api
const db = getFirestore(app);
/*enableIndexedDbPersistence(db)
  .catch((err) => {
      if (err.code == 'failed-precondition') {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          // ...
      } else if (err.code == 'unimplemented') {
          // The current browser does not support all of the
          // features required to enable persistence
          // ...
      }
});*/

export { db, app, auth, functions, firestore, provider }

export const worktimeConverter = {
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    data.date = data.date.toDate()
    data.created = data.created.toDate()
    data.updated = data.updated.toDate()
    return data
  }
}

export const processConverter = {
  fromFirestore(snapshot, options) {
    const data = snapshot.data(options);
    return data
  }
}