<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>Meldeliste anlegen</v-card-title>
      <v-card-text>
        <v-select
            v-model="docid"
            :items= "securityincidenttypes"
            :menu-props="{ maxHeight: '400' }"
            label="Sicherheitsrelevante Vorfälle Arten"
            prepend-icon="mdi-tooltip-text-outline"
            hint=""
            persistent-hint
        ></v-select>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primaryLight"
          text
          @click="canclesecurityincidenttype"
          value="CANCELSECURITYINCEDENTTYPE"
        >
          Abbrechen
        </v-btn>
        <v-btn color="primaryLight" text @click="delsecurityincidenttype">
          Löschen
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  //import firebase from 'firebase/app';
  
  export default {
    data() {
      return {
        isLoading: false,
        dialogVisible: false,
        docid: '',
      };
    },
  
    computed: {},
  
    watch: {
      active() {
        this.dialogVisible = true;
      },
    },
  
    methods: {
      delsecurityincidenttype() {
        if (this.docid !== '') {
          this.$store.dispatch("deleteSecurityincidenttype", { docid: this.docid });
          this.docid = '';
          this.dialogVisible = false;
        }
      },
      canclesecurityincidenttype() {
        this.docid = '';
        this.dialogVisible = false;
      },
    },
  
    created() {},
  
    props: {
      active: Number,
      securityincidenttypes: {
        type: Array,
        default: function () {
            return [];
        },
        },
    },
  };
  </script>
  