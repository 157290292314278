<template>
  <v-card>
    <v-card-text class="pl-4">
      <div class="autocomplete">
        <v-text-field
          class="autocomplete-text-field"
          type="text"
          @input="onAutocompleteChange"
          v-model="search"
          @keydown.down="onArrowDown"
          @keydown.up="onArrowUp"
          @keydown.enter="onEnter"
          :placeholder="placeholder"
        />
        <ul
          id="autocomplete-results"
          v-show="isOpen"
          class="autocomplete-results"
        >
          <li
            class="loading"
            v-if="isLoading"
          >
            Loading results...
          </li>
          <li
            v-else
            v-for="(result, i) in results"
            :key="i"
            @click="setResult(result)"
            class="autocomplete-result"
            :class="{ 'is-active': i === arrowCounter }"
          >
            {{ result[itemfield] }}
          </li>
        </ul>
      </div>
      <div id="app">
        <keyboard 
          @onChange="onkeyboardChange" 
          @onKeyPress="onKeyPress" 
          :input="search"/>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import keyboard from "@/components/app/keyboard"

  export default {
    components: {
      keyboard,
    },
    name: 'SearchAutocomplete',
    props: {
      items: {
        type: Array,
        required: false,
        default: () => [],
      },
      isAsync: {
        type: Boolean,
        required: false,
        default: false,
      },
      itemfield: {
        type: Text,
        required: true,
        default: "pnd"
      },
      placeholder: {
        type: String,
        required: false,
        default: ""
      }
    },
    data() {
      return {
        isOpen: false,
        results: [],
        search: '',
        isLoading: false,
        arrowCounter: -1,
        keyboardinput: null,
      };
    },
    watch: {
      items: function (value, oldValue) {
        //console.log(value, oldValue)
        if (value.length !== oldValue.length) {
          this.results = value
          this.isLoading = false
        }
      },
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    },
    methods: {
      onkeyboardChange(input) {
        //console.log("onkeyboardChange", input)
        this.$emit('input', input)
        this.search = input
        this.filterResults();
        this.isOpen = true;
        this.onAutocompleteChange
      },
      onKeyPress(button) {
        //console.log("button", button)
      },
      onInputChange(input) {
        //console.log("onInputChange", input)
        this.search = input.target.value
        this.filterResults();
        this.isOpen = true;
        this.onAutocompleteChange
      },

      setResult(result) {
        this.search = result[this.itemfield]
        this.isOpen = false
        this.$emit('result', result)
        this.search = ""
      },
      filterResults() {
      const _filter = this.duplicates(this.items, it => it[this.itemfield])
      this.results = _filter.filter((item) => {
          return item[this.itemfield].toLowerCase().indexOf(this.search.toLowerCase()) > -1;
        });
      },
      duplicates(data, key) {
        return[
          ...new Map(data.map( x => [key(x), x])).values()
        ]
      },
      onAutocompleteChange() {
        this.$emit('input', this.search)

        if (this.isAsync) {
          this.isLoading = true;
        } else {
          this.filterResults();
          this.isOpen = true;
        }
      },
      handleClickOutside(event) {
        if (!this.$el.contains(event.target)) {
          this.isOpen = false
          this.arrowCounter = -1
        }
      },
      onArrowDown() {
        if (this.arrowCounter < this.results.length) {
          this.arrowCounter = this.arrowCounter + 1
        }
      },
      onArrowUp() {
        if (this.arrowCounter > 0) {
          this.arrowCounter = this.arrowCounter - 1
        }
      },
      onEnter() {
        this.search = this.results[this.arrowCounter][this.itemfield]
        this.isOpen = false
        this.arrowCounter = -1
      },
    },

    created() {
    },
  };
</script>

<style>
  .autocomplete {
    position: relative;
  }

  .autocomplete-text-field {
    font-size: 1.5em;
    color: #9b004b;
  }

  .autocomplete-results {
    font-size: 1.5em;
    padding: 0;
    margin: 0;
    border: 1px solid #eeeeee;
    height: 120px;
    overflow: auto;
    color: #9b004b;
  }

  .autocomplete-result {
    list-style: none;
    text-align: left;
    padding: 4px 2px;
    cursor: pointer;
  }

  .autocomplete-result.is-active,
  .autocomplete-result:hover {
    background-color: #9b004b;
    color: white;
  }
</style>