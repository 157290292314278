import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, setDoc, updateDoc, getDoc, deleteDoc } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    data: [],
    firestoreListener: null,
    isLoading: false,
    isOnline: false,
    lastChange: null,
}

const getters = {}

const mutations = {}

const actions = {
    async insertBusexitlist({ state }, { docid, type }) {
        const docSnap = getDoc(doc(db, "busexitlist", docid));
        if (docSnap.exists) {
            console.log(docSnap.data())
        } else {
            await setDoc(doc(db, "busexitlist", docid), {
                docid: docid,
                name: docid,
                type: type,
                daten: []
            }, { merge: true });
        }
    },

    async deleteBusexitlist({ state }, { docid }) {
        deleteDoc(doc(db, "busexitlist", docid ));
    },

    async updateBusexitlist({ state }, { docid, data}) {
        const docRef = doc(db, "busexitlist", docid);
        await updateDoc(docRef, 
            data
        )
    },

    async attachBusexitlist({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.data);
                return;
            }
            state.isLoading = true;
            const q = query(collection(db, "busexitlist"),
                // where("meldezeit", ">=", zeit),
                // where("gemeldet", "==", false),
                orderBy("docid"));
            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.data = []
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.data.push({ docid: doc.docid, ...doc.data() });
                        }
                        // const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        // console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.data);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
    detachBusexitlist({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
    },
}

export default { state, getters, mutations, actions }
