<template>
    <ZBLreservedienst v-bind:readonly="readonly"></ZBLreservedienst>
</template>

<script>

import ZBLreservedienst from "@/components/app/FormZblreservedienst";

export default {
    name: "ZBL-Reservedienst",
    data() {
        return {
            readonly: false,
        };
    },
    created: function () {
        this.readonly = this.$route.params.readonly;
    },
    computed: {
        
    },
    watch: {

    },
    methods: {

    },
    mounted() {
        this.$store.state.auth.withMenu = true;
//        this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
    },
    beforeDestroy() {
        this.$store.dispatch("detachRESBusexit");
//        this.$store.dispatch("detachDayrosterAll");
//        this.$store.dispatch("detachBusLineOnSnapshot");
//        this.$store.dispatch("detachBusStationOnSnapshot");
        this.$route.params.date = null;
    },
    components: {
        ZBLreservedienst,
    },
};
</script>
