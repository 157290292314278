import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, updateDoc, getDocs } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    data: [],
    busexitpersnrdata: [],
    busexitdata: [],
    busentrancedata: [],
    overduedata: [],
    donedata: [],
    countdata: [],
    firestoreListener: null,
    firestoreBusExitListener: null,
    firestoreBusEntranceListener: null,
    firestoreBusExitPersnrListener: null,
    firestoreCountListener: null,
    firestoreOverdueListener: null,
    firestoreDoneListener: null,
    isLoading: false,
    EntranceisLoading: false,
    CountisLoading: false,
    isOnline: false,
    EntranceisOnline: false,
    CountisOnline: false,
    lastChange: null,
    EntrancelastChange: null,
    personalTask: {}
}

const getters = {
    getEntranceData: (state) => (anwesendmeldung, statedata) => {
        //console.log("Entrance:", anwesendmeldung)
        return statedata.filter(data => data.anwesendmeldung == anwesendmeldung)
            .sort((a, b) => {
                const anfangA = a.ende.toUpperCase();
                const anfangB = b.ende.toUpperCase();
                if (anfangA < anfangB) {
                    return -1;
                }
                if (anfangA > anfangB) {
                    return 1;
                }
                return 0;
            })
    },

    getExitData: (state) => (timediff, specialServices, statedata) => {
        //console.log("Exit", statedata)
        //return state.busexitdata.filter(data => data.anfang > timediff &&
        return statedata.filter(data => data.meldezeit > timediff && !data.nichtBesetzt &&
            !data.gemeldet && !specialServices.includes(data.linie))
            .sort((a, b) => {
                const anfangA = a.meldezeit.toUpperCase();
                const anfangB = b.meldezeit.toUpperCase();
                if (anfangA < anfangB) {
                    return -1;
                }
                if (anfangA > anfangB) {
                    return 1;
                }
                return 0;
            })
    },

    getOverdueData: (state) => (timediff, akthour, specialServices, statedata) => {
        //console.log("Overdue", statedata)
        return statedata.filter(data => data.anfang <= timediff && !data.nichtBesetzt &&
            data.meldezeit <= akthour && !data.gemeldet && !specialServices.includes(data.linie))
            .sort((a, b) => {
                const anfangA = a.anfang.toUpperCase();
                const anfangB = b.anfang.toUpperCase();
                if (anfangA < anfangB) {
                    return 1;
                }
                if (anfangA > anfangB) {
                    return -1;
                }
                return 0;
            })
    },

    getUnmannedData: (state) => (timediff, akthour, specialServices, statedata) => {
        //console.log("getUnmanned", statedata)
        return statedata.filter(data => data.nichtBesetzt&& !data.gemeldet && 
            !specialServices.includes(data.linie))
            .sort((a, b) => {
                const anfangA = a.anfang.toUpperCase();
                const anfangB = b.anfang.toUpperCase();
                if (anfangA < anfangB) {
                    return -1;
                }
                if (anfangA > anfangB) {
                    return 1;
                }
                return 0;
            })
    },

    getSpecialData: (state) => (type) => {
        return state.data.filter(data => data.linie == type)
            .sort((a, b) => {
                const anfangA = a.anfang.toUpperCase();
                const anfangB = b.anfang.toUpperCase();
                if (anfangA < anfangB) {
                    return -1;
                }
                if (anfangA > anfangB) {
                    return 1;
                }
                return 0;
            })
    },
    
    getDoneData: (state) => (specialServices, statedata) => {
        return statedata.filter(data => data.gemeldet && data.gemeldet_um && !specialServices.includes(data.linie))
            .sort((a, b) => {
                const anfangA = a.anfang.toUpperCase();
                const anfangB = b.anfang.toUpperCase();
                if (anfangA < anfangB) {
                    return -1;
                }
                if (anfangA > anfangB) {
                    return 1;
                }
                return 0;
            })
    },

    getReserveWG(state) {
        return state.data.filter(data => data.reserve_wg == true)
            .sort((a, b) => {
                const anfangA = a.anfang.toUpperCase();
                const anfangB = b.anfang.toUpperCase();
                if (anfangA < anfangB) {
                    return -1;
                }
                if (anfangA > anfangB) {
                    return 1;
                }
                return 0;
            })
    },
    getPersonalTasks(state, _, rootState) {
        return state.busexitpersnrdata.filter(data => data.personnel_nr == rootState.auth.personnelnr)
            .sort((a, b) => {
                const anfangA = a.anfang.toUpperCase();
                const anfangB = b.anfang.toUpperCase();
                if (anfangA < anfangB) {
                    return -1;
                }
                if (anfangA > anfangB) {
                    return 1;
                }
                return 0;
            })

        // const personalTask = personalTasks.find(data => !data.gemeldet)

        // if (personalTask) {
        //     return personalTask
        // } else {
        //     return personalTasks[personalTasks.length - 1]
        // }
    },

    getBusexitServicenr: (state) => (servicenr) => {
        return state.data.filter(data => data.dienst == servicenr)
    },

    getPersonalManuelleTasks: (state) => (personnelnr, time) => {
        //console.log(personnelnr)
        if (time === 'alle') {
            return state.busexitdata.filter(data => data.personnel_nr == personnelnr)
                .sort((a, b) => {
                    const anfangA = a.anfang.toUpperCase();
                    const anfangB = b.anfang.toUpperCase();
                    if (anfangA < anfangB) {
                        return -1;
                    }
                    if (anfangA > anfangB) {
                        return 1;
                    }
                    return 0;
                })
        } else {
            return state.busexitdata.filter(data => data.personnel_nr == personnelnr && data.anfang >= "00:00" )
                .sort((a, b) => {
                    const anfangA = a.anfang.toUpperCase();
                    const anfangB = b.anfang.toUpperCase();
                    if (anfangA < anfangB) {
                        return -1;
                    }
                    if (anfangA > anfangB) {
                        return 1;
                    }
                    return 0;
                })
        }
    },

    getDriverData(state) {
        return state.data.filter(data => data.personnel_nr != '')
            .sort((a, b) => {
                const fahrerA = a.fahrer.toUpperCase();
                const fahrerB = b.fahrer.toUpperCase();
                if (fahrerA < fahrerB) {
                    return -1;
                }
                if (fahrerA > fahrerB) {
                    return 1;
                }
                return 0;
            })
    },

    getBusexitFahrerList(state, rootState) {
        const arr = state.busexitdata
            .filter(busexit =>
                busexit.personnel_nr != null)
            .map(busexit => {
                let _persnr = busexit?.personnel_nr || "0"
                if (_persnr === "" ){
                    _persnr = "0"
                }
                let _dienst = busexit?.dienst || ""
                if (("neuFahrer" in busexit)) {
                    return {
                        name: busexit.neuFahrer.trim(),
                        Service_nr: _dienst,
                        Personnel_nr: _persnr,
                        nd: busexit.neuFahrer.trim() + ' ' +busexit.dienst,
                        pnd: busexit.personnel_nr + ' ' + busexit.neuFahrer.trim() + ' ' +busexit.dienst + ' ' +busexit.info
                    }
                } else {
                    return {
                        name: busexit.fahrer.trim(),
                        Service_nr: _dienst,
                        Personnel_nr: _persnr,
                        nd: busexit.fahrer.trim() + ' ' +busexit.dienst,
                        pnd: busexit.personnel_nr + ' ' + busexit.fahrer.trim() + ' ' +busexit.dienst + ' ' +busexit.info
                    }
                }
            })
            .sort((a, b) => {
                const servicenrA = a.name.toUpperCase();
                const servicenrB = b.name.toUpperCase();
                if (servicenrA < servicenrB) {
                    return -1;
                }
                if (servicenrA > servicenrB) {
                    return 1;
                }
                return 0;
            })
            //console.log(arr)
        return [...new Set(arr)]
    },
    getAktBusExitCount(state) {
        const _time = DateTime.now().setLocale('de').toFormat("HH:mm");
        console.log(state.countdata)
        return state.countdata.filter(data => data.from <= _time && data.to > _time)
                .sort((a, b) => {
                    const sortA = a.to.toUpperCase();
                    const sortB = b.to.toUpperCase();
                    if (sortA < sortB) {
                        return -1;
                    }
                    if (sortA > sortB) {
                        return 1;
                    }
                    return 0;
                })
    }
}

const mutations = {

}

const actions = {
    async updateBusExit({ state, commit }, { date, docid, data }) {
        //console.log(date, docid, data);
        const docRef = doc(db, "busexit", date, "ausfahrt", docid);
        try {
            await updateDoc(docRef,
                data
            )
            // commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        } catch (error) {
            this.$store.commit("showSnackbar", {
                status: "error",
                message: error.message,
            });
        }
    },
    attachBusExitGroupCounts({ state, _, rootState }, { date }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreCountListener) {
                resolve(state.countdata);
                return;
            }
            state.CountisLoading = true;
            let _bet = [];
            const q = query(collection(db, "busexit", date, "ausfahrt"),
                orderBy("meldezeit"));
            state.firestoreCountListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    //console.log("BUSEXITTYPES: ", rootState.settings.busexittypes);
                    let sbsflg = false;
                    _bet.splice(0, _bet.length);
                    for (const _bt of rootState.settings.busexittypes) {
                        if (_bt.id == 'sbs') {
                            sbsflg = true;
                        }
                        _bet.push({SL: 0, gSL: 0, GB: 0, gGB: 0, ESLMB: 0, gESLMB: 0,
                                ESLBYD: 0, gESLBYD: 0, EGB: 0, gEGB: 0, 
                                id: _bt.id, from: _bt.timefrom, to: _bt.timeto, text: _bt.text});
                    }
                    state.countdata.splice(0, state.countdata.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            const _doc = doc.data()
                            let neflg = false
                            if (Array.isArray(_doc.hinweis.match(/NE[1-9]/g)) == true || 
                                Array.isArray(_doc.hinweis.match(/NE [1-9]/g)) == true) {
                                neflg = true;
                            }
                            for(const _b of _bet) {
                                if(_b.from <= _doc.anfang && _b.to > _doc.anfang) {
                                    if((_b.id == "sbs" && _doc.sbs == true && neflg == false) || 
                                        (_b.id != "sbs" && _doc.sbs == false && neflg == false) || 
                                        (_b.id == "nachtexpress" && neflg == true) ||
                                        (sbsflg == false && neflg == false)) {
                                        switch (_doc.fahrzeug) {
                                            case "SL":
                                            case "SLz":
                                                if (_doc.gemeldet == true) {
                                                    if (_doc.hinweis == 'E-Bus') {
                                                        _b.gESLMB += 1;
                                                    } else {
                                                        _b.gSL += 1;
                                                    }
                                                }
                                                if (_doc.hinweis == 'E-Bus') {
                                                    _b.ESLMB += 1;
                                                } else {
                                                    _b.SL += 1;
                                                }
                                                break;
                                            case "ESL":
                                                if (_doc.gemeldet == true) {
                                                    if (_doc.hinweis == 'MB') {
                                                        _b.gESLMB += 1;
                                                    } else if (_doc.hinweis == 'BYD') {
                                                        _b.gESLBYD += 1;
                                                    }
                                                }
                                                if (_doc.hinweis == 'MB') {
                                                    _b.ESLMB += 1;
                                                } else if (_doc.hinweis == 'BYD') {
                                                    _b.ESLBYD += 1;
                                                }
                                                break;
                                            case "GB":
                                            case "GBz":
                                                if (_doc.gemeldet == true) {
                                                    if (_doc.hinweis == 'E-Bus') {
                                                        _b.gEGB += 1;
                                                    } else {
                                                        _b.gGB += 1;
                                                    }
                                                }
                                                if (_doc.hinweis == 'E-Bus') {
                                                    _b.EGB += 1;
                                                } else {
                                                    _b.GB += 1;
                                                }
                                                break;
                                            default:
                                                break;
                                        }
                                        break;
                                    }
                                }
                            }
                        }
                        if (snapshot.metadata.fromCache) {
                            state.CountisOnline = false;
                        } else {
                            state.CountisOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                    });
                    for(const _b of _bet) {
                        state.countdata.push(_b);
                    }
//                    console.log("Fahrzeugcount: ", state.countdata);
                    state.CountisLoading = false;
                    resolve(state.countdata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
    attachBusExit({ state }, { date }) {
        return new Promise((resolve, reject) => {
            state.isLoading = true;
            const q = query(collection(db, "busexit", date, "ausfahrt"),
                orderBy("meldezeit"));
            state.firestoreBusExitListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.busexitdata.splice(0, state.busexitdata.length)
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.busexitdata.push({ docid: doc.id, ...doc.data() });
                        }
                        // const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        // console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.busexitdata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
    attachBusExitPersnr({ state }, { date, persnr }) {
        return new Promise((resolve, reject) => {
            state.isLoading = true;
            //console.log("BUSEXIT PersNr:", persnr)
            const q = query(collection(db, "busexit", date, "ausfahrt"),
                where("personnel_nr", "==", persnr),
                orderBy("meldezeit"));
            state.firestoreBusExitPersnrListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.busexitpersnrdata.splice(0, state.busexitpersnrdata.length)
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.busexitpersnrdata.push({ docid: doc.id, ...doc.data() });
                        }
                        // const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        // console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    //console.log("BUSEXIT PersNr:", persnr, state.busexitpersnrdata)
                    resolve(state.busexitpersnrdata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    attachBusExitOnSnapshotoffline({ state }, { date }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.data);
                return;
            }
            state.isLoading = true;
            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, "busexit", date, "ausfahrt"),
                // where("meldezeit", ">=", zeit),
                // where("gemeldet", "==", false),
                orderBy("meldezeit"));
            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.data.splice(0, state.data.length)
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.data.push({ docid: doc.id, ...doc.data() });
                        }
                        // const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        // console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.data);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
    async attachBusOverdueExitOnSnapshotoffline({ state }, { date }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreOverdueListener) {
                resolve(state.overduedata);
                return;
            }
            state.isLoading = true;
            const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, "busexit", date, "ausfahrt"),
                where("meldezeit", "<", zeit),
                where("gemeldet", "==", false),
                orderBy("meldezeit"));
            state.firestoreOverdueListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.overduedata.splice(0, state.overduedata.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            if (doc.data().linie != 'RES') {
                                state.overduedata.push({ docid: doc.id, ...doc.data() });
                            }
                        }
                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        //                        console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.userdata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
    async attachBusDoneExitOnSnapshotoffline({ state }, { date }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreDoneListener) {
                resolve(state.donedata);
                return;
            }
            state.isLoading = true;
            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, "busexit", date, "ausfahrt"),
                where("gemeldet", "==", true),
                orderBy("meldezeit", "desc"));
            state.firestoreDoneListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.donedata.splice(0, state.donedata.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            if (doc.data().linie != 'RES') {
                                state.donedata.push({ docid: doc.id, ...doc.data() });
                            }
                        }
                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        //                        console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.userdata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    attachBusEntrance({ state }, { date }) {
        return new Promise((resolve, reject) => {
            state.EntranceisLoading = true;
            const q = query(collection(db, "busexit", date, "ausfahrt"),
                orderBy("ende"));
            state.firestoreBusEntranceListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.busentrancedata.splice(0, state.busentrancedata.length)
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.busentrancedata.push({ docid: doc.id, ...doc.data() });
                        }
                        // const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.EntranceisOnline = false;
                        } else {
                            state.EntranceisOnline = true;
                            state.EntrancelastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        // console.log("Data came from " + source);
                    });
                    state.EntranceisLoading = false;
                    resolve(state.busentrancedata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachBusentrance({ state }) {
        if (state.firestoreBusEntranceListener) {
            state.firestoreBusEntranceListener();
            state.firestoreBusEntranceListener = null;
        }
    },
    detachBusexit({ state }) {
        if (state.firestoreBusExitListener) {
            state.firestoreBusExitListener();
            state.firestoreBusExitListener = null;
        }
    },
    detachBusexitOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
        if (state.firestoreOverdueListener) {
            state.firestoreOverdueListener();
            state.firestoreOverdueListener = null;
        }
        if (state.firestoreDoneListener) {
            state.firestoreDoneListener();
            state.firestoreDoneListener = null;
        }
    },

    detachBusexitGroupCount({ state }) {
        if (state.firestoreCountListener) {
            state.firestoreCountListener();
            state.firestoreCountListener = null;
        }
    },
}

export default { state, getters, mutations, actions }
