<template>
  <v-container class="my-5">
    <v-data-table
      class="pa-5"
      no-results-text="Keine Ergebnisse gefunden"
      :search="search"
      :headers="headers"
      :header-props="headerProps"
      :footer-props="footerProps"
      :custom-filter="filterTitle"
      :items="filteredNotifications"
    >
      <template v-slot:top>
        <v-row>
          <v-col class="d-flex" cols="12" sm="6">
            <v-text-field v-model="search" label="Suchen" />
          </v-col>
          <v-col class="d-flex" cols="12" sm="6">
            <v-select
              v-model="selectedDuration"
              label="Zeitspanne"
              :items="durationFilter"
            ></v-select>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.createdAt`]="{ item }">
        {{ convertToLocaleDateString(item) }}
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headerProps: {
        sortByText: "Sortieren nach",
      },
      headers: [
        {
          text: "Überschrift",
          align: "start",
          value: "title",
        },
        { text: "Destination", value: "destination" },
        {
          text: "Timestamp",
          value: "createdAt",
        },
        { text: "Inhalt", value: "content" },
      ],
      footerProps: {
        "items-per-page-all-text": "Alle",
        "items-per-page-text": "Benachrichtigungen pro Seite",
      },
      search: "",
      durationFilter: [
        { text: "Letzte 1 Tage", value: "1" },
        { text: "Letzte 7 Tage", value: "7" },
        { text: "Letzte 14 Tage", value: "14" },
        { text: "Alle", value: "all" },
      ],
      selectedDuration: "1",
    };
  },

  methods: {
    convertToLocaleDateString(notification) {
      return notification?.createdAt?.toDate()?.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },

    filterTitle(value, search, item) {
      const lowercaseSearch = search.toLowerCase();
      const lowercaseTitle = item.title.toString().toLowerCase();
      const lowercaseContent = item.content.toString().toLowerCase();
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        (lowercaseTitle.indexOf(lowercaseSearch) !== -1 ||
          lowercaseContent.indexOf(lowercaseSearch) !== -1)
      );
    },
  },

  computed: {
    filteredNotifications() {
      if (this.selectedDuration != "all") {
        const customDuration = new Date(
          Date.now() - parseInt(this.selectedDuration) * 24 * 60 * 60 * 1000
        );
        /*return this.$store.getters.personalNotifications.filter(
          (notification) => {
            return notification.createdAt.toDate() >= customDuration;
          }
        );*/
        return this.$store.state.notifications.notifications.filter(
          (notification) => {
            return notification.createdAt.toDate() >= customDuration;
          }
        );
      }
      //return this.$store.getters.personalNotifications;
      return this.$store.state.notifications.notifications
    },
  },

  created() {
    this.$store.dispatch("attachNotificationsOnSnapshot", {})
  },
};
</script>
