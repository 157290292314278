import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, updateDoc } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    data: [],
    firestoreListener: null,
    isLoading: false,
    isOnline: false,
    lastChange: null,
}

const actions = {
    // async updateBusExit({ state, commit }, { date, docid, data }) {
    //     console.log(date, docid, data);
    //     const docRef = doc(db, "busexit", date, "ausfahrt", docid);
    //     await updateDoc(docRef,
    //         data
    //     )
    //     commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
    // },
    attachDelayOnSnapshotoffline({ state }, { date }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.data);
                return;
            }
            state.isLoading = true;
            console.log(date)
            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, "delays", date, "entries"))
            // where("meldezeit", ">=", zeit),
            // where("gemeldet", "==", false),
            // orderBy("meldezeit"));

            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.data.splice(0, state.data.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.data.push(doc.data());
                        }
                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.data);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachDelayOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
        // if (state.firestoreOverdueListener) {
        //     state.firestoreOverdueListener();
        //     state.firestoreOverdueListener = null;
        // }
        // if (state.firestoreDoneListener) {
        //     state.firestoreDoneListener();
        //     state.firestoreDoneListener = null;
        // }
    },
}

export default { state, actions }
