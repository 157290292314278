import { db } from '@/firebase/firebase';
import { collection, onSnapshot, where, query } from "firebase/firestore";
//import { doc, getDoc } from "firebase/firestore";

const state = {
    isAuthenticating: false,
    withMenu: true,
    userid: null,
    personnelnr: null,
    user: {},
    userData: {},
    employee: {},
    photoURL: null,
    permissions: {
        userManagement: false,
        useFunctions: false,
    },
    firestoreListener: null,
    isLoading: true,
    hinweis: "" //"Loading..."
}

const mutations = {
    setUserLogin(state, user) {
        if(user != null) {
            state.userid = user.uid;
            state.user = user;
        } else {
            state.userid = null;
            state.user = null;
        }
    },

    setUserClaims(state, claims) {
        if(claims && claims.permissions) {
            state.permissions.userManagement = claims.permissions.userManagement === true;
        }else{
            state.permissions.userManagement = false;
        }
    },

    setUserLogout(state) {
        state.user = null
    },

    setIsAuthenticating(state, isAuthenticating) {
        state.isAuthenticating = isAuthenticating
    },

    setwithMenue(state, withMenue) {
        state.withMenu = withMenue
    },

    setHinweis(state, hinweis, isLoading) {
        state.hinweis = hinweis
        state.isLoading = isLoading
    },

    setUserPhotoURL(state, photoURL) {
        state.photoURL = photoURL
    },

    setUserPersonnelNr(state, persnr) {
        state.personnelnr = persnr
    },
}

const actions = {
/*    setUserLogin: ({ commit }, user) => {
        commit('setUserLogin', user);
    },*/ 

    attachEmployee({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.employee);
                return;
            }
            state.isLoading = true;
            const q = query(collection(db, "employees"), where("persid", "==", state.personnelnr));
            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.employee = doc.data();
                        } else {
                            state.employee = {};
                        }
//                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
//                        console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.employee);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachEmployee({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
    }
}

export default { state, mutations, actions }
