import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, updateDoc, setDoc, deleteDoc } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    busLocationV2: [],
    busseKategorie: [
        'E-Solobus',
        'E-Gelenkbus',
        'Gelenkbus',
        'Solobus'
    ],
    firestoreListener: null,
    isLoading: false,
    isOnline: false,
    lastChange: null,
    busQuantity: 0
}

const getters = {
    busTypInLocationV2: (state) => (busType) => {
        return state.busLocationV2
            .filter(location => {
                return location.Kategorie == busType
            })
    },
}

const actions = {
    attachBusLocationV2OnSnapshotoffline({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.busLocationV2);
                return;
            }
            state.isLoading = true;

            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, "buslocationv2"))
            // where("meldezeit", ">=", zeit),
            // where("gemeldet", "==", false),
            // orderBy("meldezeit"));

            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.busLocationV2.splice(0, state.busLocationV2.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.busLocationV2.push(
                                { id: doc.id, ...doc.data() }
                            );
                        }
                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.busLocationV2);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachBusLocationV2OnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
    },

    updateBusLocation({ commit }, { docid, data }) {
        console.log('UPDATE', docid, data);
        const docRef = doc(db, "buslocationv2", docid);
        updateDoc(docRef, data).then(() => {
            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    // addStrang({ commit }, { docid, data }) {
    //     console.log('INSERT', docid, data);
    //     const docRef = doc(db, "strang", docid);
    //     setDoc(docRef, data).then(() => {
    //         commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
    //     }).catch(err => {
    //         commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
    //     })
    // },

    // deleteStrang({ commit }, { docid }) {
    //     console.log('DELETE', docid)
    //     const docRef = doc(db, "strang", docid);
    //     deleteDoc(docRef).then(() => {
    //         commit('showSnackbar', { message: 'Eintrag gelöscht', status: 'success' }, { root: true })
    //     }).catch(err => {
    //         commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
    //     })
    // },

    // getBusAnzahlProTypeInStrang({ state, dispatch }, { busType }) {
    //     const date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    //     dispatch("attachBusExitOnSnapshotoffline", { date })
    //         .then(() => {
    //             dispatch('attachStrangOnSnapshotoffline')
    //             dispatch("getPersonalTask");
    //             console.log('RECEIVEDBUSTYPE', busType)
    //         }).then(() => {
    //             state.busQuantity = state.strang
    //                 .filter(strang => {
    //                     return strang.Kategorie == busType
    //                 })
    //                 .reduce((acc, cur) => acc + cur.anzahl, 0)
    //             console.log(state.busQuantity)
    //         })


    // return state
    // .filter(strang => {
    //     console.log(strang)
    //     return strang
    // })
    // .reduce((acc, cur) => acc + cur.anzahl, 0)
    // }
}

export default { state, getters, actions }
