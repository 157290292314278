<template>
  <form>
    <v-container fill-height fluid style="height: 100%" v-if="isLoading">
      <v-row class="text-center">
        <v-col cols="12">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <v-card v-if="$store.state.auth.isAuthenticating">
        <v-card-title><v-icon>mdi-security</v-icon>&nbsp;Sicherheitsrelevante Vorfälle. </v-card-title>
        <v-card-actions>
          <vdatetime
            :value="datefrom"
            :onlyDate="true"
            :onlyTime="false"
            :menu-props="{ top: false }"
            :date-props="{ outlined: false, label: 'Datum von' }"
            :date-picker-props="{max: datemax, locale: 'de'}"
            :error-messages="errors"
            @change="changedatefrom($event)"
          ></vdatetime>
          <v-spacer></v-spacer>
          <vdatetime
            :value="dateto"
            :onlyDate="true"
            :onlyTime="false"
            :menu-props="{ top: false }"
            :date-props="{ outlined: false, label: 'bis' }"
            :date-picker-props="{max: datemax, locale: 'de'}"
            :error-messages="errors"
            @change="changedateto($event)"
          ></vdatetime>
          <v-spacer></v-spacer>
        </v-card-actions>
        <v-card-text>
          <excelxlsx
            :data="$store.state.securityincident.statistic"
            :columns="$store.state.securityincident.excelcolumn"
            :fileName="'sicherheitsrelevantevorfaelle'"
            :fileType="'xlsx'"
            :sheetName="'Sicherheitsrelevante Vorfälle'"
          >Download Excel</excelxlsx>
        </v-card-text>
      </v-card>
      <v-card>
        <v-data-table
          :headers="$store.state.securityincident.dtheader"
          :items="$store.state.securityincident.statistic"
          :single-expand="true"
          :expanded.sync="expanded"
          item-key="time"
          show-expand
          class="elevation-1"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-row v-for="(field) in $store.state.securityincident.dtexpandheader" :key="field.text">
                <v-col sm="4">
                  <v-card tile flat>{{ field.text }}:</v-card>
                </v-col>
                <v-col sm="4">
                  <v-icon v-if="item[field.value]==true" color="green">mdi-check-bold</v-icon>
                  <v-icon v-else-if="item[field.value]==false" color="red">mdi-close</v-icon>
                  <v-card v-else tile flat>{{ item[field.value] }}</v-card>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </form>
</template>

<script>

import { DateTime } from "luxon";
import vdatetime from "@/components/app/v-datetime";
import excelxlsx from "@/components/app/ExcelXlsx";

export default {
  name: "Sicherheitsrelevante Vorfälle",
  data() {
      return {
        isLoading: true,
        datefrom: DateTime.now().setLocale("de").plus({ days: -7 }).toFormat("yyyy-MM-dd"),
        dateto: DateTime.now().setLocale('de').toFormat("yyyy-MM-dd"),
        datemax: DateTime.now().setLocale('de').toFormat("yyyy-MM-dd"),
        expanded: [],
      };
  },
  created: function () {},
  computed: {},
  watch: {
    "$store.state.auth.isAuthenticating": {
      handler: function (isAuthenticating) {
        if (isAuthenticating) {
          this.isLoading = false;
        }
      }, immediate: true,
    },
  },
  methods: {
    changedatefrom(objdate) {
      this.datefrom = objdate.datetime;
      this.$store.dispatch("attachSecurityincidentStatistic", { from: this.datefrom, to: this.dateto });
    },
    changedateto(objdate) {
      this.dateto = objdate.datetime;
      this.$store.dispatch("attachSecurityincidentStatistic", { from: this.datefrom, to: this.dateto });
    },
  },
  mounted() {
    this.datefrom = DateTime.now().setLocale("de").plus({ days: -7 }).toFormat("yyyy-MM-dd");
    this.dateto = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.$store.dispatch("attachSecurityincidentStatistic", { from: this.datefrom, to: this.dateto });
  },
  beforeDestroy() {
  },
  components: {
    vdatetime,
    excelxlsx
  },
};
</script>
