<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card>
      <v-card-title> HILFE </v-card-title>
      <v-card-text>
        <v-expansion-panels v-model="panel">
          <v-expansion-panel>
            <v-expansion-panel-header>
              Mein Strang ist leer
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card>
                <v-card-text>
                  Bist du dir sicher, dass Strang {{ strangNummer }} leer ist?
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="strangLeerAction">
                    Ja
                  </v-btn>
                  <v-btn color="primary" text @click="closePanel"> Nein </v-btn>
                </v-card-actions>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Ich kann nicht rausfahren
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-expansion-panels v-model="subPanel">
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Fahrzeug defekt
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-card-text> Bist du dir sicher? </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="kannNichtRausfahrenAction"
                        >
                          Ja
                        </v-btn>
                        <v-btn color="primary" text @click="closeSubPanel">
                          Nein
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Strang blockiert
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-card>
                      <v-card-text> Bist du dir sicher? </v-card-text>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="kannNichtRausfahrenAction"
                        >
                          Ja
                        </v-btn>
                        <v-btn color="primary" text @click="closeSubPanel">
                          Nein
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primaryLight" text @click="cancelAusfahrtHilfe">
          Schließen
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    active: Number,
    task: Object,
  },

  data() {
    return {
      dialogVisible: false,
      panel: [],
      subPanel: [],
    };
  },

  computed: {
    strangNummer() {
      return this.task.strang?.strangNummer;
    },
  },

  watch: {
    active() {
      this.dialogVisible = true;
    },

    panel(value) {
      console.log(value);
    },
  },

  methods: {
    closePanel() {
      this.panel = [];
    },

    closeSubPanel() {
      this.subPanel = [];
    },

    cancelAusfahrtHilfe() {
      this.dialogVisible = false;
    },

    strangLeerAction() {
      this.$store.dispatch("updateStrang", {
        docid: this.task.strang.strangNummer,
        data: {
          anzahl: 0,
        },
        strangNummer: this.task.strang.strangNummer
      });

      this.$store.dispatch("updateBusExit", {
        date: this.task.meldedatum,
        docid: this.task.docid,
        data: { strang: "" },
      });

      this.dialogVisible = false;
    },

    kannNichtRausfahrenAction() {
      this.$store.dispatch("updateStrang", {
        docid: this.task.strang.strangNummer,
        data: {
          istGesperrt: true,
        },
        strangNummer: this.task.strang.strangNummer
      });

      this.$store.dispatch("updateBusExit", {
        date: this.task.meldedatum,
        docid: this.task.docid,
        data: { strang: "" },
      });

      this.dialogVisible = false;
    },
  },
};
</script>
