//import { firestore } from '@/firebase/firebase';
import { db } from '@/firebase/firebase';
import { collection, onSnapshot, where, query } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    itemdata: [],
    firestoreListener: null,
    isLoading: false,
    isOnline: false,
    lastChange: null,
}

const mutations = {

}

const getters = {
    busstations(state) {
        let busstations = [];
        state.itemdata.forEach(busstation => {
            busstations.push(busstation);
        });
        console.log(busstations);
        return busstations;
    },
}

const actions = {
    attachBusStationOnSnapshotoffline({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.itemdata);
                return;
            }
            state.isLoading = true;
            const q = query(collection(db, "busstation"), where("active", "==", "yes"));
            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.itemdata.push(doc.data());
                        } 
                    });
                    if(snapshot.metadata.fromCache) {
                        state.isOnline = false;
                    } else {
                        state.isOnline = true;
                        state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm")
                    }
                    state.isLoading = false;
                    resolve(state.itemdata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachBusStationOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
    }
}

export default { state, getters, mutations, actions }