import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, updateDoc, setDoc, deleteDoc, getDoc } from "firebase/firestore";

const state = {
  settings: {
    timing: 0,
    prod: false
  },
  securityincidenttype: [],
  busexittypes: [],
  isLoading: true,
  isOnline:false,
  busexittypesisOnline: false,
  busexittypesisLoading: true,
  firestoreListener: null,
  securityincidenttypeListener: null,
  busexittypesListener: null,
}

const getters = {
  strangNamenAlsText(state) {
    const list = state.busexittypes.map(name => name.text)
//        list.push('Freitext')
    return list
  },
}

const actions = {
  attachSettingsOnSnapshotoffline({ state }) {
    return new Promise((resolve, reject) => {
      if (state.firestoreListener) {
        resolve(state.settings);
        return;
      }
      state.isLoading = true;

      state.firestoreListener =
        onSnapshot(doc(db, 'config', 'settings'), snapshot => {
          state.settings = { ...snapshot.data() }
          state.isLoading = false
          resolve(state.settings)
        }, (error) => {
          console.log(error.message);
          reject(error);
        })

      // getDoc(doc(db, 'config', 'settings'))
      //     .then(docSnap => {
      //         state.settings = { ...docSnap.data() }
      //         state.isLoading = false;
      //         resolve(state.settings)
      //     }).catch(error => {
      //         console.log(error.message);
      //         reject(error);
      //     })
    })
  },

  async updateSettings({ commit }, { docid, data }) {
    console.log('UPDATE', docid, data);
    const docRef = doc(db, "config", docid);
    updateDoc(docRef, data).then(() => {
      commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
    }).catch(err => {
      commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
    });
  },

  async insertSecurityincidenttype({ commit }, { docid, type }) {
    const docSnap = getDoc(doc(db, "config", "settings", "securityincidenttypes", docid));
    if (docSnap.exists) {
      console.log(docSnap.data())
    } else {
      setDoc(doc(db, "config", "settings", "securityincidenttypes", docid), {
        docid: docid,
        text: docid,
      }, { merge: true }).then(() => {
        commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
      }).catch(err => {
        commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
      });
    }
  },

  async deleteSecurityincidenttype({ state }, { docid }) {
      deleteDoc(doc(db, "config", "settings", "securityincidenttypes", docid ));
  },
  attachSecurityincidenttype({ state }) {
    return new Promise((resolve, reject) => {
      if (state.securityincidenttypeListener) {
        resolve(state.securityincidenttype);
        return;
      }
      state.isLoading = true;

      const q = query(collection(db, "config", "settings", "securityincidenttypes"));
      state.securityincidenttypeListener = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
          snapshot.docs.forEach((doc) => {
              if (doc.exists) {
                  state.securityincidenttype.push(doc.data());
              }
          });
          if (snapshot.metadata.fromCache) {
              state.isOnline = false;
          } else {
              state.isOnline = true;
          }
          state.isLoading = false;
          resolve(state.securityincidenttype);
      }, (error) => {
          console.log(error.message);
          reject(error);
      })
    })
  },

  attachbusexittypes({ state }) {
    return new Promise((resolve, reject) => {
      if (state.busexittypesListener) {
        resolve(state.busexittypes);
        return;
      }
      state.busexittypesisLoading = true;

      const q = query(collection(db, "config", "settings", "busexittypes"));
      state.busexittypesListener = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
        state.busexittypes.splice(0, state.busexittypes.length);
        snapshot.docs.forEach((doc) => {
          if (doc.exists) {
            state.busexittypes.push(doc.data());
          }
        });
        if (snapshot.metadata.fromCache) {
          state.busexittypesisOnline = false;
        } else {
          state.busexittypesisOnline = true;
        }
        state.busexittypesisLoading = false;
        resolve(state.busexittypes);
      }, (error) => {
        console.log(error.message);
        reject(error);
      })
    })
  },

  detachsecurityincidenttype({ state }) {
    if (state.securityincidenttypeListener) {
        state.securityincidenttypeListener()
        state.securityincidenttypeListener = null
    }
  },

  detachhbusexittypes({ state }) {
    if (state.busexittypesListener) {
        state.busexittypesListener()
        state.busexittypesListener = null
    }
  },
}

export default { state, actions, getters }
