<template>
    <v-container>
        <v-card v-if="$store.state.auth.isAuthenticating">
            <v-banner  v-if="$store.state.dayroster.isOnline == false" single-line >
                <v-icon slot="icon" color="warning" size="36">mdi-wifi-strength-alert-outline</v-icon>
                Offline-Modus!
            </v-banner>
            <v-card-title><v-icon>mdi-calendar</v-icon>&nbsp;{{$store.state.dayroster.userdata.Surname}}&nbsp;{{$store.state.dayroster.userdata.Firstname}}<br/>&nbsp;Dienst-Nr: {{$store.state.dayroster.userdata.Service_nr}}&nbsp;am&nbsp;{{datum}}</v-card-title>
            <v-card-subtitle>letzte Aktualisierung: {{$store.state.dayroster.lastChange}}</v-card-subtitle>
            <v-card-text>
                <template v-if="Object.keys(fdk).length <= 1">
                    <v-alert
                        v-if="Object.keys(fdk).length <= 1"
                        type="info"
                        border="left"
                        colored-border
                        elevation="2"
                        color="primary"
                    >Keine FDK vorhanden!
                    </v-alert>
                </template>
                <template v-else>
                    <v-simple-table :mobile-breakpoint="250">
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td width="25vh">Dienst anfang: {{ $store.state.dayroster.userdata.service_begin }}</td>
                                    <td width="25vh">Ort: {{ $store.state.dayroster.userdata.begin_location }}</td>
                                    <td width="25vh">Dienst ende: {{ $store.state.dayroster.userdata.service_end }}</td>
                                    <td width="25vh">Ort: {{ $store.state.dayroster.userdata.end_location }}</td>
                                    
                                </tr>
                                <tr>
                                    <td width="25vh">Pause anfang: {{ $store.state.dayroster.userdata.pause_begin }}</td>
                                    <td width="25vh">Pause ende: {{ $store.state.dayroster.userdata.pause_end }}</td>
                                    <td width="25vh">LZU soll: {{ $store.state.dayroster.userdata.lzu_nominal }}</td>
                                    <td width="25vh">LZU Ist: {{ $store.state.dayroster.userdata.lzu_actual }}</td>
                                </tr>
                                <tr>
                                    <td width="25vh">Dauer: {{ $store.state.dayroster.userdata.service_length }}</td>
                                    <td width="25vh">Lohn Std: {{ $store.state.dayroster.userdata.work_hours }}</td>
                                    <td width="25vh">G&uuml;ltig ab {{ $store.state.dayroster.userdata.valid_from }}</td>
                                    <td width="25vh"></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </v-card-text>
            <v-card-text>
                <template>
<!--                    <v-card>-->
<!--                        <v-simple-table  height="50vh" :mobile-breakpoint="250">-->
                        <v-simple-table :mobile-breakpoint="250">
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="item in $store.state.dayroster.userdata.fdk" :key="item.row">
                                        <td>{{ item.vehicle }}</td>
                                        <td>{{ item.note }}</td>
                                        <td>{{ item.route }}</td>
                                        <td>{{ item.departure }}</td>
                                        <td>{{ item.text }}</td>
                                        <td>{{ item.arrival }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
<!--                    </v-card>-->
                </template>
            </v-card-text>
            <v-card-text>
                <v-card v-if="Object.keys(lzu).length > 0 || Object.keys(note).length > 0">
                    <v-card-title v-if="Object.keys(lzu).length > 0">
                        Bemerkungen / Anrechenbare LZU
                    </v-card-title>
                    <v-card-title v-else>
                        Bemerkungen
                    </v-card-title>
                    <v-container fluid>
                        <v-row dense>
                            <v-col>
                                <template>
                                    <v-simple-table :mobile-breakpoint="250">
                                        <template v-slot:default>
                                            <tbody>
                                                <tr v-for="item in $store.state.dayroster.userdata.note" :key="item.id">
                                                    <td>{{ item.n1 }}</td>
                                                    <td>{{ item.n2 }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                            </v-col>
                            <v-col>
                                <template>
                                    <v-simple-table :mobile-breakpoint="250">
                                        <template v-slot:default>
                                            <tbody>
                                                <tr v-for="item in $store.state.dayroster.userdata.lzu" :key="item.id">
                                                    <td>{{ item.l1 }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-card-text>
        </v-card>
        <v-spacer></v-spacer>
        <v-card v-if="$store.state.auth.isAuthenticating && reserve == true">
            <v-card-title><v-icon>mdi-calendar</v-icon>&nbsp;Reservedienst: {{ $store.state.dayroster.userdata.reserve.Service_nr }}
            </v-card-title>
            <v-card-text>
                <template>
                    <v-simple-table :mobile-breakpoint="250">
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td width="25vh">Dienst anfang: {{ $store.state.dayroster.userdata.reserve.Service_begin }}</td>
                                    <td width="25vh">Ort: {{ $store.state.dayroster.userdata.reserve.Begin_location }}</td>
                                    <td width="25vh">Dienst ende: {{ $store.state.dayroster.userdata.reserve.Service_end }}</td>
                                    <td width="25vh">Ort: {{ $store.state.dayroster.userdata.reserve.End_location }}</td>
                                    
                                </tr>
                                <tr>
                                    <td width="25vh">Pause anfang: {{ $store.state.dayroster.userdata.reserve.Pause_begin }}</td>
                                    <td width="25vh">Pause ende: {{ $store.state.dayroster.userdata.reserve.Pause_end }}</td>
                                    <td width="25vh">LZU soll: {{ $store.state.dayroster.userdata.reserve.Lzu_nominal }}</td>
                                    <td width="25vh">LZU Ist: {{ $store.state.dayroster.userdata.reserve.Lzu_actual }}</td>
                                </tr>
                                <tr>
                                    <td width="25vh">Dauer: {{ $store.state.dayroster.userdata.reserve.Service_length }}</td>
                                    <td width="25vh">Lohn Std: {{ $store.state.dayroster.userdata.reserve.Work_hours }}</td>
                                    <td width="25vh">G&uuml;ltig ab {{ $store.state.dayroster.userdata.reserve.Valid_from }}</td>
                                    <td width="25vh"></td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </template>
            </v-card-text>
<!--            <v-card-text>-->
                <template>
<!--                    <v-card>-->
                        <v-simple-table  :mobile-breakpoint="250">
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="item in $store.state.dayroster.userdata.reserve.fdk" :key="item.row">
                                        <td>{{ item.vehicle }}</td>
                                        <td>{{ item.note }}</td>
                                        <td>{{ item.route }}</td>
                                        <td>{{ item.departure }}</td>
                                        <td>{{ item.text }}</td>
                                        <td>{{ item.arrival }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
<!--                    </v-card>-->
                </template>
<!--            </v-card-text>-->
            <v-card-text>
                <v-card v-if="$store.state.dayroster.userdata.reserve.lzu.length > 0 || $store.state.dayroster.userdata.reserve.note.length > 0">
                    <v-card-title v-if="$store.state.dayroster.userdata.reserve.lzu.length > 0">
                        Bemerkungen / Anrechenbare LZU
                    </v-card-title>
                    <v-card-title v-else>
                        Bemerkungen
                    </v-card-title>
                    <v-container fluid>
                        <v-row dense>
                            <v-col>
                                <template>
                                    <v-simple-table :mobile-breakpoint="250">
                                        <template v-slot:default>
                                            <tbody>
                                                <tr v-for="item in $store.state.dayroster.userdata.reserve.note" :key="item.id">
                                                    <td>{{ item.n1 }}</td>
                                                    <td>{{ item.n2 }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                            </v-col>
                            <v-col>
                                <template>
                                    <v-simple-table :mobile-breakpoint="250">
                                        <template v-slot:default>
                                            <tbody>
                                                <tr v-for="item in $store.state.dayroster.userdata.reserve.lzu" :key="item.id">
                                                    <td>{{ item.l1 }}</td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </template>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-card-text>
        </v-card>
  </v-container>
</template>

<script>

//import { firestore } from "@/firebase/firebase";
import { DateTime } from "luxon";

export default {
    name: "FDK-Tagessicht",
    data() {
        return {
            isLoading: false,
            isTruncated: this.$vuetify.breakpoint.mobile,
            date: null,
            datum: null,
        };
    },
    created: function () {
    },
    computed: {
        fdk() {
            if(typeof(this.$store.state.dayroster.userdata.fdk) != "undefined") {
                return this.$store.state.dayroster.userdata.fdk;
            } else {
                const fdk = [];
                return fdk;
            }
        },
        note() {
            if(typeof(this.$store.state.dayroster.userdata.note) != "undefined") {
                return this.$store.state.dayroster.userdata.note;
            } else {
                const note = [];
                return note;
            }
        },
        lzu() {
            if(typeof(this.$store.state.dayroster.userdata.lzu) != "undefined") {
                return this.$store.state.dayroster.userdata.lzu;
            } else {
                const lzu = [];
                return lzu;
            }
        },
        reserve() {
            if(typeof(this.$store.state.dayroster.userdata.reserve) != "undefined") {
                return this.$store.state.dayroster.userdata.reserve.aktive;
            } else {
                return false;
            }
        },
    },
    watch: {
        "$store.state.auth.isAuthenticating": {
            handler: function (isAuthenticating) {
                if (isAuthenticating) {
                    if(typeof(this.$route.params.date) == 'undefined') {
                        this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
                    } else {
                        console.log(this.$route.params.date);
                        this.date = DateTime.fromString(this.$route.params.date, "yyyy-MM-dd").toFormat("yyyy-MM-dd");
                        if(this.date == "Invalid DateTime") {
                            this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
                        }
                    }
                    this.datum = DateTime.fromString(this.date, "yyyy-MM-dd").toFormat("dd.MM.yyyy");
                    if(this.$store.state.auth.personnelnr === '011489' || 
                        this.$store.state.auth.personnelnr === '010463' ||
                        this.$store.state.auth.personnelnr === '012114' ||
                        this.$store.state.auth.personnelnr === '546084' ) {
                        this.$store.dispatch("attachDayrosterOnSnapshotoffline", { persid: '011985', date: this.date} );
                    } else {
                        this.$store.dispatch("attachDayrosterOnSnapshotoffline", { persid: this.$store.state.auth.personnelnr, date: this.date} );
                    }
                }
            },
            immediate: true,
        },
    },
    methods: {},
    mounted() {
        this.$store.state.auth.withMenu = false;
        if(typeof(this.$route.params.date) == 'undefined') {
            this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
        } else {
            console.log(this.$route.params.date);
            this.date = DateTime.fromString(this.$route.params.date, "yyyy-MM-dd").toFormat("yyyy-MM-dd");
            if(this.date == "Invalid DateTime") {
                this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
            }
        }
        this.datum = DateTime.fromString(this.date, "yyyy-MM-dd").toFormat("dd.MM.yyyy");
        if(this.$store.state.auth.personnelnr === '011489' || 
            this.$store.state.auth.personnelnr === '012114' ||
            this.$store.state.auth.personnelnr === '546084' ) {
            this.$store.dispatch("attachDayrosterOnSnapshotoffline", { persid: '011985', date: this.date} );//'110328'} );
        } else {
            this.$store.dispatch("attachDayrosterOnSnapshotoffline", { persid: this.$store.state.auth.personnelnr, date: this.date} );
        }
    },
    beforeDestroy() {
        this.$store.dispatch("detachDayrosterOnSnapshot");
        this.$route.params.date = null;
        this.$store.state.auth.withMenu = true;
    },
    components: {},
};
</script>
<style>
    @media (min-width: 950px) {
        /* breite Browserfenster */
        .v-data-table .v-data-table__wrapper table tbody tr td {
            font-size: 1.3rem !important;
        }
    }
    @media (min-width: 550px) and 
    (max-width: 950px) {
    /* Darstellung auf Netbooks */ 
        .v-data-table .v-data-table__wrapper table tbody tr td {
            font-size: 1.1rem !important;
        }
    } 
    @media (max-width: 550px) {
     /* mobile Geräte */ 
        .v-data-table .v-data-table__wrapper table tbody tr td {
            font-size: 0.95rem !important;
            padding: 0 0.4rem;
        }
        .v-card__text {
            padding-left: 4px;
        }
    }
    @media (max-width: 430px) {
     /* mobile Geräte */ 
        .v-data-table .v-data-table__wrapper table tbody tr td {
            font-size: 0.85rem !important;
            padding: 0 0.4rem;
        }
        .v-card__text {
            padding-left: 4px;
        }
    }
    
</style>
