<template>
  <v-container>
    <v-card class="mt-5">
      <v-card-title>
        Einen freien Bus suchen.
      </v-card-title>
      <v-card-text>
        <v-radio-group
          v-model="bustype"
          inline
        >
          <v-radio
            label="Solobus"
            value="Solobus"
          ></v-radio>
          <v-radio
            label="Gelenkbus"
            value="Gelenkbus"
          ></v-radio>
          <v-radio
            label="E-Solobus MB"
            value="E-Solobus MB"
          ></v-radio>
          <v-radio
            label="E-Solobus BYD"
            value="E-Solobus BYD"
          ></v-radio>
        </v-radio-group>
        <v-card-text
          fill-height
          fluid
          style="height: 100%"
          v-if="isLoading"
        >
          <v-row class="text-center">
            <v-col cols="12">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-else-if="strang.status">
          Bitte wähle den {{ strang.kategorie }} 
          aus <span class="font-weight-bold">Strang {{strang.strangNummer}} 
          Position {{strang.strangPosition}} {{strang.strangText}}</span>
        </v-card-text>
        <v-card-text v-else>
          {{ strang.text }}
        </v-card-text>
      </v-card-text>
      <v-card-actions v-if="bustype">
        <v-btn @click="BusSearchAction" color="primary" min-width="200px">
          Bus suchen!
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import { app } from '@/firebase/firebase';
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  props: {
    task: {
      type: Object,
    },
  },

  data() {
    return {
      date: null,
      time: null,
      bustype: null,
      strang: {
        status: false,
        text: "freien Bus suchen!"
      },
      isLoading: false,
    }
  },

  methods: {
    BusSearchAction() {
      this.isLoading = true;
      this.time = DateTime.now().setLocale("de").toFormat("HH:mm");
      this.strang = { status: false };
      const functions = getFunctions( app, 'europe-west1' );
      const manuelleBusAusgabe = httpsCallable(functions, 'manuelleBusAusgabe');
      manuelleBusAusgabe({ bustype: this.bustype, time: this.time, sbs: false, kurzdienst: false, nachtexpress: false })
        .then((result) => {
          // Read result of the Cloud Function.
          /** @type {any} */
          this.bustype = null;
          this.strang = result.data.strang;
          this.isLoading = false;
        })
        .catch((error) => {
          this.bustype = null;
          const code = error.code;
          const message = `ERROR: ${error.code} ${error.message} ${error}`;
          const details = error.details;
          console.log("Call Func Error: ", code, message, error);
          this.bustype = null;
          this.strang = { status: false, text:  message};
          this.isLoading = false;
        });
    },
  },

  watch: {
    bustype: {
      handler(newValue, oldValue) {
        console.log("Value: ", oldValue, newValue);
        if(newValue != null) {
          this.strang = { status: false, text: "freien Bus suchen!" };
        }
      }
    }
  },

  created() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.time = DateTime.now().setLocale("de").toFormat("HH:mm");
  },

  beforeDestroy() {
  },
};
</script>
