import AllNotifications from '@/views/AllNotifications.vue';

const routesnotification = [
  {
    path: '/allNotifications',
    name: 'Alle Benachrichtigungen',
    icon: 'mdi-bell-outline',
    right: 'all_notification',
    navParent: 'Parent Notification',
    component: AllNotifications,
    navIgnore: false
  },
];

export default routesnotification;