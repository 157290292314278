import { db } from '@/firebase/firebase'
import { collection, onSnapshot, query, doc, updateDoc } from "firebase/firestore";

const state = {
    vehicles: [],
    vehiclesposition: [],
    firestoreListener: null,
    isLoading: false,
    isOnline: true,
    vehiclespositionfirestoreListener: null,
    vehiclespositionisLoading: false,
    vehiclespositionisOnline: true
}

const getBusType = type => {
    switch (type) {
        case 'B-SL':
        case 'B-NL': return 'Solobus'
        case 'B-NG': return 'Gelenkbus'
        case 'B-ELEK': return 'E-Solobus'
        default: return 'Unbekannt'
    }
}

const getters = {
    vehicleList(state) {
        return state.vehicles
            // .filter(vehicle =>
            //     vehicle.active == 'yes')
            .map(vehicle => {
                return {
                    docid: vehicle.docid,
                    wagennummer: vehicle.equipment.substring(1),
                    typ: getBusType(vehicle.objtype),
                    active: vehicle.active
                }
            })
            .sort((a, b) => {
                const vehicleA = a.wagennummer.toUpperCase();
                const vehicleB = b.wagennummer.toUpperCase();
                if (vehicleA < vehicleB) {
                    return -1;
                }
                if (vehicleA > vehicleB) {
                    return 1;
                }
                return 0;
            })
    },
    KomList(state) {
        const arr = state.vehicles
            .filter(vehicle =>
                vehicle.active == 'yes')
            .map(vehicle => {
                return {
                    kom: vehicle.equipment,
                    mark: vehicle.mark,
                    text: vehicle.equipment + ' ' + vehicle.mark
                }
            })
            .sort((a, b) => {
                const vehicleA = a.kom.toUpperCase();
                const vehicleB = b.kom.toUpperCase();
                if (vehicleA < vehicleB) {
                    return -1;
                }
                if (vehicleA > vehicleB) {
                    return 1;
                }
                return 0;
            })
        return [...new Set(arr)]
    }
}

const actions = {
    async attachVehiclesOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.vehicles)
                return
            }
            state.isLoading = true
            const q = query(collection(db, "vehicle"));
            state.firestoreListener = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                state.vehicles = []
                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        state.vehicles.push({
                            ...doc.data(),
                            docid: doc.id
                        });
                    }
                });
                if (snapshot.metadata.fromCache) {
                    state.isOnline = false;
                } else {
                    state.isOnline = true;
                }
                state.isLoading = false;
                resolve(state.vehicles);
            }, (error) => {
                console.log(error.message);
                reject(error);
            })
        })
    },

    async attachVehiclesPosition({ state }) {
        return new Promise((resolve, reject) => {
            if (state.vehiclespositionfirestoreListener) {
                resolve(state.vehiclesposition)
                return
            }
            state.vehiclespositionisLoading = true
            const q = query(collection(db, "vehicle"))
            state.vehiclespositionfirestoreListener = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                state.vehiclesposition = []
                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        const vehicledata = doc.data()
                        const docId = doc.id
                        const qvp = query(collection(db, "vehicle", vehicledata.equipment, "position"))
                        const vehiclepos = onSnapshot(qvp,  { includeMetadataChanges: true }, (snapshot) => {
                            let position = {}
                            snapshot.docs.forEach((doc) => {
                                if (doc.exists) {
                                    position[doc.id] = doc.data()
                                }
                            })
                            const gps = position?.gps || null
                            const stellplatz = position?.stellplatz || null
                            state.vehiclesposition.push({
                                ...vehicledata, gps: gps,
                                stellplatz: stellplatz,
                                docid: doc.id
                            })
                        })
                    }
                })
                if (snapshot.metadata.fromCache) {
                    state.vehiclespositionisOnline = false
                } else {
                    state.vehiclespositionisOnline = true
                }
                state.vehiclespositionisLoading = false
                console.log("Vehicleposition:", state.vehiclesposition)
                resolve(state.vehiclesposition)
            }, (error) => {
                console.log(error.message)
                reject(error)
            })
        })
    },

    detachVehiclesOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener()
            state.firestoreListener = null
        }
    },

    updateVehicle({ commit }, { docid, data }) {
        console.log('UPDATE', docid, data);
        const docRef = doc(db, "vehicle", docid);

        updateDoc(docRef, data).then(() => {
            // commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },
}

export default { state, getters, actions }
