<template>
  <div>
    <v-autocomplete
      v-model="location"
      label="Straße/Ort"
      :items="searchResults"
      :loading="isLoading"
      :search-input.sync="locationsearch"
      hide-no-data
      hide-selected
      prepend-icon="mdi-city-variant-outline"
      v-on:change="savelocation()"
    ></v-autocomplete>
  </div>
</template>

<script>

  export default {
    name: 'GoogleMap',
    props: {
        apikey: String,
        countries: Array,
        lat: Number,
        lng: Number
    },
    data: () => ({
      location: '',
      searchResults: [],
      service: null,
      descriptionLimit: 60,
      isLoading: false,
      locationsearch: null,
    }),
    metaInfo () {
      //npm i vue-meta
      return {
        script: [{
          src: "https://maps.googleapis.com/maps/api/js?key="+this.apikey+"&libraries=places",
          async: true,
          defer: true,
          callback: () => this.MapsInit() // will declare it in methods
        }]
      }
    },
    mounted() {
    },
    methods: {
      MapsInit () {
        this.service = new window.google.maps.places.AutocompleteService();
      },
      displaySuggestions (predictions, status) {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          this.searchResults = []
          return
        }
        this.searchResults = predictions.map(prediction => prediction.description) 
      },
      savelocation () {
        this.$emit('changelocation', {location: this.location});
      }
    },
    watch: {
      locationsearch (newValue) {
        if (newValue) {
          const _location = new window.google.maps.LatLng({ lat: 51.195552, lng: 6.444506 });
          const _radius = 25000;
          this.service.getPlacePredictions({
            input: newValue,
            location: _location,
            radius: _radius,
            componentRestrictions: { 
              country: this.countries
            },
            fields: ["formatted_address", "address_components", "geometry", "name"],
            types: ["address"]
          }, this.displaySuggestions)
        }
      }
    }
  }
</script>