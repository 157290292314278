<template>
    <v-dialog v-model="dialogVisible" persistent max-width="950px">
      <v-card :loading="isLoading" :disabled="isLoading">
        <v-card-title>Reservedienst anlegen</v-card-title>
        <v-card-text>
          <v-card width='100%'>
            <v-row>
              <v-col md="12">
                <v-row>
                  <v-col sm="2">
                    <v-card tile flat>
                      <v-text-field 
                        v-model="rd.Service_nr" 
                        label="Dienst" 
                        prepend-icon="mdi-account-outline"
                        :readonly="false"
                      >
                      </v-text-field>
                    </v-card>
                  </v-col>
                  <v-col sm="2">
                    <v-card tile flat>
                      <timepicker 
                        v-bind:label="labelvon"
                        v-bind:field="rd.service_begin" 
                        v-bind:modal="modal"
                        v-bind:i1=0
                        v-bind:i2=0
                        v-bind:i3=0
                        v-bind:ro="false"
                        v-bind:typ="'reservedienstvon'"
                        @changetime="changetime($event)"
                      ></timepicker>
                    </v-card>
                  </v-col>
                  <v-col sm="2">
                    <v-card tile flat>
                      <timepicker 
                        v-bind:label="labelbis"
                        v-bind:field="rd.service_end" 
                        v-bind:modal="modal"
                        v-bind:i1=0
                        v-bind:i2=0
                        v-bind:i3=0
                        v-bind:ro="false"
                        v-bind:typ="'reservedienstbis'"
                        @changetime="changetime($event)"
                      ></timepicker>
                    </v-card>
                  </v-col>
                  <v-col sm="2=">
                    <v-card tile flat>
                      <v-text-field 
                        v-model="rd.begin_location" 
                        label="Ort" 
                        prepend-icon="mdi-account-outline"
                        :readonly="false"
                      >
                      </v-text-field>
                    </v-card>
                  </v-col>
                  <v-col sm="3">
                    <v-card tile flat>
                      <v-autocomplete
                        :items="$store.getters.FahrerList"
                        item-text='name'
                        item-value='Personnel_nr'
                        v-model="fahrer" 
                        :readonly="false"
                        label="Name" data-vv-name="rd.personnel_nr" prepend-icon="mdi-account-outline"  return-object
                      >
                      </v-autocomplete>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primaryLight"
          text
          @click="canclereservedienst"
          value="CANCELRESERVEDIENST"
        >
          Abbrechen
        </v-btn>
        <v-btn color="primaryLight" text @click="addreservedienst">
          Speichern
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { DateTime } from "luxon";
import { Timestamp } from "firebase/firestore";
import timepicker from "@/components/app/RDTimePicker";

export default {
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      fahrer: null,
      rd: {
        Active: 1,
        Created: "",
        Depot: "",
        Firstname: "",
        Personnel_nr: "",
        Personnel_type: "",
        Roster_id: "",
        Service_date: null,
        Service_datetimestamp: null,
        Service_mark: "",
        Service_nr: "",
        Service_typ: "",
        Stand: "",
        Standtimestamp: null,
        Surname: "",
        begin_location: "",
        datum: "",
        end_location: "",
        fdk: [],
        id: "",
        logo: "",
        lzu: [],
        lzu_actual: "",
        lzu_nominal: "",
        note: [],
        service_begin: "00:00",
        service_end: "00:00",
        pause_begin: "00:00",
        pause_end: "00:00",
        service_length: "00:00",
        valid_from: "",
        work_hours: "",

        delete: true,
      },
      modal: false,
    };
  },

  computed: {},

  watch: {
    active() {
      this.dialogVisible = true;
      this.rd.Service_date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
      const now = new Date(DateTime.now().setLocale('de'));
      this.rd.Service_datetimestamp = Timestamp.fromDate(now);
      this.rd.Created = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd hh:mm:ss");
      this.rd.Stand = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd hh:mm");
      this.rd.meldedatum = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
      this.rd.Service_typ = this.type;
    },
  },

  methods: {
    addreservedienst() {
      if (this.rd !== '' && this.fahrer && this.rd.anfang !== '' && this.rd.ende !== '') {
        this.rd.Personnel_nr = this.fahrer.Personnel_nr
        this.rd.id = this.rd.Service_date+"_"+this.rd.Personnel_nr+"_MAN"
        this.rd.fahrer = this.fahrer.name
        //this.rd.dayroster.service_begin = this.rd.anfang;
        //this.rd.dayroster.service_end = this.rd.ende;
        //this.rd.dayroster.end_location = this.rd.dayroster.begin_location;
        //this.rd.umlauf = 'RES'+this.rd.dienst+this.rd.personnel_nr;
        this.rd.Service_typ = this.type;
        const _docid = this.rd.id;
        this.$store.dispatch("insertRESBusExit", { date: this.rd.Service_date, docid: _docid, docdata: this.rd });
        this.$store.commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        this.resetRD();
      } else {
        this.$store.commit('showSnackbar', { message: 'Eintrag wurde nicht gespeichert', status: 'error' }, { root: true })
      }
      this.dialogVisible = false;
    },
    canclereservedienst() {
      this.resetRD();
      this.dialogVisible = false;
    },
    changetime(objtime) {
      switch(objtime.typ) {
        case "reservedienstvon":
          this.rd.service_begin = objtime.time;
          break;
        case "reservedienstbis":
          this.rd.service_end = objtime.time;
          break;
      }
    },
    resetRD() {
      this.rd = {
        Active: 1,
        Created: "",
        Depot: "",
        Firstname: "",
        Personnel_nr: "",
        Personnel_type: "",
        Roster_id: "",
        Service_date: null,
        Service_datetimestamp: null,
        Service_mark: "",
        Service_nr: "",
        Service_typ: "",
        Stand: "",
        Standtimestamp: null,
        Surname: "",
        begin_location: "",
        datum: "",
        end_location: "",
        fdk: [],
        id: "",
        logo: "",
        lzu: [],
        lzu_actual: "",
        lzu_nominal: "",
        note: [],
        service_begin: "00:00",
        service_end: "00:00",
        pause_begin: "00:00",
        pause_end: "00:00",
        service_length: "00:00",
        valid_from: "",
        work_hours: "",

        delete: true,
      }
      this.fahrer = null;
    },
  },

  created() {},

  props: {
    active: Number,
    type: String,
  },

  components: {
    timepicker,
  },
};
</script>