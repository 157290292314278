<template>
  <v-container
    fill-height
    fluid
    style="height: 100%"
    v-if="$store.state.busexit.isLoading"
  >
    <v-row class="text-center">
      <v-col cols="12">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else-if="!$store.state.busexit.isLoading">
    <v-card>
      <v-card-text class="pl-4">
        <!--:items="$store.getters.getFahrerList"-->
        <v-autocomplete
          :items="$store.getters.getBusexitFahrerList"
          :search-input.sync="searchString"
          item-text="pnd"
          item-value="pnd"
          @change="fahrerUpdate"
          v-model="searchFahrer"
          label="Fahrer:in"
          data-vv-name="pnd"
          prepend-icon="mdi-account-outline"
          hide-no-data
          clearabel
          return-object
        >
        </v-autocomplete>
      </v-card-text>
    </v-card>
    <template v-if="isPersonalTasksAvailable">
      <v-card>
        <v-card-title> Hallo {{ this.fahrer.name }} </v-card-title>
        <div v-for="(task, index) in $store.getters.getPersonalManuelleTasks(
            this.fahrer.Personnel_nr, 'alle')" :key="index"
        >
          <PersonalTask :task="task" :meldetype="'manuell'"></PersonalTask>
        </div>
      </v-card>
      <GetFreeBus />
      <Version />
    </template>
    <template v-else>
      <v-card class="mt-5">
        <v-card-title> Hallo </v-card-title>
        <v-card-text class="pl-4"> Bitte einen Fahrer auswählen. </v-card-text>
      </v-card>
      <GetFreeBus />
      <Version />
    </template>
  </v-container>
</template>

<script>
import { DateTime } from "luxon";

import PersonalTask from "@/components/app/PersonalTask";
import Version from "@/components/version";
import GetFreeBus from "@/components/app/GetFreeBus";

export default {
  components: {
    PersonalTask,
    Version,
    GetFreeBus,
  },
  data() {
    return {
      isLoading: true,
      date: null,
      time: null,
      gettime: null,
      fahrer: {
        personnel_nr: "auswahl",
      },
      searchString: "",
      searchFahrer: null,
      wuensche: ["Fahr vorsichtig!", "Gute Fahrt!"],
      timeInterval: null,
    };
  },

  watch: {
    "$store.getters.getPersonalManuelleTasks": {
      handler: function (pt) {
        this.isLoading = false;
      },
      deep: true,
    },
  },

  computed: {
    firstName() {
      return this.$store.state.auth.user.displayName.split(",")[1];
    },

    isPersonalTasksAvailable() {
      if(this.fahrer !== null) {
        return this.$store.getters.getPersonalManuelleTasks(
          this.fahrer.Personnel_nr, 'alle'
        ).length;
      } else {
        return false;
      }
    },

    generateWuensche() {
      return this.wuensche[this.getRandomInt(this.wuensche.length)];
    },

    settings() {
      return this.$store.state.settings.settings;
    },

    istFahrerGemeldet() {
      return this.$store.getters
        .getPersonalManuelleTasks(this.fahrer.Personnel_nr, 'alle')
        .every((task) => task.anwesend === false);
    },
  },

  methods: {
    fahrerUpdate(e) {
      this.fahrer = e
      this.$nextTick(() => {
          this.searchString = ''
          this.searchFahrer = null
        });
    },

    searchBus() {
      this.fahrer= { personnel_nr: "auswahl" }
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * max)
    },

    fahrerMelden() {
      clearTimeout(this.timeInterval);
      this.time = DateTime.now().setLocale("de").toFormat("HH:mm");
      this.gettime = DateTime.now().minus({ minutes: 60 }).setLocale("de").toFormat("HH:mm");
      console.log("Time: ", this.gettime);
      this.$store.getters
        .getPersonalManuelleTasks(this.fahrer.Personnel_nr, this.gettime)
        .forEach((task) => {
          if (!task.anwesend) {
            this.$store.dispatch("updateBusExit", {
              date: this.date,
              docid: task.docid,
              data: { anwesendzeit: this.time, anwesend: true, anwesendmeldung: "manuell" },
            });
          }
        });
      this.isLoading = true;
    },
  },

  created() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.time = DateTime.now().setLocale("de").toFormat("HH:mm");
//    console.log("Day: ",DateTime.now().setLocale("de").setZone('Europe/Berlin').toFormat("ccc"));

//    this.timeInterval = setInterval(() => {
//      this.time = DateTime.now().setLocale("de").toFormat("HH:mm");
//    }, 1000);
  },
  
  mounted() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.time = DateTime.now().setLocale("de").toFormat("HH:mm");
    this.$store.dispatch("attachBusExit", { date: this.date });
    //this.$store.dispatch("attachStrangOnSnapshotoffline");
    this.$store.dispatch("attachDayrosterAll", { date: this.date });
    this.$forceUpdate();
  },

  beforeDestroy() {
    navigator.geolocation.clearWatch(this.currentCoordinatesId);
    clearTimeout(this.timeInterval);
    this.$store.dispatch("detachhbusexittypes");
  },
};
</script>
