<template>
  <form>
    <v-container>
      <Einfahrtmelden
        :active="dialog.einfahrtmelden"
        :datum="date"
        :doc="doc"
      ></Einfahrtmelden>
      <v-card v-if="$store.state.auth.isAuthenticating">
        <v-banner v-if="$store.state.busexit.EntranceisOnline == false" single-line>
          <v-icon slot="icon" color="warning" size="36">
            mdi-wifi-strength-alert-outline
          </v-icon>
          Offline-Modus!
        </v-banner>
        <v-card-title>
          <v-icon>mdi-bus-clock</v-icon>
          &nbsp; Einfahrt
        </v-card-title>
        <v-card-subtitle>
          letzte Aktualisierung:
          {{ $store.state.busexit.EntrancelastChange }}
        </v-card-subtitle>
        <v-card-text> </v-card-text>
        <v-card-actions>
          <v-switch
            :input-value="manuell"
            label="manuell gemeldete / alle"
            @change="toggleentranceAction()"
          ></v-switch>
        </v-card-actions>
      </v-card>
      <v-card id="exit-busentrance">
        <v-card-text>
          <template>
            <v-data-table
              :headers="busentranceheaders"
              fixed-header
              :items="this.entrancedata"
              :items-per-page="1000"
              @click:row="clickEinfahrtmelden"
              :mobile-breakpoint="550"
              item-key="id"
              :loading="EntranceisLoading"
              :class="scrollbarTheme"
              hide-default-footer
              height="50em"
            >
              <template v-slot:[`item.fahrer`]="{ item }">
                {{ item.neuFahrer ? item.neuFahrer : item.fahrer }}
              </template>
              <template v-slot:[`item.strang`]="{ item }">
                {{ getBusLocation(item.strang) }}
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </v-container>
  </form>
</template>

<script>
import { DateTime } from "luxon";
import Einfahrtmelden from "@/components/dialog/einfahrtmelden"

export default {
  data() {
    return {
      busentranceheaders: [
        {
          text: "Dienst",
          value: "dienst",
        },
        { text: "Fahrer/in", value: "fahrer" },
        { text: "Betrieb", value: "info" },
        { text: "Ausfahrtszeit", value: "anfang" },
        { text: "Linie", value: "linie" },
        { text: "Bus", value: "fahrzeug" },
        { text: "Wagen Nr", value: "wagennr" },
        { text: "Strang", value: "strang" },
        { text: "Einfahrt", value: "ende" },
        { text: "", value: "einfahrt" },
        { text: "gemeldet", value: "einfahrtgemeldet_um" },
      ],
      timerInterval: null,
      manuell: false,
      date: null,
      entrancedata: [],
      dialog: {
        einfahrtmelden: 0,
      },
    };
  },

  watch: {
  },
  computed: {
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "busentrance-dark dark" : "busentrance-light light";
    },
  },
  methods: {
    fetchData() {
      if (!this.manuell) {
        this.entrancedata = this.$store.getters.getEntranceData("manuell", this.$store.state.busexit.busentrancedata)
      } else {
        this.entrancedata = this.$store.getters.getEntranceData("", this.$store.state.busexit.busentrancedata)
      }
      //this.entrancedata = this.$store.state.busexit.busentrancedata
    },
    getBusLocation(strang) {
      if (isNaN(strang)) {
        return `${strang.strangNummer}-${strang.strangPosition}`;
      } else {
        return "";
      }
    },
    toggleentranceAction() {
      this.manuell = !this.manuell
    },
    clickEinfahrtmelden(value) {
      this.doc = value;
      const gemeldet = this.doc?.einfahrtgemeldet || false
      if (gemeldet == false) {
        this.dialog.einfahrtmelden++;
      }
    },
  },
  created() {
  },
  mounted() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.$store.dispatch("attachBusEntrance", { date: this.date }).then(() => {
      this.fetchData();
      this.timerInterval = setInterval(this.fetchData, 1000);
    });
  },
  beforeDestroy() {
    clearInterval(this.timerInterval);
    this.$store.dispatch("detachBusentrance");
  },
  components: {
    Einfahrtmelden,
  },
};
</script>

<style>
.container {
  max-width: 98% !important;
}
#exit-busentrance {
  height: 50em;
}
.busentrance-dark .v-data-table__wrapper::-webkit-scrollbar {
  width: 25px;
  height: 25px;
}
.busentrance-dark .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.busentrance-dark .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.busentrance-dark .v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: white;
}

.busentrance-light .v-data-table__wrapper::-webkit-scrollbar {
  width: 25px;
  height: 25px;
}

.busentrance-light .v-data-table__wrapper::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.busentrance-light .v-data-table__wrapper::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.busentrance-light .v-data-table__wrapper::-webkit-scrollbar-thumb:hover {
  background: black;
}

</style>
