<template>
  <nav>
    <v-app-bar app clipped-left>
      <v-app-bar-nav-icon
        v-if="$store.state.auth.isAuthenticating && $store.state.auth.withMenu"
        class="mr-3"
      >
        <v-btn icon @click.stop="drawer = !drawer">
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <div class="d-flex align-center">
        <a href="/">
          <v-img
            alt="Login NEW Logo"
            class="shrink mr-2"
            contain
            :src="require('@/assets/Logos/logo_colored.svg')"
            transition="scale-transition"
            width="64"
            v-if="!$vuetify.theme.dark"
          />
          <v-img
            alt="Login NEW Logo"
            class="shrink mr-2"
            contain
            :src="require('@/assets/Logos/logo_dark.svg')"
            transition="scale-transition"
            width="64"
            v-else
          />
        </a>
      </div>

      <v-toolbar-title class="flex text-center">MöBis</v-toolbar-title>
      <!--      <v-toolbar-title class="flex text-center">Terminportal für Mitarbeiter {{$store.state.windowtitel}}</v-toolbar-title>-->
      <!--<NotificationBell />-->

      <v-menu
        v-if="$store.state.auth.isAuthenticating"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-avatar v-bind="attrs" v-on="on" color="primary" class="white">
            <v-img
              v-if="$store.state.auth.photoURL != null"
              aspect-ratio="1"
              :src="$store.state.auth.photoURL"
              alt="Profilbild"
            />
            <span v-else>{{ letters }}</span>
          </v-avatar>
        </template>
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-avatar
                color="primary"
                class="white--text justify-center"
              >
                <v-img
                  v-if="$store.state.auth.photoURL != null"
                  :src="$store.state.auth.photoURL"
                  alt="Profilbild"
                />
                <span v-else>{{ letters }}</span>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $store.state.auth.user.displayName }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $store.state.auth.user.email }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <!---              <v-switch
                label="Dunkelmodus umschalten"
                @change="toggledarkmode()"
              ></v-switch>-->
            <v-list-item
              v-if="
                $store.state.auth.isAuthenticating &&
                $store.state.auth.permissions.useFunctions
              "
              @click="importAllEmployees()"
            >
              <v-list-item-subtitle>
                Mitarbeiter importieren
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item
              v-if="
                $store.state.auth.isAuthenticating &&
                $store.state.auth.permissions.useFunctions
              "
              @click="deleteOldDayroster()"
            >
              <v-list-item-subtitle>
                Tagesdienste löschen
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-divider></v-divider>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$store.state.auth.isAuthenticating && $store.state.auth.withMenu"
      v-model="drawer"
      app
      clipped
    >
      <v-list dense nav>
        <!-- parent menu = daten, parentmenuName = Key, :key setzt was die ID ist :value="parentMenuName" -->
        <v-list-item @click="openMoeBis()" class="pl-4" value="MöBis">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard-edit-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>MöBis</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <template v-for="(Menu, idx) in $store.state.menu.link">
          <v-list-group
            v-if="Menu.sublinks"
            :key="Menu.name"
            color="primaryLight"
            :group="Menu.name"
            :value="expand"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ Menu.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              v-for="(MenuItem, index) in Menu.sublinks"
              :key="Menu.name + '_' + index"
              :to="MenuItem.path"
              class="pl-4"
              :value="MenuItem.name"
            >
              <v-list-item-icon>
                <v-icon>{{ MenuItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ MenuItem.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </v-list-group>
          <div v-else :key="Menu.path">
            <v-list-item :key="Menu.name + '_' + idx" @click="openDoc(Menu)" class="pl-4" :value="Menu.name">
              <v-list-item-icon>
                <v-icon>{{ Menu.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ Menu.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
          </div>
        </template>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script>
//import { getApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { app } from "@/firebase/firebase";
//import NotificationBell from "./NotificationBell.vue";

export default {
  components: {
  //  NotificationBell,
  },

  data() {
    return {
      drawer: null,
      group: null,
      expand: false,
      dialogs: {},
    };
  },
  methods: {
    importAllEmployees() {
      const functions = getFunctions(app, "europe-west1");
      const importEmployees = httpsCallable(functions, "importEmployees");
      importEmployees().then((result) => {
        const res = result;
        console.log(res);
      });
    },
    deleteOldDayroster() {
      const functions = getFunctions(app, "europe-west1");
      const deleteDayroster = httpsCallable(functions, "deleteDayroster");
      deleteDayroster().then((result) => {
        const res = result;
        console.log(res);
      });
    },
    openDoc(menuItem) {
      this.$router.push({ name: menuItem.name }).catch(() => {});
      this.expand = true;
    },
    openMoeBis() {
      window.open(process.env.VUE_APP_MOEBIS_URL, "_self");
    },
    toggledarkmode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
  },
  watch: {
    "$store.state.auth.isAuthenticating": {
      handler: function (isAuthenticating) {
        if (isAuthenticating) {
          this.$store.dispatch("attachUserRoutesPermission").then(() => {
            this.$store.dispatch("loadMenu");
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    letters() {
      if ("displayName" in this.$store.state.auth.user) {
        var parts = this.$store.state.auth.user.displayName.split(" ");
        if (parts.length >= 2) {
          return (
            parts[parts.length - 1].substring(0, 1) + parts[0].substring(0, 1)
          );
        } else if (parts.length == 1) {
          return parts[0].substring(0, 3);
        }
      }
      return "";
    },
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      if (theme === "true") {
        this.$vuetify.theme.dark = true;
      } else {
        this.$vuetify.theme.dark = false;
      }
    }
    if (
      window.matchMedia &&
      window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
      this.$vuetify.theme.dark = true;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    } else {
      this.$vuetify.theme.dark = false;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    }
  },
};
</script>
