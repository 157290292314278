import securityincidentstatisticview from '@/views/statistic/securityincidentstatisticview.vue'

const routesstatistic = [
    {
      path: '/securityincidentstatisticview',
      name: 'Sicherheitsrel. Vorf. Übersicht',
      icon: 'mdi-security',
      right: 'securityincidentstatisticview',
      navParent: "Statistik",
      component: securityincidentstatisticview
    },
];

export default routesstatistic;

