import zbltagesbericht from '@/views/zbl/Zbltagesbericht.vue'
import zbltagesberichtview from '@/views/zbl/Zbltagesberichtview.vue'
import zblreservedienst from '@/views/zbl/Zblreservedienst.vue'
import zblreservedienstview from '@/views/zbl/Zblreservedienstview.vue'

const routeszbltagesbericht = [
    {
        path: '/Zbltagesbericht/:readonly',
        name: 'ZBL-Tagesbericht',
        icon: 'mdi-calendar',
        right: 'Zbltagesbericht',
        readonly: false,
        navParent: "ZBL",
        component: zbltagesbericht
    },
    {
        path: '/Zbltagesbuebersicht/:readonly',
        name: 'ZBL-Tagesb. übersicht',
        icon: 'mdi-calendar',
        right: 'Zbltagesbericht',
        readonly: true,
        navParent: "ZBL",
        component: zbltagesberichtview
    },
    {
        path: '/Zblreservedienst/:readonly',
        name: 'ZBL-Reservedienst',
        icon: 'mdi-calendar',
        right: 'Zblreservedienst',
        readonly: false,
        navParent: "ZBL",
        component: zblreservedienst
    },
    {
        path: '/Zblreservedienstuebersicht/:readonly',
        name: 'ZBL-Reserved. übersicht',
        icon: 'mdi-calendar',
        right: 'Zblreservedienst',
        readonly: true,
        navParent: "ZBL",
        component: zblreservedienstview
    },
];

export default routeszbltagesbericht;