<template>
  <v-container>
    <v-list-item-title class="headline mb-1"></v-list-item-title>
  </v-container>
</template>

<script>
//import firebase from 'firebase/app';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  created: function () {
    
  },
  computed: {
    
  },
  watch: {
    "$store.state.auth.user": {
      handler: function (user) {
        if (user) {
          //this.$store.dispatch("attachUserDataOnSnapshot");
        }
      },
      immediate: true,
    },
  },
  methods: {

  },
  mounted() {
/*    this.$store.dispatch("loadContent", {art: ""});
    this.$store.dispatch("loadGroupRights", {subart: ""}).then(() => {
      this.$store.dispatch("loadEmployeeData").then(() => {
        this.$store.dispatch("loadEmployees", {loggedin: true});
        this.$store.dispatch("loadAppointments", {typ: "", art: ""});
      });
    });*/
  },
  components: {
    
  },
};
</script>
