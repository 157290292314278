<template>
  <v-dialog v-model="dialogVisible" persistent max-width="950px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>Reservediensteinsatz anlegen</v-card-title>
      <v-card-text>
        <v-card width='100%'>
          <v-row>
            <v-col md="12">
              <v-row>
                <v-col sm="3">
                  <v-card tile flat>
                    <v-autocomplete
                      :items="$store.state.dayroster.roster"
                      item-text='Service_nr'
                      item-value='Service_nr'
                      v-model="rd.dienst" 
                      label="Dienst" data-vv-name="rd.dienst" prepend-icon="mdi-account-outline" return-object
                      v-on:change="servicechanged(rd.dienst)"
                    >
                    </v-autocomplete>
                  </v-card>
                </v-col>
                <v-col sm="2">
                  <v-switch
                    :input-value="rd.busexit"
                    @change="toggleBusexitAction(rd)"
                    :disabled="isService"
                    label="Ausfahrt ändern"
                  ></v-switch>
                </v-col>
                <v-col sm="2">
                  <v-switch
                    :input-value="rd.komplett"
                    @change="toggleKompleteAction(rd)"
                    label="kompl. Dienst"
                  ></v-switch>
                </v-col>
                <v-col sm="2">
                  <v-card tile flat>
                    <timepicker 
                      v-bind:label="labelvon"
                      v-bind:field="rd.anfang" 
                      v-bind:modal="modal"
                      v-bind:i1=0
                      v-bind:i2=0
                      v-bind:i3=0
                      v-bind:ro="false"
                      v-bind:typ="'rdeinsatzvon'"
                      @changetime="changetime($event)"
                    ></timepicker>
                  </v-card>
                </v-col>
                <v-col sm="2">
                  <v-card tile flat>
                    <timepicker 
                      v-bind:label="labelbis"
                      v-bind:field="rd.ende" 
                      v-bind:modal="modal"
                      v-bind:i1=0
                      v-bind:i2=0
                      v-bind:i3=0
                      v-bind:ro="false"
                      v-bind:typ="'rdeinsatzbis'"
                      @changetime="changetime($event)"
                    ></timepicker>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primaryLight"
          text
          @click="canclereservedienst"
          value="CANCELRESERVEDIENST"
        >
          Abbrechen
        </v-btn>
        <v-btn color="primaryLight" text @click="addreservedienst">
          Speichern
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { DateTime } from "luxon";
import timepicker from "@/components/app/RDTimePicker";

export default {
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      service: {},
      busexit: [],
      rd: {
        dienst: "",
        von: null,
        bis: null,
        busexit: false,
        komplett: false,
        modal: false,
        firebase: false,
      },
      modal: false,
    };
  },

  computed: {
    isService() {
      return this.busexit.length == 0 ? true : false
    }
  },

  watch: {
    active() {
      this.dialogVisible = true;
    },
  },

  methods: {
    addreservedienst() {
      if (this.rd !== '' && this.rd.von && this.rd.bis && this.rd.dienst !== '') {

        this.$store.dispatch('addEinsatz', {date: this.date, docid: this.docid, einsatzdata: this.rd, typ: this.typ})
        if (this.rd.busexit) {
          for (const _be of this.busexit) {
            console.log("AUSFAHRT: ", _be)
            if (_be.anfang >= this.rd.von && this.rd.bis > _be.anfang) {
              console.log("AUSFAHRT: ", `${this.employee[0].name}, ${this.employee[0].firstname}`)
              const data = {
                neuFahrer: `${this.employee[0].name}, ${this.employee[0].firstname}`,
                personnel_nr: this.employee[0].persid,
              };
              console.log("DATA: ", _be.docid, data)
              this.$store.dispatch("updateBusExit", {
                date: this.date,
                docid: _be.docid,
                data,
              });
            }
          }
        }
        //this.$store.commit('showSnackbar', { message: 'Eintrag gespeichert!', status: 'success' }, { root: true })
        this.resetRD();
      } else {
        this.$store.commit('showSnackbar', { message: 'Eintrag wurde nicht gespeichert!', status: 'error' }, { root: true })
      }
      this.dialogVisible = false;
    },
    canclereservedienst() {
      this.resetRD();
      this.dialogVisible = false;
    },
    changetime(objtime) {
      if (this.rd.komplett) {
        this.rd.komplett = !this.rd.komplett
      }
      switch(objtime.typ) {
        case "rdeinsatzvon":
          this.rd.von = objtime.time;
          break;
        case "rdeinsatzbis":
          this.rd.bis = objtime.time;
          break;
      }
    },
    resetRD() {
      this.rd = {
        dienst: "",
        von: null,
        bis: null,
        busexit: false,
        komplett: false,
        modal: false,
        firebase: false,
      }
    },
    servicechanged(service) {
      //console.log("SERVICE: ", service)
      this.service = service
      this.busexit = this.$store.getters.getBusexitServicenr(service.Service_nr)
/*      if (this.busexit.length > 0) {
        console.log("SERVICE: ", this.busexit)
      }*/
    },
    toggleKompleteAction(_rd) {
      _rd.komplett = !_rd.komplett
      if (_rd.komplett) {
        _rd.anfang = this.service.service_begin
        _rd.von = _rd.anfang
        _rd.ende = this.service.service_end
        _rd.bis = _rd.ende        
      } else {
        _rd.ende = null
        _rd.bis = null
        _rd.anfang = null
        _rd.von = null
      }
    },
    toggleBusexitAction(_rd) {
      _rd.busexit = !_rd.busexit
    },
  },

  created() {},

  props: {
    active: Number,
    date: String,
    docid: String,
    typ: String,
    employee: Object,
  },

  components: {
    timepicker,
  },
};
</script>