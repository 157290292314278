import fdkuserdayview from '@/views/fahrer/fdkuserdayview.vue'
import fdkuseraktdayview from '@/views/fahrer/fdkuseraktdayview.vue'
import securityincidentview from '@/views/fahrer/securityincidentview.vue'
import digitaleausfahrt from '@/views/fahrer/digitaleausfahrt.vue'
import manuellefahrerausfahrt from '@/views/fahrer/manuellefahrerausfahrt.vue'

const routesfahrer = [
    {
        path: '/fdkuseraktdayview',
        name: 'FDK-Tagessicht',
        icon: 'mdi-bus-clock',
        right: 'fdkuseraktdayview',
        navParent: "Fahrer",
        component: fdkuseraktdayview
    },
    {
        path: '/securityincidentview',
        name: 'Sicherheitsrel. Vorfälle',
        icon: 'mdi-security',
        right: 'securityincidentview',
        navParent: "Fahrer",
        component: securityincidentview
    },
    {
        path: '/fdkuserdayview/:date',
        name: 'FDK-Tagessicht',
        icon: 'mdi-bus-clock',
        right: 'fdkuserdayview',
        navParent: "",
        component: fdkuserdayview,
        navIgnore: true
    },
    {
        path: '/digitaleausfahrt',
        name: 'Digitale Ausfahrt',
        icon: 'mdi-location-exit',
        right: 'digitaleausfahrt',
        navParent: "Fahrer",
        component: digitaleausfahrt
    },
    {
        path: '/manuellefahrerausfahrt',
        name: 'Ausfahrt manuel',
        icon: 'mdi-location-exit',
        right: 'MUM',
        navParent: "Fahrer",
        component: manuellefahrerausfahrt
    },
];

export default routesfahrer;
