<template>
    <ZBLtagesbericht v-bind:readonly="readonly"></ZBLtagesbericht>
</template>

<script>

import ZBLtagesbericht from "@/components/app/FormZbltagesbericht";

export default {
    name: "ZBL-Tagesbericht Übersicht",
    data() {
        return {
            readonly: true,
        };
    },
    created: function () {
        this.readonly = this.$route.params.readonly;
    },
    computed: {
        
    },
    watch: {

    },
    methods: {

    },
    mounted() {
        this.$store.state.auth.withMenu = true;
//        this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
    },
    beforeDestroy() {
        this.$store.dispatch("detachZBLDayReportOnSnapshot");
        this.$store.dispatch("detachDayrosterAll");
        this.$store.dispatch("detachBusLineOnSnapshot");
        this.$store.dispatch("detachBusStationOnSnapshot");
        this.$route.params.date = null;
    },
    components: {
        ZBLtagesbericht,
    },
};
</script>
