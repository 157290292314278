<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card v-if="$store.state.auth.isAuthenticating">
          <v-banner v-if="$store.state.strang.isOnline == false" single-line>
            <v-icon slot="icon" color="warning" size="36">
              mdi-wifi-strength-alert-outline
            </v-icon>
            Offline-Modus!
          </v-banner>
          <v-card-title>
            <v-icon>mdi-bus-clock</v-icon>
            &nbsp; Stellplatzerfassung
          </v-card-title>
          <v-card-subtitle>
            letzte Aktualisierung:
            {{ $store.state.strang.lastChange }}
          </v-card-subtitle>
          <v-row dense>
            <v-col cols="6">
              <v-card>
      <!--          <v-card-title>
                  <span class="text-h5">Stellplatz</span>
                </v-card-title>-->
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        type="tel"
                        :items="$store.getters.sortStraenge('frueh')"
                        :loading="$store.state.strang.isLoading"
                        :item-text="item => `${item.strangNummer} - ${item.name}`"
                        :item-value="item => `${item.strangNummer} - ${item.name}`"
                        v-model="selectedStellplatz1"
                        label="Strangnummer"
                        density="compact"
                        return-object
                        @change="(event) => updateStellplatz(event)"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="selSP1.strangNummer < 300">
                    <v-col cols="12" v-for="(pos, indexpos) in selSP1.position" :key="indexpos">
                      <v-row>
                        <v-col cols="10">
                          <template v-if="selSP1.position[indexpos].anzahl > 0">
                            <v-autocomplete v-if="Object.keys(selSP1.position[indexpos].kom).length == 0"
                              type="tel"
                              :items="vehicles"
                              item-text="text"
                              item-value="text"
                              v-model="selSP1.position[indexpos].kom"
                              :label="`Pos ${indexpos}`"
                              density="compact"
                              return-object
                              hide-no-data
                              @change="updatePosition(selSP1)"
                            >
                            </v-autocomplete>
                            <div id="vehicletext" v-else>
                              <div id="vehiclepos">
                                Pos {{ indexpos }}
                              </div>
                              {{ selSP1.position[indexpos].kom.text }}
                            </div>
                          </template>
                          <div id="vehicletext" 
                            v-if="selSP1.position[indexpos].anzahl == 0">Pos. leer</div>
                        </v-col>
                        <v-col cols="2">
                          <v-icon 
                            v-if="selSP1.position[indexpos].anzahl <= 0"
                            @click="insertVehicle(selSP1, indexpos)"> 
                            mdi-plus 
                          </v-icon>
                          <v-icon 
                            v-if="selSP1.position[indexpos].anzahl > 0"
                            @click="deleteVehicle(selSP1, indexpos)"> 
                            mdi-minus 
                          </v-icon>
                          <v-icon @click="deleteStellplatz(selSP1, indexpos)"> mdi-delete </v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <p v-else>Auf dem Strang {{ selectedStellplatz1.strangNummer }} können keine Fahrzeuge erfasst werden!</p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card>
      <!--          <v-card-title>
                  <span class="text-h5">Stellplatz</span>
                </v-card-title>-->
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        type="tel"
                        :items="$store.getters.sortStraenge('frueh')"
                        :loading="$store.state.strang.isLoading"
                        :item-text="item => `${item.strangNummer} - ${item.name}`"
                        :item-value="item => `${item.strangNummer} - ${item.name}`"
                        v-model="selectedStellplatz2"
                        label="Strangnummer"
                        density="compact"
                        return-object
                        @change="(event) => updateStellplatz(event)"
                      >
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row v-if="selSP2.strangNummer < 300">
                    <v-col cols="12" v-for="(pos, indexpos) in selSP2.position" :key="indexpos">
                      <v-row>
                        <v-col cols="10">
                          <template v-if="selSP2.position[indexpos].anzahl > 0">
                            <v-autocomplete v-if="Object.keys(selSP2.position[indexpos].kom).length == 0"
                              type="tel"
                              :items="vehicles"
                              item-text="text"
                              item-value="text"
                              v-model="selSP2.position[indexpos].kom"
                              :label="`Pos ${indexpos}`"
                              density="compact"
                              return-object
                              hide-no-data
                              @change="updatePosition(selSP2)"
                            >
                            </v-autocomplete>
                            <div id="vehicletext" v-else>
                              <div id="vehiclepos">
                                Pos {{ indexpos }}
                              </div>
                              {{ selSP2.position[indexpos].kom.text }}
                            </div>
                          </template>
                          <div id="vehicletext" 
                            v-if="selSP2.position[indexpos].anzahl == 0">Pos. leer</div>
                        </v-col>
                        <v-col cols="2">
                          <v-icon 
                            v-if="selSP2.position[indexpos].anzahl <= 0"
                            @click="insertVehicle(selSP2, indexpos)"> 
                            mdi-plus 
                          </v-icon>
                          <v-icon 
                            v-if="selSP2.position[indexpos].anzahl > 0"
                            @click="deleteVehicle(selSP2, indexpos)"> 
                            mdi-minus 
                          </v-icon>
                          <v-icon @click="deleteStellplatz(selSP2, indexpos)"> mdi-delete </v-icon>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                  <p v-else>Auf dem Strang {{ selectedStellplatz2.strangNummer }} können keine Fahrzeuge erfasst werden!</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <version />
  </v-container>
</template>

<script>
import { DateTime } from "luxon"
import version from '@/components/version';

export default {
  components: {
    version,
  },
  data() {
    return {
      selectedStellplatz1: {},
      selectedStellplatz2: {},
      selSP1: {},
      selSP2: {},
      friends: [],
    }
  },

  watch: {
    selectedStellplatz1(newVal) {
      const _selItem = this.$store.getters.getStrangbyNr(newVal.strangNummer, newVal.nameobj.id)
      if (_selItem.length == 1)
        this.selSP1 = _selItem[0]
    },
    selectedStellplatz2(newVal) {
      const _selItem = this.$store.getters.getStrangbyNr(newVal.strangNummer, newVal.nameobj.id)
      if (_selItem.length == 1)
        this.selSP2 = _selItem[0]
    },
  },

  computed: {
    vehicles() {
      let _vehicles = []
      const _st = this.$store.getters.sortStraenge('frueh')
      for (let strang of _st) {
        if (strang.strangNummer == this.selSP1.strangNummer &&
            strang.nameobj.id == this.selSP1.nameobj.id) {
              this.selSP1 = strang
        }
        if (strang.strangNummer == this.selSP2.strangNummer &&
            strang.nameobj.id == this.selSP2.nameobj.id) {
              this.selSP2 = strang
        }
        if (strang.hasOwnProperty("position")) {
          for (let _pos = 1; _pos <= Object.keys(strang.position).length; _pos++) {
            if (strang.position[_pos].hasOwnProperty("kom")) {
              if (Object.keys(strang.position[_pos].kom).length > 0) {
                _vehicles.push(strang.position[_pos].kom)
              }
            }
          }
        }
      }
      const idsSet = new Set(_vehicles.map(obj => obj.text));
      const cObjects = this.$store.getters.KomList.filter(obj => !idsSet.has(obj.text));
      return cObjects 
    },
  },

  methods: {
    updateStellplatz(stellplatz) {
      for(let _pos in stellplatz.position) {
        stellplatz.position[_pos].kom = stellplatz.position[_pos]?.kom || ""
      }
    },
    updatePosition(stellplatz) {
      this.$store.dispatch("updateStrang", {
        docid: stellplatz.strangNummer+'_'+stellplatz.nameobj.id,
        data: {
          ...stellplatz,
        },
        strangNummer: stellplatz.strangNummer.toString(),
        name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
      });
      if (this.$refs?.autocomplete)
        this.$refs.autocomplete.hideMenu();
    },
    insertVehicle(stellplatz, index) {
      if (stellplatz.maxBus > stellplatz.anzahl) {
        stellplatz.anzahl += 1
        stellplatz.position[index].anzahl = 1
        this.updatePosition(stellplatz)
      }
    },
    deleteVehicle(stellplatz, index) {
      if (stellplatz.maxBus > stellplatz.anzahl) {
        stellplatz.anzahl -= 1
        stellplatz.position[index].anzahl = 0
        stellplatz.position[index].kom = {}
        this.updatePosition(stellplatz)
      }
    },
    deleteStellplatz(stellplatz, index) {
      console.log("DELETE", index, stellplatz)
      stellplatz.position[index].kom = {}
      this.updatePosition(stellplatz)
    },
  },

  created() {
    this.$store.dispatch("attachVehiclesOnSnapshot")
    this.$store.dispatch("attachbusexittypes")
  },

  mounted() {
    const date = DateTime.now()
          .setLocale("de")
          .plus({ days: -2 })
          .toFormat("yyyy-MM-dd 00:00:00");
  },

  beforeDestroy() {
 
  },
};
</script>

<style scoped>
  #vehicletext {
    font-size: 16px;
  }
  #vehiclepos {
    font-size: 12px;
  }
  .autocomplete {
    position: relative;
    max-width: 300px;
    margin: auto;
  }
  input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
  }
  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ccc;
    background: #fff;
    max-height: 150px;
    overflow-y: auto;
    z-index: 1000;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .dropdown li {
    padding: 10px;
    cursor: pointer;
  }
  .dropdown li:hover {
    background: #f0f0f0;
  }
</style>
