import { db } from '@/firebase/firebase'
import { collection, commit, doc, onSnapshot, query, where, updateDoc, setDoc, deleteDoc } from "firebase/firestore";
import { DateTime } from "luxon";
import axios from "axios";
import { _ } from 'core-js';

const state = {
    tb: {
        datum: DateTime.now().setLocale('de').toFormat("yyyy-MM-dd"),
        datumanzeige: DateTime.now().setLocale('de').toFormat("dd.MM.yyyy"),
        type: 1,
        edit: "",
        edittime: null,
        platz1: [{
            dienst: "",
            name: "",
            modal1: false,
            modal2: false,
            von: null,
            bis: null,
            bemerkung: ""
        }],
        platz2: [{
            dienst: "",
            name: "",
            modal1: false,
            modal2: false,
            von: null,
            bis: null,
            bemerkung: ""
        }],
        wetterlage: "",
        wetter: [{
            zeit: "",
            temperatur: 0,
            hydro: 0,
            wetterlage: ""
        }],
        betriebsablauf: [{
            dienst: "",
            bericht: ""
        }],
        anzahl: [{
            dienst: "",
            anzahlunfall: 0,
            anzahlpersonal: 0,
            anzahlausfall: 0,
            anzahlverspaetung: 0,
        }],
        polizei: [{
            zeit: null,
            dienst: "",
            meldung: "",
            modal: false
        }],
        zblstoerung: "",
        streudienst: [{
            zeit: null,
            strecke: "",
            modal: false
        }],
        meldung: [{
            dienst: "",
            meldung: ""
        }],
        readonly: false,
        isBlocktimerVisible: false,
        BlocktimerText: '',
    },
    fahrtausfall: [{
        id: DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss"),//moment(new Date()).locale('de').format('YYYYMMDDHHmmss'),
        linie: "",
        dienst: "",
        zeit: null,
        zeitbis: null,
        fahrtvon: "",
        fahrtnach: "",
        grund: "",
        bemerkung: ""
    }],
    taxibestellung: [{
        id: DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss"),//moment(new Date()).locale('de').format('YYYYMMDDHHmmss'),
        dienst: "",
        zeit: null,
        haltestelle: "",
        ankunft: null,
        ort: "",
        bemerkung: ""
    }],
    sammeltaxi: [{
        id: DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss"),//moment(new Date()).locale('de').format('YYYYMMDDHHmmss'),
        abfahrt: null,
        einstieg: "",
        ausstieg: "",
        personen: 0,
        bestellzeit: null,
        bestellperson: "",
    }],
    meldungverkehrsabteilung: [{
        id: DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss"),//moment(new Date()).locale('de').format('YYYYMMDDHHmmss'),
        linie: "",
        dienst: "",
        zeit: null,
        fahrer: "",
        kom: "",
        grund: "",
        bemerkung: "",
        meister: "",
    }],
    euavongelenkbus: [{
        id: DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss"),//moment(new Date()).locale('de').format('YYYYMMDDHHmmss'),
        kom: "",
        dienst: "",
        modal1: false,
        modal2: false,
        von: null,
        bis: null,
        typ: "",
    }],
    zuaueinsatzwagen: [{
        id: DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss"),//moment(new Date()).locale('de').format('YYYYMMDDHHmmss'),
        linie: "",
        zeit: null,
        fahrtvon: "",
        fahrtnach: "",
        kom: "",
        dienst: "",
        fahrer: "",
        grund: "",
        modal1: false,
    }],
    ausgewechseltewagen: [{
        id: DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss"),//moment(new Date()).locale('de').format('YYYYMMDDHHmmss'),
        lfdnr: 0,
        kom: "",
        dienst: "",
        linie: "",
        fahrer: "",
        zeit: null,
        ort: "",
        ausfall: "",
        ekom: "",
        efahrer: "",
        grund: "",
        kennziffer: "",
        erleuterung: "",
        modal1: false,
    }],
    geraetwechsel: [{
        id: DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss"),//moment(new Date()).locale('de').format('YYYYMMDDHHmmss'),
        typ: "",
        zeit: null,
        neugeraet: "",
        altgeraet: "",
        position: "",
        fahrer: "",
        dienst: "",
        kom: "",
        vermerk: "",
        stoerung: "",
        modal: false,
    }],
/*    rd: [{
        datum: DateTime.now().setLocale('de').toFormat("yyyy-MM-dd"),
        datumanzeige: DateTime.now().setLocale('de').toFormat("dd.MM.yyyy"),
        kommg: "",
        komry: "",
        frueh: [{
            dienst: "",
            von: null,
            bis: null,
            name: "",
            persid: "",
            ro: true,
            modal: false,
            einsatz: [{}],
        }],
        mittel: [{
            dienst: "",
            von: null,
            bis: null,
            name: "",
            persid: "",
            ro: true,
            modal: false,
            einsatz: [{}],
        }],
        spaet: [{
            dienst: "",
            von: null,
            bis: null,
            name: "",
            persid: "",
            ro: true,
            modal: false,
            einsatz: [{}],
        }],
    }],*/
    zbldayreportListener: null,
    zblfahrtausfallListener: null,
    zbltaxibestellung: null,
    zblsammeltaxi: null,
    zblmeldungverkehrsabteilung: null,
    zbleuavongelenkbus: null,
    zblzuaueinsatzwagen: null,
    zblausgewechseltewagen: null,
    zblgeraetwechsel: null,
    isLoading: false,
    isOnline: true,
    zblreserveserviceListener: null,
    isRDLoading: false,
    isRDOnline: true
}

const setTB = date => {
    const _tb = {
        datum: date,
        datumanzeige: DateTime.fromFormat(date, "yyyy-MM-dd").setLocale('de').toFormat("dd.MM.yyyy"),
        type: 1,
        edit: "",
        edittime: null,
        platz1: [{
            dienst: "",
            name: "",
            modal1: false,
            modal2: false,
            von: null,
            bis: null,
            bemerkung: ""
        }],
        platz2: [{
            dienst: "",
            name: "",
            modal1: false,
            modal2: false,
            von: null,
            bis: null,
            bemerkung: ""
        }],
        wetterlage: "",
        wetter: [{
            zeit: "",
            temperatur: 0,
            hydro: 0,
            wetterlage: ""
        }],
        betriebsablauf: [{
            dienst: "",
            bericht: ""
        }],
        anzahl: [{
            dienst: "",
            anzahlunfall: 0,
            anzahlpersonal: 0,
            anzahlausfall: 0,
            anzahlverspaetung: 0,
        }],
        polizei: [{
            zeit: null,
            dienst: "",
            meldung: "",
            modal: false
        }],
        zblstoerung: "",
        streudienst: [{
            zeit: null,
            strecke: "",
            modal: false
        }],
        meldung: [{
            dienst: "",
            meldung: ""
        }],
        readonly: false,
        isBlocktimerVisible: false,
        BlocktimerText: '',
    }
    return _tb;
}
/*const setRD = date => {
    const _rd = {
        datum: date,
        datumanzeige: DateTime.fromFormat(date, "yyyy-MM-dd").setLocale('de').toFormat("dd.MM.yyyy"),
        type: 2,
        kommg: "",
        komry: "",
        frueh: [{
            dienst: "",
            von: null,
            bis: null,
            name: "",
            persid: "",
            ro: true,
            modal: false,
            einsatz: [{}],
        }],
        mittel: [{
            dienst: "",
            von: null,
            bis: null,
            name: "",
            persid: "",
            ro: true,
            modal: false,
            einsatz: [{}],
        }],
        spaet: [{
            dienst: "",
            von: null,
            bis: null,
            name: "",
            persid: "",
            ro: true,
            modal: false,
            einsatz: [{}],
        }],
    }
    return _rd;
}*/
const setFahrtausfall = _id => {
    const _fahrtausfall = {
        id: _id,
        linie: "",
        dienst: "",
        zeit: null,
        zeitbis: null,
        fahrtvon: "",
        fahrtnach: "",
        grund: "",
        bemerkung: ""
    }
    return _fahrtausfall
}
const setTaxibestellung = _id => {
    const _taxibestellung = {
        id: _id,
        dienst: "",
        zeit: null,
        haltestelle: "",
        ankunft: null,
        ort: "",
        bemerkung: ""
    }
    return _taxibestellung;
}
const setSammeltaxi = _id => {
    const _sammeltaxi = {
        id: _id,
        abfahrt: null,
        einstieg: "",
        ausstieg: "",
        personen: 0,
        bestellzeit: null,
        bestellperson: ""
    }
    return _sammeltaxi;
}
const setMeldungverkehrsabteilung = id => {
    const meldungverkehrsabteilung = {
        id: id,
        linie: "",
        dienst: "",
        zeit: null,
        fahrer: "",
        kom: "",
        grund: "",
        bemerkung: "",
        meister: "",
    }
    return meldungverkehrsabteilung;
}
const setEuavongelenkbus = id => {
    const euavongelenkbus = {
        id: id,
        kom: "",
        dienst: "",
        modal1: false,
        modal2: false,
        von: null,
        bis: null,
        typ: "",
    }
    return euavongelenkbus;
}
const setZuaueinsatzwagen = id => {
    const zuaueinsatzwagen = {
        id: id,
        linie: "",
        zeit: null,
        fahrtvon: "",
        fahrtnach: "",
        kom: "",
        dienst: "",
        fahrer: "",
        grund: "",
        modal1: false,
    }
    return zuaueinsatzwagen;
}
const setAusgewechseltewagen = id => {
    const ausgewechseltewagen = {
        id: id,
        lfdnr: 0,
        kom: "",
        dienst: "",
        linie: "",
        fahrer: "",
        zeit: null,
        ort: "",
        ausfall: "",
        ekom: "",
        efahrer: "",
        grund: "",
        kennziffer: "",
        erleuterung: "",
        modal1: false,
    }
    return ausgewechseltewagen;
}
const setGeraetwechsel = id => {
    const geraetwechsel = {
        id: id,
        typ: "",
        zeit: null,
        neugeraet: "",
        altgeraet: "",
        position: "",
        fahrer: "",
        dienst: "",
        kom: "",
        vermerk: "",
        stoerung: "",
        modal: false,
    }
    return geraetwechsel;
}

const getters = {}

const actions = {

    attachZBLDayReport({ state, commit }, { date }) {
        return new Promise((resolve, reject) => {
            if (state.zbldayreportListener) {
                resolve(state.tb);
                if (state.zblfahrtausfallListener) {
                    resolve(state.fahrtausfall);
                }
                if (state.zbltaxibestellung) {
                    resolve(state.taxibestellung);
                }
                if (state.zblsammeltaxi) {
                    resolve(state.sammeltaxi);
                }
                if (state.zblmeldungverkehrsabteilung) {
                    resolve(state.meldungverkehrsabteilung);
                }
                if (state.zbleuavongelenkbus) {
                    resolve(state.euavongelenkbus);
                }
                if (state.zblzuaueinsatzwagen) {
                    resolve(state.zuaueinsatzwagen);
                }
                if (state.zblausgewechseltewagen) {
                    resolve(state.ausgewechseltewagen);
                }
                if (state.zblgeraetwechsel) {
                    resolve(state.geraetwechsel);
                }
                return;
            }
            state.isLoading = true;
            const docid =  date + "_1";
            const q = query(collection(db, "zblreport"), where("type", "==", 1), where("datum", "==", date));
            state.zbldayreportListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    if(snapshot.docs.length == 0) {
                        console.log('INSERT', docid);
                        state.tb = setTB(date);
                        const docRef = doc(db, "zblreport", docid);
                        setDoc(docRef, state.tb).then(() => {
                            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
                            
                        }).catch(err => {
                            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
                        })
                    }
                    snapshot.docs.forEach((document) => {
                        if (document.exists) {
                            state.tb = document.data();
//*** Fahrtausfall */
                            let _q = query(collection(db,"zblreport", docid, "fahrtausfall"));
                            const _id = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
                            state.zblfahrtausfallListener =
                                onSnapshot(_q, (snapshot) => {
//                                    console.log(_id, snapshot.docs.length);
                                    if(snapshot.docs.length === 0) {
                                        setDoc(doc(db, "zblreport", docid, "fahrtausfall", _id), setFahrtausfall(_id));
                                    }
                                    state.fahrtausfall.splice(0, state.fahrtausfall.length);
                                    snapshot.docs.forEach((doc) => {
                                        if (doc.exists) {
                                            state.fahrtausfall.push(doc.data());
                                        }
                                    })
                                    resolve(state.fahrtausfall);
                                }, (error) => {
                                    console.log(error.message);
                                    reject(error);
                                })
//*** Taxibestellung */
                            _q = query(collection(db,"zblreport", docid, "taxibestellung"));
                            state.zbltaxibestellung =
                                onSnapshot(_q, (snapshot) => {
                                    if(snapshot.docs.length === 0) {
                                        setDoc(doc(db, "zblreport", docid, "taxibestellung", _id), setTaxibestellung(_id));
                                    }
                                    state.taxibestellung.splice(0, state.taxibestellung.length);
                                    snapshot.docs.forEach((doc) => {
                                        if (doc.exists) {
                                            state.taxibestellung.push(doc.data());
                                        }
                                    })
                                    resolve(state.taxibestellung);
                                }, (error) => {
                                    console.log(error.message);
                                    reject(error);
                                })
//*** Sammeltaxi */
                                _q = query(collection(db,"zblreport", docid, "sammeltaxi"));
                                state.zblsammeltaxi =
                                    onSnapshot(_q, (snapshot) => {
                                        if(snapshot.docs.length === 0) {
                                            setDoc(doc(db, "zblreport", docid, "sammeltaxi", _id), setSammeltaxi(_id));
                                        }
                                        state.sammeltaxi.splice(0, state.sammeltaxi.length);
                                        snapshot.docs.forEach((doc) => {
                                            if (doc.exists) {
                                                state.sammeltaxi.push(doc.data());
                                            }
                                        })
                                        resolve(state.sammeltaxi);
                                    }, (error) => {
                                        console.log(error.message);
                                        reject(error);
                                    })
//*** Meldung an die Verkehrsabteilung meldungverkehrsabteilung */
                                _q = query(collection(db,"zblreport", docid, "meldungverkehrsabteilung"));
                                state.zblmeldungverkehrsabteilung =
                                    onSnapshot(_q, (snapshot) => {
                                        if(snapshot.docs.length === 0) {
                                            setDoc(doc(db, "zblreport", docid, "meldungverkehrsabteilung", _id), setMeldungverkehrsabteilung(_id));
                                        }
                                        state.meldungverkehrsabteilung.splice(0, state.meldungverkehrsabteilung.length);
                                        snapshot.docs.forEach((doc) => {
                                            if (doc.exists) {
                                                state.meldungverkehrsabteilung.push(doc.data());
                                            }
                                        })
                                        resolve(state.meldungverkehrsabteilung);
                                    }, (error) => {
                                        console.log(error.message);
                                        reject(error);
                                    })
//*** Einsatz und abzug von Bussen euavongelenkbus */
                                _q = query(collection(db,"zblreport", docid, "euavongelenkbus"));
                                state.zbleuavongelenkbus =
                                    onSnapshot(_q, (snapshot) => {
                                        if(snapshot.docs.length === 0) {
                                            setDoc(doc(db, "zblreport", docid, "euavongelenkbus", _id), setEuavongelenkbus(_id));
                                        }
                                        state.euavongelenkbus.splice(0, state.euavongelenkbus.length);
                                        snapshot.docs.forEach((doc) => {
                                            if (doc.exists) {
                                                state.euavongelenkbus.push(doc.data());
                                            }
                                        })
                                        resolve(state.euavongelenkbus);
                                    }, (error) => {
                                        console.log(error.message);
                                        reject(error);
                                    })
//*** Einsatz und abzug von Bussen zuaueinsatzwagen */
                                _q = query(collection(db,"zblreport", docid, "zuaueinsatzwagen"));
                                state.zblzuaueinsatzwagen =
                                    onSnapshot(_q, (snapshot) => {
                                        if(snapshot.docs.length === 0) {
                                            setDoc(doc(db, "zblreport", docid, "zuaueinsatzwagen", _id), setZuaueinsatzwagen(_id));
                                        }
                                        state.zuaueinsatzwagen.splice(0, state.zuaueinsatzwagen.length);
                                        snapshot.docs.forEach((doc) => {
                                            if (doc.exists) {
                                                state.zuaueinsatzwagen.push(doc.data());
                                            }
                                        })
                                        resolve(state.zuaueinsatzwagen);
                                    }, (error) => {
                                        console.log(error.message);
                                        reject(error);
                                    })
//*** Ausgewechselte Wagen ausgewechseltewagen */
                                _q = query(collection(db,"zblreport", docid, "ausgewechseltewagen"));
                                state.zblausgewechseltewagen =
                                    onSnapshot(_q, (snapshot) => {
                                        if(snapshot.docs.length === 0) {
                                            setDoc(doc(db, "zblreport", docid, "ausgewechseltewagen", _id), setAusgewechseltewagen(_id));
                                        }
                                        state.ausgewechseltewagen.splice(0, state.ausgewechseltewagen.length);
                                        snapshot.docs.forEach((doc) => {
                                            if (doc.exists) {
                                                state.ausgewechseltewagen.push(doc.data());
                                            }
                                        })
                                        resolve(state.ausgewechseltewagen);
                                    }, (error) => {
                                        console.log(error.message);
                                        reject(error);
                                    })
//*** Ausgabe Reseve VDT geraetwechsel */
                                _q = query(collection(db,"zblreport", docid, "geraetwechsel"));
                                state.zblgeraetwechsel =
                                    onSnapshot(_q, (snapshot) => {
                                        if(snapshot.docs.length === 0) {
                                            setDoc(doc(db, "zblreport", docid, "geraetwechsel", _id), setGeraetwechsel(_id));
                                        }
                                        state.geraetwechsel.splice(0, state.geraetwechsel.length);
                                        snapshot.docs.forEach((doc) => {
                                            if (doc.exists) {
                                                state.geraetwechsel.push(doc.data());
                                            }
                                        })
                                        resolve(state.geraetwechsel);
                                    }, (error) => {
                                        console.log(error.message);
                                        reject(error);
                                    })
                        } 
                        if(snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm")
                        }
//                        console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.tb);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
/*
    attachZBLReserveService({ state, commit }, { date }) {
        return new Promise((resolve, reject) => {
            if (state.zblreserveserviceListener) {
                resolve(state.rd);
            }
            state.isRDLoading = true;
            const docid =  date + "_2";
            const q = query(collection(db, "zblreport"), where("type", "==", 2), where("datum", "==", date));
            state.zblreserveserviceListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    if(snapshot.docs.length == 0) {
                        console.log('INSERT', docid);
                        state.rd = setRD(date);
                        const docRef = doc(db, "zblreport", docid);
                        setDoc(docRef, state.rd).then(() => {
                            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
                            
                        }).catch(err => {
                            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
                        })
                    }
                    snapshot.docs.forEach((document) => {
                        if (document.exists) {
                            state.rd = document.data();
                        } 
                        if(snapshot.metadata.fromCache) {
                            state.isRDOnline = false;
                        } else {
                            state.isRDOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm")
                        }
                    });
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },*/

    addZBLDayReport({ commit }, { docid, data }) {
//        console.log('INSERT', docid, data);
        const docRef = doc(db, "zblreport", docid);
        setDoc(docRef, data).then(() => {
            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    updateZBLDayReport({ commit, state }, { docid }) {
//        console.log('UPDATE', docid );
        const docRef = doc(db, "zblreport", docid);
        updateDoc(docRef, state.tb).then(() => {
            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    async updateZBLDaySubReport({ commit, state }, { docid, collection, subdocid, index, date }) {
//        console.log('UPDATE', docid, index, collection, subdocid );
        let data = null;
        switch(collection) {
            case "fahrtausfall": {
                if(index === 'NEW') {
                    subdocid = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
                    data = setFahrtausfall(subdocid);
                } else {
                    data = state.fahrtausfall[index];
                }
                break;
            }
            case "taxibestellung": {
                if(index === 'NEW') {
                    subdocid = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
                    data = setTaxibestellung(subdocid);
                } else {
                    data = state.taxibestellung[index];
                }
                break;
            }
            case "sammeltaxi": {
                if(index === 'NEW') {
                    subdocid = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
                    data = setSammeltaxi(subdocid);
                } else {
                    data = state.sammeltaxi[index];
                }
                break;
            }
            case "meldungverkehrsabteilung": {
                if(index === 'NEW') {
                    subdocid = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
                    data = setMeldungverkehrsabteilung(subdocid);
                } else {
                    data = state.meldungverkehrsabteilung[index];
                }
                break;
            }
            case "euavongelenkbus": {
                if(index === 'NEW') {
                    subdocid = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
                    data = setEuavongelenkbus(subdocid);
                } else {
                    data = state.euavongelenkbus[index];
                }
                break;
            }
            case "zuaueinsatzwagen": {
                if(index === 'NEW') {
                    subdocid = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
                    data = setZuaueinsatzwagen(subdocid);
                } else {
                    data = state.zuaueinsatzwagen[index];
                }
                break;
            }
            case "ausgewechseltewagen": {
                if(index === 'NEW') {
                    subdocid = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
                    data = setAusgewechseltewagen(subdocid);
                } else {
                    data = state.ausgewechseltewagen[index];
                }
                break;
            }
            case "geraetwechsel": {
                if(index === 'NEW') {
                    subdocid = DateTime.now().setLocale('de').toFormat("yyyyMMddHHmmss");
                    data = setGeraetwechsel(subdocid);
                } else {
                    data = state.geraetwechsel[index];
                }
                break;
            }
        }
        await setDoc(doc(db, "zblreport", docid, collection, subdocid), data ).then(() => {
            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    deleteZBLDaySubReport({ commit, state }, { docid, collection, subdocid}) {
        deleteDoc(doc(db, "zblreport", docid, collection, subdocid)).then(() => {
            commit('showSnackbar', { message: 'Eintrag gelöscht', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    detachZBLDayReportOnSnapshot({ state }) {
        if (state.zbldayreportListener) {
            state.zbldayreportListener();
            state.zbldayreportListener = null;
        }
        if (state.zblfahrtausfallListener) {
            state.zblfahrtausfallListener();
            state.zblfahrtausfallListener = null;
        }
        if (state.zbltaxibestellung) {
            state.zbltaxibestellung();
            state.zbltaxibestellung = null;
        }
        if (state.zblsammeltaxi) {
            state.zblsammeltaxi();
            state.zblsammeltaxi = null;
        }
        if (state.zblmeldungverkehrsabteilung) {
            state.zblmeldungverkehrsabteilung();
            state.zblmeldungverkehrsabteilung = null;
        }
        if (state.zbleuavongelenkbus) {
            state.zbleuavongelenkbus();
            state.zbleuavongelenkbus = null;
        }
        if (state.zblzuaueinsatzwagen) {
            state.zblzuaueinsatzwagen();
            state.zblzuaueinsatzwagen = null;
        }
        if (state.zblausgewechseltewagen) {
            state.zblausgewechseltewagen();
            state.zblausgewechseltewagen = null;
        }
        if (state.zblgeraetwechsel) {
            state.zblgeraetwechsel();
            state.zblgeraetwechsel = null;
        }
    },
/*
    detachZBLReserveService({ state }) {
        if (state.zblreserveserviceListener) {
            state.zblreserveserviceListener();
            state.zblreserveserviceListener = null;
        }
    }*/
}

const mutations = {
    SET_TIMEPICKER: (state, {index1, index2, index3, typ, wert}) => {
        if(typ == '') {
            state.tb.geraetwechsel[index1].zeit = wert;
        } else if(typ == 'geraetwechselzeit') {
            state.tb.geraetwechsel[index1].zeit = wert;
        } else if(typ == 'p1von') {
            state.tb.platz1[index1].von = wert;
        } else if(typ == 'p1bis') {
            state.tb.platz1[index1].bis = wert;
        } else if(typ == 'p2von') {
            state.tb.platz2[index1].von = wert;
        } else if(typ == 'p2bis') {
            state.tb.platz2[index1].bis = wert;
        } else if(typ == 'polizeizeit') {
            state.tb.polizei[index1].zeit = wert;
        } else if(typ == 'streudienstzeit') {
            state.tb.streudienst[index1].zeit = wert;
        } else if(typ == 'favon') {
            state.fahrtausfall[index1].zeit = wert;
        } else if(typ == 'fabis') {
            state.fahrtausfall[index1].zeitbis = wert;
        } else if(typ == 'wagenwechselzeit') {
            state.ausgewechseltewagen[index1].zeit = wert;
        } else if(typ == 'zuaueinsatzwagenzeit') {
            state.zuaueinsatzwagen[index1].zeit = wert;
        } else if(typ == 'euavongelenkbusvon') {
            state.euavongelenkbus[index1].von = wert;
        } else if(typ == 'euavongelenkbusbis') {
            state.euavongelenkbus[index1].bis = wert;
        } else if(typ == 'sammeltaxibestellzeit') {
            state.sammeltaxi[index1].bestellzeit = wert;
        } else if(typ == 'sammeltaxiabfahrtzeit') {
            state.sammeltaxi[index1].abfahrt = wert;
        } else if(typ == 'taxiankunftzeit') {
            state.taxibestellung[index1].ankunft = wert;
        } else if(typ == 'taxizeit') {
            state.taxibestellung[index1].zeit = wert;
        } else if(typ == 'meldungverkehrsabteilungzeit') {
            state.meldungverkehrsabteilung[index1].zeit = wert;
        }
    },

    addNewFormZBLReport: (state, {index1, index2, index3, typ, data}) => {
        if(typ == 'platz1') {
            state.tb.platz1.push(data);
        } else if(typ == 'platz2') {
            state.tb.platz2.push(data);
        } else if(typ == 'wetter') {
            state.tb.wetter.push(data);
        } else if(typ == 'betriebsablauf') {
            state.tb.betriebsablauf.push(data);
        } else if(typ == 'anzahl') {
            state.tb.anzahl.push(data);
        } else if(typ == 'polizei') {
            state.tb.polizei.push(data);
        } else if(typ == 'streudienst') {
            state.tb.streudienst.push(data);
        } else if(typ == 'meldung') {
            state.tb.meldung.push(data);
        } else if(typ == 'fahrtausfall') {
            state.fahrtausfall.push(data);
        } else if(typ == 'taxibestellung') {
            state.taxibestellung.push(data);
        } else if(typ == 'euavongelenkbus') {
            state.euavongelenkbus.push(data);
        } else if(typ == 'sammeltaxi') {
            state.sammeltaxi.push(data);
        } else if(typ == 'meldungverkehrsabteilung') {
            state.meldungverkehrsabteilung.push(data);
        } else if(typ == 'zuaueinsatzwagen') {
            state.zuaueinsatzwagen.push(data);
        } else if(typ == 'ausgewechseltewagen') {
            state.ausgewechseltewagen.push(data);
        } else if(typ == 'geraetwechsel') {
            state.geraetwechsel.push(data);
        }
    },
    delFormZBLReport: (state, {index1, index2, index3, typ}) =>{
        if(typ == 'platz1') {
            state.tb.platz1.splice(index2, 1);
        } else if(typ == 'platz2') {
            state.tb.platz2.splice(index2, 1);
        } else if(typ == 'wetter') {
            state.tb.wetter.splice(index2, 1);
        } else if(typ == 'betriebsablauf') {
            state.tb.betriebsablauf.splice(index2, 1);
        } else if(typ == 'anzahl') {
            state.tb.anzahl.splice(index2, 1);
        } else if(typ == 'polizei') {
            state.tb.polizei.splice(index2, 1);
        } else if(typ == 'streudienst') {
            state.tb.streudienst.splice(index2, 1);
        } else if(typ == 'meldung') {
            state.tb.meldung.splice(index2, 1);
        } else if(typ == 'fahrtausfall') {
            axios.post('/?site=sendtofirebase', {
                data: state.tb.fahrtausfall,
                id: state.tb.fahrtausfall[index2].id,
                datum: state.tbdate,
                tagesberichtdatum: state.tbdate,
                function: 'deleteFahrtausfallAktuellnewmobil',
            })
            .then(function (response) {
//                saveoutput = response.data;
                state.tb.fahrtausfall.splice(index2, 1);
            })
            .catch(function (error) {
//                saveoutput = error;
            });
        } else if(typ == 'taxibestellung') {
            state.taxibestellung.splice(index2, 1);
        } else if(typ == 'euavongelenkbus') {
            state.euavongelenkbus.splice(index2, 1);
        } else if(typ == 'sammeltaxi') {
            state.sammeltaxi.splice(index2, 1);
        } else if(typ == 'meldungverkehrsabteilung') {
            state.meldungverkehrsabteilung.splice(index2, 1);
        } else if(typ == 'zuaueinsatzwagen') {
            state.zuaueinsatzwagen.splice(index2, 1);
        } else if(typ == 'ausgewechseltewagen') {
            state.ausgewechseltewagen.splice(index2, 1);
        } else if(typ == 'geraetwechsel') {
            state.geraetwechsel.splice(index2, 1);
        } 
    },
}

export default { state, getters, actions, mutations }