import { db } from '@/firebase/firebase'
import { collection, onSnapshot, query } from "firebase/firestore";

const state = {
    employees: [],
    firestoreListener: null,
    isLoading: false
}

const getters = {
    busDrivers(state) {
        return state.employees.filter(empl =>
            empl.department == 'U07-714/1' || 'U07-714/2' || 'U07-714/3' || 
                                'U07-West-Bus' || 'U07-TransdevW' || 'U07-TransdevSW' ||
                                empl.persid == '012420' || empl.persid == '012114' || empl.persid == '011489'
        ).map(user => {
            return {
                fahrer: `${user.name}, ${user.firstname}`,
                personnel_nr: user.persid
            }
        }).sort((a, b) => {
            const nameA = a.fahrer.toUpperCase();
            const nameB = b.fahrer.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        })
    },
    getEmployee: (state) => (persid) => {
        return state.employees.filter(data => data.persid == persid)
    },
}

const actions = {
    async attachEmployeesOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.employees)
                return
            }
            state.isLoading = true
            const q = query(collection(db, "employees"));
            state.firestoreListener = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                snapshot.docs.forEach((doc) => {
                    if (doc.exists) {
                        state.employees.push(doc.data());
                    }
                });
                if (snapshot.metadata.fromCache) {
                    state.isOnline = false;
                } else {
                    state.isOnline = true;
                }
                state.isLoading = false;
                resolve(state.employees);
            }, (error) => {
                console.log(error.message);
                reject(error);
            })
        })
    },

    detachEmployeesOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener()
            state.firestoreListener = null
        }
    }
}

export default { state, getters, actions }
