<template>
  <form>
    <v-container>
      <!-- <Ausfahrtmelden
        :active="dialog.ausfahrtmelden"
        :datum="date"
        :doc="doc"
      ></Ausfahrtmelden> -->
      <v-card v-if="$store.state.auth.isAuthenticating">
        <!-- @todo offline funktionalität prüfen -->
        <!-- <v-banner v-if="$store.state.delay.isOnline == false" single-line>
          <v-icon slot="icon" color="warning" size="36">
            mdi-wifi-strength-alert-outline
          </v-icon>
          Offline-Modus!
        </v-banner> -->
        <v-card-title>
          <v-icon>mdi-bus-clock</v-icon>
          &nbsp; Ausfahrt
        </v-card-title>
        <v-card-subtitle>
          letzte aktualisierung:
          {{ $store.state.busexit.lastChange }}
        </v-card-subtitle>
        <v-card-text> </v-card-text>
      </v-card>
      <v-card>
        <v-card-title>
          <v-icon>mdi-bus-clock</v-icon>
          &nbsp; Verspätungen
        </v-card-title>
        <v-card-text>
          <template>
            <v-data-table
              :class="scrollbarTheme"
              :headers="delayheaders"
              :items="overdueDataComputed"
              item-key="id"
              @click:row="clickAusfahrtmelden"
              :mobile-breakpoint="550"
              :loading="isLoading"
              disable-pagination
              hide-default-footer
              fixed-header
              height="350"
            >
              <template v-slot:top>
                <v-card flat color="transparent" width="250" class="ml-3 mt-10">
                  <v-slider
                    v-model="sliderValue"
                    thumb-label
                    class="delay-filter"
                    min="0"
                    max="100"
                    color="#9b004b"
                  >
                    <template v-slot:prepend> Filter </template>
                  </v-slider>
                </v-card>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </v-container>
  </form>
</template>

<script>
//import { firestore } from "@/firebase/firebase";
//import Vue from 'vue';
//import axios from 'axios';
// import Ausfahrtmelden from "@/components/dialog/ausfahrtmelden";
import { DateTime } from "luxon";
//import { debounce } from 'debounce';

export default {
  data() {
    return {
      date: null,
      doc: "",
      delayheaders: [
        {
          text: "Dienst",
          align: "start",
          sortable: false,
          value: "dienst",
        },
        { text: "Persid", value: "persid" },
        { text: "Fahrer:in", value: "fahrer" },
        { text: "Delay", value: "delayMin" },
      ],
      dialog: {
        ausfahrtmelden: 0,
      },
      reloadtimer: "",
      overduedata: [],
      sliderValue: 0,
      currentTime: "",
    };
  },

  computed: {
    // busexitdone() {
    //   if (this.$store.state.busexit.isLoading) {
    //     return [];
    //   }
    //   const data = [];
    //   this.$store.state.busexit.donedata.forEach((busexit) => {
    //     if (busexit.gemeldet === true) {
    //       data.push(busexit);
    //     }
    //   });
    //   return data;
    // },

    overdueDataComputed() {
      if (this.sliderValue == 0) {
        return this.overduedata;
      }
      return this.overduedata.filter(
        (data) => data.delayMin <= this.sliderValue
      );
    },

    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  watch: {
    "$store.state.auth.isAuthenticating": {
      handler: function (isAuthenticating) {
        if (isAuthenticating) {
          this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
          this.$store
            .dispatch("attachBusExitOnSnapshotoffline", { date: this.date })
            .then(() => {
              // this.$store.dispatch("attachBusDoneExitOnSnapshotoffline", {
              //   date: this.date,
              // });
              this.reloadtimer = setInterval(this.fetchData, 60000);
              // this.exitdata = this.busexit();
              this.overduedata = this.busexitoverdue();
            });
        }
      },
      immediate: true,
    },

    // busexitdone: {
    //   handler: function () {
    //     this.exitdata = this.busexit();
    //     this.overduedata = this.busexitoverdue();
    //   },
    //   immediate: true,
    // },
  },

  methods: {
    // itemRowColor(item) {
    //   if (item.gemeldet == true) {
    //     return "style-6";
    //   }
    //   let zeit = DateTime.now().plus({}).setLocale("de").toFormat("HH:mm");
    //   if (item.meldezeit < zeit) {
    //     return "style-5";
    //   }
    //   zeit = DateTime.now()
    //     .plus({ minutes: +2 })
    //     .setLocale("de")
    //     .toFormat("HH:mm");
    //   if (item.meldezeit <= zeit) {
    //     return "style-4";
    //   }
    //   zeit = DateTime.now()
    //     .plus({ minutes: +5 })
    //     .setLocale("de")
    //     .toFormat("HH:mm");
    //   if (item.meldezeit <= zeit) {
    //     return "style-3";
    //   }
    //   zeit = DateTime.now()
    //     .plus({ minutes: +10 })
    //     .setLocale("de")
    //     .toFormat("HH:mm");
    //   if (item.meldezeit <= zeit) {
    //     return "style-2";
    //   }
    //   zeit = DateTime.now()
    //     .plus({ minutes: +75 })
    //     .setLocale("de")
    //     .toFormat("HH:mm");
    //   if (item.meldezeit <= zeit) {
    //     return "style-1";
    //   }
    //   //https://stackoverflow.com/questions/57961043/how-does-one-style-a-specific-row-on-v-data-table-vuetify
    //   return "style-0";
    // },

    // delay() {
    //   if (this.$store.state.busexit.isLoading) {
    //     return [];
    //   }
    //   const zeit = DateTime.now().setLocale("de").toFormat("HH:mm");
    //   const data = [];
    //   this.$store.state.busexit.data.forEach((busexit) => {
    //     if (busexit.meldezeit < zeit && busexit.gemeldet === false) {
    //       data.push(busexit);
    //     }
    //   });
    //   return data;
    //   //return this.$store.state.busexit.overduedata;
    // },

    busexitoverdue() {
      if (this.$store.state.busexit.isLoading) {
        return [];
      }
      const zeit = DateTime.now().setLocale("de").toFormat("HH:mm");
      const data = [];
      this.$store.state.busexit.data.forEach((busexit) => {
        if (busexit.meldezeit < zeit && busexit.gemeldet === false) {
          // data.push(busexit);
          data.push({
            dienst: busexit.dienst,
            persid: busexit.persid,
            fahrer: busexit.fahrer,
            delayMin: this.getMinuteDifference(
              this.currentTime,
              busexit.meldezeit
            ),
          });
        }
      });
      return data;
      //return this.$store.state.busexit.overduedata;
    },

    getMinute(time) {
      return parseInt(time.split(":")[1]);
    },

    getHour(time) {
      return parseInt(time.split(":")[0]);
    },

    convertHourStringToMinutes(hour) {
      return this.getHour(hour) * 60 + this.getMinute(hour);
    },

    getMinuteDifference(hour1, hour2) {
      return Math.abs(
        this.convertHourStringToMinutes(hour1) -
          this.convertHourStringToMinutes(hour2)
      );
    },

    async fetchData() {
      // this.overduedata = this.delay();
      this.currentTime = DateTime.now().setLocale("de").toFormat("HH:mm");
      this.overduedata = this.busexitoverdue();
      //   console.log("TIMER:", this.exitdata);
    },

    cancelAutoUpdate() {
      clearInterval(this.reloadtimer);
    },

    clickAusfahrtmelden(value) {
      this.doc = value;
      this.dialog.ausfahrtmelden++;
    },
  },

  mounted() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.currentTime = DateTime.now().setLocale("de").toFormat("HH:mm");
  },

  beforeDestroy() {
    this.cancelAutoUpdate();
    // this.$store.dispatch("detachDelayOnSnapshot");
    // this.$store.dispatch("detachBusexitOnSnapshot");
  },

  components: {
    // Ausfahrtmelden,
  },
};
</script>
