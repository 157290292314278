<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>{{ text }}</v-card-title>
      <v-card-text>
        <timepicker 
          v-bind:label="labelbet"
          v-bind:field="editbusexittime" 
          v-bind:modal="modal"
          v-bind:i1=0
          v-bind:i2=0
          v-bind:i3=0
          v-bind:ro="false"
          v-bind:typ="'busexittime'"
          @changetime="changetime($event)"
        ></timepicker>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primaryLight"
          text
          @click="canceleditbusexittime"
          value="CANCELEDITBUSEXITTIME"
        >
          Abbrechen
        </v-btn>
        <v-btn color="primaryLight" text @click="addeditbusexittime">
          Speichern
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
//import firebase from 'firebase/app';
import timepicker from "@/components/app/RDTimePicker";

export default {
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      editbusexittime: '',
      labelbet: 'Zeit',
      modal: false,
    };
  },

  computed: {},

  watch: {
    active() {
      console.log("Time: ", this.busexittime);
      this.dialogVisible = true;
      this.editbusexittime = this.busexittime;
    },
  },

  methods: {
    addeditbusexittime() {
      if (this.editbusexittime !== '') {
        this.$store.dispatch("updateSettings", { docid: 'settings', data: { busexittime: this.editbusexittime } });
        this.editbusexittime = '';
        this.dialogVisible = false;
      }
    },
    canceleditbusexittime() {
      this.editbusexittime = '';
      this.dialogVisible = false;
    },
    changetime(objtime) {
      switch(objtime.typ) {
        case "busexittime":
          this.editbusexittime = objtime.time;
          break;
      }
    },
  },

  created() {},

  components: {
    timepicker,
  },

  props: {
    active: Number,
    busexittime: String,
    text: String,
  },
};
</script>
