<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>Einfahrt melden</v-card-title>
      <v-card-text>
        Wurde dem Fahrer {{ doc.fahrer }} die Einfahrt {{ doc.einfahrt }} gemeldet?
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primaryLight"
          text
          @click="cancelEinfahrtmelden"
          value="CANCELEINFAHRTMELDEN"
        >
          Abbrechen
        </v-btn>
        <v-btn
          color="primaryLight"
          text
          @click="updateEinfahrtmelden"
        >
          Einfahrt gemeldet
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import { DateTime } from "luxon";

export default {
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
    };
  },

  computed: {
  },

  methods: {
    updateEinfahrtmelden() {
      const data = {
        einfahrtgemeldet: true,
        einfahrtgemeldet_um: DateTime.now().setLocale('de').toFormat("HH:mm"),
      };

      console.log(data);
      if (Object.keys(data).length != 0 && data.constructor === Object) {
        this.$store.dispatch("updateBusExit", {
          date: this.datum,
          docid: this.doc.docid,
          data,
        });
        this.dialogVisible = false;
      }
    },
    cancelEinfahrtmelden() {
      this.dialogVisible = false;
    },
  },

  watch: {
    active() {
      this.dialogVisible = true;
    },
  },

  props: {
    active: Number,
    datum: String,
    doc: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  created() {
  },
};
</script>
