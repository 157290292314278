<template>
  <form>
    <v-container fill-height fluid style="height: 100%" v-if="isLoading">
      <v-row class="text-center">
        <v-col cols="12">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else>
      <insertsecurityincident :active="dialogs.addsecurityincidenttype"></insertsecurityincident>
      <deletesecurityincident 
        :active="dialogs.deletesecurityincidenttype" 
        :securityincidenttypes="$store.state.settings.securityincidenttype"
      ></deletesecurityincident>
      <v-card v-if="$store.state.auth.isAuthenticating" max-width="700">
        <v-card-title><v-icon>mdi-security</v-icon>&nbsp;Sicherheitsrelevanter Vorfall, Meldung vom {{date}} </v-card-title>
        <v-card-subtitle>
          Persnr.: {{ $store.state.auth.employee.persid }} Name: {{ $store.state.auth.employee.name }}, {{ $store.state.auth.employee.firstname }}
        </v-card-subtitle>
        <v-card tile flat>
          <v-card-title>Was ist vorgefallen?</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  v-model="srv.type"
                  :items="$store.state.settings.securityincidenttype"
                  item-text='text'
                  item-value='docid'
                  label="Vorfallart"
                  multiple
                  chips
                  persistent-hint
                  prepend-icon="mdi-alert-octagon"
                  clearable
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  rows="3"
                  v-model="srv.text"
                  label="Kurzbescheibung des Vorfalls. (nur wenn möglich!)"
                  clearable
                  auto-grow
                  prepend-icon="mdi-card-text-outline"
                ></v-textarea>
              </v-col>
            </v-row>
            
          </v-card-text>
        </v-card>
        <v-card tile flat>
          <v-card-title>Wann passierte der Vorfall?</v-card-title>
          <v-card-text>
            <v-row>
                <v-col sm="6">
                  <vdatetime
                    :value="srv.time"
                    :onlyDate="false"
                    :onlyTime="true"
                    :menu-props="{ top: true }"
                    :time-props="{ outlined: false, label: labelzeit }"
                    :time-picker-props="{format: '24hr'}"
                    :error-messages="errors"
                    @change="changetime($event)"
                  ></vdatetime>
                </v-col>
                <v-col sm="6">
                  <vdatetime
                    :value="srv.date"
                    :onlyDate="true"
                    :onlyTime="false"
                    :menu-props="{ top: true }"
                    :date-props="{ outlined: false, label: labeldatum }"
                    :date-picker-props="{max: datemax, locale: 'de'}"
                    :error-messages="errors"
                    @change="changedate($event)"
                  ></vdatetime>
                </v-col>
              </v-row>
          </v-card-text>
        </v-card>
        <v-card tile flat>
          <v-card-title>Wo passierte der Vorfall?</v-card-title>
            <v-card-text>
              <v-chip-group
              v-model="srv.place"
              column
              multiple
              active-class="primary--text"
            >
              <v-chip
                v-for="pt in placetags"
                :key="pt"
                :value="pt"
                filter
              >
                {{ pt }}
              </v-chip>
            </v-chip-group>
            <v-row>
              <v-col sm="4">
                <v-autocomplete
                  :items="$store.state.busline.itemdata"
                  item-text='line'
                  item-value='line'
                  v-model="srv.busline" 
                  v-on:change="buslineChange()"
                  label="Linie"
                  prepend-icon="mdi-bus-side"
                  clearable
                  return-object
                ></v-autocomplete>
              </v-col>
              <v-col sm="8">
                <v-autocomplete v-if="!platformflg"
                  :items="$store.state.busline.buslineplatforms"
                  item-text='name'
                  item-value='name'
                  v-model="srv.platform" 
                  label="Haltestelle"
                  prepend-icon="mdi-bus-stop-uncovered"
                  clearable
                  :disabled="platformflg"
                  return-object
                ></v-autocomplete>
                <googlemap v-else
                  :apikey="apikey" 
                  :countries="['de', 'nl']"
                  :lat="currentCoordinates.lat"
                  :lng="currentCoordinates.lng"
                  @changelocation="changelocation($event)"
                ></googlemap>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="ma-5">
          <v-card-subtitle>Position {{ currentCoordinates.lat }}, {{ currentCoordinates.lng }}</v-card-subtitle>
        </v-card>
        <v-card-actions v-if="getSave">
          <v-spacer></v-spacer>
          <v-btn text color="green" @click.native="addsecurityincident">
            <v-icon color="green">mdi-note-plus-outline</v-icon>
            Sicherheisrelevanten Vorfall melden
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
      <v-speed-dial
        v-if="$store.getters.hasCustomPermission('securityincidentedit')"
        v-model="fab"
        fixed
        right
        bottom
        direction="top"
        transition="slide-y-reverse-transition"
      >
      <template v-slot:activator>
        <v-btn
          v-model="fab"
          color="primary"
          dark
          fab
          value="SpeedDail"
          v-show="!isLoading"
        >
          <v-icon v-if="fab">
            mdi-close
          </v-icon>
          <v-icon v-else>
            mdi-pencil
          </v-icon>
        </v-btn>
      </template>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="dialogs.addsecurityincidenttype++"
              elevation="2"
              fab
              small
              color="success"
              v-show="!isLoading"
              v-bind="attrs"
              v-on="on"
              value="addsecurityincident"
              ><v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Neuen Sicherheitsrelevante Art erstellen</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
            <v-btn
                @click="dialogs.deletesecurityincidenttype++"
                elevation="2"
                fab
                small
                color="error"
                :disabled="getsecurityincidentlength"
                v-show="!isLoading"
                v-bind="attrs"
                v-on="on"
                value="delsecurityincident"
                ><v-icon>mdi-delete</v-icon></v-btn>
            </template>
            <span>Sicherheitsrelevante Art löschen</span>
        </v-tooltip>
      </v-speed-dial>
    </v-container>
  </form>
</template>

<script>
import { DateTime } from "luxon";
import vdatetime from "@/components/app/v-datetime";
import insertsecurityincident from "@/components/dialog/insertsecurityincident";
import deletesecurityincident from "@/components/dialog/deletesecurityincident";
import googlemap from '@/components/app/GoogleMap.vue'

export default {
  name: "Sicherheitsrelevante Vorfälle",
  data() {
      return {
        isLoading: true,
        fab: false,
        readonly: false,
        modal: false,
        apikey: "",
        datemax: DateTime.now().setLocale('de').toFormat("yyyy-MM-dd"),
        srv: {
          id: null,
          date: DateTime.now().setLocale('de').toFormat("yyyy-MM-dd"),
          time: DateTime.now().setLocale('de').toFormat("HH:mm"),
          type: [],
          text: '',
          place: [],
          address: {},
          busline: null,
          platform: null,
          location: null,
          personnelnr: this.$store.state.auth.employee.persid,
          name: this.$store.state.auth.employee.name + ', ' + this.$store.state.auth.employee.firstname,
        },
        currentCoordinatesId: null,
        currentCoordinates: {
          lat: 0,
          lng: 0,
        },
        dialogs: {
          addsecurityincidenttype: 0,
          deletesecurityincidenttype: 0,
        },
        placetags: [],
        depotfahrt: {
          id: "Depot-Fahrt",
          line: "Depot-Fahrt",
          direction_1: "Ausfahrt",
          direction_2: "Einfahrt",
          active: "yes"
        },
        platformflg: false,
      };
  },
  created: function () {
    //this.readonly = this.$route.params.readonly;
    this.$store.dispatch("attachSecurityincidenttype");    
    this.$store.dispatch("attachBusLineOnSnapshotoffline", { data: this.depotfahrt } );
    this.$forceUpdate();
    this.getCurrentCoordinates();
  },
  computed: {
    getsecurityincidentlength() {
      if (!this.$store.state.settings.securityincidenttype) {
        return true;
      } else {
        if (this.$store.state.settings.securityincidenttype.length <= 0) {
          return true;
        } else {
          return false;
        }
      }
    },
    getSave() {
      if (!this.srv.busline || !this.srv.platform || this.srv.place.length <= 0 || this.srv.type.length <= 0) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    "$store.state.auth.isAuthenticating": {
      handler: function (isAuthenticating) {
        if(this.readonly === 'true') {
          this.formreadonly = true;
        } else {
          this.formreadonly = false;
        }
        if (isAuthenticating) {
          this.placetags = JSON.parse(process.env.VUE_APP_SECURITYINCEDENTPLACETAGS);
          if(this.date == null) {
            this.date = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy");
            this.isLoading = false;
          }
          this.$store.dispatch("attachSecurityincidenttype");    
          this.$store.dispatch("attachBusLineOnSnapshotoffline", { data: this.depotfahrt } );
        }
      },
      immediate: true,
    },
  },
  methods: {
    getCurrentCoordinates() {
      this.currentCoordinatesId = navigator.geolocation.watchPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          this.currentCoordinates.lat = latitude;
          this.currentCoordinates.lng = longitude;
/*          this.$store.commit("showSnackbar", {
            status: "message",
            message: "Position gefunden!",
          });*/
        },
        (error) => {
          this.$store.commit("showSnackbar", {
            status: "error",
            message: error.message,
          });
        },
        { enableHighAccuracy: true, maximumAge: 120000 }
      );
    },

    addsecurityincident() {
      if (!this.getSave) {
        console.log("NO-DATEN: ", this.srv);
        this.$store.commit('showSnackbar', { message: 'Bitte füllen Sie alle Felder aus!', status: 'error' }, { root: true })
      } else {
//        this.srv.id = this.srv.personnelnr+'_'+this.srv.busline.id+'_'+this.srv.platform.nr+'_'+
//                                    DateTime.now().setLocale('de').toFormat("yyyy-MM-dd_HH:mm:ss");
        this.srv.id = this.srv.personnelnr+'_'+DateTime.now().setLocale('de').toFormat("yyyy-MM-dd_HH:mm:ss");
        this.$store.dispatch("insertSecurityincident", { docid: this.srv.id, securityincident: this.srv });
        this.$store.commit('showSnackbar', { message: 'Der Sicherheitsrelevante Vorfall wurde gespeichert!', status: 'success' }, { root: true })
        this.srv = {
          id: null,
          date: DateTime.now().setLocale('de').toFormat("yyyy-MM-dd"),
          time: DateTime.now().setLocale('de').toFormat("HH:mm"),
          type: [],
          text: '',
          place: [],
          address: {},
          busline: null,
          platform: null,
          personnelnr: this.$store.state.auth.employee.persid,
          name: this.$store.state.auth.employee.name + ', ' + this.$store.state.auth.employee.firstname,
        };
        this.platformflg = false;
      }
    },

    changetime(objtime) {
      this.srv.time = objtime.datetime;
    },

    changedate(objdate) {
      this.srv.date = objdate.datetime;
    },

    changelocation(objlocation) {
      console.log("Save Location:");
      console.log(objlocation);
      this.srv.platform = {
        lineid: "",
        linetext: objlocation.location,
        name: objlocation.location,
        title: objlocation.location,
        nr: objlocation.location,
      };
    },

    buslineChange() {
      this.$store.dispatch("detachBusLinePlatforms").then(() => {
        this.srv.platform = null;
        if (this.srv.busline) {
          this.$store.dispatch("attachBusLinePlatforms", { busline: this.srv.busline.id}).then(() => {
            if (this.$store.state.busline.buslineplatforms.length <= 0) {
              this.platformflg = true;
              this.srv.platform = {
                lineid: "",
                linetext: "Depot-Fahrt",
                name: "Depot-Fahrt",
                title: "Depot-Fahrt",
                nr: "Depot-Fahrt",
              };
            } else {
              this.platformflg = false;
            }
          });
        }
      });
    },
  },
  mounted() {
    this.$store.state.auth.withMenu = true;
//    console.log("KEY: ", process.env.VUE_APP_GOOGLE_MAPPS_API_KEY);
    this.apikey = process.env.VUE_APP_GOOGLE_MAPPS_API_KEY;
//        this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
  },
  beforeDestroy() {
    navigator.geolocation.clearWatch(this.currentCoordinatesId);
    this.$store.dispatch("detachsecurityincidenttype");
    this.$store.dispatch("detachBusLineOnSnapshot");
    this.$store.dispatch("detachBusLinePlatforms");
  },
  components: {
    vdatetime,
    insertsecurityincident,
    deletesecurityincident,
    googlemap,
  },
};
</script>
