<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>Ausfahrt melden</v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="6" sm="3" md="3">
              <v-text-field
                v-model="doc.dienst"
                label="Dienst"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-text-field
                v-model="doc.info"
                label="Betrieb"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-text-field
                v-model="doc.meldezeit"
                label="Meldezeit"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-menu
                ref="menu"
                v-model="timePicker"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="doc.anfang"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="doc.anfang"
                    label="Ausfahrtszeit"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timePicker"
                  v-model="doc.anfang"
                  full-width
                  format="24hr"
                  @click:minute="updateMeldezeit"
                ></v-time-picker>
              </v-menu>
              <!-- <v-text-field
                v-model="doc.anfang"
                label="Ausfahrtszeit"
                disabled
              ></v-text-field> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="3" md="3">
              <v-text-field
                v-model="doc.linie"
                label="Linie"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-text-field
                v-model="doc.fahrzeug"
                label="Fahrzeug"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-autocomplete
                label="Wagennummer"
                :items="$store.getters.vehicleList"
                v-model="doc.wagennr"
                no-data-text="Kein Fahrzeug gefunden"
                item-text="wagennummer"
                item-value="wagennummer"
              ></v-autocomplete>
            </v-col>
            <v-col cols="6" sm="3" md="3">
              <v-text-field
                v-model="doc.strang"
                label="Strang"
                disabled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="4" md="4">
              <v-text-field
                v-model="doc.ende"
                label="Ende"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="6" sm="4" md="4">
              <v-text-field
                v-model="doc.hinweis"
                label="Hinweis"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-autocomplete
                :items="$store.getters.busDrivers"
                :value="defaultDriver"
                item-text="fahrer"
                item-value="personnel_nr"
                label="Fahrer:in"
                no-data-text="Keine Busfahrer:in gefunden"
                return-object
                @change="changeDriverTrigger"
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn v-if="hasPermission" text @click="triggerAusfahrtStorno">
          Ausfahrt Storno
        </v-btn>
        <v-btn
          color="primaryLight"
          text
          @click="cancelAusfahrtmelden"
          value="CANCELAUSFAHRTMELDEN"
        >
          Abbrechen
        </v-btn>
        <v-btn color="primaryLight" text @click="updateAusfahrtmelden('update')">
          Speichern
        </v-btn>
        <v-btn
          color="primaryLight"
          text
          @click="updateAusfahrtmelden('ausfahrt')"
        >
          Ausfahrt melden
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
//import firebase from 'firebase/app';
import { DateTime } from "luxon";

export default {
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      selectedDriver: {},
      timePicker: false,
    };
  },

  computed: {
    selectedDriverIndex() {
      return this.$store.getters.busDrivers.findIndex(
        (driver) => driver.personnel_nr == this.doc.personnel_nr
      );
    },

    selectedBusIndex() {
      return this.$store.getters.vehicleList.findIndex(
        (vehicle) => vehicle.wagennummer == this.doc.wagennr
      );
    },

    defaultDriver() {
      return {
        fahrer: this.doc.fahrer,
        personnel_nr: this.doc.personnel_nr,
      };
    },

    hasPermission() {
      return this.$store.getters.hasPermission({
        type: "customUserPermission",
        right: "ausfahrtstorno",
      });
    },
  },

  methods: {
    getMeldezeit(timeInput) {
      let minute = timeInput.charAt(3);
      if (minute == "0") {
        let hour = timeInput.charAt(1);
        if (hour == "0") {
          return "23:5" + timeInput.substring(4);
        }
        hour -= 1;
        return (
          timeInput.substring(0, 1) + hour + ":" + "5" + timeInput.substring(4)
        );
      } else {
        minute -= 1;
        return timeInput.substring(0, 3) + minute + timeInput.substring(4);
      }
    },

    updateMeldezeit() {
      this.$refs.menu.save(this.doc.anfang);
      this.doc.meldezeit = this.getMeldezeit(this.doc.anfang);
    },

    changeDriverTrigger($event) {
      this.selectedDriver = $event;
    },

    cancelAusfahrtmelden() {
      this.dialogVisible = false;
    },

    updateAusfahrtmelden(action = "update") {
      const neuFahrer = this.selectedDriver.fahrer
        ? this.selectedDriver.fahrer
        : this.defaultDriver.fahrer;
      const personnel_nr = this.selectedDriver.personnel_nr
        ? this.selectedDriver.personnel_nr
        : this.defaultDriver.personnel_nr;
      // ? this.$store.getters.busDrivers[this.selectedDriverIndex].name
      // : "";
      const wagennr = this.$store.getters.vehicleList[this.selectedBusIndex]
        ? this.$store.getters.vehicleList[this.selectedBusIndex].wagennummer
        : this.doc.wagennr;

      if (action == "ausfahrt") {
        const fremdausfahrt = this.doc?.fremdausfahrt || false
        console.log("AUSFAHRT", fremdausfahrt, this.doc)
        if (fremdausfahrt == false) {
          if (wagennr == "") {
            this.$store.commit("showSnackbar", {
              status: "error",
              message: "Bitte wählen Sie eine Wagennummer aus.",
            });
            return;
          }
          if (neuFahrer == "") {
            this.$store.commit("showSnackbar", {
              status: "error",
              message: "Bitte wählen Sie eine:n Fahrer:in aus.",
            });
            return;
          }
        }
      }
      const data = {
        neuFahrer,
        wagennr: wagennr,
        personnel_nr,
        anfang: this.doc.anfang,
        meldezeit: this.doc.meldezeit,
      };

      if (action == "ausfahrt") {
        data.gemeldet = true;
        data.gemeldet_um = DateTime.now().setLocale('de').toFormat("HH:mm");
      }

      console.log(data);
      if (Object.keys(data).length != 0 && data.constructor === Object) {
        this.$store.dispatch("updateBusExit", {
          date: this.datum,
          docid: this.doc.docid,
          data,
        });
        this.dialogVisible = false;
      }
      this.selectedDriver = {};
    },

    triggerAusfahrtStorno() {
      const data = {
        fahrer: "Fahrt storniert",
        neuFahrer: "Fahrt storniert",
        personnel_nr: "000000",
        gemeldet: true,
        gemeldet_um: DateTime.now().setLocale('de').toFormat("HH:mm"),
      };
      this.$store.dispatch("updateBusExit", {
        date: this.datum,
        docid: this.doc.docid,
        data,
      });
      this.dialogVisible = false;
      this.selectedDriver = {};
    },
  },

  watch: {
    active() {
      this.dialogVisible = true;
    },
  },

  props: {
    active: Number,
    datum: String,
    doc: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },

  created() {
    this.$store.dispatch("attachEmployeesOnSnapshot");
    this.$store.dispatch("attachVehiclesOnSnapshot");
  },
};
</script>
