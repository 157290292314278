<template>
<v-flex>
    <v-text-field
        v-model="fieldtime"
        v-bind:label="label"
        type="time"
        prepend-inner-icon="mdi-clock-outline"
        @click:prepend-inner="compmodal = getModal"
        @keydown="windowevent"
        v-on:change="save($refs)"
        :readonly="compro"
    ></v-text-field>
        <v-dialog
            ref="dialog"
            v-model="compmodal"
            :return-value.sync="time"
            :disabled="compro"
            persitent
            width="290px"
        >
            <v-time-picker
                v-if="compmodal"
                v-model="time"
                full-width
                format="24hr"
                @click:minute="save($refs)" 
            >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="compmodal = false">Cancel</v-btn>
                <v-btn text color="primary" @click="save($refs)">OK</v-btn>
            </v-time-picker>
        </v-dialog>
    </v-flex>
</template>

<script>
    import { store } from '@/store';
    import { debounce } from 'debounce';

    export default {
        props: ['label', 'field', 'modal', 'i1', 'i2', 'i3', 'ro', 'typ', 'dataid', 'collection'],
        ready: function () { 
            this.time = this.field;
        },
        data: function() {
//            debugger;
            return {
                time: this.fieldtime,
                compmodal: this.modal,
                compro: this.ro
            }
        },
        computed: {
            fieldtime: {
                get: function () {
//                    this.time = this.field;
                    return this.field
                },
                set: function (newValue) {
                    this.time = newValue;
                    store.commit('SET_TIMEPICKER', {index1: this.i1, index2: this.i2, index3: this.i3, 
                                                    typ: this.typ, wert: this.time});
                }
            },
            getModal() {
                if(this.compro === true) {
                    return false;
                } else {
                    return true;
                }
            }
        },
        methods: {
            save(ref) {
                ref.dialog.save(this.time);
                this.compmodal = false;
                store.commit('SET_TIMEPICKER', {index1: this.i1, index2: this.i2, index3: this.i3, 
                                                typ: this.typ, wert: this.time});
                this.debouncedUpdate();
            },
            windowevent: function (event) {
//                console.log('Window event: ' + event.which);
//                console.log(this.$vuetify.breakpoint.width);
                if(event.which == 13) {
                    this.compmodal = true;
                }
            },
            async updateFirebase() {
                if(typeof(this.dataid) === 'undefined') {
                    store.dispatch("updateZBLDayReport", { docid: store.state.zbl.tb.datum + "_1"} );
                } else {
                    store.dispatch("updateZBLDaySubReport", { docid: store.state.zbl.tb.datum + "_1", 
                                                                    collection: this.collection,
                                                                    subdocid: this.dataid,
                                                                    index: this.i1,
                                                                    date: store.state.zbl.tb.datum } );
                }
            },
        
            debouncedUpdate: debounce(function() {
                this.updateFirebase()
            }, 500),
        }
    }
</script>