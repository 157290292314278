import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, updateDoc, setDoc, deleteDoc, getDoc } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    busLocations: [],
    busLocationOptions: [],
    busLocationsFirestoreListener: null,
    busLocationsIsLoading: false,
    busLocationOptionsFirestoreListener: null,
    busLocationOptionsIsLoading: false,
    isOnline: false,
    lastChange: null,
}

const getters = {
    busLocationOptionsAlsText(state) {
        return state.busLocationOptions.map(option => option.value)
    },

    busLocationTable(state) {
        return state.busLocations.map(bus => {
            return {
                ...bus,
                koordinaten: [bus.koordinaten.lat, bus.koordinaten.lng],
            }
        })
    },

    busLocationMarkers(state) {
        const busLocations = state.busLocations.reduce((acc, cur) => {
            const ort = cur.ort
            if (acc[ort]) {
                acc[ort].push({ ...cur })
            } else {
                acc[ort] = [{ ...cur }]
            }
            return acc
        }, {})

        const busLocationOptions = state.busLocationOptions
            .filter(location => location.value != 'Freitext')
            .map(location => {
                return {
                    ...location,
                    koordinaten: { lat: location.koordinaten.lat, lng: location.koordinaten.lng },
                    content: busLocations[location.value]
                }
            })
        return busLocationOptions
    },

    busTypInLocation: (state) => (busType) => {
        return state.busLocations
            .filter(bus => bus.isTaken == false)
            .find(bus => {
                return bus.typ == busType
            })
    }
}

const actions = {
    attachBusLocationOnSnapshotoffline({ state }) {
        return new Promise((resolve, reject) => {
            if (state.busLocationsFirestoreListener) {
                resolve(state.busLocations);
                return;
            }
            state.busLocationsIsLoading = true;

            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, "buslocation"))
            // where("meldezeit", ">=", zeit),
            // where("gemeldet", "==", false),
            // orderBy("meldezeit"));

            state.busLocationsFirestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.busLocations.splice(0, state.busLocations.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.busLocations.push(doc.data());
                        }
                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        //console.log("Data came from " + source);
                    });
                    state.busLocationsIsLoading = false;
                    resolve(state.busLocations);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    attachBusLocationOptionsOnSnapshotoffline({ state }) {
        return new Promise((resolve, reject) => {
            if (state.busLocationOptionsFirestoreListener) {
                resolve(state.busLocationOptions);
                return;
            }
            state.busLocationOptionsIsLoading = true;

            // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
            const q = query(collection(db, "buslocationoption"))
            // where("meldezeit", ">=", zeit),
            // where("gemeldet", "==", false),
            // orderBy("meldezeit"));

            state.busLocationOptionsFirestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.busLocationOptions.splice(0, state.busLocationOptions.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.busLocationOptions.push(doc.data());
                        }
                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                        console.log("Data came from " + source);
                    });
                    state.busLocationOptionsIsLoading = false;
                    resolve(state.busLocationOptions);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachBusLocationsOnSnapshot({ state }) {
        if (state.busLocationsFirestoreListener) {
            state.busLocationsFirestoreListener();
            state.busLocationsFirestoreListener = null;
        }
    },

    detachBusLocationOptionsOnSnapshot({ state }) {
        if (state.busLocationOptionFirestoreListener) {
            state.busLocationOptionFirestoreListener();
            state.busLocationOptionFirestoreListener = null;
        }
    },

    addBusLocation({ commit }, { docid, data }) {
        console.log('INSERT', docid, data);
        const docRef = doc(db, "buslocation", docid);
        setDoc(docRef, data, { merge: true }).then(() => {
            commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    addBusLocationOption({ commit }, { docid, data }) {
        const docRef = doc(db, 'buslocationoption', docid)
        setDoc(docRef, data, { merge: true }).then(() => {
            // Neue Location gespeichert
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    // updateStrang({ commit }, { docid, data }) {
    //     console.log('UPDATE', docid, data);
    //     const docRef = doc(db, "strang", docid);
    //     updateDoc(docRef, data).then(() => {
    //         commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
    //     }).catch(err => {
    //         commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
    //     })
    // },

    // deleteStrang({ commit }, { docid }) {
    //     console.log('DELETE', docid)
    //     const docRef = doc(db, "strang", docid);
    //     deleteDoc(docRef).then(() => {
    //         commit('showSnackbar', { message: 'Eintrag gelöscht', status: 'success' }, { root: true })
    //     }).catch(err => {
    //         commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
    //     })
    // }
}

export default { state, getters, actions }
