<template>
  <form>
    <v-container>
      <Ausfahrtmelden
        :active="dialog.ausfahrtmelden"
        :datum="date"
        :doc="doc"
      ></Ausfahrtmelden>
      <v-card v-if="$store.state.auth.isAuthenticating">
        <v-banner v-if="$store.state.busexit.isOnline == false" single-line>
          <v-icon slot="icon" color="warning" size="36">
            mdi-wifi-strength-alert-outline
          </v-icon>
          Offline-Modus!
        </v-banner>
        <v-card-title>
          <!--<DigitalClock :datefield="false" :timefield="true"/>-->
          <v-icon>mdi-bus-clock</v-icon>
          &nbsp; Ausfahrt {{ exitdate }}
        </v-card-title>
        <v-card-subtitle>
          letzte Aktualisierung:
          {{ $store.state.busexit.lastChange }}
        </v-card-subtitle>
        <v-card-text> </v-card-text>
        <v-card-actions v-if="monitor == false">
          <v-btn text color="primary" @click.native="getAusfahrt(false)">
            <v-icon>mdi-arrow-left</v-icon>
            zurück
          </v-btn>
          <v-spacer></v-spacer>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="exitdate"
                label="Datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :max="aktdate"
              :locale="'de'"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click.native="getAusfahrt(true)">
            vor
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-row>
        <v-col  sm="9">
          <v-expansion-panels multiple v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card>
                  <v-card-title>
                    <v-icon>mdi-bus-clock</v-icon>
                    &nbsp; Überfällig
                  </v-card-title>
                </v-card>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card id="overdue">
                  <v-card-text>
                    <v-data-table
                      id="virtual-scroll-table"
                      :class="scrollbarTheme"
                      :headers="busexitheaders"
                      :items="overduedata"
                      :item-class="itemRowColor"
                      item-key="id"
                      @click:row="clickAusfahrtmelden"
                      :mobile-breakpoint="550"
                      :loading="isLoading"
                      disable-pagination
                      hide-default-footer
                      fixed-header
                    >
                      <template v-slot:[`item.notes`]="{ item }">
                        <span v-if="item.kurzdienst" class="itemfontsize">*</span>
                        <span v-else-if="item.sbs" class="itemfontsize">#</span>
                        <span v-else class="itemfontsize"></span>
                      </template>
                      <template v-slot:[`item.dienst`]="{ item }">
                        <span class="itemfontsize">{{ item.dienst }}</span>
                      </template>
                      <template v-slot:[`item.info`]="{ item }">
                        <span class="itemfontsize">{{ item.info }}</span>
                      </template>
                      <template v-slot:[`item.meldezeit`]="{ item }">
                        <span class="itemfontsize">{{ item.meldezeit }}</span>
                      </template>
                      <template v-slot:[`item.anwesendzeit`]="{ item }">
                        <span class="itemfontsize">{{ item.anwesendzeit }}</span>
                      </template>
                      <template v-slot:[`item.anfang`]="{ item }">
                        <span class="itemfontsize">{{ item.anfang }}</span>
                      </template>
                      <template v-slot:[`item.linie`]="{ item }">
                        <span class="itemfontsize">{{ item.linie }}</span>
                      </template>
                      <template v-slot:[`item.fahrzeug`]="{ item }">
                        <span class="itemfontsize">{{ item.fahrzeug }}</span>
                      </template>
                      <template v-slot:[`item.wagennr`]="{ item }">
                        <span class="itemfontsize">{{ item.wagennr }}</span>
                      </template>
                      <template v-slot:[`item.drivernumber`]="{ item }">
                        <span class="itemfontsize">{{ item.drivernumber }}</span>
                      </template>
                      <template v-slot:[`item.strang`]="{ item }">
                        <span class="itemfontsize">{{ getBusLocation(item.strang) }}</span>
                      </template>
                      <template v-slot:[`item.ende`]="{ item }">
                        <span class="itemfontsize">{{ item.ende }}</span>
                      </template>
                      <template v-slot:[`item.hinweis`]="{ item }">
                        <span class="itemfontsize">{{ item.hinweis }}</span>
                      </template>
                      <template v-slot:[`item.fahrer`]="{ item }">
                        <span class="itemfontsize">{{ item.neuFahrer ? item.neuFahrer : item.fahrer }}</span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card>
                  <v-card-title>
                    <v-icon>mdi-bus-clock</v-icon>
                    &nbsp; Ausfahrt
                  </v-card-title>
                </v-card>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card id="exit">
                  <v-card-text>
                    <v-data-table
                      id="virtual-scroll-table"
                      fixed-header
                      :class="scrollbarTheme"
                      :headers="busexitheaders"
                      :items="exitdata"
                      :item-class="itemRowColor"
                      item-key="id"
                      @click:row="clickAusfahrtmelden"
                      :mobile-breakpoint="550"
                      :loading="isLoading"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.notes`]="{ item }">
                        <span v-if="item.kurzdienst" class="itemfontsize">*</span>
                        <span v-else-if="item.sbs" class="itemfontsize">#</span>
                        <span v-else class="itemfontsize"></span>
                      </template>
                      <template v-slot:[`item.dienst`]="{ item }">
                        <span class="itemfontsize">{{ item.dienst }}</span>
                      </template>
                      <template v-slot:[`item.info`]="{ item }">
                        <span class="itemfontsize">{{ item.info }}</span>
                      </template>
                      <template v-slot:[`item.meldezeit`]="{ item }">
                        <span class="itemfontsize">{{ item.meldezeit }}</span>
                      </template>
                      <template v-slot:[`item.anwesendzeit`]="{ item }">
                        <span class="itemfontsize">{{ item.anwesendzeit }}</span>
                      </template>
                      <template v-slot:[`item.anfang`]="{ item }">
                        <span class="itemfontsize">{{ item.anfang }}</span>
                      </template>
                      <template v-slot:[`item.linie`]="{ item }">
                        <span class="itemfontsize">{{ item.linie }}</span>
                      </template>
                      <template v-slot:[`item.fahrzeug`]="{ item }">
                        <span class="itemfontsize">{{ item.fahrzeug }}</span>
                      </template>
                      <template v-slot:[`item.wagennr`]="{ item }">
                        <span class="itemfontsize">{{ item.wagennr }}</span>
                      </template>
                      <template v-slot:[`item.drivernumber`]="{ item }">
                        <span class="itemfontsize">{{ item.drivernumber }}</span>
                      </template>
                      <template v-slot:[`item.strang`]="{ item }">
                        <span class="itemfontsize">{{ getBusLocation(item.strang) }}</span>
                      </template>
                      <template v-slot:[`item.ende`]="{ item }">
                        <span class="itemfontsize">{{ item.ende }}</span>
                      </template>
                      <template v-slot:[`item.hinweis`]="{ item }">
                        <span class="itemfontsize">{{ item.hinweis }}</span>
                      </template>
                      <template v-slot:[`item.fahrer`]="{ item }">
                        <span class="itemfontsize">{{ item.neuFahrer ? item.neuFahrer : item.fahrer }}</span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
<!--
            <v-expansion-panel>
              <v-expansion-panel-header>
                <v-card>
                  <v-card-title>
                    <v-icon>mdi-bus-clock</v-icon>
                    &nbsp; Erledigt
                  </v-card-title>
                </v-card>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card id="done">
                  <v-card-text>
                    <v-data-table
                      id="virtual-scroll-table"
                      height="350"
                      fixed-header
                      :class="scrollbarTheme"
                      :headers="busexitheaders"
                      :items="$store.getters.getDoneData(specialServices)"
                      :item-class="itemRowColor"
                      item-key="id"
                      :mobile-breakpoint="550"
                      :loading="isLoading"
                      disable-pagination
                      hide-default-footer
                    >
                      <template v-slot:[`item.fahrer`]="{ item }">
                        {{ item.neuFahrer ? item.neuFahrer : item.fahrer }}
                      </template>
                      <template v-slot:[`item.strang`]="{ item }">
                        {{ getBusLocation(item.strang) }}
                      </template>
                      <template v-slot:[`item.notes`]="{ item }">
                        <span v-if="item.kurzdienst">*</span>
                        <span v-else-if="item.sbs">#</span>
                        <span v-else></span>
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>-->
          </v-expansion-panels>
        </v-col>
        <v-col  sm="3">
          <v-card v-for="_bus in $store.getters.getBusAnzahlAkt($store.state.busexit.data)" :key="_bus.id">
            <v-card-title>
              {{ _bus.id }}
            </v-card-title>
            <v-card-subtitle>
              <p :class="busColor(_bus.SL, _bus.SLbenoetigt)">
                Solobus {{ _bus.SL }} frei / {{ _bus.SLbenoetigt }} benötigt / {{ _bus.SLsperre }} gesperrt
              </p>
            </v-card-subtitle>
            <v-card-subtitle v-if="_bus.id != 'Ausfahrt Nachtexpress'">
              <p :class="busColor(_bus.GB, _bus.GBbenoetigt)">
                Gelenkbus {{ _bus.GB }} frei / {{ _bus.GBbenoetigt }} benötigt / {{ _bus.GBsperre }} gesperrt
              </p>
            </v-card-subtitle>
            <v-card-subtitle v-if="_bus.id != 'Ausfahrt Nachtexpress'">
              <p :class="busColor(_bus.ESLBYD, _bus.ESLBYDbenoetigt)">
                BYD E-Solobus {{ _bus.ESLBYD }} frei / {{ _bus.ESLBYDbenoetigt }} benötigt / {{ _bus.ESLBYDsperre }} gesperrt
              </p>
            </v-card-subtitle>
            <v-card-subtitle v-if="_bus.id != 'Ausfahrt Nachtexpress'">
              <p :class="busColor(_bus.ESLMB, _bus.ESLMBbenoetigt)">
                MB E-Solobus {{ _bus.ESLMB }} frei / {{ _bus.ESLMBbenoetigt }} benötigt / {{ _bus.ESLMBsperre }} gesperrt
              </p>
            </v-card-subtitle>
            <v-card-subtitle v-if="_bus.EGB > 0">
              <p :class="busColor(_bus.EGB, _bus.EGBbenoetigt)">
                E-Gelenkbus {{ _bus.EGB }} frei / {{ _bus.EGBbenoetigt }} benötigt / {{ _bus.EGBsperre }} gesperrt
              </p>
            </v-card-subtitle>
          </v-card>
          <v-spacer></v-spacer>
          <v-card>
            <v-card-title>Mitteilungen</v-card-title>
            <!--<v-card-subtitle v-for="_notification in $store.getters.getNotificationsType('strangverwaltung')"-->
            <v-card-subtitle v-for="_notification in $store.state.notifications.zblnotifications" 
            :key="_notification.createdAt"
            >
            <p class="notification">
              {{ convertToLocaleDateString(_notification) }} {{ _notification.title }}
            </p>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <DigitalClock 
        :datefield="false" 
        :timefield="true" 
        clockstyle="top: 90%; left: 87%;"
        timestyle="font-size: 7.5em;"/>
    </v-container>
  </form>
</template>

<script>
import { DateTime } from "luxon";
import DigitalClock from '@/components/app/DigitalClock.vue'
import Ausfahrtmelden from "@/components/dialog/ausfahrtmelden"

export default {
  data() {
    return {
      busexitheaders: [
        {
          text: "",
          align: "start",
          value: "notes",
        },
        {
          text: "Dienst",
          value: "dienst",
        },
        { text: "Betrieb", value: "info" },
        { text: "Meldezeit", value: "meldezeit" },
        { text: "Anwesendzeit", value: "anwesendzeit" },
        { text: "Ausfahrtszeit", value: "anfang" },
        { text: "Linie", value: "linie" },
        { text: "Bus", value: "fahrzeug" },
        { text: "Wagen Nr", value: "wagennr" },
        { text: "Strang", value: "strang" },
        { text: "Einfahrt", value: "ende" },
        { text: "Hinweis", value: "hinweis" },
        { text: "Fahrer/in", value: "fahrer" },
      ],
      dialog: {
        ausfahrtmelden: 0,
      },
      monitor: false,
      exitdate: null,
      fiveMinutesAfter: null,
      fifteenMinutesAfter: null,
      akthour: null,
      exitdata: [],
      overduedata: [],
      //donedata: [],
      //reservedata: [],
      //rangiererdata: [],
      specialServices: ["RAN", "RES"],
      timerInterval: null,
      date: null,
      aktdate: DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"),
      panel: [],
      panelValue: [
        { value: "overdue", position: 0 },
        { value: "exit", position: 1 },
        { value: "done", position: 2 },
        { value: "reserve", position: 3 },
        { value: "rangierer", position: 4 },
      ],
    };
  },

  watch: {
    date: {
      handler(newValue, oldValue) {
        if (newValue > DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")) {
          newValue = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
          this.date = newValue;
        }
        this.exitdate = DateTime.fromFormat(newValue, "yyyy-MM-dd")
          .setLocale("de")
          .toFormat("dd.MM.yyyy");
        console.log("Datumwatch: ", newValue, oldValue);
        //clearInterval(this.timerInterval);
        //this.timerInterval = setInterval(this.setfiveMinutesAfter, 2000);
/*        this.$store.dispatch("detachBusexitOnSnapshot").then(() => {
          this.$store.dispatch("attachBusExitOnSnapshotoffline", { date: newValue })
            .then(() => {
              this.timerInterval = setInterval(this.setfiveMinutesAfter, 2000);
            });
        });*/
      },
      deep: true,
    },
  },

  computed: {
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark zblbusexitview" : "light zblbusexitview";
    },
  },

  methods: {
    getdata() {
      //setInterval(() => {
          this.overduedata = this.$store.getters.getOverdueData(this.fiveMinutesAfter, this.akthour, 
                        this.specialServices, this.$store.state.busexit.data)
          this.exitdata = this.$store.getters.getExitData(this.fifteenMinutesAfter, this.specialServices, 
                        this.$store.state.busexit.data)
          this.setfiveMinutesAfter()
        //}, 2000)
    },

    setfiveMinutesAfter() {
      this.fiveMinutesAfter = DateTime.now()
        .plus({ minutes: 5 })
        .setLocale("de")
        .toFormat("HH:mm");
      this.fifteenMinutesAfter = DateTime.now()
        .plus({ minutes: -5 })
        .setLocale("de")
        .toFormat("HH:mm");
      this.akthour = DateTime.now().setLocale("de").toFormat("HH:mm");
    },

    convertToLocaleDateString(notification) {
      return notification?.createdAt?.toDate()?.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },

    getBusLocation(strang) {
      if (isNaN(strang)) {
        return `${strang.strangNummer}-${strang.strangPosition}`;
      } else {
        return strang;
      }
    },

    busColor(anzahl, benoetigt) {
      if (anzahl >= 10 && anzahl >= benoetigt) {
        return "busstyle-1";
      } else if (anzahl < 10 && anzahl >= benoetigt) {
        return "busstyle-2";
      } else if (anzahl < 10 && anzahl < benoetigt) {
        return "busstyle-3";
      } else if (anzahl >= benoetigt) {
        return "busstyle-1";
      } else if (anzahl < benoetigt) {
        return "busstyle-2"
      }
    },

    itemRowColor(item) {
      if (item.gemeldet == true) {
        return "zblstyle-6";
      }
      if (this.date === DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")) {
        let zeit = DateTime.now().plus({}).setLocale("de").toFormat("HH:mm");
        if (item.meldezeit < zeit) {
          if (item.anfang <= zeit) {
            let zeitaus = DateTime.now()
              .minus({ minutes: 5 })
              .setLocale("de")
              .toFormat("HH:mm");
//              console.log("ITEM-Color", item.anfang, zeit, zeitaus)
              if (item.anfang >= zeitaus) {  
                item.class = item.class == 'zblstyle-5' ? 'zblstyle-5-1' : 'zblstyle-5'
              } else {
                item.class = 'zblstyle-5'
              }
            return item.class;
          }
          return "zblstyle-5";
        }
        zeit = DateTime.now()
          .plus({ minutes: +2 })
          .setLocale("de")
          .toFormat("HH:mm");
        if (item.meldezeit <= zeit) {
          return "zblstyle-4";
        }
        zeit = DateTime.now()
          .plus({ minutes: +5 })
          .setLocale("de")
          .toFormat("HH:mm");
        if (item.meldezeit <= zeit) {
          return "zblstyle-3";
        }
        zeit = DateTime.now()
          .plus({ minutes: +10 })
          .setLocale("de")
          .toFormat("HH:mm");
        if (item.meldezeit <= zeit) {
          return "zblstyle-2";
        }
        zeit = DateTime.now()
          .plus({ minutes: +75 })
          .setLocale("de")
          .toFormat("HH:mm");
        if (item.meldezeit <= zeit) {
          return "zblstyle-1";
        }
        //https://stackoverflow.com/questions/57961043/how-does-one-style-a-specific-row-on-v-data-table-vuetify
        return "zblstyle-0";
      } else {
        let zeit = DateTime.now().plus({}).setLocale("de").toFormat("HH:mm");
        if (item.anfang >= zeit) {
          return "zblstyle-5";
        }
        return "zblstyle-5";
      }
    },

    clickAusfahrtmelden(value) {
      this.doc = value;
      //this.dialog.ausfahrtmelden++;
    },

    getAusfahrt(_day) {
      if (_day) {
        this.date = DateTime.fromFormat(this.date, "yyyy-MM-dd")
          .setLocale("de")
          .plus({ days: +1 })
          .toFormat("yyyy-MM-dd");
      } else {
        this.date = DateTime.fromFormat(this.date, "yyyy-MM-dd")
          .setLocale("de")
          .plus({ days: -1 })
          .toFormat("yyyy-MM-dd");
      }
    },
  },

  created() {
    //this.$store.dispatch("attachSettingsOnSnapshotoffline")
    //this.$store.dispatch("attachStrangOnSnapshotoffline")
    this.$store.dispatch("attachbusexittypes")
    
  },

  mounted() {
    //console.log("TEST: ",this.$route.query);
    this.$store.state.auth.withMenu = false;
    if (typeof this.$route.query.monitor !== "undefined") {
      this.monitor = this.$route.query.monitor;
    } else {
      this.monitor = false;
    }
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.exitdate = DateTime.fromFormat(this.date, "yyyy-MM-dd")
      .setLocale("de")
      .toFormat("dd.MM.yyyy");
    this.$store.dispatch("attachZBLNotifications")
    this.$store.dispatch("attachBusExitOnSnapshotoffline", { date: this.date })
      .then(() => {
        //this.fetchData();
        clearInterval(this.timerInterval);
        this.timerInterval = setInterval(this.getdata, 2000);
      });
    const params = Object.keys(this.$route.query);
    const panel = [];
    params.forEach((param) => {
      this.panelValue.forEach((val) => {
        if (val.value == param) {
          panel.push(val.position);
        }
      });
    });
    this.panel = panel;
  },

  beforeDestroy() {
    clearInterval(this.timerInterval);
    this.$store.dispatch("detachBusexitOnSnapshot");
    this.$store.dispatch("detachhbusexittypes");
  },

  components: {
    Ausfahrtmelden,
    DigitalClock,
  },
};
</script>

<style>
.notification {
  font-size: 1.75em;
}
.busstyle-1 {
  font-size: 2.025em; /* 20px/16=1.25em */
  color: rgb(14, 212, 30);
}
.busstyle-2 {
  font-size: 2.025em; /* 20px/16=1.25em */
  color: rgb(236, 236, 11);
}
.busstyle-3 {
  font-size: 2.025em; /* 20px/16=1.25em */
  color: rgb(237, 7, 7);
}
.container {
  max-width: 98% !important;
}
.itemfontsize {
  font-size: 1.525em!important;
}
.zblstyle-1 {
  font-size: 1.525em!important;
  color: rgb(93, 93, 19);
}
.zblstyle-2 {
  font-size: 1.525em!important;
  color: rgb(148, 173, 7);/*240, 240, 125*/
}
.zblstyle-3 {
  font-size: 1.525em!important;
  color: rgb(235, 118, 10);
}
.zblstyle-4 {
  font-size: 1.525em!important;
  color: rgb(226, 70, 49);
}
.zblstyle-5 {
  font-size: 1.525em!important;
  color: rgb(185, 7, 7);
}
.zblstyle-5-1 {
  font-size: 1.525em!important;
  color: rgb(235, 9, 223);
}
.zblstyle-6 {
  font-size: 1.525em!important;
  color: rgb(14, 212, 30);
}
.zblbusexitview.v-data-table .v-data-table-header tr th {
  font-size: 1.525em !important;
}
#virtual-scroll-table {
  max-height: 38em;
  overflow: auto;
}
#overdue {
  height: 38em;
}
#exit {
  height: 38em;
}
#done {
  height: 28em;
}
.light::-webkit-scrollbar {
  width: 15px;
}

.light::-webkit-scrollbar-track {
  background: #e6e6e6;
  border-left: 1px solid #dadada;
}

.light::-webkit-scrollbar-thumb {
  background: #b0b0b0;
  border: solid 3px #e6e6e6;
  border-radius: 7px;
}

.light::-webkit-scrollbar-thumb:hover {
  background: black;
}

.dark::-webkit-scrollbar {
  width: 25px;
}

.dark::-webkit-scrollbar-track {
  background: #202020;
  border-left: 1px solid #2c2c2c;
}

.dark::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border: solid 3px #202020;
  border-radius: 7px;
}

.dark::-webkit-scrollbar-thumb:hover {
  background: white;
}
</style>
