import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, updateDoc, setDoc, deleteDoc, getDoc, getDocs } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
  securityincidentdata: [],
  statistic: [],
  excelcolumn: [],
  dtheader: [],
  dtexpandheader: [],
  securityincidentlistener: null,
  isloading: false,
  isonline: false,
  lastchange: null,
}

const getters = {

}

const actions = {
  async insertSecurityincident({ state }, { docid, securityincident }) {
    const docSnap = getDoc(doc(db, "securityincident", docid));
    if (docSnap.exists) {
      console.log(docSnap.data())
    } else {
      await setDoc(doc(db, "securityincident", docid), securityincident, { merge: true });
    }
  },

  async attachSecurityincidentStatistic({ state }, { from, to }) {
    const qs = query(collection(db,  "config", "settings", "securityincidenttypes"));
    const qss = await getDocs(qs)
    const qsi = query(collection(db, "securityincident"), where("date", ">=", from), where("date", "<=", to));
    const qssi = await getDocs(qsi);
    state.statistic.splice(0, state.statistic.length);
    state.excelcolumn.splice(0, state.excelcolumn.length);
    state.dtheader.splice(0, state.dtheader.length);
    state.dtexpandheader.splice(0, state.dtexpandheader.length);
    const placetags = JSON.parse(process.env.VUE_APP_SECURITYINCEDENTPLACETAGS);
    state.excelcolumn.push({ label: "Datum", field: "date" });
    state.excelcolumn.push({ label: "Zeit", field: "time" });
//    state.excelcolumn.push({ label: "Name", field: "name" });
//    state.excelcolumn.push({ label: "Personalnr.", field: "personnelnr" });
    state.excelcolumn.push({ label: "Text", field: "text" });
    state.excelcolumn.push({ label: "Linie", field: "busline" });
    state.excelcolumn.push({ label: "Linientext", field: "buslinetext" });
    state.excelcolumn.push({ label: "Haltestelle/Straße", field: "platform" });
    state.excelcolumn.push({ label: "Haltestellennr.", field: "platformnr" });
    state.excelcolumn.push({ label: "Haltestellentitel", field: "platformtitle" });
    state.dtheader.push({ text: "Datum", value: "date" });
    state.dtheader.push({ text: "Zeit", value: "time" });
    state.dtheader.push({ text: "Name", value: "name" });
    state.dtheader.push({ text: "Personalnr.", value: "personnelnr" });
    state.dtheader.push({ text: "Text", value: "text" });
    state.dtheader.push({ text: "Linie", value: "buslinetext" });
    state.dtheader.push({ text: "Haltestelle/Straße", value: "platform" });
    for (const _tag of placetags) {
      const _t = _tag.replaceAll(' ', '_');
      state.excelcolumn.push({ label: _tag, field: _t });
      state.dtexpandheader.push({ text: _tag, value: _t });
    }
    for (const _qss of qss.docs) {
      const _t = _qss.data().text.replaceAll(' ', '_');
      state.excelcolumn.push({ label: _qss.data().text, field: _t });
      state.dtexpandheader.push({ text: _qss.data().text, value: _t });
    }
    for (const _qssi of qssi.docs) {
      const _sidata = _qssi.data();
      let _data = {};
      _data.date =  DateTime.fromFormat(_sidata.date, "yyyy-MM-dd").setLocale('de').toFormat("dd.MM.yyyy");
      _data.time = _sidata.time;
      _data.name = _sidata.name;
      _data.personnelnr = _sidata.personnelnr;
      _data.text = _sidata.text;
      _data.busline = _sidata.busline.id;
      _data.buslinetext = _sidata.busline.line;
      _data.platform = _sidata.platform.name;
      _data.platformnr = _sidata.platform.nr;
      _data.platformtitle = _sidata.platform.title;
      for (const _tag of placetags) {
        const _t = _tag.replaceAll(' ', '_');
        if(_sidata.place.includes(_tag)) {
          _data[_t] = true;
        } else {
          _data[_t] = false;
        }
      }
      for (const _qss of qss.docs) {
        const _t = _qss.data().text.replaceAll(' ', '_');
        if(_sidata.type.includes(_qss.data().text)) {
          _data[_t] = true;
        } else {
          _data[_t] = false;
        }
      }

      state.statistic.push(_data);
    }
  },
}

export default { state, getters, actions }