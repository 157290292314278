<template>
  <form>
    <v-container>
      <v-row>
        <!--<v-col sm="3">
          <v-combobox
            v-model="wagennr"
            :items="$store.getters.vehicleList"
            item-text="wagennummer"
            item-value="wagennummer"
            label="Wagennummer"
            prepend-icon="mdi-bus"
          ></v-combobox>
        </v-col>-->
        <v-col sm="3">
          <v-autocomplete
            v-model="wagennr"
            :items="$store.getters.vehicleList"
            item-text="wagennummer"
            item-value="wagennummer"
            label="Wagennummer"
            prepend-icon="mdi-bus"
          ></v-autocomplete>
        </v-col>
        <v-col sm="6">
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="date"
                label="Datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :max="aktdate"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-card v-if="trapezedata">

          <v-card-text>
            <div class="font-weight-bold ml-8 mb-2">
              Wagen: {{ wagennr }} Datum: {{ date }}
            </div>

            <v-timeline
              align-top
              dense
            >
              <v-timeline-item
                v-for="message in $store.state.trapeze.data"
                :key="message.datum"
                :color="getColor(message.MessageType)"
                small
              >
                <v-alert 
                  :color="getColor(message.MessageType)" 
                  :icon="getIcon(message.MessageType)">
                  <div class="font-weight-normal">
                    <strong>{{ message.MessageType }}</strong> {{ message.datum }}
                  </div>
                  <div>
                    <tree-view :data="message" :options="{maxDepth: 6, rootObjectKey: 'Meldung'}"></tree-view>
                  </div>
                </v-alert>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
        <v-card v-else>
          <v-card-text>
            <div class="font-weight-bold ml-8 mb-2">
              Wagen: {{ wagennr }} Datum: {{ date }} hat keine Daten!
            </div>
          </v-card-text>
        </v-card>
      </v-row>
    </v-container>
  </form>
</template>

<script>

import { DateTime } from "luxon";

export default {
  data() {
    return {
      wagennr: "",
      date: DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"),
      aktdate: DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"),
      menu: false,
      trapezedata: false,
    };
  },

  watch: {
    wagennr: {
      handler(newValue, oldValue) {
        //console.log("Wagen-Nr:", newValue, oldValue)
        this.$store.dispatch("detachTrapeze")
        this.$store.dispatch("detachTrapezedata")
        const docid = newValue + '_' + DateTime.fromFormat(this.date, "yyyy-MM-dd").setLocale('de').toFormat("yyyyMMdd")
        //this.wagennr = newValue
        //console.log("Wagen: ", docid, this.wagennr)
        this.$store.dispatch("attachTapeze", { docid: docid })
        this.$store.dispatch("attachTapezedata", { docid: docid })
      }, deep: true
    },

    date: {
      handler(newValue, oldValue) {
        if(this.wagennr != "") {
          this.$store.dispatch("detachTrapeze");
          this.$store.dispatch("detachTrapezedata");
          const docid = this.wagennr + '_' + DateTime.fromFormat(newValue, "yyyy-MM-dd").setLocale('de').toFormat("yyyyMMdd");
          //console.log("Datum: ", docid, this.wagennr);
          this.$store.dispatch("attachTapeze", { docid: docid });
          this.$store.dispatch("attachTapezedata", { docid: docid });
        }
      }, deep: true
    },

    "$store.state.trapeze.data": {
      handler: function (data) {
        if(data.length > 0) {
          this.trapezedata = true;
        } else {
          this.trapezedata = false;
        }
      }, immediate: true,
    },

    "$store.state.auth.isAuthenticating": {
      handler: function (isAuthenticating) {
      }, immediate: true,
    },
  },

  computed: {},

  methods: {
    getColor(_type) {
      switch(_type) {
        case "LogOn":
          return "green";
        case "LogOff":
          return "orange";
        case "Update":
          return "grey";
      }
      return "red"
    },
    getIcon(_type) {
      switch(_type) {
        case "LogOn":
          return "mdi-login";
        case "LogOff":
          return "mdi-logout";
        case "Update":
          return "mdi-message-reply-text-outline";
      }
      return "red"
    }
  },

  mounted() {
    this.aktdate = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.$store.dispatch("attachVehiclesOnSnapshot");
  },

  beforeDestroy() {
    this.$store.dispatch("detachVehiclesOnSnapshot");
    this.$store.dispatch("detachTrapeze");
    this.$store.dispatch("detachTrapezedata");
  },

  components: {},
};
</script>
