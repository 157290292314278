<template>
  <v-dialog v-model="open" width="500px" @click:outside="close">
    <v-card elevation="0" style="background-color: rgb(91, 91, 91)">
      <v-card-title style="color:rgb(255, 255, 255)"> {{ title }} </v-card-title>
      <v-card-text style="color:rgb(255, 255, 255)">
        {{ meldung }}
      </v-card-text>
      <v-card-actions class="elevation-3 mt-1 d-flex space-between flex-row">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="ok">
          <span>Bestätigen</span>
        </v-btn>
        <v-btn color="secondary" @click="abbruch">
          <span>Abbrechen</span>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "confirmdlg",
  props: {
    title: {
      type: String,
      default:"DialogMeldung"
    },
    meldung: {
      type: String,
      default: "",
    },
    open: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: ""
    }
  },
  data() {
    return {}
  },
  methods: {
    abbruch() {
      this.$emit("confirm:abort", false, this.type)
    },
    ok() {
      this.$emit("confirm:ok", true, this.type)
    },
    close() {
      this.$emit("confirm:close", false, this.type)
    },
  },
}
</script>