import { db } from '@/firebase/firebase';
import { collection, collectionGroup, onSnapshot, where, query, orderBy } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    itemdata: [],
    buslineplatforms: [],
    firestoreListener: null,
    BuslinePlatformsListener: null,
    isLoading: false,
    isOnline: false,
    lastChange: null,
}

const mutations = {

}

const actions = {
    attachBusLineOnSnapshotoffline({ state }, { data }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.itemdata);
                return;
            }
            state.isLoading = true;
            const q = query(collection(db, "busline"), where("active", "==", "yes"));
            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.itemdata.splice(0, state.itemdata.length);
                    if (data) {
                        state.itemdata.push(data);
                    }
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.itemdata.push(doc.data());
                        }
//                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if(snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm")
                        }
//                        console.log("Data came from " + date + "/" + source);
                    });
                    state.isLoading = false;
                    resolve(state.itemdata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    attachBusLinePlatforms({ state }, { busline }) {
        return new Promise((resolve, reject) => {
            if (state.BuslinePlatformsListener) {
                resolve(state.buslineplatforms);
                return;
            }
            state.isLoading = true;
            const q = query(collectionGroup(db, "buslineplatforms"), where("lineid", "==", busline), orderBy("name", "asc"));
            state.BuslinePlatformsListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.buslineplatforms.splice(0, state.buslineplatforms.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.buslineplatforms.push(doc.data());
                        }
//                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if(snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm")
                        }
//                        console.log("Data came from " + date + "/" + source);
                    });
                    state.isLoading = false;
                    resolve(state.buslineplatforms);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachBusLineOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
    },

    detachBusLinePlatforms({ state }) {
        if (state.BuslinePlatformsListener) {
            state.BuslinePlatformsListener();
            state.BuslinePlatformsListener = null;
        }
    },
}

export default { state, mutations, actions }