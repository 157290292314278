<template>
  <v-container
    fill-height
    fluid
    style="height: 100%"
    v-if="$store.state.settings.isLoading && $store.state.busexit.isLoading"
  >
    <v-row class="text-center">
      <v-col cols="12">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-card v-if="!$store.state.settings.settings.prod && !hasPermission">
      <v-card-text class="pl-4">
        Persönliche Ausfahrt ist zurzeit deaktiviert!
      </v-card-text>
    </v-card>
    <div v-else>
      <v-container>
        <template v-if="isPersonalTasksAvailable">
          <v-card>
            <v-card-title> Hallo {{ firstName }} </v-card-title>
            <div v-for="(task, index) in $store.getters.getPersonalTasks" :key="index">
              <PersonalTask :task="task" :meldetype="'digitaleausfaht'" :isNearby="isNearRheinstr"></PersonalTask>
            </div>
          </v-card>
        </template>
        <template v-else>
          <v-card class="mt-5">
            <v-card-title> Hallo {{ firstName }} </v-card-title>
            <v-card-text class="pl-4"> Du hast kein Dienst. </v-card-text>
          </v-card>
        </template>
      </v-container>
      <v-container v-if="useremail == 'a002536@itvv.org'">
        <v-card>
          <v-card-title> Koordinaten: </v-card-title>
          <v-card-text class="pl-4"> 
            {{ currentCoordinates.lat }} : {{ currentCoordinates.lng }} - {{ isNearRheinstr }} m
          </v-card-text>
        </v-card>
      </v-container>
    </div>
    <Version />
  </v-container>
</template>

<script>
import { DateTime } from "luxon";

import PersonalTask from "@/components/app/PersonalTask";
import Version from "@/components/version";

export default {
  components: {
    PersonalTask,
    Version,
  },

  data() {
    return {
      isAnwesend: false,
      date: null,
      time: null,
      currentdistance: 0,
      currentCoordinatesId: null,
      currentCoordinates: {
        lat: 0,
        lng: 0,
      },
      centerCoordinateRheinstr: {
        lat: 51.184527163181166,
        lng: 6.449758375657727,
      },
      locations: [
        //{ lat: 51.184527163181166, lng: 6.449758375657727 }, // Rheinstr. 70
        { lat: 51.184540, lng: 6.451454 }, // Rheinstr.
        //{ lat: 51.168562659097766, lng: 6.448795724251258 }, // Test Nico
        //{ lat: 51.3179658, lng: 6.4585718 },
        { lat: 51.1814050, lng: 6.4961190 } //Test Andreas
      ],
      wuensche: ["Fahr vorsichtig!", "Gute Fahrt!"],
      anwesendInterval: null,
    };
  },

  watch: {
    "$store.getters.getPersonalTasks": {
      handler: function (pt) {
        if (pt.every((task) => task.anwesend)) {
          this.isAnwesend = true;
        } else {
          this.isAnwesend = false;
        }
      },
      deep: true,
    },
    currentCoordinates: {
      handler(newValue, oldValue) {
        console.log("LOCATION", oldValue, newValue)
      },
      deep: true,
    },
  },

  computed: {
    firstName() {
      return this.$store.state.auth.user.displayName.split(",")[1]
    },

    useremail() {
      return this.$store.state.auth.user.email
    },

    isPersonalTasksAvailable() {
      return this.$store.getters.getPersonalTasks.length
    },

    isNearRheinstr() {
      //return true;
      const distanceArray = this.locations.map((loc) => {
        const _distance = this.calculateDistanceInM(
          loc.lat,
          loc.lng,
          this.currentCoordinates.lat,
          this.currentCoordinates.lng
        );
        return _distance
      });
      const idx = distanceArray.indexOf(Math.min.apply(null,distanceArray))
      //console.log("LOCATION-Distance:", idx, distanceArray)
      return distanceArray[idx]
      /*if (distanceArray[idx] <= 500) {
        
      } else {
        return distanceArray[idx]
      }*/
      //   return distanceArray.some((distance) => distance <= 220);
      //return distanceArray.some((distance) => distance <= 5000); // zu Testzwecken gedacht
    },

    generateWuensche() {
      return this.wuensche[this.getRandomInt(this.wuensche.length)];
    },

    hasPermission() {
      return this.$store.getters.hasPermission({
        type: "customUserPermission",
        right: "personalAusfahrtTest",
      });
    },

    istFahrerGemeldet() {
      return this.$store.getters.getPersonalTasks.every(
        (task) => task.anwesend
      );
    },
  },

  methods: {
    getRandomInt(max) {
      return Math.floor(Math.random() * max);
    },

    degrees_to_radians(degrees) {
      return degrees * (Math.PI / 180);
    },

    calculateDistanceInM(lat1, lng1, lat2, lng2) {
      const R = 6371000;
      const x1 = lat2 - lat1;
      const dLat = this.degrees_to_radians(x1);
      const x2 = lng2 - lng1;
      const dLon = this.degrees_to_radians(x2);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.degrees_to_radians(lat1)) *
          Math.cos(this.degrees_to_radians(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = R * c;
      return Math.round(d);
    },

    getCurrentCoordinates() {
      if (!("geolocation" in navigator)) {
        this.$store.commit("showSnackbar", {
          status: "error",
          message: "Geolocation is not available.",
        });
      }
      this.currentCoordinatesId = navigator.geolocation.watchPosition(
        (position) => {
          //console.log(position.coords.accuracy)
          //if (position.coords.accuracy < 50) {
          //  navigator.geolocation.clearWatch(this.currentCoordinatesId);
          //}
          const { latitude, longitude } = position.coords;
          this.currentCoordinates.lat = latitude;
          this.currentCoordinates.lng = longitude;
          //const _near = this.isNearRheinstr
          //console.log("NEAR", _near)
        },
        (error) => {
          this.$store.commit("showSnackbar", {
            status: "error",
            message: error.message,
          });
        },
        { enableHighAccuracy: true, maximumAge: 120000 }
      );
      //console.log("Koordianten:", this.currentCoordinates)
    },

    fahrerMelden() {
      clearTimeout(this.anwesendInterval);
      this.$store.getters.getPersonalTasks.forEach((task) => {
        if (!task.anwesend) {
          this.$store.dispatch("updateBusExit", {
            date: this.date,
            docid: task.docid,
            data: { anwesendzeit: this.time, anwesend: true, anwesendmeldung: "digitaleausfaht" },
          });
        }
      });
      this.isAnwesend = true;
    },
  },

  created() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")
    this.time = DateTime.now().setLocale("de").toFormat("HH:mm")
    this.$store.dispatch("attachBusExitPersnr", { date: this.date, persnr: this.$store.state.auth.personnelnr })
    //this.$store.dispatch("attachBusExitOnSnapshotoffline", { date: this.date })
    //this.$store.dispatch("attachSettingsOnSnapshotoffline")
    // this.$forceUpdate();

    this.timeInterval = setInterval(() => {
      this.time = DateTime.now().setLocale("de").toFormat("HH:mm")
      this.getCurrentCoordinates()
    }, 1000)
  },

  beforeDestroy() {
    navigator.geolocation.clearWatch(this.currentCoordinatesId);
    clearInterval(this.timeInterval);
    clearTimeout(this.anwesendInterval);
    this.$store.dispatch("detachBusexit");
  },
};
</script>

<style scoped>
.btnanwesend.v-btn.v-btn.v-btn--has-bg {
  background-color:#9b004b !important;
  color: white !important;
}

.btnname.v-btn.v-btn.v-btn--has-bg {
  background-color:#aa3f73 !important;
  color: rgb(146, 238, 189) !important;
}
</style>
