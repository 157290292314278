<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>Meldeliste anlegen</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="meldeliste"
          label="Meldeliste"
        ></v-text-field>
        <v-autocomplete
          :items="meldelistentypen"
          item-text='label'
          item-value='value'
          v-model="meldelisteid" 
          data-vv-name="meldeliste" 
          prepend-icon="mdi-account-outline"
        >
        </v-autocomplete>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primaryLight"
          text
          @click="cancleMeldeliste"
          value="CANCELAUSFAHRTMELDEN"
        >
          Abbrechen
        </v-btn>
        <v-btn color="primaryLight" text @click="addbusexitlist">
          Speichern
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
//import firebase from 'firebase/app';

export default {
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      meldeliste: '',
      meldelisteid: '',
      meldelistentypen: [
        {
          label: "Normal Mo-Fr",
          value: "NMF"
        },
        {
          label: "Normal Sa",
          value: "NSA"
        },
        {
          label: "Normal So",
          value: "NSO"
        },
        {
          label: "Ferien Mo-Fr",
          value: "FMF"
        },
        {
          label: "Ferien Sa",
          value: "FSA"
        },
        {
          label: "Ferien So",
          value: "FSO"
        },
        {
          label: "kleine Ferien Mo-Fr",
          value: "KMF"
        },
        {
          label: "kleine Ferien Sa",
          value: "KSA"
        },
        {
          label: "kleine Ferien So",
          value: "KSO"
        },
        {
          label: "Heiligabend",
          value: "WHA"
        },
        {
          label: "Silvester",
          value: "WSS"
        }
      ]
    };
  },

  computed: {},

  watch: {
    active() {
      this.dialogVisible = true;
    },
  },

  methods: {
    addbusexitlist() {
      if (this.meldeliste !== '' && this.meldelisteid !== '') {
        this.$store.dispatch("insertBusexitlist", { docid: this.meldeliste, type: this.meldelisteid });
        this.meldeliste = '';
        this.meldelisteid = '';
        this.dialogVisible = false;
      }
    },
    cancleMeldeliste() {
      this.meldeliste = '';
      this.meldelisteid = '';
      this.dialogVisible = false;
    },
  },

  created() {},

  props: {
    active: Number,
  },
};
</script>
