<template>
  <v-container fill-height fluid style="height: 100%" v-if="$store.state.resserviceisLoading">
    <v-row class="text-center">
      <v-col cols="12">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else fluid grid-list-sm>
    <form @submit.prevent="updateFirebase">
      <v-card v-if="$store.state.auth.isAuthenticating">
        <v-banner  v-if="$store.state.resservice.isOnline == false" single-line >
            <v-icon slot="icon" color="warning" size="36">mdi-wifi-strength-alert-outline</v-icon>
            Offline-Modus!
        </v-banner>
        <v-card-title><v-icon>mdi-calendar</v-icon>&nbsp;ZBL - Tagesbericht vom {{$store.state.resservice.rd[0].datumanzeige}} </v-card-title>
        <v-card-subtitle>letzte Aktualisierung: {{$store.state.resservice.lastChange}}</v-card-subtitle>
        <insertreservedienst
          :active="this.dialog.addreservedienst"
          :type="this.type"
        ></insertreservedienst>
        <insertrdeinsatz
          :active="this.dialog.addrdeinsatz"
          :date="this.einsatzdate"
          :docid="this.einsatzdocid"
          :typ="this.einsatztyp"
          :employee="this.employee"
        ></insertrdeinsatz>
        <v-card-text>
          <v-layout  class="reservedienst-form" row wrap v-for="(rd, rdindex) in $store.state.resservice.rd"  :key="rd.datum">
<!--********** Reserve KOM *******************************************************************************************-->
            <v-card width='100%'>
              <v-row v-for="(reswg, reswgindex) in reserve_wg" :key="reswgindex">
                <v-col>
                  <v-text-field 
                      :value="`${reswg.hinweis}  Dienst: ${reswg.dienst}  Beginn: ${reswg.anfang}  Fahrer: ${reswg.fahrer}  Wagen: ${reswg.equinr}`" 
                      label="Reserve" 
                      prepend-icon="mdi-account-outline"
                      readonly
                    >
                    </v-text-field>
                </v-col>
              </v-row>
            </v-card>
<!--            <v-card width='100%'>
              <v-row>
                <v-col md3>
                  <v-card tile flat>
                    <v-text-field 
                      v-model="rd.kommg" 
                      label="Reserve KOM am MG Hbf" 
                      prepend-icon="mdi-account-outline"
                      readonly
                    >
                    </v-text-field>
                  </v-card>
                </v-col>
                <v-col md3>
                  <v-card tile flat>
                    <v-autocomplete
                      :items="kom"
                      item-text='equipment'
                      item-value='equipment'
                      v-model="rd.kommg_kom" 
                      label="KOM" data-vv-name="reserve.kommg_kom" prepend-icon="mdi-account-outline" return-object
                    >
                    </v-autocomplete>
                  </v-card>
                </v-col>
                <v-col md3>
                  <v-card tile flat>
                    <v-text-field 
                      v-model="rd.komry" 
                      label="Reserve KOM am ZOB Ry" 
                      prepend-icon="mdi-account-outline"
                      readonly
                    >
                    </v-text-field>
                  </v-card>
                </v-col>
                <v-col md3>
                  <v-card tile flat>
                    <v-autocomplete
                      :items="kom"
                      item-text='equipment'
                      item-value='equipment'
                      v-model="rd.komry_kom" 
                      label="KOM" data-vv-name="reserve.komry_kom" prepend-icon="mdi-account-outline" return-object
                    >
                    </v-autocomplete>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>-->
<!--********** früh Dienst *******************************************************************************************-->
            <v-expansion-panels v-model="panel" multiple popout>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-card>
                    <v-card-title>
                      <v-toolbar flat>
                      <v-toolbar-title>Frühdienst</v-toolbar-title>
                    </v-toolbar>
                    </v-card-title>
                  </v-card>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card width='100%'>
                    <v-card-actions>
                      <v-btn text color="green" @click.native="addreservedienst(rdindex, 'DPfrü')" :disabled="formreadonly">
                        <v-icon color="green">mdi-note-plus-outline</v-icon>
                        neuer Frühdienst
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-row v-for="(fr, frindex) in rd.frueh" :key="frindex">
                      <v-col md="12">
                        <v-row>
                          <v-col sm="2">
                            <v-card tile flat>
                              <v-text-field 
                                  v-model="fr.dienst" 
                                  label="Dienst" 
                                  prepend-icon="mdi-account-outline"
                                  :readonly="fr.ro >= 1"
                              >
                              </v-text-field>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelvon"
                                  v-bind:field="fr.von" 
                                  v-bind:modal="fr.modal"
                                  v-bind:i1="frindex"
                                  v-bind:i2=0
                                  v-bind:i3=0
                                  v-bind:ro="fr.ro"
                                  v-bind:typ="'fruehdienstvon'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelbis"
                                  v-bind:field="fr.bis" 
                                  v-bind:modal="fr.modal"
                                  v-bind:i1="frindex"
                                  v-bind:i2=0
                                  v-bind:i3=0
                                  v-bind:ro="fr.ro"
                                  v-bind:typ="'fruehdienstbis'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2=">
                            <v-card tile flat>
                              <v-text-field 
                                  v-model="fr.ort" 
                                  label="Ort" 
                                  prepend-icon="mdi-account-outline"
                                  :readonly="fr.ro >= 1"
                              >
                              </v-text-field>
                            </v-card>
                          </v-col>
                          <v-col sm="3">
                            <v-card tile flat>
                              <v-autocomplete
                                  :items="$store.getters.FahrerList"
                                  item-text='name'
                                  item-value='Personnel_nr'
                                  v-model="fr.persid" 
                                  :readonly="fr.ro >= 1"
                                  label="Name" data-vv-name="fr.persid" prepend-icon="mdi-account-outline"
                                  @change="(event) => updateName(event, mtindex, 'FR')"
                              >
                              </v-autocomplete>
                            </v-card>
                          </v-col>
                          <v-col sm="1">
                            <v-card tile flat>
                              <v-toolbar-title class="grey--text text--darken-4">Einsatz</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-btn icon light @click.native="addNewEinsatzForm(frindex, fr, 'DPfrü')" :disabled="formreadonly">
                                <v-icon color="grey darken-2">mdi-plus</v-icon>
                              </v-btn>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row v-for="(ein, einindex) in fr.einsatz['DPfrü']" :key="einindex">
                          <v-col sm="4">
                            <v-card tile flat>
                            </v-card>
                          </v-col>
                          <v-col sm="3">
                            <v-card tile flat>
                              <v-autocomplete
                                :items="$store.state.dayroster.roster"
                                item-text='Service_nr'
                                item-value='Service_nr'
                                v-model="ein.dienst" 
                                label="Dienst" data-vv-name="ein.dienst" prepend-icon="mdi-account-outline" return-object
                              >
                              </v-autocomplete>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelvon"
                                  v-bind:field="ein.von" 
                                  v-bind:modal="ein.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3="einindex"
                                  v-bind:ro=false
                                  v-bind:typ="'fruehvon'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelbis"
                                  v-bind:field="ein.bis" 
                                  v-bind:modal="ein.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3="einindex"
                                  v-bind:ro=false
                                  v-bind:typ="'fruehbis'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-btn icon color="green" @click.native="delEinsatzForm(einindex, fr, 'DPfrü')" :disabled="formreadonly">
                              <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
<!--********** mittel Dienst *******************************************************************************************-->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-card>
                    <v-card-title>
                      <v-toolbar flat>
                      <v-toolbar-title>Mitteldienst</v-toolbar-title>
                    </v-toolbar>
                    </v-card-title>
                  </v-card>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card width='100%'>
                    <v-card-actions>
                      <v-btn text color="green" @click.native="addreservedienst(rdindex, 'DPmit')" :disabled="formreadonly">
                        <v-icon color="green">mdi-note-plus-outline</v-icon>
                        neuer Mitteldienst
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-row v-for="(fr, frindex) in rd.mittel" :key="frindex">
                      <v-col md="12">
                        <v-row>
                          <v-col sm="2">
                            <v-card tile flat>
                              <v-text-field 
                                  v-model="fr.dienst" 
                                  label="Dienst" 
                                  prepend-icon="mdi-account-outline"
                                  :readonly="fr.ro >= 1"
                              >
                              </v-text-field>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelvon"
                                  v-bind:field="fr.von" 
                                  v-bind:modal="fr.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3=0
                                  v-bind:ro="fr.ro"
                                  v-bind:typ="'fruehdienstvon'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelbis"
                                  v-bind:field="fr.bis" 
                                  v-bind:modal="fr.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3=0
                                  v-bind:ro="fr.ro"
                                  v-bind:typ="'fruehdienstbis'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2=">
                            <v-card tile flat>
                              <v-text-field 
                                  v-model="fr.ort" 
                                  label="Ort" 
                                  prepend-icon="mdi-account-outline"
                                  :readonly="fr.ro >= 1"
                              >
                              </v-text-field>
                            </v-card>
                          </v-col>
                          <v-col sm="3">
                            <v-card tile flat>
                              <v-autocomplete
                              :items="$store.getters.FahrerList"
                                  item-text='name'
                                  item-value='Personnel_nr'
                                  v-model="fr.persid" 
                                  :readonly="fr.ro >= 1"
                                  label="Name" data-vv-name="fr.persid" prepend-icon="mdi-account-outline"
                                  @change="(event) => updateName(event, mtindex, 'FR')"
                              >
                              </v-autocomplete>
                            </v-card>
                          </v-col>
                          <v-col sm="1">
                            <v-card tile flat>
                              <v-toolbar-title class="grey--text text--darken-4">Einsatz</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-btn icon light @click.native="addNewEinsatzForm(frindex, fr, 'DPmit')" :disabled="formreadonly">
                                <v-icon color="grey darken-2">mdi-plus</v-icon>
                              </v-btn>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row v-for="(ein, einindex) in fr.einsatz['DPmit']" :key="einindex">
                          <v-col sm="4">
                            <v-card tile flat>
                            </v-card>
                          </v-col>
                          <v-col sm="3">
                            <v-card tile flat>
                              <v-autocomplete
                                :items="$store.state.dayroster.roster"
                                item-text='Service_nr'
                                item-value='Service_nr'
                                v-model="ein.dienst" 
                                label="Dienst" data-vv-name="ein.dienst" prepend-icon="mdi-account-outline" return-object
                              >
                              </v-autocomplete>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelvon"
                                  v-bind:field="ein.von" 
                                  v-bind:modal="ein.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3="einindex"
                                  v-bind:ro=false
                                  v-bind:typ="'fruehvon'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelbis"
                                  v-bind:field="ein.bis" 
                                  v-bind:modal="ein.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3="einindex"
                                  v-bind:ro=false
                                  v-bind:typ="'fruehbis'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-btn icon color="green" @click.native="delEinsatzForm(einindex, fr, 'DPmit')" :disabled="formreadonly">
                              <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
<!--********** spät Dienst *******************************************************************************************-->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-card>
                    <v-card-title>
                      <v-toolbar flat>
                        <v-toolbar-title>Spätdienst</v-toolbar-title>
                      </v-toolbar>
                    </v-card-title>
                  </v-card>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card width='100%'>
                    <v-card-actions>
                      <v-btn text color="green" @click.native="addreservedienst(rdindex, 'DPspä')" :disabled="formreadonly">
                        <v-icon color="green">mdi-note-plus-outline</v-icon>
                        neuer Spätdienst
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                    <v-row v-for="(fr, frindex) in rd.spaet" :key="frindex">
                      <v-col md="12">
                        <v-row>
                          <v-col sm="2">
                            <v-card tile flat>
                              <v-text-field 
                                  v-model="fr.dienst" 
                                  label="Dienst" 
                                  prepend-icon="mdi-account-outline"
                                  :readonly="fr.ro >= 1"
                              >
                              </v-text-field>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelvon"
                                  v-bind:field="fr.von" 
                                  v-bind:modal="fr.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3=0
                                  v-bind:ro="fr.ro"
                                  v-bind:typ="'fruehdienstvon'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelbis"
                                  v-bind:field="fr.bis" 
                                  v-bind:modal="fr.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3=0
                                  v-bind:ro="fr.ro"
                                  v-bind:typ="'fruehdienstbis'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2=">
                            <v-card tile flat>
                              <v-text-field 
                                  v-model="fr.ort" 
                                  label="Ort" 
                                  prepend-icon="mdi-account-outline"
                                  :readonly="fr.ro >= 1"
                              >
                              </v-text-field>
                            </v-card>
                          </v-col>
                          <v-col sm="3">
                            <v-card tile flat>
                              <v-autocomplete
                              :items="$store.getters.FahrerList"
                                  item-text='name'
                                  item-value='Personnel_nr'
                                  v-model="fr.persid" 
                                  :readonly="fr.ro >= 1"
                                  label="Name" data-vv-name="fr.persid" prepend-icon="mdi-account-outline"
                                  @change="(event) => updateName(event, mtindex, 'FR')"
                              >
                              </v-autocomplete>
                            </v-card>
                          </v-col>
                          <v-col sm="1">
                            <v-card tile flat>
                              <v-toolbar-title class="grey--text text--darken-4">Einsatz</v-toolbar-title>
                              <v-spacer></v-spacer>
                              <v-btn icon light @click.native="addNewEinsatzForm(frindex, fr, 'DPspä')" :disabled="formreadonly">
                                <v-icon color="grey darken-2">mdi-plus</v-icon>
                              </v-btn>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row v-for="(ein, einindex) in fr.einsatz['DPspä']" :key="einindex">
                          <v-col sm="4">
                            <v-card tile flat>
                            </v-card>
                          </v-col>
                          <v-col sm="3">
                            <v-card tile flat>
                              <v-autocomplete
                                :items="$store.state.dayroster.roster"
                                item-text='Service_nr'
                                item-value='Service_nr'
                                v-model="ein.dienst" 
                                label="Dienst" data-vv-name="ein.dienst" prepend-icon="mdi-account-outline" return-object
                              >
                              </v-autocomplete>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelvon"
                                  v-bind:field="ein.von" 
                                  v-bind:modal="ein.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3="einindex"
                                  v-bind:ro=false
                                  v-bind:typ="'fruehvon'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelbis"
                                  v-bind:field="ein.bis" 
                                  v-bind:modal="ein.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3="einindex"
                                  v-bind:ro=false
                                  v-bind:typ="'fruehbis'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-btn icon color="green" @click.native="delEinsatzForm(einindex, fr, 'DPspä')" :disabled="formreadonly">
                              <v-icon>mdi-delete-outline</v-icon>
                          </v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
<!--********** nicht besetzt Dienst *******************************************************************************************-->
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <v-card>
                    <v-card-title>
                      <v-toolbar flat>
                        <v-toolbar-title>nicht besetzte Dienste</v-toolbar-title>
                      </v-toolbar>
                    </v-card-title>
                  </v-card>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-card width='100%'>
                    <v-row v-for="(fr, frindex) in rd.nb" :key="frindex">
                      <v-col md="12">
                        <v-row>
                          <v-col sm="2">
                            <v-card tile flat>
                              <v-text-field 
                                  v-model="fr.dienst" 
                                  label="Dienst" 
                                  prepend-icon="mdi-account-outline"
                                  :readonly="fr.ro >= 1"
                              >
                              </v-text-field>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelvon"
                                  v-bind:field="fr.von" 
                                  v-bind:modal="fr.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3=0
                                  v-bind:ro="fr.ro"
                                  v-bind:typ="'fruehdienstvon'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2">
                            <v-card tile flat>
                              <timepicker 
                                  v-bind:label="labelbis"
                                  v-bind:field="fr.bis" 
                                  v-bind:modal="fr.modal"
                                  v-bind:i1="rdindex"
                                  v-bind:i2="frindex"
                                  v-bind:i3=0
                                  v-bind:ro="fr.ro"
                                  v-bind:typ="'fruehdienstbis'"
                              ></timepicker>
                            </v-card>
                          </v-col>
                          <v-col sm="2=">
                            <v-card tile flat>
                              <v-text-field 
                                  v-model="fr.ort" 
                                  label="Ort" 
                                  prepend-icon="mdi-account-outline"
                                  :readonly="fr.ro >= 1"
                              >
                              </v-text-field>
                            </v-card>
                          </v-col>
                          <v-col sm="3">
                            <v-card tile flat>
                              <v-autocomplete
                              :items="$store.getters.FahrerList"
                                  item-text='name'
                                  item-value='Personnel_nr'
                                  v-model="fr.persid" 
                                  :readonly="fr.ro >= 1"
                                  label="Name" data-vv-name="fr.persid" prepend-icon="mdi-account-outline"
                              >
                              </v-autocomplete>
                            </v-card>
                          </v-col>
                          <v-col sm="1">
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-layout>
        </v-card-text>
      </v-card>
    </form>
  </v-container>
</template>

<script>

import { DateTime } from "luxon";
//import { debounce } from 'debounce';
import timepicker from "@/components/app/ZBLTimePicker";
import insertreservedienst from "@/components/dialog/insertreservedienst";
import insertrdeinsatz from "@/components/dialog/insertreservediensteinsatz";

export default {
  name: "Form ZBL-Reservedienst",
  props: ['readonly'],

  data() {
    return {
      panel: [0, 1, 2],
      isLoading: false,
      isTruncated: this.$vuetify.breakpoint.mobile,
      date: null,
      tabbox: null,
      formreadonly: false,
      reserve_wg: [],
      dialog: {
        addreservedienst: 0,
        delreservedienst: 0,
        addrdeinsatz: 0,
      },
      type: '',
      einsatzdate: null,
      employee: null,
      einsatzdocid: '',
      einsatztyp: '',
    };
  },
  created: function () {},
  computed: {},
  watch: {
    "$store.state.auth.isAuthenticating": {
      handler: function (isAuthenticating) {
        if(this.readonly === 'true') {
          this.formreadonly = true;
        } else {
          this.formreadonly = false;
        }
        if (isAuthenticating) {
          if(this.date == null) {
            this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd")
              //this.date = DateTime.now().setLocale('de').plus({ days: -1 }).toFormat("yyyy-MM-dd");
          }
          this.$store.dispatch("attachEmployeesOnSnapshot")
          this.$store.dispatch("attachDayrosterAll", { date: this.date} )
          //this.$store.dispatch("attachRESBusExit", { date: this.date} )
          this.$store.dispatch("attachReserveService", { date: this.date} )
          this.$store.dispatch("attachBusExitOnSnapshotoffline", { date: this.date }).then(() => {
            this.reserve_wg = this.$store.getters.getReserveWG
          })
        }
      },
      immediate: true,
    },
  },
  methods: {
    addreservedienst(index, type) {
      this.dialog.addreservedienst++
      this.type = type
    },
    delreservedienst() {
      this.dialog.delreservedienst++
    },
    addNewEinsatzForm(index, obj, typ) {
      this.employee = this.$store.getters.getEmployee(obj.persid)
      //console.log("EMPLOYEE: ", obj.persid, this.employee)
      this.einsatzdate = obj.date
      this.einsatzdocid = obj.id
      this.einsatztyp = typ
      this.dialog.addrdeinsatz++
    },
    delEinsatzForm(index, obj, typ) {
      this.$store.dispatch('deleteEinsatz', {date: obj.date, docid: obj.id, index: index, typ: typ});
      this.$store.commit('showSnackbar', { message: 'Eintrag gelöscht!', status: 'success' }, { root: true });
    },
  },
  components: {
    timepicker,
    insertreservedienst,
    insertrdeinsatz,
  },
}

</script>

<style>
  .v-tabs-items {
    width:100%;
  }
  /* Hide Calendar/Clock Icon In Chrome */
  input[type="time"]::-webkit-calendar-picker-indicator { background: none; display:none;}
</style>