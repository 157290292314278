<template>
  <v-container
    fill-height
    fluid
    style="height: 100%"
    v-if="$store.state.busexit.isLoading"
  >
    <v-row class="text-center">
      <v-col cols="12">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else-if="!$store.state.busexit.isLoading">
    <template v-if="isPersonalTasksAvailable">
      <v-card>
        <v-card-title> 
          <DigitalClock :datefield="false" :timefield="true" clockstyle="top: 10%; left: 85%;"/>
          Hallo {{ this.fahrer.name }} 
        </v-card-title>
        <div v-for="(task, index) in $store.getters.getPersonalManuelleTasks(
            this.fahrer.Personnel_nr, 'alle')" :key="index"
        >
          <PersonalTask 
            :task="task"
            :meldetype="'manuell'"
            @taskaction="inputAutocomplete"
          ></PersonalTask>
        </div>
      </v-card>
    </template>
    <template v-else>
      <v-card class="mt-5">
        <v-card-title> 
          <DigitalClock :datefield="false" :timefield="true"/>
          Hallo 
        </v-card-title>
        <v-card-text class="pl-4"> Bitte einen Fahrer auswählen. </v-card-text>
      </v-card>
    </template>
    <searchAutocomplete 
      ref="searchinput"
      :items="$store.getters.getBusexitFahrerList"
      itemfield="pnd"
      @result="searchAutocompleteResult"
      @input="inputAutocomplete"
      placeholder="Bitte Namen, Dienstnummer oder Kennung eingeben!"
    ></searchAutocomplete>
    <TimerRoute
      ref="TimerRoute"
      :intervall="240"
      :routeextern="true"
      :route="moebis"
    ></TimerRoute>
    <Version />
  </v-container>
</template>

<script>
import { DateTime } from "luxon";
import PersonalTask from "@/components/app/PersonalTask";
import TimerRoute from "@/components/app/timerroute";
import DigitalClock from '@/components/app/DigitalClock.vue'
import Version from "@/components/version";

import searchAutocomplete from "@/components/app/searchAutocomplete"

export default {
  components: {
    PersonalTask,
    Version,
    searchAutocomplete,
    TimerRoute,
    DigitalClock,
  },
  data() {
    return {
      isLoading: true,
      date: null,
      time: null,
      gettime: null,
      fahrer: {
        personnel_nr: "auswahl",
      },
      wuensche: ["Fahr vorsichtig!", "Gute Fahrt!"],
      timeInterval: null,
      moebis: process.env.VUE_APP_MOEBIS_INTERN_URL,
    };
  },
  
  watch: {
    "$store.getters.getPersonalManuelleTasks": {
      handler: function (pt) {
        this.isLoading = false;
      },
      deep: true,
    },
  },

  computed: {
    firstName() {
      return this.$store.state.auth.user.displayName.split(",")[1];
    },

    isPersonalTasksAvailable() {
      if(this.fahrer !== null) {
        return this.$store.getters.getPersonalManuelleTasks(
          this.fahrer.Personnel_nr, 'alle'
        ).length;
      } else {
        return false;
      }
    },

    generateWuensche() {
      return this.wuensche[this.getRandomInt(this.wuensche.length)];
    },

    settings() {
      return this.$store.state.settings.settings;
    },

    istFahrerGemeldet() {
      return this.$store.getters
        .getPersonalManuelleTasks(this.fahrer.Personnel_nr, 'alle')
        .every((task) => task.anwesend === false);
    },
  },

  methods: {    
    searchAutocompleteResult(e) {
      //console.log("Result", e)
      this.$refs.TimerRoute.Reset()
      this.fahrer = e
    },

    inputAutocomplete(e) {
      console.log("Input", e)
      this.fahrer = null
      this.$refs.TimerRoute.Reset()
    },

    searchBus() {
      this.fahrer= { personnel_nr: "auswahl" }
    },

    getRandomInt(max) {
      return Math.floor(Math.random() * max)
    },

    fahrerMelden() {
      clearTimeout(this.timeInterval);
      this.time = DateTime.now().setLocale("de").toFormat("HH:mm");
      this.gettime = DateTime.now().minus({ minutes: 60 }).setLocale("de").toFormat("HH:mm");
      console.log("Time: ", this.gettime);
      this.$store.getters
        .getPersonalManuelleTasks(this.fahrer.Personnel_nr, this.gettime)
        .forEach((task) => {
          if (!task.anwesend) {
            this.$store.dispatch("updateBusExit", {
              date: this.date,
              docid: task.docid,
              data: { anwesendzeit: this.time, anwesend: true, anwesendmeldung: "manuell" },
            });
          }
        });
      this.isLoading = true;
    },
  },

  created() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")
    this.time = DateTime.now().setLocale("de").toFormat("HH:mm")
    this.moebis = process.env.VUE_APP_MOEBIS_INTERN_URL
    //this.$refs.searchinput.focus();
  },
  
  mounted() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")
    this.time = DateTime.now().setLocale("de").toFormat("HH:mm")
    this.$store.dispatch("attachBusExit", { date: this.date })
    //this.$store.dispatch("attachStrangOnSnapshotoffline");
    this.$store.dispatch("attachDayrosterAll", { date: this.date })
    this.$forceUpdate()
    this.$refs.searchinput.focus;
  },

  beforeDestroy() {
    navigator.geolocation.clearWatch(this.currentCoordinatesId);
    clearTimeout(this.timeInterval);
    this.$store.dispatch("detachhbusexittypes");
  },
};
</script>

<style>

</style>
