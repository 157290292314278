//import { firestore } from '@/firebase/firebase';
import Vue from 'vue';
import { db } from '@/firebase/firebase';
import { doc, collection, query, onSnapshot, getDoc, setDoc, updateDoc, deleteDoc } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    data: [],
    image: [],
    firestoreListener: null,
    firestoreImgListener: null,
    isLoading: false,
    isImgLoading: true,
    isOnline: false,
    lastChange: null,
}

const mutations = {

}

const getters = {
    clearobject: () => (_obj) => {
        const _mo = Object.keys(_obj); 
        _mo.forEach(m => {
            Vue.delete(_obj, m)
        });
    },
}

const actions = {
    async updateBusStationCheck({ state }, { docid, data}) {
        console.log(data);
        const docRef = doc(db, "busstationcheck", docid);
        await updateDoc(docRef, 
            data
        )
    },
    async insertImage({ state }, { docid, imgid, data, typ}) {
        if(typ === "insert") {
            await setDoc(doc(db, "busstationcheck", docid, "image", imgid), {
                data
            });
        }
        if(typ === "delete") {
            await deleteDoc(doc(db, "busstationcheck", docid, "image", imgid));    
        }
    },
    deleteImage({ state }, { docid, imgid}) {
        deleteDoc(doc(db, "busstationcheck", docid, "image", imgid));
    },
    createBusStationCheck({ state }, { docid, persid }) {
        return new Promise((resolve, reject) => {
            const docSnap = getDoc(doc(db, "busstationcheck", docid));
            if (docSnap.exists) {
                console.log(docSnap.data())
            } else {
                setDoc(doc(db, "busstationcheck", docid), {
                    datum: DateTime.now().setLocale('de').toFormat("dd.MM.yyyy HH:mm:ss"),
                    haltestelle: {},
                    linie: {},
                    richtung: {},
                    persid: persid,
                    fahrplan: "",
                    tarifinfo: "",
                    haltestellenschild: "",
                    wartehalle: "",
                    bemerkung: "",
                    muelleimer: "",
                    anzeige: "",
                    img: [],
                    ange: false,
                    synced: ""
                }, { merge: true });
            }
            resolve();
        })
    },
    attachBusStationCheckOnSnapshotoffline({ state }, { docid }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.data);
                return;
            }
            state.isLoading = true;

            state.firestoreListener =
               onSnapshot(doc(db, "busstationcheck", docid), { includeMetadataChanges: true }, (doc) => {
                    if (doc.exists) {
                        state.data = doc.data();
                    }
                    //const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                    //console.log(source, " data: ", doc.data());
                    if(doc.metadata.fromCache) {
                        state.isOnline = false;
                    } else {
                        state.isOnline = true;
                        state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm")
                    }
                    state.isLoading = false;
                    resolve(state.data);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
    attachBusStationCheckImageOnSnapshotoffline({ state, getters }, { docid }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreImgListener) {
                resolve(state.data);
                return;
            }
            state.isImgLoading = true;
            state.firestoreImgListener =

                onSnapshot(query(collection(db, "busstationcheck", docid, "image")), (snapshot) => {
                    state.image.splice(0, state.image.length);
//                    console.log("Image-Reset", state.image);
                    snapshot.forEach((doc) => {
                        state.image.push(doc.data().data)
                    })
                    state.isImgLoading = false;
//                    console.log("Image", state.image);
                    resolve(state.image);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachBusStationCheck({ state }, { docid }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
        if (state.firestoreImgListener) {
            state.firestoreImgListener();
            state.firestoreImgListener = null;
        }
    },

    detachBusStationCheckDelete({ state }, { docid }) {
        for (let i = 0; i < state.image.length; i++) {
            deleteDoc(doc(db, "busstationcheck", docid, "image", i.toString()));   
        }
        deleteDoc(doc(db, "busstationcheck", docid));
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
        if (state.firestoreImgListener) {
            state.firestoreImgListener();
            state.firestoreImgListener = null;
        }
    }
}

export default { state, getters, mutations, actions }