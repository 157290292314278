<template>
  <v-container>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="text-h5">Busposition festlegen</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="4" sm="4" md="4">
                <v-dialog v-model="dialogsl" overlay-opacity="0.8" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      width="7em"
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Solo
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}<br> Solobus</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="8">
                          <v-autocomplete
                            :items="$store.getters.getStrangSonderposition('Solobus', false)"
                            item-text="strangText"
                            item-value="strangNummer"
                            v-model="strangsonderposition"
                            label="Strang"
                            prepend-icon="mdi-account-outline"
                            return-object
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="grey" text @click="closePosition('SL')">
                        Abbrechen
                      </v-btn>
                      <v-btn color="primary" text @click="saveStrangSonderPosition">
                        Speichern
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-dialog v-model="dialoggb" overlay-opacity="0.8" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      width="7em"
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Gelenk
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}<br> Gelenkbus</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="8">
                          <v-autocomplete
                            :items="$store.getters.getStrangSonderposition('Gelenkbus', false)"
                            item-text="strangText"
                            item-value="strangNummer"
                            v-model="strangsonderposition"
                            label="Strang"
                            prepend-icon="mdi-account-outline"
                            return-object
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="grey" text @click="closePosition('GB')">
                        Abbrechen
                      </v-btn>
                      <v-btn color="primary" text @click="saveStrangSonderPosition">
                        Speichern
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
              <v-col cols="4" sm="4" md="4">
                <v-dialog v-model="dialogne" overlay-opacity="0.8" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      width="7em"
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      NE
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}<br> Nachtexpress</span>
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                        <v-col cols="12" sm="6" md="8">
                          <v-autocomplete
                            :items="$store.getters.getStrangSonderposition('Solobus', true)"
                            item-text="strangText"
                            item-value="strangNummer"
                            v-model="strangsonderposition"
                            label="Strang"
                            prepend-icon="mdi-account-outline"
                            return-object
                          >
                          </v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="grey" text @click="closePosition('NE')">
                        Abbrechen
                      </v-btn>
                      <v-btn color="primary" text @click="saveStrangSonderPosition">
                        Speichern
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="text-h5">Angeforderte Fahrzeuge</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="$store.getters.sortStraenge('frueh')"
                  :loading="$store.state.strang.isLoading"
                  :item-text="item => `${item.strangNummer} - ${item.name}`"
                  :item-value="item => `${item.strangNummer} - ${item.name}`"
                  v-model="selectedAngefordert"
                  label="Strangnummer"
                  return-object
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-dialog 
              transition="dialog-top-transition"
              overlay-opacity="0.8"
              v-model="dialogangefordert" max-width="500px" 
              @close="closeangefordert"
            >
              <v-card>
                <v-card-title>
                  <span class="text-h5">
                    Angeforderte Fahrzeuge<br>
                    Strang {{ selectedAngefordert.strangNummer }} - {{ selectedAngefordert.strangText }}<br>
                    Anzahl {{ selectedAngefordert.anzahl }}
                  </span>
                </v-card-title>
                <v-card-text>
                  Position
                  <v-row v-if="selectedAngefordert.strangNummer < 50">
                    <v-col v-for="(pos, indexpos) in selectedAngefordert.position" :key="indexpos">
                      <v-btn v-if="selectedAngefordert.position[indexpos].anzahl >= 0"
                        :class="classAngefordertBtn(indexpos)"
                        @click="setAngefordert(indexpos)">P{{ indexpos }}
                      </v-btn>
                      <v-btn v-else
                        :class="classAngefordertBtn(indexpos)"
                        @click="setAngefordert(indexpos)">P{{ indexpos }}-{{ selectedAngefordert.position[indexpos].anzahl }}
                      </v-btn>
                    </v-col>
                  </v-row>
                  <p v-else>Auf dem Strang {{ selectedAngefordert.strangNummer }} kann kein angefordertes Fahrzeug markiert werden!</p>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="closeangefordert()">
                    Beenden
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <span class="text-h5">Strang sperren/entsperren</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="$store.getters.sortStraenge('ALL')"
                  :loading="$store.state.strang.isLoading"
                  :item-text="item => `${item.strangNummer} - ${item.name}`"
                  :item-value="item => `${item.strangNummer} - ${item.name}`"
                  v-model="selectedStrang"
                  label="Strangnummer"
                  return-object
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-dialog v-model="dialogstrang" max-width="500px" overlay-opacity="0.8" @close="closestrang">
              <v-card>
                <v-card-title>
                  <span class="text-h5">
                    Strang {{ selectedAngefordert.strangNummer }} - {{ selectedAngefordert.strangText }}<br> 
                    sperren/entsperren
                  </span>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="6" sm="6" md="6">
                      <v-text-field
                        v-model="selectedStrang.maxBus"
                        label="Max Anzahl"
                        type="number"
                        min="0"
                        :max="selectedStrang.maxBus"
                        disabled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                      <v-text-field
                        v-model="selectedStrang.anzahl"
                        label="Anzahl"
                        type="number"
                        min="0"
                        :max="selectedStrang.maxBus"
                        disabled
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-if="Object.keys(selectedStrang).length > 0">
                    <v-col cols="6" sm="6" md="6">
                      <p>Kurzdienst</p>
                      <v-switch
                        :input-value="selectedStrang.kurzdienst"
                        @change="selectedStrang.kurzdienst = !selectedStrang.kurzdienst"
                        disabled
                      ></v-switch>
                    </v-col>
                    <v-col cols="6" sm="6" md="6">
                      <p>ist gesperrt</p>
                      <v-switch
                        :input-value="selectedStrang.istGesperrt"
                        @change="setStrangSperren(0)"
                        :disabled="selectedStrang.isBlocked"
                      >
                      </v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col v-for="(pos, indexpos) in selectedStrang.position" :key="indexpos">
                      <v-btn v-if="selectedStrang.position[indexpos].anzahl >= 0"
                        :class="classStrangSperrenBtn(indexpos)"
                        @click="setStrangSperren(indexpos)">P{{ indexpos }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="grey" text @click="closestrang()">
                    Beenden
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <v-card>
          <v-card-text v-for="_bec in $store.getters.getAktBusExitCount" :key="_bec.id">
            <v-card-title>
              {{ _bec.text }} bis {{ _bec.to }} Uhr
            </v-card-title>
            <v-row  v-if="_bec.SL > 0">
              <v-col>
                <v-progress-linear v-if="_bec.SL > 0"
                  :value="getValue(_bec, 'SL')"
                  color="#660031"
                  background-color="black"
                  height="15"
                  striped
                >
                  <strong class="strongcolor">
                    Solo 
                    {{ _bec.gSL}} von
                    {{ _bec.SL }} noch
                    {{ getAnz(_bec, 'SL') }} Bus(se)
                    {{ getValue(_bec, 'SL') }}%
                    verfügbar {{ getBusAnz(_bec, 'SL')  }}
                  </strong>
                </v-progress-linear>
              </v-col>
            </v-row>
            <v-row  v-if="_bec.GB > 0">
              <v-col>
                <v-progress-linear v-if="_bec.GB > 0"
                  :value="getValue(_bec, 'GB')"
                  color="#9b004b"
                  background-color="black"
                  height="15"
                  striped
                >
                  <strong class="strongcolor">
                    Gelenk 
                    {{ _bec.gGB}} von
                    {{ _bec.GB }} noch
                    {{ getAnz(_bec, 'GB') }} Bus(se)
                    {{ getValue(_bec, 'GB') }}%
                    verfügbar {{ getBusAnz(_bec, 'GB')  }}
                  </strong>
                </v-progress-linear>
              </v-col>
            </v-row>
            <v-row  v-if="_bec.ESLBYD > 0">
              <v-col>
                <v-progress-linear v-if="_bec.ESLBYD > 0"
                  :value="getValue(_bec, 'ESLBYD')"
                  color="#b80058"
                  background-color="black"
                  height="15"
                  striped
                >
                  <strong class="strongcolor">
                    E-Solo BYD
                    {{ _bec.gESLBYD}} von
                    {{ _bec.ESLBYD }} noch
                    {{ getAnz(_bec, 'ESLBYD') }} Bus(se)
                    {{ getValue(_bec, 'ESLBYD') }}%
                    verfügbar {{ getBusAnz(_bec, 'ESLBYD')  }}
                  </strong>
                </v-progress-linear>
              </v-col>
            </v-row>
            <v-row  v-if="_bec.ESLMB > 0">
              <v-col>
                <v-progress-linear v-if="_bec.ESLMB > 0"
                  :value="getValue(_bec, 'ESLMB')"
                  color="#b80058"
                  background-color="black"
                  height="15"
                  striped
                >
                  <strong class="strongcolor">
                    E-Solo MB
                    {{ _bec.gESLMB}} von
                    {{ _bec.ESLMB }} noch
                    {{ getAnz(_bec, 'ESLMB') }} Bus(se)
                    {{ getValue(_bec, 'ESLMB') }}%
                    verfügbar {{ getBusAnz(_bec, 'ESLMB')  }}
                  </strong>
                </v-progress-linear>
              </v-col>
            </v-row>
            <v-row  v-if="_bec.EGB > 0">
              <v-col>
                <v-progress-linear v-if="_bec.EGB > 0"
                  :value="getValue(_bec, 'EGB')"
                  color="green darken-2"
                  background-color="black"
                  height="15"
                  striped
                >
                  <strong class="strongcolor">
                    E-Gelenk 
                    {{ _bec.gEGB}} von
                    {{ _bec.EGB }} noch
                    {{ getAnz(_bec, 'EGB') }} Bus(se)
                    {{ getValue(_bec, 'EGB') }}%
                    verfügbar {{ getBusAnz(_bec, 'EGB')  }}
                  </strong>
                </v-progress-linear>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <Version />
  </v-container>
</template>

<script>
//import { GeoPoint } from "firebase/firestore";
import Version from "@/components/version";
import { DateTime } from "luxon";

export default {
  components: {
    Version,
  },

  data() {
    return {
      dialogsl: false,
      dialoggb: false,
      dialogne:false,
      dialogstrang: false,
      dialogangefordert: false,
      geoLocationId: null,
      currentLocation: { latitude: 0, longitude: 0, accuracy: 0 },
      selectedLocation: "",
      selectedVehicle: { docid: "", wagennummer: "", typ: "", active: "no" },
      customBusLocation: "",
      strangsonderposition: {},
      selectedStrang: {},
      selectedAngefordert: {},
    };
  },

  watch: {
    selectedVehicle: {
      deep: true,
      immediate: true,
      handler: function (vehicle) {
        this.triggerBusLocationAutocomplete();
      },
    },

    selectedStrang: {
      deep: true,
      handler(newValue, oldValue) {
//        console.log("Strang",newValue)
        if (Object.keys(this.selectedStrang).length > 0) {
          this.dialogstrang = true
        }
      }
    },
    
    selectedAngefordert: {
      deep: true,
      handler(newValue, oldValue) {
//        console.log("Angefordert",newValue)
        if (Object.keys(this.selectedAngefordert).length > 0) {
          this.dialogangefordert = true
        }
      }
    },

    dialogsl(val) {
      val || this.closePosition();
    },

    dialoggb(val) {
      val || this.closePosition();
    },

    dialogne(val) {
      val || this.closePosition();
    },

    dialogstrang(val) {
      val || this.closestrang();
    },

    dialogangefordert(val) {
      val || this.closeangefordert();
    },
  },

  computed: {
    formTitle() {
      //return this.editedIndex === -1 ? "Neu" : "Strang bearbeiten";
      return "Busposition festlegen";
    },

    vehicleCoordinates() {
      return [this.currentLocation.latitude, this.currentLocation.longitude];
    },

    statusLabel() {
      return this.selectedVehicle.active == "yes"
        ? "Deaktivieren"
        : "Aktivieren";
    },
  },

  methods: {
    classAngefordertBtn: function (index) {
      return this.selectedAngefordert.position[index].kurzdienst == (0 || 1) ? 'btntrue' : 'btnfalse';
    },
    classStrangSperrenBtn: function (index) {
      return this.selectedStrang.position[index].anzahl === 0 ? 'btnfalse' : 'btntrue';
    },
    closePosition(_dialog) {
      switch(_dialog) {
        case 'SL':
          this.dialogsl = false
          break
        case 'GB':
          this.dialoggb = false
          break
        case 'NE':
          this.dialogne = false
          break
      }
      this.$nextTick(() => {
        this.strangsonderposition = {}
      })
    },

    closestrang() {
      this.dialogstrang = false
      this.$nextTick(() => {
        this.selectedStrang = {}
      })
    },

    closeangefordert() {
      this.dialogangefordert = false
      this.$nextTick(() => {
        this.selectedAngefordert = {}
      })
    },

    saveStrangSonderPosition() {
//      console.log("Strangsonder: ", this.strangsonderposition)
      for (let _pos = 1; _pos <= Object.keys(this.strangsonderposition.position).length; _pos++) {
//        console.log("Strangsonder: ", this.strangsonderposition.position[_pos])
        if (this.strangsonderposition.position[_pos].anzahl === 0) {
          this.strangsonderposition.anzahl += 1;
          this.strangsonderposition.position[_pos].kurzdienst = false
          this.strangsonderposition.position[_pos].anzahl = 1
//          console.log("Pos: ", _pos)
          this.$store.dispatch("updateStrang", {
            docid: this.strangsonderposition.strangNummer.toString()+"_"+this.strangsonderposition.type,
            data: this.strangsonderposition,
            strangNummer: this.strangsonderposition.strangNummer.toString(),
            name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
          });
          break;
        }
      }
      
      switch(this.strangsonderposition.Kategorie) {
        case 'Solobus':
          this.dialogsl = false;
          this.dialogne = false;
          break;
        case 'Gelenkbus':
          this.dialoggb = false;
          break;
      }
      this.$nextTick(() => {
        this.strangsonderposition = {};
      });
    },

    success(position) {
      const { accuracy, latitude, longitude } = position.coords;
      this.currentLocation.latitude = latitude;
      this.currentLocation.longitude = longitude;
      this.currentLocation.accuracy = accuracy;
    },

    error(error) {
      this.$store.commit("showSnackbar", {
        status: "error",
        message: error.message,
      });
    },

    degrees_to_radians(degrees) {
      return degrees * (Math.PI / 180);
    },

    calculateDistanceInMeter(lat1, lon1, lat2, lon2) {
      const R = 6371000;
      const x1 = lat2 - lat1;
      const dLat = this.degrees_to_radians(x1);
      const x2 = lon2 - lon1;
      const dLon = this.degrees_to_radians(x2);
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.degrees_to_radians(lat1)) *
          Math.cos(this.degrees_to_radians(lat2)) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      return R * c;
    },

    triggerBusLocationAutocomplete() {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const nearestBusLocation =
            this.$store.state.buslocation.busLocationOptions
              .filter((busLocation) => busLocation.value != "Freitext")
              .map((busLocation) => {
                return {
                  ...busLocation,
                  distance: this.calculateDistanceInMeter(
                    position.coords.latitude,
                    position.coords.longitude,
                    busLocation.koordinaten.lat,
                    busLocation.koordinaten.lng
                  ),
                };
              })
              .sort((a, b) => {
                const distanceA = a.distance;
                const distanceB = b.distance;
                return distanceA - distanceB;
              });

          this.selectedLocation = nearestBusLocation[0]?.value;
        },
        this.error,
        {}
      );
    },

    savestrang() {
      if (this.selectedStrang.anzahl <= this.selectedStrang.maxBus) {
        this.$store.dispatch("updateStrang", {
          docid: this.selectedStrang.strangNummer.toString()+"_"+this.selectedStrang.type,
          //data: this.selectedStrang,
          data: {
            ...this.selectedStrang,
            anzahl: +this.selectedStrang.anzahl,
            maxBus: +this.selectedStrang.maxBus,
            isBlocked: false,
//            lastdate: _lastdate,
          },
          strangNummer: this.selectedStrang.strangNummer.toString(),
          name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
        });
      } else {
        this.selectedStrang.anzahl = this.selectedStrang.maxBus
        this.$store.dispatch("updateStrang", {
          docid: this.selectedStrang.strangNummer.toString()+"_"+this.selectedStrang.type,
          data: {
            ...this.selectedStrang,
            anzahl: +this.selectedStrang.maxBus,
            maxBus: +this.selectedStrang.maxBus,
            isBlocked: false,
//            lastdate: _lastdate,
          },
          strangNummer: this.selectedStrang.strangNummer.toString(),
          name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
        });
      }
    },

    setAngefordert(index) {
      this.selectedAngefordert.position[index].kurzdienst = !this.selectedAngefordert.position[index].kurzdienst
      this.selectedAngefordert.position[index].anzahl = 1
      this.$store.dispatch("updateStrang", {
        docid: this.selectedAngefordert.strangNummer.toString()+"_"+this.selectedAngefordert.type,
        data: {
          ...this.selectedAngefordert,
        },
        strangNummer: this.selectedAngefordert.strangNummer.toString(),
        name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
        position: index.toString(),
      });
    },

    setStrangSperren(index) {
      if (index === 0) {
        this.selectedStrang.istGesperrt = !this.selectedStrang.istGesperrt
      } else {
        if (this.selectedStrang.position[index].anzahl == 0)
        {
          this.selectedStrang.position[index].anzahl = 1
          this.selectedStrang.anzahl += 1
        } else {
          this.selectedStrang.position[index].anzahl = 0
          this.selectedStrang.anzahl -= 1
        }
      }
      this.$store.dispatch("updateStrang", {
        docid: this.selectedStrang.strangNummer.toString()+"_"+this.selectedStrang.type,
        data: {
          ...this.selectedStrang,
        },
        strangNummer: this.selectedStrang.strangNummer.toString(),
        name: `${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid})`,
        position: index.toString(),
      });
    },

    toggleSwitch(val) {
      this.selectedVehicle.active = val ? "yes" : "no";
      this.$store.dispatch("updateVehicle", {
        docid: this.selectedVehicle.docid,
        data: { active: val ? "yes" : "no" },
      });
    },

    getValue(data, bus) {
      if(this.$store.state.busexit.CountisOnline == true) {
//        console.log(bus, data)
        const soll = data[bus];
        const ist = data['g'+bus];
        if (soll > 0) {
          return Math.round(ist / soll * 100);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getAnz(data, bus) {
      if(this.$store.state.busexit.CountisOnline == true) {
        const soll = data[bus]
        const ist = data['g'+bus]
        if (soll > 0) {
          return Math.round(soll - ist)
        } else {
          return 0
        }
      } else {
        return 0
      }
    },
    getBusAnz(data, bus) {
      this.anzahlbus = this.$store.getters.getBusAnzahl(this.$store.state.busexit.busexitdata)
      return this.anzahlbus[data.text][bus]
    }
  },

  created() {
    try {
      this.$store.dispatch("attachbusexittypes")
      this.$store.dispatch("attachVehiclesOnSnapshot");
      this.$store.dispatch("attachBusLocationOnSnapshotoffline");
      //this.$store.dispatch("attachStrangOnSnapshotoffline");
      this.$store
        .dispatch("attachBusLocationOptionsOnSnapshotoffline")
        .then(() => {
          this.triggerBusLocationAutocomplete();
        });
      this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
      this.$store.dispatch("attachBusExitGroupCounts", { date: this.date });
      this.locationID = navigator.geolocation.watchPosition(
         this.success,
         this.error,
         {}
       );
    } catch (err) {
      this.$store.commit("showSnackbar", {
        status: "error",
        message: `Fehler beim Abrufen der Koordinaten -> ${err.message}`,
      });
      return;
    }
  },

  mounted() {
    this.$store.dispatch("attachBusExit", { date: this.date })
  },

  beforeDestroy() {
    this.$store.dispatch("detachBusexitGroupCount");
  },

  destroyed() {
    navigator.geolocation.clearWatch(this.geoLocationId);
  },
};
</script>

<style scoped>
.btntrue {
  background-color: #9b004b !important;
}
.strongcolor {
  color: rgb(255,255,255)
}
</style>
