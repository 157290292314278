<template>
  <v-dialog v-model="dialogVisible" persistent max-width="750px">
    <v-card :loading="isLoading" :disabled="isLoading">
      <v-card-title>Sicherheitsrelevanten Vorfall Type</v-card-title>
      <v-card-text>
        <v-text-field
          v-model="securityincidenttype"
          label="Sicherheitsrelevante Vorfälle Arten"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primaryLight"
          text
          @click="canclesecurityincidenttype"
          value="CANCELSECURITYINCEDENTTYPE"
        >
          Abbrechen
        </v-btn>
        <v-btn color="primaryLight" text @click="addsecurityincidenttype">
          Speichern
        </v-btn>
        <slot></slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
//import firebase from 'firebase/app';

export default {
  data() {
    return {
      isLoading: false,
      dialogVisible: false,
      securityincidenttype: '',
    };
  },

  computed: {},

  watch: {
    active() {
      this.dialogVisible = true;
    },
  },

  methods: {
    addsecurityincidenttype() {
      if (this.securityincidenttype !== '') {
        this.$store.dispatch("insertSecurityincidenttype", { docid: this.securityincidenttype });
        this.securityincidenttype = '';
        this.dialogVisible = false;
      }
    },
    canclesecurityincidenttype() {
      this.securityincidenttype = '';
      this.dialogVisible = false;
    },
  },

  created() {},

  props: {
    active: Number,
  },
};
</script>
