//import { firestore } from '@/firebase/firebase'
import Vue from 'vue';
import router from '@/router';
import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, query } from "firebase/firestore";
import { store } from '@/store';

const state = {
    link: {},
    routepermission: [],
    routemenu: [],
    firestoreListener: null,
    firestorepermissionListener: null,
    isLoading: false
}

const mutations = {
    setUserPhotoURL(state, photoURL) {
        state.photoURL = photoURL;
    },
}

const getters = {
    hasPermission: (state) => (route) => {
        const routes = router.options.routes;
        let _routes = routes.find(r => r.name == route.name)
        if (typeof (_routes) !== 'undefined') {
            if (_routes.right === 'all') {
                return true;
            }
            if (_routes.right === navigator.userAgent) {
                return true;
            }
            let _permission = state.routepermission[_routes.right]
            if (typeof (_permission) !== 'undefined') {
                let _users = _permission['user'];
                let _right = _users.find(u => u == store.state.auth.personnelnr);
                if (typeof (_right) !== 'undefined') {
                    return true;
                }
            }
        }

        if (route.type == 'customUserPermission') {
            let _permission = state.routepermission[route.right]

            if (!_permission) {
                return false;
            }

            let _users = _permission['user']
            let _right = _users.find(u => u == store.state.auth.personnelnr)
            if (typeof (_right) !== 'undefined') {
                return true;
            }
        }

        return false;
    },
    hasCustomPermission: (state) => (right) => {
        let _permission = state.routepermission[right]
        if (!_permission) {
            return false;
        }
        let _users = _permission['user']
        let _right = _users.find(u => u == store.state.auth.personnelnr)
        if (typeof (_right) !== 'undefined') {
            return true;
        }
        return false;
    },
}

const actions = {
    loadMenu({ state, getters }) {
        state.isLoading = true;
        const routes = router.options.routes;
        for (var route of routes) {
            if (typeof (route.readonly) !== 'undefined') {
                route.path = route.path.replace(':readonly', route.readonly)
            }
            if (route.navIgnore !== true && "navParent" in route) {
                let _right = getters.hasPermission(route);
                if (typeof (_right) !== 'undefined') {
                    if (!(route.navParent in state.link)) {
                        if (route.navParent === '' || route.navParent.includes('Parent')) {
                            if (_right == true) {
                                Vue.set(state.link, route.navParent, route);
                            }
                        } else {
                            let slink = {
                                name: route.navParent,
                                sublinks: []
                            };
                            if (_right == true) {
                                Vue.set(slink.sublinks, slink.sublinks.length, route);
                                Vue.set(state.link, route.navParent, slink);
                            }
                        }
                    } else {
                        for (let [, slink] of Object.entries(state.link)) {
                            if (slink.name == route.navParent) {
                                if (_right == true) {
                                    Vue.set(slink.sublinks, slink.sublinks.length, route);
                                    Vue.set(state.link, route.navParent, slink);
                                }
                            }
                        }
                    }
                }
            }
        }
//        console.log("Link: ", state.link);
    },
    attachUserRoutesPermission({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestorepermissionListener) {
                resolve(state.routepermission);
                return;
            }

            state.firestorepermissionListener =
                onSnapshot(query(collection(db, "config", "users", "permission")), (snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            Vue.set(state.routepermission, doc.id, doc.data());
                        }
                    });
                    resolve(state.routepermission);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
}

export default { state, mutations, getters, actions }
