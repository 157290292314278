<template>
  <div>
    <v-card class="mt-5">
      <Ausfahrthilfe
        :active="dialog.ausfahrtHilfe"
        :task="task"
      ></Ausfahrthilfe>
      <confirmdlg
        :title="confirmtitle"
        :meldung="confirmmsg"
        :open="confirmopen"
        :type="confirmtype"
        @confirm:ok="closeConfirmDialog"
        @confirm:abort="closeConfirmDialog"
        @confirm:close="closeConfirmDialog"
      ></confirmdlg>
      <v-badge
        bordered
        color="success"
        icon="mdi-check"
        overlap
        offset-x="25"
        offset-y="25"
        :style="
          task.gemeldet ? 'width: 100%;border: 1px solid green' : 'width: 100%'
        "
        :value="task.gemeldet"
      >
        <template v-if="task.strang.status">
          <v-card-text class="pl-4 red--text">
            AUSFAHRT - {{ task.dienst }} - {{ task.anfang }} Uhr
          </v-card-text>
          <v-card-text class="pl-4">
            hier sind deine Infos zum Dienst:
            {{ task.dienst }}
          </v-card-text>
          <template v-if="task.strang.status == 'SUCCESS'">
            <v-card-text class="pl-4">
              Bitte nimm den {{ convertBusType(task) }}
              <span v-html="locationToGetBus(task)"></span>
            </v-card-text>
            <v-card-text v-if="task.zaehler != ''">
              <h2 class="red--text">ACHTUNG Zählpersonal!</h2>
              <p>{{ task.zaehler }}</p>
            </v-card-text>
            <v-card-text class="pl-4">
              <template v-if="task.strang.location == 'platz'">
                <GmapMap
                  ref="map"
                  :center="centerCoordinateRheinstr"
                  :zoom="18"
                  :options="{
                    mapTypeControl: false,
                    streetViewControl: false,
                    zoomControl: false,
                  }"
                  map-type-id="satellite"
                  style="width: 100%; height: 550px"
                >
                  <GmapMarker
                    :position="{
                      lat: task.strang.koordinaten.lat,
                      lng: task.strang.koordinaten.lng,
                    }"
                    :draggable="false"
                    :icon="{
                      url: 'https://firebasestorage.googleapis.com/v0/b/moebis-dev.appspot.com/o/busLocationIcon.png?alt=media&token=2a0dfcd0-6e48-4b1b-aef7-b8706a2258e4',
                      scaledSize: { height: 50, width: 50 },
                    }"
                  ></GmapMarker>

                  <GmapMarker
                    :position="currentCoordinates"
                    :draggable="false"
                    :icon="{
                      url: 'https://firebasestorage.googleapis.com/v0/b/moebis-dev.appspot.com/o/blueDot.png?alt=media&token=21914c9d-6b9a-4fcb-a52c-a99976c9b50b',
                      scaledSize: { height: 50, width: 50 },
                    }"
                  ></GmapMarker>
                </GmapMap>
              </template>
              <!--<template v-else>
                Wähle bitte den nächsten freien Bus von vorne.
              </template>-->
            </v-card-text>
            <v-card-actions v-if="isShowHelpButton">
              <v-row>
                <v-col cols="12" sm="6" md="3">
                  <!--<v-btn v-if="time <= $store.state.settings.settings.changebusexit" strangLeerAction-->
                  <v-btn @click="openConfirmDialog('posleer')" color="primary" min-width="200px">
                    Position leer!
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <!--<v-btn v-if="time <= $store.state.settings.settings.changebusexit" strangLeerAction-->
                  <v-btn @click="openConfirmDialog('leer')" color="primary" min-width="200px">
                    Strang leer!
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <!--<v-btn v-if="time <= $store.state.settings.settings.changebusexit" StrangBlockiertAction-->
                  <v-btn @click="openConfirmDialog('blockiert')" color="primary" min-width="200px">
                    Strang blockiert!
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-btn @click="openConfirmDialog('defekt')" color="primary" min-width="200px">
                    Fahrzeug defekt!
                  </v-btn>
                </v-col>
              </v-row>
<!--              <v-spacer></v-spacer>
              <v-btn @click="openDialog" fab x-small color="primary">
                <v-icon> mdi-help </v-icon>
              </v-btn>-->
            </v-card-actions>
          </template>
          <template v-else-if="task.strang.status.toUpperCase() == 'OPJÜCK'">
            <v-card-text class="pl-4">
              OpJück.
            </v-card-text>
          </template>
          <template v-else>
            <v-card-text class="pl-4">
              Aktuell sind keine Fahrzeuge in den Strängen und Plätzen
              verfügbar.
            </v-card-text>
            <v-card-text v-if="task.strang.type == 'frueh'" class="pl-4">
              Bitte kontaktiere die ZBL.
            </v-card-text>
            <v-card-text v-else class="pl-4">
              Bitte kontaktiere die ZBL.
            </v-card-text>
            <v-card-actions v-if="task.strang.type == 'frueh'" class="pl-4">
              <v-btn href="tel:+4921666884566"> ZBL anrufen </v-btn>
              <v-btn @click="resetStrang"> Daten Aktualisieren </v-btn>
            </v-card-actions>
            <v-card-actions v-else class="pl-4">
              <v-btn href="tel:+4921666884566"> ZBL anrufen </v-btn>
              <v-btn @click="resetStrang"> Daten Aktualisieren </v-btn>
            </v-card-actions>
          </template>
        </template>
        <template v-else>
          <v-card-text class="pl-4 red--text">
            AUSFAHRT - {{ task.dienst }} - {{ task.anfang }} Uhr
          </v-card-text>
          <v-card-text class="pl-4" v-if="task.anwesend">
            Deine Meldezeit zum Dienst {{ task.dienst }} ist um
            {{ task.meldezeit }}. <br />
            <span>
              Erst in 
            </span>
            <v-progress-circular
              v-if="!task.strang.status"
              :size="180"
              :width="15"
              color="primary"
              indeterminate
            ><p class="pl-2 red--text">{{ countDown }}</p></v-progress-circular>
            <span>
              erhältst du die Info aus welchem Strang
              ein Fahrzeug zu entnehmen ist.
            </span>
          </v-card-text>
          <v-card-text v-if="!task.anwesend && this.tasktime >= task.meldezeit">
            Deine Meldezeit zum Dienst {{ task.dienst }} ist um
            {{ task.meldezeit }}. <br />
            <v-btn v-if="this.isNearby <= this.distance"
              :class="btnclass"
              color="primary"
              @click="fahrerMelden(task)"
            >
              {{ this.btntext }}
            </v-btn>
            <p v-else><br />Du befindest dich nicht in dem Berreich um dich Anzumelden!<br />
              Erst in {{ isNearby-distance }} m</p>
          </v-card-text>
        </template>
      </v-badge>
    </v-card>
    <v-card class="ma-5" v-if="task.einfahrt != ''">
      <v-card-text class="pl-4 green--text">
        EINFAHRT - {{ task.dienst }}
      </v-card-text>
      <v-card-text class="pl-4">
        {{ task.einfahrt }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { DateTime } from "luxon";
import Ausfahrthilfe from "@/components/dialog/ausfahrthilfe";
import confirmdlg from "@/components/dialog/confirmdlg"
import { app } from "@/firebase/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

export default {
  props: {
    task: {
      type: Object,
    },
    meldetype: {
      type: String,
      default: ""
    },
    isNearby: {
      type: Number,
      default: 0
    },
    distance: {
      type: Number,
      default: 250
    }
  },

  components: {
    Ausfahrthilfe,
    confirmdlg,
  },

  data() {
    return {
      time: "",
      tasktime: "",
      countDown: "",
      timeInterval: null,
      secondInterval: null,
      btnInterval: null,
      btntext: "Du bist nicht anwesend",
      btnclass: "btnanwesend",
      dialog: {
        ausfahrtHilfe: 0,
      },
      isShowHelpButton: true,
      confirmopen: false,
      confirmtitle: "",
      confirmmsg: "",
      confirmtype: "",
    };
  },

  methods: {
    fahrerMelden(task) {
      this.loader = null
      this.time = DateTime.now().setLocale("de").toFormat("HH:mm")
      this.gettime = DateTime.now().minus({ minutes: 60 }).setLocale("de").toFormat("HH:mm")
      this.$emit('taskaction', task)
      if (!task.anwesend) {
        if (task.hinweis.toUpperCase() === "OPJÜCK") {
          console.log("OpJück")
          this.$store.dispatch("updateBusExit", {
            date: DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"),
            docid: task.docid,
            data: { 
              anwesendzeit: this.time, 
              anwesend: true,
              strang: {
                status: "OpJück",
                strangNummer: "OpJück",
              },
              gemeldet: true,
              gemeldet_um: this.time,
            },
          })
        } else {
          this.$store.dispatch("updateBusExit", {
            date: DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"),
            docid: task.docid,
            data: { anwesendzeit: this.time, anwesend: true, anwesendmeldung: this.meldetype },
          })
        }
      }
    },

    getCountdown(meldedatum, meldezeit, timing = 10) {
      const currentTime = DateTime.now().setLocale("de");
      const berechnungszeit = DateTime.fromFormat(
        `${meldedatum} ${meldezeit}`,
        "yyyy-MM-dd HH:mm"
      )
        .setLocale("de-DE")
        .minus({ minutes: timing });
      const { hours, minutes, seconds } = berechnungszeit
        .diff(currentTime, ["hours", "minutes", "seconds"])
        .toObject();

      const displayedHours =
        hours > 0
          ? hours == 1
            ? hours + " Stunde "
            : hours + " Stunden "
          : "";

      const ceilMinute = Math.ceil(minutes) + 1;
      const displayedMinutes =
        ceilMinute == 1 ? ceilMinute + " Minute" : ceilMinute + " Minuten";
      const ceilSeconds = Math.ceil(seconds);
      if (ceilMinute > 1) {
        this.countDown = displayedHours + displayedMinutes;
      } else {
//        console.log("sec:", ceilSeconds);
        if (ceilSeconds > 0) {
          // Normal countdown wenn es weniger als eine Minute
          this.getNormalSecondsCountdown(ceilSeconds);
        } else {
          // Countdown bis zu einer vollen Minute (Fahrer hat Timing überschritten)
          this.getRestSeconds();
          /*const functions = getFunctions( app, 'europe-west1' );
          const busZuweisenCall = httpsCallable(functions, 'busZuweisenCall');
          busZuweisenCall({ docid: this.task.docid })
          .then((result) => {
            console.log("Result: ", result);
            clearInterval(this.secondInterval);
          })
          .catch((error) => {
            const code = error.code;
            const message = `ERROR: ${error.code} ${error.message} ${error}`;
            const details = error.details;
            console.log("Call Func Error: ", code, message, error);
          });*/
        }
      }
    },

    getNormalSecondsCountdown(seconds) {
      clearInterval(this.secondInterval);
      this.secondInterval = setInterval(() => {
        const currentSeconds = seconds--;
        this.countDown =
          currentSeconds > 1
            ? `${currentSeconds} Sekunden`
            : `${currentSeconds} Sekunde`;
      }, 1000);
    },

    getRestSeconds() {
      const coeff = 1000 * 60;
      const date = new Date();
      const ceiled = new Date(Math.ceil(date.getTime() / coeff) * coeff);
      clearInterval(this.secondInterval);
      let seconds = Math.ceil((ceiled.getTime() - date.getTime()) / 1000);
      if(!this.task.strang) {
        this.secondInterval = setInterval(() => {
          const currentSeconds = seconds--;
          this.countDown =
            currentSeconds > 1
              ? `${currentSeconds} Sekunden`
              : `${currentSeconds} Sekunde`;
        }, 1000);
      }
    },

    locationToGetBus({ strang }) {
      if (strang.location == "strang") {
        return `<span class="font-weight-bold">${strang.kom?.text || ""}</span> aus <span class="font-weight-bold">Strang ${strang.strangNummer} Position ${strang.strangPosition} ${strang.strangText}</span>.`;
      } else {
        return `vom Ort <span class="font-weight-bold">${strang.platz}</span>.`;
      }
    },

    convertBusType({ fahrzeug, hinweis, strang }) {
      const kat = strang?.kategorie || ''
      if (kat != '') {
        return kat
      }
      if (hinweis == "E-Bus") {
        switch (fahrzeug) {
          case "SL":
          case "SLz":
            return "E-Solobus MB";
          case "GB":
          case "GBz":
            return "E-Gelenkbus";
          default:
            return "Unbekannt";
        }
      }

      switch (fahrzeug) {
        case "SL":
        case "SLz":
          return "Solobus";
        case "ESL":
          switch (hinweis) {
            case "BYD":
              return "E-Solobus BYD";
            case "MB":
              return "E-Solobus MB";
            default:
              return "Unbekannt";
          }
        case "GB":
        case "GBz":
          return "Gelenkbus";
        default:
          return "Unbekannt";
      }
    },

    openDialog() {
      this.dialog.ausfahrtHilfe++;
    },
    openConfirmDialog(type) {
      this.confirmtype = type
      switch (type) {
        case 'defekt':
          this.confirmtitle = "Fahrzeug defekt"
          this.confirmmsg = `Ist das Fahrzeug wirklich defekt?`
          break;
        case 'leer':
          this.confirmtitle = "Strang leer"
          this.confirmmsg = `Ist der Strang ${this.task.strang.strangNummer} wirklich leer?`
          break;
        case 'posleer':
          this.confirmtitle = "Strangposition leer"
          this.confirmmsg = `Ist der Strang ${this.task.strang.strangNummer} Position ${this.task.strang.strangPosition} wirklich leer?`
          break;
        case 'blockiert':
          this.confirmtitle = "Strang blockiert"
          this.confirmmsg = `Ist der Strang ${this.task.strang.strangNummer} wirklich blockiert?`
          break;
      }
      this.confirmopen = true
    },

    closeConfirmDialog(state, type) {
      this.confirmopen = false
      if (state) {
        switch (type) {
        case 'defekt':
          this.FahrzeugDefektAction()
          break
        case 'leer':
          this.strangLeerAction()
          break
        case 'posleer':
          this.strangPosleerAction()
          break
        case 'blockiert':
          this.StrangBlockiertAction()
          break
      }
      }
    },

    strangPosleerAction() {
      let VMs = this.$store.getters.VMNotification(DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"))
      this.$store.dispatch("Strangposleer", {
        docid: this.task.strang.DocId,
        data: { anzahl: 0, position: {} },
        strangNummer: this.task.strang.strangNummer,
        strangPosition: this.task.strang.strangPosition
      })
      this.$emit('taskaction', this.task)
      this.$store.dispatch("updateBusExit", {
        date: this.task.meldedatum,
        docid: this.task.docid,
        data: { 
          strang: "",
          einfahrt: "",
          gemeldet: false,
          gemeldet_um: "",
          wagennr: "",
          equinr: ""
        },
      });
      let _to = VMs //+ "011489,012114"
      let _departmentto = ""
      if (process.env.VUE_APP_ENV === 'staging') {
        _to = "011489,460190"
      }
      let headerContent = { headers: { "Content-Type": "application/json", "User-Agent": "MAPP"}};
      let bodyContent = {
        set: "notification",
        apikey: "432UvrzMz2CX3d3dGpsdfdwqJKkwksckcDNuvsdee==",
        to_persids: _to,
        to_departments: _departmentto,
        notification: {
          title: `Ausfahrt Strang ${this.task.strang.strangNummer} Position ${this.task.strang.strangPosition} Leer!`,
          body: `Der Strang ${this.task.strang.strangNummer}\r\n Position ${this.task.strang.strangPosition}\r\nwurde von ${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name}\r\nals Leer markiert!`,
          priority: "high",
        },
        data: { notifyid: this.task.dienst, groupid: "MoeBis", group: "Ausfahrt", article_id: null},
      }
      const functions = getFunctions(app, "europe-west1")
      const sendNotification = httpsCallable(functions, 'sendNotificationMapp')
      //const setNotification = httpsCallable(functions, 'setNotification')
      sendNotification({ header: headerContent, body: bodyContent })
      /*setNotification({
        data: {
          content: `Der Strang ${this.task.strang.strangNummer} Position ${this.task.strang.strangPosition} wurde von ${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid}) als leer markiert.`,
          createdAt: new Date(),
          destination: "strangverwaltung",
          dienst: `${this.task.dienst}`,
          openedBy: [],
          permissions: ["strangverwaltung"],
          title: `Ausfahrt Strang ${this.task.strang.strangNummer} Position ${this.task.strang.strangPosition} Leer!`,
          viewedBy: [],
        }
      });*/
    },

    strangLeerAction() {
      let VMs = this.$store.getters.VMNotification(DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"))
      this.$store.dispatch("Strangleer", {
        docid: this.task.strang.DocId,
        data: { anzahl: 0, position: {} },
        strangNummer: this.task.strang.strangNummer
      })
      this.$emit('taskaction', this.task)
      this.$store.dispatch("updateBusExit", {
        date: this.task.meldedatum,
        docid: this.task.docid,
        data: { 
          strang: "",
          einfahrt: "",
          gemeldet: false,
          gemeldet_um: "",
          wagennr: "",
          equinr: ""
        },
      });
      let _to = VMs //+ "011489,012114"
      let _departmentto = ""
      if (process.env.VUE_APP_ENV === 'staging') {
        _to = "011489,460190"
      }
      let headerContent = { headers: { "Content-Type": "application/json", "User-Agent": "MAPP"}};
      let bodyContent = {
        set: "notification",
        apikey: "432UvrzMz2CX3d3dGpsdfdwqJKkwksckcDNuvsdee==",
        to_persids: _to,
        to_departments: _departmentto,
        notification: {
          title: `Ausfahrt Strang ${this.task.strang.strangNummer} Leer!`,
          body: `Der Strang ${this.task.strang.strangNummer}\r\nwurde von ${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name}\r\nals Leer markiert!`,
          priority: "high",
        },
        data: { notifyid: this.task.dienst, groupid: "MoeBis", group: "Ausfahrt", article_id: null},
      }
      const functions = getFunctions(app, "europe-west1")
      const sendNotification = httpsCallable(functions, 'sendNotificationMapp')
      //const setNotification = httpsCallable(functions, 'setNotification')
      sendNotification({ header: headerContent, body: bodyContent })
      /*setNotification({
        data: {
          content: `Der Strang ${this.task.strang.strangNummer} wurde von ${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name} (${this.$store.state.auth.employee.persid}) als leer markiert.`,
          createdAt: new Date(),
          destination: "strangverwaltung",
          dienst: `${this.task.dienst}`,
          openedBy: [],
          permissions: ["strangverwaltung"],
          title: `Ausfahrt Strang ${this.task.strang.strangNummer} Leer!`,
          viewedBy: [],
        }
      });*/
    },

    FahrzeugDefektAction() {
      console.log("FahrzeugDefektAction", this.task.strang);
      let VMs = this.$store.getters.VMNotification(DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"))
      this.$store.dispatch("updateStrang", {
        docid: this.task.strang.DocId,
        data: {
          istGesperrt: true,
        },
        strangNummer: this.task.strang.strangNummer
      })
      this.$emit('taskaction', this.task)
      this.$store.dispatch("updateBusExit", {
        date: this.task.meldedatum,
        docid: this.task.docid,
        data: { 
          strang: "",
          einfahrt: "",
          gemeldet: false,
          gemeldet_um: "",
          wagennr: "",
          equinr: ""
        },
      })
      let _to = VMs
      let _departments = "U07-722/1,U07-722/2"
      if (process.env.VUE_APP_ENV === 'staging') {
        _to = "011489,460190"
        _departments = ""
      }
      let headerContent = { headers: { "Content-Type": "application/json", "User-Agent": "MAPP"}};
      let bodyContent = {
        set: "notification",
        apikey: "432UvrzMz2CX3d3dGpsdfdwqJKkwksckcDNuvsdee==",
        to_persids: _to,
        to_departments: _departments,
        notification: {
          title: `Ausfahrt Strang ${this.task.strang.strangNummer} Position ${this.task.strang.strangPosition} Fahrzeug defekt!`,
          body: `Das Fahrzeug für die Ausfahrt, \r\nDienst ${this.task.dienst} um ${this.task.anfang} \r\n
            aus dem Strang ${this.task.strang.strangNummer} Position ${this.task.strang.strangPosition} ist nicht möglich!\r\n
            Von ${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name}!`,
          priority: "high",
        },
        data: { notifyid: this.task.dienst, groupid: "MoeBis", group: "Ausfahrt", article_id: null},
      }
      const functions = getFunctions(app, "europe-west1")
      const sendNotification = httpsCallable(functions, 'sendNotificationMapp')
      sendNotification({ header: headerContent, body: bodyContent })
      const setNotification = httpsCallable(functions, 'setNotification')
      setNotification({
        data: {
          content: `Das Fahrzeug für die Ausfahrt, \r\nDienst ${this.task.dienst} um ${this.task.anfang} \r\n
            aus dem Strang ${this.task.strang.strangNummer} Position ${this.task.strang.strangPosition} ist nicht möglich!\r\n
            Von ${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name}!`,
          createdAt: new Date(),
          destination: "strangverwaltung",
          dienst: `${this.task.dienst}`,
          openedBy: [],
          permissions: ["strangverwaltung"],
          title: `Ausfahrt Strang ${this.task.strang.strangNummer} Fahrzeug defekt!`,
          viewedBy: [],
        }
      });
    },

    StrangBlockiertAction() {
      let VMs = this.$store.getters.VMNotification(DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"))
      console.log("VMs", VMs)
      this.$store.dispatch("updateStrang", {
        docid: this.task.strang.DocId,
        data: {
          istGesperrt: true,
        },
        strangNummer: this.task.strang.strangNummer
      })
      this.$emit('taskaction', this.task)
      this.$store.dispatch("updateBusExit", {
        date: this.task.meldedatum,
        docid: this.task.docid,
        data: { 
          strang: "",
          einfahrt: "",
          gemeldet: false,
          gemeldet_um: "",
          wagennr: "",
          equinr: ""
        },
      })
      let _to = VMs //+ "011489,012114,"
      let _departments = ""
      if (process.env.VUE_APP_ENV === 'staging') {
        _to = "011489,460190"
        _departments = ""
      }
      let headerContent = { headers: { "Content-Type": "application/json", "User-Agent": "MAPP"}}
      let bodyContent = {
        set: "notification",
        apikey: "432UvrzMz2CX3d3dGpsdfdwqJKkwksckcDNuvsdee==",
        to_persids: _to,
        to_departments: _departments,
        notification: {
          title: `Ausfahrt Strang ${this.task.strang.strangNummer} blockiert!`,
          body: `Die Ausfahrt für den Dienst ${this.task.dienst} \r\num ${this.task.anfang} aus dem Strang ${this.task.strang.strangNummer} \r\nist nicht möglich!\r\nVon ${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name}!`,
          priority: "high",
        },
        data: { notifyid: this.task.dienst, groupid: "MoeBis", group: "Ausfahrt", article_id: null},
      }
      const functions = getFunctions(app, "europe-west1")
      const sendNotification = httpsCallable(functions, 'sendNotificationMapp')
      sendNotification({ header: headerContent, body: bodyContent })
      const setNotification = httpsCallable(functions, 'setNotification')
      setNotification({
        data: {
          content: `Die Ausfahrt für den Dienst ${this.task.dienst} \r\num ${this.task.anfang} aus dem Strang ${this.task.strang.strangNummer} \r\nist nicht möglich!\r\nVon ${this.$store.state.auth.employee.firstname} ${this.$store.state.auth.employee.name}!`,
          createdAt: new Date(),
          destination: "strangverwaltung",
          dienst: `${this.task.dienst}`,
          openedBy: [],
          permissions: ["strangverwaltung"],
          title: `Ausfahrt Strang ${this.task.strang.strangNummer} blockiert!`,
          viewedBy: [],
        }
      });
    },

    getCountdownForHelpButton(task) {
      if (task.gemeldet) {
        const currentTime = DateTime.now().setLocale("de");
        const tenMinutesAfterGemeldet = DateTime.fromFormat(
          `${task.meldedatum} ${task.gemeldet_um}`,
          "yyyy-MM-dd HH:mm"
        )
          .setLocale("de-DE")
          .plus({ minutes: 10 });
        const { seconds } = tenMinutesAfterGemeldet
          .diff(currentTime, ["seconds"])
          .toObject();
//        console.log("CONDITION", this.task.strang?.status == "SUCCESS");
        this.isShowHelpButton = seconds > 0;
      }
    },

    resetStrang() {
      this.$store.dispatch("updateBusExit", {
        date: this.task.meldedatum,
        docid: this.task.docid,
        data: { strang: "" },
      });
    },
  },

  watch: {
    time: {
      handler: function (newTime) {
        this.getCountdown(
          this.task.meldedatum,
          this.task.meldezeit,
          this.$store.state.settings.settings.timing
        );
        this.getCountdownForHelpButton(
          this.task
        );
      },
    },

    task: {
      handler: function (newtask) {
        if(newtask.strang === "") {
          this.getCountdown(
            this.task.meldedatum,
            this.task.meldezeit,
            this.$store.state.settings.settings.timing
          );
        } else {
          clearInterval(this.secondInterval);
        }
      },
    },

    "$store.state.settings.settings.timing": {
      handler: function (timing) {
        if (timing != 0) {
          this.getCountdown(this.task.meldedatum, this.task.meldezeit, timing);
        }
      },
    },
  },

  created() {
    //this.$store.dispatch("attachSettingsOnSnapshotoffline")
    this.time = DateTime.now().setLocale("de").toFormat("HH:mm");
    this.timeInterval = setInterval(() => {
      this.time = DateTime.now().setLocale("de").toFormat("HH:mm");
      this.tasktime = DateTime.now().setLocale("de").plus({ minutes: 30 }).toFormat("HH:mm")
      //console.log("tasktime", this.tasktime)
    }, 1000);
    this.btnInterval = setInterval(() => {
      this.btnclass = this.btnclass == 'btnanwesend' ? 'btnname' : 'btnanwesend'
      this.btntext = this.btntext == 'Du bist nicht anwesend' ? `hier Bitte drücken ...` : `Du bist nicht anwesend`
    }, 3000)
  },

  beforeDestroy() {
    clearInterval(this.timeInterval)
    clearInterval(this.secondInterval)
    clearInterval(this.btnInterval)
  },
};
</script>

<style scoped>
.btnanwesend.v-btn.v-btn.v-btn--has-bg {
  background-color:#9b004b !important;
  color: white !important;
}

.btnname.v-btn.v-btn.v-btn--has-bg {
  background-color:#aa3f73 !important;
  color: rgb(146, 238, 189) !important;
}
</style>
