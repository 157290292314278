//import { firestore } from '@/firebase/firebase';
import { db } from '@/firebase/firebase';
import { collection, onSnapshot, where, query, orderBy } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    userdata: {},
    roster: [],
    firestoreListener: null,
    AllListener: null,
    isLoading: false,
    isOnline: false,
    lastChange: null,
}

const mutations = {

}

const getters = {
    FahrerList(state) {
        const arr = state.roster
            .filter(roster =>
                roster.Active == 1)
            .map(roster => {
                return {
                    firstname: roster.Firstname,
                    surname: roster.Surname,
                    name: roster.Firstname.trim() + ', ' + roster.Surname,
                    Service_nr: roster.Service_nr,
                    Personnel_nr: roster.Personnel_nr,
                    nd: roster.Firstname.trim() + ' ' + roster.Surname + ' ' +roster.Service_nr,
                    pnd: roster.Personnel_nr + ' ' + roster.Firstname.trim() + ' ' + roster.Surname + ' ' +roster.Service_nr
                }
            })
            .sort((a, b) => {
                const servicenrA = a.Service_nr.toUpperCase();
                const servicenrB = b.Service_nr.toUpperCase();
                if (servicenrA < servicenrB) {
                    return -1;
                }
                if (servicenrA > servicenrB) {
                    return 1;
                }
                return 0;
            })
        return [...new Set(arr)]
    },
    getFahrerList(state, rootState) {
        const arr = state.roster
            .filter(roster =>
                roster.Active == 1)
            .map(roster => {
                return {
                    firstname: roster.Firstname,
                    surname: roster.Surname,
                    name: roster.Firstname.trim() + ', ' + roster.Surname,
                    Service_nr: roster.Service_nr,
                    Personnel_nr: roster.Personnel_nr,
                    nd: roster.Firstname.trim() + ' ' + roster.Surname + ' ' +roster.Service_nr,
                    pnd: roster.Personnel_nr + ' ' + roster.Firstname.trim() + ' ' + roster.Surname + ' ' +roster.Service_nr
                }
            })
            .sort((a, b) => {
                const servicenrA = a.Service_nr.toUpperCase();
                const servicenrB = b.Service_nr.toUpperCase();
                if (servicenrA < servicenrB) {
                    return -1;
                }
                if (servicenrA > servicenrB) {
                    return 1;
                }
                return 0;
            })
//            console.log(arr)
        return [...new Set(arr)]
    },
    VMList(state) {
        const arr = state.roster
            .filter(roster => 
                roster.Personnel_type == "VM" &&
                roster.Active == 1)
            .map(roster => {
                return {
                    firstname: roster.Firstname,
                    surname: roster.Surname,
                    name: roster.Firstname.trim() + ', ' + roster.Surname,
                    Service_nr: roster.Service_nr,
                    Personnel_nr: roster.Personnel_nr,
                    nd: roster.Firstname.trim() + ' ' + roster.Surname + ' ' +roster.Service_nr,
                    pnd: roster.Personnel_nr + ' ' + roster.Firstname.trim() + ' ' + roster.Surname + ' ' +roster.Service_nr
                }
            })
            .sort((a, b) => {
                const servicenrA = a.Service_nr.toUpperCase();
                const servicenrB = b.Service_nr.toUpperCase();
                if (servicenrA < servicenrB) {
                    return -1;
                }
                if (servicenrA > servicenrB) {
                    return 1;
                }
                return 0;
            })
        return [...new Set(arr)]
    },
    VMNotification: (state) => (date) => {
        const arr = state.roster
            .filter(roster => 
                roster.Personnel_type == "VM" &&
                roster.Active == 1 &&
                roster.Service_date == date)
            .filter(roster => 
                roster.Service_mark == " . " ||
                roster.Service_mark == "DT ")
            .map(roster => {
                return {
                    date: roster.Service_date,
                    mark: roster.Service_mark,
                    firstname: roster.Firstname.trim(),
                    surname: roster.Surname,
                    name: roster.Firstname.trim() + ', ' + roster.Surname,
                    Service_nr: roster.Service_nr,
                    Personnel_nr: roster.Personnel_nr,
                }
            })
            .sort((a, b) => {
                const servicenrA = a.Service_nr.toUpperCase();
                const servicenrB = b.Service_nr.toUpperCase();
                if (servicenrA < servicenrB) {
                    return -1;
                }
                if (servicenrA > servicenrB) {
                    return 1;
                }
                return 0;
            })
        let _ret = ""
        for(const vm of arr) {
            if (_ret == "") {
                _ret = vm.Personnel_nr
            } else {
                _ret += ',' + vm.Personnel_nr
            }
        }
        //return [...new Set(arr)]
        return _ret
    }
}

const actions = {
/*    attachDayrosterOnSnapshot({ state }, { persid }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.userdata);
                return;
            }
            state.isLoading = true;
            const _dayrosterid =  DateTime.now().setLocale('de').toFormat("yyyy-MM-dd") + '_' + persid;
            state.firestoreListener = firestore
                .collection("dayroster").doc(_dayrosterid)
                .onSnapshot((doc) => {
                    if (doc.exists) {
                        state.userdata = doc.data();
                    } else {
                        state.userdata = {};
                    }
                    state.isLoading = false;
                    resolve(state.userdata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },*/
    attachDayrosterOnSnapshotoffline({ state }, { persid, date }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.userdata);
                return;
            }
            state.isLoading = true;
            const _dayrosterid =  date + '_' + persid;
            const q = query(collection(db, "dayroster"), where("id", "==", _dayrosterid));
            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.userdata = doc.data();
                        } else {
                            state.userdata = {};
                        }
//                        const source = snapshot.metadata.fromCache ? "local cache" : "server";
                        if(snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm")
                        }
//                        console.log("Data came from " + source);
                    });
                    state.isLoading = false;
                    resolve(state.userdata);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    async attachDayrosterAll({ state }, { date }) {
        return new Promise((resolve, reject) => {
            if (state.AllListener) {
                resolve(state.roster);
                return;
            }

            const q = query(collection(db, "dayroster"), where("Service_date", "==", date), orderBy("Service_nr"));
            state.VMsListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.roster.splice(0, state.roster.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            const _roster = doc.data();
                            if(_roster['Service_nr'] != "frei") {
                                state.roster.push(_roster);
                            }
                        }
                    });
                    resolve(state.roster);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    detachDayrosterOnSnapshot({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
    },

    detachDayrosterAll({state}) {
        if (state.VMsListener) {
            state.VMsListener();
            state.VMsListener = null;
        }
    }
}

export default { state, mutations, actions, getters }