import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, updateDoc, getDocs } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
  busparkingspacedata: [],
  firestoreListener: null,
  isLoading: false,
  isOnline: false,
  lastChange: null,
}

const getters = {

}

const mutations = {

}

const actions = {
  attachBusParkingSpace({ state }, { date }) {
    return new Promise((resolve, reject) => {
      if (state.firestoreListener) {
        resolve(state.busparkingspacedata);
        return;
      }
      state.isLoading = true;
      // const zeit = DateTime.now().setLocale('de').toFormat("HH:mm")
      const q = query(collection(db, "busparkingspace"),
        where("afzs_route", "==", "000"),
        where("afzs_trip", "==", "00"),
        where("afzs_gpsx", "!=", null),
        orderBy("afzs_gpsx"));
      state.firestoreListener =
        onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
          state.busparkingspacedata.splice(0, state.busparkingspacedata.length);
          snapshot.docs.forEach((doc) => {
            if (doc.exists) {
              const docdata = doc.data();
              let vehicle = {};
              vehicle = { position: { lat: docdata.afzs_gpsy, lng: docdata.afzs_gpsx}, data: docdata}
              state.busparkingspacedata.push({ docid: doc.id, vehicle });
            }
            // const source = snapshot.metadata.fromCache ? "local cache" : "server";
            if (snapshot.metadata.fromCache) {
              state.isOnline = false;
            } else {
              state.isOnline = true;
              state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
            }
            // console.log("Data came from " + source);
          });
          state.isLoading = false;
          resolve(state.busparkingspacedata);
        }, (error) => {
          console.log(error.message);
          reject(error);
        })
    })
  },
}

export default { state, getters, mutations, actions }
