<template>
  <div id="clock" :style="clockstyle">
    <p v-if="datefield" class="date" :style="datestyle">{{ date }}</p>
    <p v-if="timefield" class="time" :style="timestyle">{{ time }}</p>
  </div>
</template>
  
  <script>
  export default {
    name: 'DigitalClock',
    props: {
      timefield: {
        type: Boolean,
        default: false
      },
      datefield: {
        type: Boolean,
        default: true
      },
      clockstyle: {
        type: String,
        default: 'top: 25%; left: 85%; color: #9b004b;'
      },
      timestyle: {
        type: String,
        default: 'font-size: 3em;'
      },
      datestyle: {
        type: String,
        default: 'font-size: 1em;'
      },
    },
    data () {
      return {
        time: '',
        date: '',
        week: ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA']
      }
    },
    methods: {
      updateTime() {
        setInterval(() => {
          var cd = new Date();
          this.time = this.zeroPadding(cd.getHours(), 2) + ':' + 
                    this.zeroPadding(cd.getMinutes(), 2) + ':' + 
                    this.zeroPadding(cd.getSeconds(), 2);
          this.date = this.week[cd.getDay()] + ' ' +
                    this.zeroPadding(cd.getDate(), 2) + '.' +
                    this.zeroPadding(cd.getMonth()+1, 2) + '.' +
                    this.zeroPadding(cd.getFullYear(), 4);
        }, 1000)
      },

      zeroPadding(num, digit) {
        var zero = '';
        for(var i = 0; i < digit; i++) {
            zero += '0';
        }
        return (zero + num).slice(-digit);
      },
    },
    mounted () {
      this.updateTime()
    }
  }
  
  </script>
  
  <style>
    p {
      margin: 0;
      padding: 0;
    }
    #clock {
      font-family: 'Share Tech Mono', monospace;
      text-align: center;
      position: absolute;
      transform: translate(-50%, -50%);
      text-shadow: 0 0 0.4em #f09ec6, 0 0 0.4em rgba(10, 175, 230, 0);
    }
    .time {
      letter-spacing: 0.05em;
      padding: 5px 0;
    }
    .date {
      letter-spacing: 0.1em;
    }
  </style>