import busstopcheckview from '@/views/busstop/busstopcheckview.vue'

const routesbusstop = [
    {
        path: '/busstopcheckview',
        name: 'Hst. Überprüfung',
        icon: 'mdi-bus-clock',
        right: 'busstopcheckview',
        navParent: "Haltestellen",
        component: busstopcheckview
    },
];

export default routesbusstop;