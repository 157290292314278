/* eslint-disable */
<template>
  <v-container>
    <v-card>
      <v-card-title>
        <span class="text-h5">Strang sperren/entsperren</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-autocomplete
              :items="$store.getters.sortStraenge('ALL')"
              :loading="$store.state.strang.isLoading"
              :item-text="item => `${item.strangNummer} - ${item.name}`"
              :item-value="item => `${item.strangNummer} - ${item.name}`"
              v-model="selectedStrang"
              label="Strangnummer"
              return-object
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row v-if="Object.keys(selectedStrang).length > 0">
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="selectedStrang.maxBus"
              label="Max Anzahl"
              type="number"
              min="0"
              :max="selectedStrang.maxBus"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <v-text-field
              v-model="selectedStrang.anzahl"
              label="Anzahl"
              type="number"
              min="0"
              :max="selectedStrang.maxBus"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="Object.keys(selectedStrang).length > 0">
          <v-col cols="12" sm="6" md="3">
            <p>Kurzdienst</p>
            <v-switch
              :input-value="selectedStrang.kurzdienst"
              @change="selectedStrang.kurzdienst = !selectedStrang.kurzdienst"
              :disabled="selectedStrang.isBlocked"
            ></v-switch>
          </v-col>
          <v-col cols="12" sm="6" md="3">
            <p>ist gesperrt</p>
            <v-switch
              :input-value="selectedStrang.istGesperrt"
              @change="selectedStrang.istGesperrt = !selectedStrang.istGesperrt"
              :disabled="selectedStrang.isBlocked"
            >
            </v-switch>
          </v-col>
        </v-row>
        <v-row v-if="Object.keys(selectedStrang).length > 0">
          <v-col cols="12" sm="6" md="6">
            <v-btn color="primary" text @click="save"> Speichern </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>

export default {
  data() {
    return {
      selectedStrang: {},
    };
  },

  watch: {
    selectedStrang: {
      handler(newValue, oldValue) {

      },
      deep: true,
    },
  },

  computed: {},

  mounted() {},

  methods: {
    save() {
      console.log("STRANG:", this.selectedStrang)
      if (this.selectedStrang.anzahl <= this.selectedStrang.maxBus) {
        this.$store.dispatch("updateStrang", {
          docid: this.selectedStrang.strangNummer.toString()+"_"+this.selectedStrang.type,
          //data: this.selectedStrang,
          data: {
            ...this.selectedStrang,
            anzahl: +this.selectedStrang.anzahl,
            maxBus: +this.selectedStrang.maxBus,
            isBlocked: false,
//            lastdate: _lastdate,
          },
          strangNummer: this.selectedStrang.strangNummer.toString()
        });
      } else {
        this.selectedStrang.anzahl = this.selectedStrang.maxBus
        this.$store.dispatch("updateStrang", {
          docid: this.selectedStrang.strangNummer.toString()+"_"+this.selectedStrang.type,
          data: {
            ...this.selectedStrang,
            anzahl: +this.selectedStrang.maxBus,
            maxBus: +this.selectedStrang.maxBus,
            isBlocked: false,
//            lastdate: _lastdate,
          },
          strangNummer: this.selectedStrang.strangNummer.toString()
        });
      }
    }
  },

  created() {
    try {
      //this.$store.dispatch("attachStrangOnSnapshotoffline");
    } catch (err) {
      this.$store.commit("showSnackbar", {
        status: "error",
        message: `Fehler beim Abrufen der Stränge -> ${err.message}`,
      });
      return;
    }
  },

  destroyed() {},
};
</script>
