import VueRouter from 'vue-router'
import Home from '@/views/Home.vue';
//import routeszbltagesbericht from './routeszbltagesbericht';
import routesfahrer from './routesfahrer';
import routesbusstop from './routesbusstop';
import routesbusexit from './routesbusexit';
import routeszbl from '@/router/routeszbl';
import routesstatistic from '@/router/routesstatistic';
import routesnotification from '@/router/routesnotification';
import { store } from '@/store';
//import { Router } from 'workbox-routing';

let routes = [
  {
    path: '/',
    name: 'Home',
    icon: 'mdi-home',
    right: 'all',
    navParent: "",
    component: Home,
    navIgnore: false
    //navIgnore true dann nicht angezeigt
  },
];

// Route ZBL-Tagesbericht "firebase-functions": "^4.0.2",
/*routeszbltagesbericht.forEach(route => {
  routes.push(route); 
});*/

// Route Notifications
routesnotification.forEach(route => {
  routes.push(route);
  store.state.menu.routemenu.push(route);
});
// Route Fahrer
routesfahrer.forEach(route => {
  routes.push(route);
  store.state.menu.routemenu.push(route);
});
// Route Busstop
routesbusstop.forEach(route => {
  routes.push(route);
  store.state.menu.routemenu.push(route);
});
// Route Busexit
routesbusexit.forEach(route => {
  routes.push(route);
  store.state.menu.routemenu.push(route);
});
// Route ZBL
routeszbl.forEach(route => {
  routes.push(route);
  store.state.menu.routemenu.push(route);
});
// Route Statistik
routesstatistic.forEach(route => {
  routes.push(route);
  store.state.menu.routemenu.push(route);
});



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  console.log("FROM:", from, store.state.auth.isAuthenticating);
  console.log("TO: ", to);
  //if(to.name !== "Home" && !store.state.auth.isAuthenticating) next ({name: "Home"})
  //else next()
  //if(store.state.auth.isAuthenticating) next ()
  //else next({name: "Home"})
  const views = []
  /*  "Home", 
    "FDK-Tagessicht", 
    "Digitale Ausfahrt", 
    "Sicherheitsrel. Vorfälle", 
    "Alle Benachrichtigungen", 
    "Hst. Überprüfung", 
    "Busrangierer Menü",
    "Rangierer Strangverwaltung", 
    "Rangierer Ausf. Übersicht",
    "ZBL-Ausfahrtsübersicht", 
    "ZBL-Reservedienst", 
    "ZBL-Reserved. übersicht",
    "Ausfahrt manuel"
  ]*/
  views.push(to.name)
  //if(to.name !== "Home") views.push(to.name)
  if (!views.includes(to.name) &&
    !store.state.auth.isAuthenticating) next({ name: "Home" })
  //if (!store.state.auth.isAuthenticating) next({ name: "Home" })
  else next()
  /*if (to.name !== "Home" &&
    to.name !== "FDK-Tagessicht" &&
    to.name !== "Persönliche Ausfahrt" &&
    to.name !== "Sicherheitsrel. Vorfälle" &&
    to.name !== "Alle Benachrichtigungen" &&
    to.name !== "Hst. Überprüfung" &&
    to.name !== "Busrangierer Menü" &&
    to.name !== "ZBL-Ausfahrtsübersicht" &&
    to.name !== "ZBL-Reservedienst" &&
    to.name !== "ZBL-Reserved. übersicht" &&
    !store.state.auth.isAuthenticating) next({ name: "Home" })
  else next()*/
})

export default router;
