import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, updateDoc, getDocs } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
    value: [],
    data: [],
    firestoreListener: null,
    firestoredataListener: null,
    isLoading: false,
    isOnline: false,
    lastChange: null,
}

const getters = {}

const mutations = {}

const actions = {
    attachTrapeze({ state }, { docid }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.value);
                return;
            }
            state.isLoading = true;

            state.firestoreListener =
               onSnapshot(doc(db, "trapeze", docid), { includeMetadataChanges: true }, (doc) => {
                    if (doc.exists) {
                        state.value = doc.data();
                    }
                    const source = doc.metadata.hasPendingWrites ? "Local" : "Server";
                    console.log(source, " data: ", doc.data());
                    if(doc.metadata.fromCache) {
                        state.isOnline = false;
                    } else {
                        state.isOnline = true;
                        state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm")
                    }
                    state.isLoading = false;
                    resolve(state.value);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
    async attachTapezedata({ state }, { docid }) {
        return new Promise((resolve, reject) => {
            if (state.firestoredataListener) {
                resolve(state.data);
                return;
            }
            state.isLoading = true;
            const q = query(collection(db, "trapeze", docid, "trapeze_data"),
                orderBy("creation"));
            state.firestoredataListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.data.splice(0, state.data.length);
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            const _data = doc.data();
                            _data.datum = DateTime.fromISO(_data.RecordedAtTime, { zone: 'Europe/Berlin' }).setLocale('de').toFormat("dd.MM.yyyy HH:mm:ss");
                            state.data.push({ docid: doc.id, ..._data });
                        }
                        if (snapshot.metadata.fromCache) {
                            state.isOnline = false;
                        } else {
                            state.isOnline = true;
                            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
                        }
                    });
                    //console.log(state.data);
                    state.isLoading = false;
                    resolve(state.data);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },
    detachTrapeze({ state }) {
        if (state.firestoreListener) {
            state.firestoreListener();
            state.firestoreListener = null;
        }
    },
    detachTrapezedata({ state }) {
        if (state.firestoredataListener) {
            state.firestoredataListener();
            state.firestoredataListener = null;
        }
    },
}

export default { state, getters, mutations, actions }
