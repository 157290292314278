var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_c('v-app-bar',{attrs:{"app":"","clipped-left":""}},[(_vm.$store.state.auth.isAuthenticating && _vm.$store.state.auth.withMenu)?_c('v-app-bar-nav-icon',{staticClass:"mr-3"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-menu")])],1)],1):_vm._e(),_c('div',{staticClass:"d-flex align-center"},[_c('a',{attrs:{"href":"/"}},[(!_vm.$vuetify.theme.dark)?_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Login NEW Logo","contain":"","src":require('@/assets/Logos/logo_colored.svg'),"transition":"scale-transition","width":"64"}}):_c('v-img',{staticClass:"shrink mr-2",attrs:{"alt":"Login NEW Logo","contain":"","src":require('@/assets/Logos/logo_dark.svg'),"transition":"scale-transition","width":"64"}})],1)]),_c('v-toolbar-title',{staticClass:"flex text-center"},[_vm._v("MöBis")]),(_vm.$store.state.auth.isAuthenticating)?_c('v-menu',{attrs:{"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-avatar',_vm._g(_vm._b({staticClass:"white",attrs:{"color":"primary"}},'v-avatar',attrs,false),on),[(_vm.$store.state.auth.photoURL != null)?_c('v-img',{attrs:{"aspect-ratio":"1","src":_vm.$store.state.auth.photoURL,"alt":"Profilbild"}}):_c('span',[_vm._v(_vm._s(_vm.letters))])],1)]}}],null,false,1256888088)},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"white--text justify-center",attrs:{"color":"primary"}},[(_vm.$store.state.auth.photoURL != null)?_c('v-img',{attrs:{"src":_vm.$store.state.auth.photoURL,"alt":"Profilbild"}}):_c('span',[_vm._v(_vm._s(_vm.letters))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$store.state.auth.user.displayName)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$store.state.auth.user.email)+" ")])],1)],1),(
              _vm.$store.state.auth.isAuthenticating &&
              _vm.$store.state.auth.permissions.useFunctions
            )?_c('v-list-item',{on:{"click":function($event){return _vm.importAllEmployees()}}},[_c('v-list-item-subtitle',[_vm._v(" Mitarbeiter importieren ")])],1):_vm._e(),(
              _vm.$store.state.auth.isAuthenticating &&
              _vm.$store.state.auth.permissions.useFunctions
            )?_c('v-list-item',{on:{"click":function($event){return _vm.deleteOldDayroster()}}},[_c('v-list-item-subtitle',[_vm._v(" Tagesdienste löschen ")])],1):_vm._e()],1),_c('v-divider')],1)],1):_vm._e()],1),(_vm.$store.state.auth.isAuthenticating && _vm.$store.state.auth.withMenu)?_c('v-navigation-drawer',{attrs:{"app":"","clipped":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item',{staticClass:"pl-4",attrs:{"value":"MöBis"},on:{"click":function($event){return _vm.openMoeBis()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard-edit-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("MöBis")])],1)],1),_c('v-divider'),_vm._l((_vm.$store.state.menu.link),function(Menu,idx){return [(Menu.sublinks)?_c('v-list-group',{key:Menu.name,attrs:{"color":"primaryLight","group":Menu.name,"value":_vm.expand,"no-action":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(Menu.name))])],1)]},proxy:true}],null,true)},[_vm._l((Menu.sublinks),function(MenuItem,index){return _c('v-list-item',{key:Menu.name + '_' + index,staticClass:"pl-4",attrs:{"to":MenuItem.path,"value":MenuItem.name}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(MenuItem.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(MenuItem.name))])],1)],1)}),_c('v-divider')],2):_c('div',{key:Menu.path},[_c('v-list-item',{key:Menu.name + '_' + idx,staticClass:"pl-4",attrs:{"value":Menu.name},on:{"click":function($event){return _vm.openDoc(Menu)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(Menu.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(Menu.name))])],1)],1),_c('v-divider')],1)]})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }