<template>
  <form>
    <v-container>
      <v-card>
        <v-card-title>
          <v-icon>mdi-bus-clock</v-icon>
          &nbsp; Platzverwaltung V2
        </v-card-title>
        <v-card-text>
          <template>
            <v-data-table
              :headers="busLocationHeaders"
              :items="$store.state.buslocationv2.busLocationV2"
              :loading="$store.state.buslocationv2.isLoading"
              group-by="Kategorie"
            >
              <template v-slot:top>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                  <!-- <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      class="mb-2"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Anlegen
                    </v-btn>
                  </template> -->
                  <v-card>
                    <v-card-title>
                      <span class="text-h5">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.platz"
                              label="Platz"
                              type="textfield"
                              :disabled="editedIndex >= 0"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-text-field
                              v-model="editedItem.anzahl"
                              label="Anzahl"
                              type="number"
                              min="0"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4">
                            <v-autocomplete
                              label="Kategorie"
                              :disabled="editedIndex >= 0"
                              :items="$store.state.buslocationv2.busseKategorie"
                              v-model="editedItem.Kategorie"
                            >
                            </v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="close">
                        Abbrechen
                      </v-btn>
                      <v-btn color="primary" text @click="save">
                        Speichern
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- <v-dialog v-model="dialogDelete" max-width="750px">
                  <v-card>
                    <v-card-title class="text-h5">
                      Möchten Sie dieses Element wirklich löschen?
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeDelete">
                        Abbrechen
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="deleteItemConfirm"
                      >
                        OK
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog> -->
              </template>
              <template v-slot:[`group.header`]="{ items, isOpen, toggle }">
                <th :colspan="busLocationHeaders.length">
                  <v-icon @click="toggle">
                    {{ isOpen ? "mdi-minus" : "mdi-plus" }}
                  </v-icon>
                  {{ items[0].Kategorie }}
                </th>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon small class="mr-2" @click="editItem(item)">
                  mdi-pencil
                </v-icon>
                <!-- <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon> -->
              </template>
              <template v-slot:no-data>
                <v-btn color="primary" @click="initialize"> Reset </v-btn>
              </template>
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </v-container>
  </form>
</template>

<script>
export default {
  data() {
    return {
      doc: "",
      busLocationHeaders: [
        {
          text: "Platz",
          align: "start",
          sortable: false,
          value: "platz",
        },
        // { text: "Max Busse", value: "maxBus", sortable: false },
        { text: "Aktuelle Anzahl", value: "anzahl" },
        { text: "Kategorie", value: "Kategorie", sortable: false },
        { text: "", value: "actions", sortable: false },
      ],
      reloadtimer: "",
      sliderValue: 0,
      dialog: false,
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        platz: "Zaun",
        anzahl: 0,
        Kategorie: "Solobus",
        id: "",
      },
      defaultItem: {
        platz: "Zaun",
        anzahl: 0,
        Kategorie: "Solobus",
        id: "",
      },
    };
  },

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "Neu" : "Anzahl bearbeiten";
    },

    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  watch: {
    "$store.state.auth.isAuthenticating": {
      handler: function (isAuthenticating) {
        if (isAuthenticating) {
          this.$store.dispatch("attachBusLocationV2OnSnapshotoffline");
        }
      },
      immediate: true,
    },

    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    editItem(item) {
      this.editedIndex =
        this.$store.state.buslocationv2.busLocationV2.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex =
        this.$store.state.buslocationv2.busLocationV2.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    // deleteItemConfirm() {
    //   this.$store.dispatch("deleteStrang", {
    //     docid: this.editedItem.strangNummer,
    //   });
    //   this.closeDelete();
    // },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex < 0) {
        this.$store.commit("showSnackbar", {
          message: "Fehler beim Speichern.",
          status: "error",
        });
        return;
      }

      const data = {
        ...this.editedItem,
        anzahl: parseInt(this.editedItem.anzahl),
      };

      console.log("UPDATE", this.editedItem);

      this.$store.dispatch("updateBusLocation", {
        docid: this.editedItem.id,
        data,
      });

      this.close();
    },
  },

  created() {
    this.$store.dispatch("attachBusLocationV2OnSnapshotoffline");
  },

  beforeDestroy() {
    this.$store.dispatch("detachBusLocationV2OnSnapshot");
  },
};
</script>
