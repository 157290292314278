<template>
    <v-flex>
        <v-text-field
            v-model="fieldtime"
            v-bind:label="label"
            type="time"
            prepend-inner-icon="mdi-clock-outline"
            @click:prepend-inner="compmodal = getModal"
            @keydown="windowevent"
            v-on:change="save()"
            :readonly="compro"
        ></v-text-field>
        <v-dialog
            ref="dialog"
            v-model="compmodal"
            :return-value.sync="time"
            :disabled="compro"
            persitent
            width="290px"
        >
            <v-time-picker
                v-if="compmodal"
                v-model="time"
                full-width
                format="24hr"
                @click:minute="save()" 
            >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="compmodal = false">Cancel</v-btn>
                <v-btn text color="primary" @click="save()">OK</v-btn>
            </v-time-picker>
        </v-dialog>
    </v-flex>
</template>
    
    <script>
        import { store } from '@/store';
        import { debounce } from 'debounce';
    
        export default {
            props: {
                label: String, 
                field: Date, 
                modal: Boolean, 
                i1: Number, 
                i2: Number, 
                i3: Number, 
                ro: Boolean, 
                typ: String, 
            },
            
            data: function() {
    //            debugger;
                return {
                    time: this.fieldtime,
                    compmodal: this.modal,
                    compro: this.ro
                }
            },

            created: function () { 
                this.time = this.field;
            },

            computed: {
                fieldtime: {
                    get: function () {
                        return this.field
                    },
                    set: function (newValue) {
                        this.time = newValue;
                    }
                },
                getModal() {
                    if(this.compro === true) {
                        return false;
                    } else {
                        return true;
                    }
                }
            },
            methods: {
                save() {
                    this.$emit('changetime', {time: this.time, typ: this.typ});
                    this.field = this.time;
                    this.compmodal = false;
                },
                windowevent: function (event) {
    //                console.log('Window event: ' + event.which);
    //                console.log(this.$vuetify.breakpoint.width);
                    if(event.which == 13) {
                        this.compmodal = true;
                    }
                },
            }
        }
    </script>