import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, query, setDoc, addDoc, where } from "firebase/firestore";
import { Timestamp } from 'firebase/firestore'
const { DateTime } = require("luxon");

// Notification Sample
// {
//     content: String
//     createdAt: Timestamp
//     destination: String (router push)
//     openedBy: Empty Array
//     permissions: String Array from collection config/user/permissions
//     title: String
//     viewedBy: Empty Array
// }

const state = {
    notifications: [],
    zblnotifications: [],
    firestoreListener: null,
    firestoreZBLListener: null,
    isLoading: true,
    isLoadingZBL: true
}

const getters = {
    unviewedNotifications(_, getters) {
        return getters.personalNotifications.filter(notification => notification.viewedBy?.length == 0)
    },

    unopenedNotifications(_, getters) {
        return getters.personalNotifications.filter(notification => notification.openedBy?.length == 0)
    },

    personalNotifications(state, _, rootState) {
        if (rootState.menu.isLoading) {
            return state.notifications.filter(notification => {
                const allowedUsers = [...new Set(notification.permissions.map(permission => {
                    return rootState.menu.routepermission[permission]?.user
                }).flat())]
                return allowedUsers.includes(rootState.auth.personnelnr)
            })
        }
        return state.notifications
    },

    getNotificationsType: (state) => (type) => {
        const searchDate = Timestamp.fromMillis(DateTime.now().minus({ hours: 1 }).setLocale("de").toMillis())
        return state.notifications.filter(notification => notification.destination == type &&
            notification.createdAt >= searchDate)
            .sort((a, b) => {
                const _timeA = a.createdAt
                const _timeB = b.createdAt
                if (_timeA > _timeB) {
                    return -1;
                }
                if (_timeA < _timeB) {
                    return 1;
                }
                return 0;
            })
    },
}

const actions = {
    attachZBLNotifications({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreZBLListener) {
                resolve(state.zblnotifications);
                return;
            }

            state.isLoadingZBL = true;
            const searchDate = Timestamp.fromMillis(DateTime.now().minus({ hours: 2 }).setLocale("de").toMillis())
            const q = query(collection(db, "notifications"),
                where('destination', '==', 'strangverwaltung'),
                where('createdAt', '>=', searchDate),
                orderBy('createdAt', 'desc'))

            state.firestoreZBLListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.zblnotifications = []
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.zblnotifications.push({ ...doc.data(), docid: doc.id });
                        }
                    });
                    state.isLoadingZBL = false;
                    console.log(state.zblnotifications)
                    resolve(state.zblnotifications);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    attachNotificationsOnSnapshot({ state }) {
        return new Promise((resolve, reject) => {
            if (state.firestoreListener) {
                resolve(state.notifications);
                return;
            }

            state.isLoading = true;

            const q = query(collection(db, "notifications"),
                orderBy('createdAt', 'desc'))

            state.firestoreListener =
                onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
                    state.notifications = []
                    snapshot.docs.forEach((doc) => {
                        if (doc.exists) {
                            state.notifications.push({ ...doc.data(), docid: doc.id });
                        }
                    });
                    state.isLoading = false;
                    resolve(state.notifications);
                }, (error) => {
                    console.log(error.message);
                    reject(error);
                })
        })
    },

    updateNotification({ commit }, { docid, data }) {
        const docRef = doc(db, "notifications", docid);
        setDoc(docRef, data, { merge: true }).then(() => {
        }).catch(err => {
            commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
        })
    },

    createNotification({ commit }, { data }) {
        addDoc(collection(db, 'notifications'), data)
            .catch(err => {
                commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
            })
    },
}

export default { state, getters, actions }
