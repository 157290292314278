<template>
  <form>
    <v-container fluid grid-list-sm>
      <insertbusexitlist
        :active="dialog.addbusexitlist"
      >
      </insertbusexitlist>
      <deletebusexitlist 
        :active="dialog.delbusexitlist"
        :busexitlists="$store.state.busexitlist.data"
      >
      </deletebusexitlist>
      <v-banner  v-if="$store.state.busexitlist.isOnline == false" single-line >
        <v-icon slot="icon" color="warning" size="36">mdi-wifi-strength-alert-outline</v-icon>
        Offline-Modus!
      </v-banner>
      <v-card-title><v-icon>mdi-calendar</v-icon>&nbsp;Meldelisten - Fremdunternehmer </v-card-title>
      <v-card-subtitle>letzte Aktualisierung: {{$store.state.busexitlist.lastChange}}</v-card-subtitle>
      <v-card-actions>
        <v-btn text color="green" @click.native="addbusexitlist">
          <v-icon color="green">mdi-note-plus-outline</v-icon>
          neue Meldeliste
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="red" @click.native="delbusexitlist">
          <v-icon color="red">mdi-delete-outline</v-icon>
          Meldeliste löschen
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <v-expansion-panels multiple>
          <v-expansion-panel v-for="(ml, mlindex) in $store.state.busexitlist.data" :key="mlindex">
            <v-expansion-panel-header>
              <v-card>
                <v-card-title>
                  <v-icon>mdi-bus-clock</v-icon>
                  &nbsp; {{ ml.name }}
                </v-card-title>
              </v-card>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-card class="mx-auto mt-10" outlined>
                <v-card-title>Meldeliste {{ ml.name }} Type {{ ml.type }}</v-card-title>
                <v-data-table 
                  :headers="headers" 
                  :items="ml.daten" 
                  :sort-by.sync="sortby"
                  :search="search" 
                  class="elevation-1" 
                  fixed-header height="550px">
                  <v-divider inset></v-divider>
                  <template v-slot:top>
                    <v-toolbar flat>
                      <div class="d-flex w-100">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" dense outlined single-line hide-details></v-text-field>
                        <v-btn
                          color="primary"
                          class="ml-2 white--text"
                          @click="addNew(mlindex)">
                          <v-icon dark>mdi-plus</v-icon>Add
                        </v-btn>
                      </div>
                    </v-toolbar>
                  </template>
                  <template v-slot:[`item.dienst`]="{ item }">
                    <v-text-field v-model="editedItem.dienst" :hide-details="true" dense single-line :autofocus="true" v-if="item.id === editedItem.id"></v-text-field>
                    <span v-else>{{item.dienst}}</span>
                  </template>
                  <template v-slot:[`item.linie`]="{ item }">
                    <v-text-field v-model.number="editedItem.linie" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                    <span v-else>{{item.linie}}</span>
                  </template>
                  <template v-slot:[`item.kurs`]="{ item }">
                    <v-text-field v-model.number="editedItem.kurs" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
<!--                    <v-text-field v-model.number="editedItem.kurs" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>-->
                    <span v-else>{{item.kurs}}</span>
                  </template>
                  <template v-slot:[`item.meldezeit`]="{ item }">
                    <v-text-field type="time" v-model="editedItem.meldezeit" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                    <span v-else>{{item.meldezeit}}</span>
                  </template>
                  <template v-slot:[`item.abfahrt`]="{ item }">
                    <v-text-field type="time" v-model="editedItem.abfahrt" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                    <span v-else>{{item.abfahrt}}</span>
                  </template>
                  <template v-slot:[`item.ort`]="{ item }">
                    <v-text-field v-model="editedItem.ort" :hide-details="true" dense single-line v-if="item.id === editedItem.id"></v-text-field>
                    <span v-else>{{item.ort}}</span>
                  </template>
                  <template v-slot:[`item.unternehmen`]="{ item }">
                    <v-select v-model="editedItem.unternehmen" :items="unternehmen" label="Unternehmen" v-if="item.id === editedItem.id"></v-select>
                    <span v-else>{{item.unternehmen}}</span>
                  </template>
                  <template v-slot:[`item.actions`]="{ item }">
                    <div v-if="item.id === editedItem.id">
                      <v-icon color="red" class="mr-3" @click="close(mlindex)">
                        mdi-window-close
                      </v-icon>
                      <v-icon color="green"  @click="save(mlindex)">
                        mdi-content-save
                      </v-icon>
                    </div>
                    <div v-else>
                      <v-icon color="green" class="mr-3" @click="editItem(item, mlindex)">
                        mdi-pencil
                      </v-icon>
                      <v-icon color="red" @click="deleteItem(item, mlindex)">
                        mdi-delete
                      </v-icon>
                    </div>
                  </template>
<!--                  <template v-slot:no-data>
                    <v-btn color="primary" @click="initialize">Reset</v-btn>
                  </template>-->
                </v-data-table>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-container>
  </form>
</template>

<script>

import { DateTime } from "luxon";

import insertbusexitlist from "@/components/dialog/insertbusexitlist";
import deletebusexitlist from "@/components/dialog/deletebusexitlist";

export default {
  data() {
    return {
      date: null,
      search: '',
      sortby: 'meldezeit',
      headers: [
        {
          text: 'Dienst',
          value: 'dienst',
          sortable: false
        },
        {
          text: 'Linie',
          value: 'linie',
          sortable: false
        },
        {
          text: 'Kurs',
          value: 'kurs',
          sortable: false
        },
        {
          text: 'Meldezeit',
          value: 'meldezeit',
          sortable: true
        },
        {
          text: 'Abfahrtszeit',
          value: 'abfahrt',
          sortable: false
        },
        {
          text: 'Einsatzort',
          value: 'ort',
          sortable: false
        },
        {
          text: 'Unternehmen',
          value: 'unternehmen',
          sortable: false
        },
        { 
          text: 'Actions', 
          value: 'actions', 
          sortable: false , 
          width: "100px"},
      ],
      unternehmen: [
          "Gerresheim", "Kremers", "KVS", "Wallrath"
      ],
      editedIndex: -1,
      editedItem: {
        id: 0,
        dienst: '',
        linie: 0,
        kurs: 0,
        meldezeit: '',
        abfahrt: '',
        ort: '',
        unternehmen: '',
      },
      defaultItem: {
        id: 0,
        dienst: '',
        linie: 0,
        kurs: 0,
        meldezeit: '',
        abfahrt: '',
        ort: '',
        unternehmen: '',
      },
      dialog: {
        addbusexitlist: 0,
        delbusexitlist: 0,
      },
    };
  },

  watch: {},

  computed: {},

  methods: {
    editItem (item, index) {
      this.editedIndex = this.$store.state.busexitlist.data[index].daten.indexOf(item);
      this.editedItem = Object.assign({}, item);
    },

    deleteItem (item, index) {
      const idx = this.$store.state.busexitlist.data[index].daten.indexOf(item);
      if (confirm('Soll die Meldeliste wirklich gelöscht werden?')) {
        this.$store.state.busexitlist.data[index].daten.splice(idx, 1);
        this.$store.dispatch("updateBusexitlist", { docid: this.$store.state.busexitlist.data[index].docid, data: this.$store.state.busexitlist.data[index] } );
      }
    },

    close (index) {
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300)
    },
    addNew(index) {
       const addObj = Object.assign({}, this.defaultItem);
       addObj.id = this.$store.state.busexitlist.data[index].daten.length + 1;
       this.$store.state.busexitlist.data[index].daten.unshift(addObj);
       this.$store.dispatch("updateBusexitlist", { docid: this.$store.state.busexitlist.data[index].docid, data: this.$store.state.busexitlist.data[index] } );
       this.editItem(addObj, index);
    },
    save (index) {
      if (this.editedIndex > -1) {
        Object.assign(this.$store.state.busexitlist.data[index].daten[this.editedIndex], this.editedItem)
        this.$store.dispatch("updateBusexitlist", { docid: this.$store.state.busexitlist.data[index].docid, data: this.$store.state.busexitlist.data[index] } );
      }
      this.close()
    },
    addbusexitlist() {
      this.dialog.addbusexitlist++;
    },
    delbusexitlist() {
      this.dialog.delbusexitlist++;
    }
  },

  mounted() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.$store.dispatch("attachBusexitlist");
  },

  beforeDestroy() {
    this.$store.dispatch("detachBusexitlist");
  },

  components: {
    insertbusexitlist,
    deletebusexitlist,
  },
};
</script>
