import Vue from 'vue'
import Vuex from 'vuex'

import auth from './modules/auth';
import menu from './modules/menu';
import dayroster from './modules/dayroster';
import busline from './modules/busline';
import busstation from './modules/busstation';
import busstationcheck from './modules/busstationcheck';
import busexit from './modules/busexit';
import busexitlist from './modules/busexitlist';
import employees from './modules/employees';
import vehicles from './modules/vehicles';
import snackbar from './modules/snackbar';
import delay from './modules/delay';
import strang from './modules/strang';
// import admin from './modules/admin';
import buslocation from './modules/buslocation'
import buslocationv2 from './modules/buslocationv2'
import zbl from '@/store/modules/zbl'
import trapeze from '@/store/modules/trapeze'
import resservice from '@/store/modules/reserveservice'
import settings from '@/store/modules/settings'
import securityincident from '@/store/modules/securityincident'
import notifications from '@/store/modules/notifications'
import busparkingspace from '@/store/modules/busparkingspace'

Vue.use(Vuex)

export const store = new Vuex.Store({
    modules: {
        auth,
        menu,
        dayroster,
        busline,
        busstation,
        busstationcheck,
        busexit,
        busexitlist,
        employees,
        vehicles,
        snackbar,
        delay,
        strang,
        // admin,
        buslocation,
        buslocationv2,
        zbl,
        trapeze,
        resservice,
        settings,
        securityincident,
        notifications,
        busparkingspace,
    },

    getters: {
        clearobject: () => (_obj) => {
            const _mo = Object.keys(_obj);
            _mo.forEach(m => {
                Vue.delete(_obj, m);
            });
        }
    }
})
