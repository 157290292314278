<template>
    <form v-if="sendange == false" @submit.prevent="updateFirebase" >
        <v-container>
            <v-card v-if="$store.state.auth.isAuthenticating">
                <v-banner  v-if="$store.state.busstationcheck.isOnline == false" single-line >
                    <v-icon slot="icon" color="warning" size="36">mdi-wifi-strength-alert-outline</v-icon>
                    Offline-Modus!
                </v-banner>
                <v-card-title><v-icon>mdi-bus-stop</v-icon>&nbsp;Haltestellen&uuml;berpr&uuml;fung </v-card-title>
                <v-card-subtitle>letzte Aktualisierung: {{$store.state.busstationcheck.lastChange}}</v-card-subtitle>
                <v-card-text>
                    <v-container fluid>
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                    :items="$store.state.busstation.itemdata"
                                    item-text='text'
                                    item-value='text'
                                    @change="haltestelleUpdate"
                                    v-model="busstopcheck.haltestelle" 
                                    label="Haltestelle" 
                                    data-vv-name="busstopcheck.haltestelle" 
                                    prepend-icon="mdi-bus-stop-uncovered" 
                                    return-object
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-autocomplete
                                    :items="$store.state.busline.itemdata"
                                    item-text='line'
                                    item-value='line'
                                    @change="linieUpdate"
                                    v-model="busstopcheck.linie" 
                                    label="Linie" 
                                    data-vv-name="busstopcheck.linie" 
                                    prepend-icon="mdi-bus-side" 
                                    return-object
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select 
                                    v-model="busstopcheck.richtung" 
                                    label="Richtung" 
                                    :items="richtungitems"  
                                    @change="richtungUpdate"
                                    :menu-props="{ maxHeight: '400' }" 
                                    prepend-icon="mdi-sign-direction" 
                                    id="Richtung">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-select 
                                    v-model="busstopcheck.fahrplan" 
                                    clearable=true
                                    label="Fahrplan" 
                                    :items="fahrplanitems"  
                                    @change="fahrplanUpdate"
                                    :menu-props="{ maxHeight: '400' }" 
                                    prepend-icon="mdi-bus-clock" 
                                    id="Fahrplan">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select 
                                    v-model="busstopcheck.tarifinfo" 
                                    clearable=true
                                    label="Tarifinfo" 
                                    :items="tarifinfoitems"  
                                    @change="tarifinfoUpdate"
                                    :menu-props="{ maxHeight: '400' }" 
                                    prepend-icon="mdi-information-outline" 
                                    id="Tarifinfo">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select 
                                    v-model="busstopcheck.haltestellenschild" 
                                    clearable=true
                                    label="Haltestellenschild" 
                                    :items="schilditems" 
                                    @change="schildUpdate" 
                                    :menu-props="{ maxHeight: '400' }" 
                                    prepend-icon="mdi-bus-stop-uncovered" 
                                    id="Haltestellenschild">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col cols="12" md="4">
                                <v-select 
                                    v-model="busstopcheck.muelleimer" 
                                    clearable=true
                                    label="Mülleimer" 
                                    :items="muelleimeritems"  
                                    @change="muelleimerUpdate"
                                    :menu-props="{ maxHeight: '400' }" 
                                    prepend-icon="mdi-delete-outline" 
                                    id="Muelleimer">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select 
                                    v-model="busstopcheck.anzeige" 
                                    clearable=true
                                    label="Efi-Anzeige" 
                                    :items="anzeigeitems"  
                                    @change="anzeigeUpdate"
                                    :menu-props="{ maxHeight: '400' }" 
                                    prepend-icon="mdi-cast" 
                                    id="EfiAnzeige">
                                </v-select>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-select 
                                    v-model="busstopcheck.wartehalle" 
                                    clearable=true
                                    label="Wartehalle" 
                                    :items="wartehalleitems" 
                                    @change="wartehalleUpdate" 
                                    :menu-props="{ maxHeight: '400' }" 
                                    prepend-icon="mdi-bus-stop-covered" 
                                    id="Wartehalle">
                                </v-select>
                            </v-col>
                        </v-row>
                        <v-row dense>
                            <v-col>
                                <v-textarea 
                                    v-model="busstopcheck.bemerkung" 
                                    @input="bemerkungUpdate"
                                    type="text" 
                                    label="Bemerkung" 
                                    rows="8"
                                    prepend-icon="mdi-card-text-outline">
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
            <v-spacer></v-spacer>
            <v-card v-if="useragent == 'MAPP'">
                <v-card-title><v-icon>mdi-image-outline</v-icon>&nbsp;Bilder:</v-card-title>
                <v-card-text v-if="imgupload == true">
                    <template>
                        <a style="width:0;height:0;display:none;" id="pictureUpload" 
                            href='mapp://GetPicture/{"id":999,"errorTitle":"Kein Bild","errorText":"Es wurde kein Bild erkannt. Bitte versuchen Sie es erneut und halten Sie die Kamera gerade.","crop":true,"source_height":500,"source_width":350,"orientation":"portrait","tophint":"<center><br><span style=\"font-size:1.7em;font-weight:normal;\">Schaden fotografieren</span><br style=>Bild am Rahmen ausrichten</center>","bottomhint":""}'>
                            Hinzufügen
                        </a>
                        <div id="images" style="min-height:30px;height:auto;width:100%;overflow: auto;">
                            <button @click="uploadPicture()" id="addphotobtn" style="width:100%;font-size:24px;padding:5px;" class="btn btn-default">
                                <v-icon>mdi-image-outline</v-icon>
                                <span class="upload-caption">Bild</span> 
                            </button>
                        </div>
                    </template>
                </v-card-text>
            </v-card>
            <v-card v-else>
                <v-card-title><v-icon>mdi-image-outline</v-icon>&nbsp;Bilder:</v-card-title>
                <v-card-text v-if="imgupload == true">
                    <v-row justify="center">
                        <v-col sm="12">
                            <v-row justify="center">
                                <image-uploader
                                    :preview="true"
                                    :maxWidth="imgmaxwidth"
                                    :quality="imgquality"
                                    :maxSize="0.4"
                                    :className="['fileinput', { 'fileinput--loaded': false }]"
                                    capture="environment"
                                    :debug="0"
                                    doNotResize="gif"
                                    :autoRotate="false"
                                    outputFormat="blob"
                                    @input="setImage"
                                >
                                    <label for="fileInput" slot="upload-label">
                                        <v-icon>mdi-image-outline</v-icon>
                                        <span class="upload-caption">Upload</span>
                                    </label>
                                </image-uploader>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-spacer></v-spacer>
            <v-card>
                <v-card-text>
                    <v-row id="imgrow" v-for="(image, index) in busstopcheckimg" :key="index">
                        <v-col sm="12">
                            <v-row justify="center">
                                <img id="imgcheck" v-img:check :src="image.data" alt="">
                                <v-btn icon color="green" @click.native="delImage(index)">
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
            <v-spacer></v-spacer>
            <v-card width='100%'>
                <v-card-text v-if="state === 'synced'">
                    <v-flex d-flex xs12 sm12 child-flex>
                        <v-layout row wrap>
                            <v-flex d-flex xs12 sm12 child-flex>
                                <v-btn icon color="green" v-on:click="sendtoange()">
                                    <v-icon>mdi-send</v-icon> Absenden
                                </v-btn>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-card-text>
            </v-card>
            <v-card>
                <button type="submit" v-if="state === 'modified'">Save Changes</button>
            </v-card>
        </v-container>
    </form>
    <form v-else>
        <v-container>
            <v-card>
                <v-card-title><v-icon>mdi-bus-stop</v-icon>&nbsp;Haltestellen&uuml;berpr&uuml;fung </v-card-title>
                <v-card-subtitle>Die Daten wurden versendet!</v-card-subtitle>
            </v-card>
        </v-container>
    </form>
</template>

<script>

//import { firestore } from "@/firebase/firebase";
import Vue from 'vue';
import VueImg from 'v-img';
import ImageUploader from "vue-image-upload-resize";
//import axios from 'axios';
import { DateTime } from "luxon";
import { debounce } from 'debounce';

Vue.use(VueImg);
Vue.use(ImageUploader);

export default {
    name: "Haltestellenueberpruefung",
    data() {
        return {
            isLoading: false,
            imgmaxwidth: 1024,
            imgquality: 0.7,
            imgupload: true,
            maximg: 6,
            sendange:false,
            isTruncated: this.$vuetify.breakpoint.mobile,
            date: null,
            checkdocid: null,
            fileData: null,
            uploadValue: 0,
            state: "new",
            useragent: "",
            picanz: 0,
            picarr:[],
            mappimg: null,
            richtungitems: [],
            fahrplanitems: ['fehlt', 'verschmutzt', 'beschädigt'],
            tarifinfoitems: ['fehlt', 'verschmutzt', 'beschädigt'],
            wartehalleitems: ['verschmutzt', 'beschädigt'],
            schilditems: ['fehlt', 'verschmutzt', 'beschädigt'],
            muelleimeritems: ['fehlt', 'verschmutzt', 'beschädigt'],
            anzeigeitems: ['fehlt', 'verschmutzt', 'beschädigt'],
        };
    },
    created: function () {
    },
    computed: {
        busstopcheck() {
            if (this.$store.state.busstationcheck.isLoading) {
                return [];
            }
            return this.$store.state.busstationcheck.data;
        },
        busstopcheckimg() {
            if (this.$store.state.busstationcheck.isImgLoading) {
                return [];
            }
            return this.$store.state.busstationcheck.image;
        },
    },
    watch: {
        "$store.state.auth.isAuthenticating": {
            handler: function (isAuthenticating) {
                if (isAuthenticating) {
                    this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
                    this.checkdocid = this.$store.state.auth.personnelnr + '-' + DateTime.now().setLocale('de').toFormat("yyyy-MM-dd_HH:mm:ss");//"011489-2022-05-16_07:46:07";//
                    this.$store.dispatch("createBusStationCheck", { docid: this.checkdocid, persid: this.$store.state.auth.personnelnr } ).then(() => {
                        this.$store.dispatch("attachBusLineOnSnapshotoffline", {  data: null } );
                        this.$store.dispatch("attachBusStationOnSnapshotoffline");
                        this.$store.dispatch("attachBusStationCheckOnSnapshotoffline", { docid: this.checkdocid} ).then(() => {
                            this.$store.dispatch("attachBusStationCheckImageOnSnapshotoffline", { docid: this.checkdocid} ).then(() => {
                                if(this.busstopcheck.linie != 'undefined') {
                                    this.richtungitems = [this.busstopcheck.linie.direction_1, this.busstopcheck.linie.direction_2];
                                }
                            });
                        });
                    });
                }
            },
            immediate: true,
        },
    },
    methods: {
        setImage: function(blob) {
            const hp = this;
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
                hp.imageUpdate({data: reader.result}, hp.busstopcheckimg.length);
                hp.busstopcheckimg.push({data: reader.result});
                if(hp.busstopcheckimg.length >= hp.maximg) {
                    hp.imgupload = false;
                }
            }
        },
        uploadPicture() {
            window.vm = this;
            document.getElementById("pictureUpload").click();
        },
/*        setPicture(base64) {
            this.mappimg = base64;
            this.picanz += 1;
            let _img = '';
            this.picarr[this.picanz-1] = base64;
            for(var i = 0; i< this.picanz; i++){
                _img += '<br>Beleg '+(i+1)+'<br><v-img width="95%;" id="picture" contain class="previewimage" src="'+this.picarr[i]+'"/><button onclick="deletePicture('+i+')" id="delphotobtn" style="width:100%;font-size:18px;padding:5px;" class="btn btn-default"><span class="glyphicon glyphicon-picture"></span> entfernen</button>';
            }
            this.busstopcheck.img.push({data: base64});
            this.imageUpdate(this.busstopcheck.img);
        },*/
        delImage(index) {
            this.busstopcheckimg.splice(index, 1);
            if(this.busstopcheckimg.length < this.maximg) {
                this.imgupload = true;
            }
            this.debouncedUpdateImg(this.busstopcheckimg, "delete", index);
//            this.imageUpdate(this.busstopcheck.img);
        },
        sendtoange() {
            this.busstopcheck.ange = true;
            this.sendange = this.busstopcheck.ange;
            this.state = 'modified';
            const data = {ange: this.busstopcheck.ange};
            this.debouncedUpdate(data);
//            this.$router.push({ path: '/' });
            /*//https://cloud.google.com/firestore/docs/reference/rest
            const FB = this;
            const _sFB =  this.$store.state.busstationcheck.data;
            axios.post('https://moebistest-newmg.msappproxy.net/?site=sendtoange', {
                    data: _sFB,
                    datum: FB.date,
                    function: 'Haltestellenueberpruefung',
                    process: 'Haltestellenüberprüfung',
                    persid: '441037'  // '460190' // Frank Grahane  //441037 Thomas Boß
                })
                .then(function (response) {
                    console.log(response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });*/
        },
        async updateFirebase(data) {
            try {
//                await db.doc(documentPath).set(this.formData);
                if(Object.keys(data).length != 0 && data.constructor === Object) {
                    this.$store.dispatch("updateBusStationCheck", { docid: this.checkdocid, data: data } );
                    this.state = 'synced';
                }
            } catch (error) {
                this.errorMessage = JSON.stringify(error)
                this.state = 'error';
            }
        },
        async updateImgFirebase(data, typ, index) {
            try {
//                await db.doc(documentPath).set(this.formData);
                console.log("UPD: " + typ + " / " + index);
                if(typ === "insert") {
                    if(Object.keys(data).length != 0 && data.constructor === Object) {
                        this.$store.dispatch("insertImage", { docid: this.checkdocid, imgid: index.toString(), data: data , typ: "insert"} );
                        this.state = 'synced';
                    }
                }
                if(typ === "delete") {
                    this.$store.dispatch("insertImage", { docid: this.checkdocid, imgid: index.toString(), data: data , typ: "delete"} );
//                    this.$store.dispatch("deletetImage", { docid: this.checkdocid, imgid: index.toString()} );
                    this.state = 'synced';
                }
                
            } catch (error) {
                this.errorMessage = JSON.stringify(error)
                this.state = 'error';
            }
        },
        imageUpdate(value, index) {
            this.state = 'modified';
            this.debouncedUpdateImg(value, "insert", index);
        },
        haltestelleUpdate(value) {
            this.state = 'modified';
            const data = {haltestelle: value};
            this.debouncedUpdate(data);
        },
        linieUpdate(value) {
            this.state = 'modified';
            this.richtungitems = [value.direction_1, value.direction_2];
            const data = {linie: value};
            this.debouncedUpdate(data);
        },
        richtungUpdate(value) {
            this.state = 'modified';
            const data = {richtung: value};
            this.debouncedUpdate(data);
        },
        fahrplanUpdate(value) {
            this.state = 'modified';
            const data = {fahrplan: value};
            this.debouncedUpdate(data);
        },
        tarifinfoUpdate(value) {
            this.state = 'modified';
            const data = {tarifinfo: value};
            this.debouncedUpdate(data);
        },
        schildUpdate(value) {
            this.state = 'modified';
            const data = {haltestellenschild: value};
            this.debouncedUpdate(data);
        },
        wartehalleUpdate(value) {
            this.state = 'modified';
            const data = {wartehalle: value};
            this.debouncedUpdate(data);
        },
        muelleimerUpdate(value) {
            this.state = 'modified';
            const data = {muelleimer: value};
            this.debouncedUpdate(data);
        },
        anzeigeUpdate(value) {
            this.state = 'modified';
            const data = {anzeige: value};
            this.debouncedUpdate(data);
        },
        bemerkungUpdate(value) {
            this.state = 'modified';
            const data = {bemerkung: value};
            this.debouncedUpdate(data);
        },
        debouncedUpdate: debounce(function(data) {
            this.updateFirebase(data)
        }, 1500),
        debouncedUpdateImg: debounce(function(data, typ, index) {
            this.updateImgFirebase(data, typ, index)
        }, 150)
    },
    mounted() {
        window.el = function(id){return document.getElementById(id);} // Get elem by ID
        window.__getPicture = function(id, base64, readJson)  {
            if(id == 999) {
                let __getPictureResponse = readJson;
                console.log(__getPictureResponse);
                base64 = "data:image/jpg;base64," + base64;
                let index = window.vm.busstopcheckimg.length;
                window.vm.imageUpdate({data: base64}, index)
                window.vm.busstopcheckimg.push({data: base64});
                if(window.vm.busstopcheckimg.length >= window.vm.maximg) {
                    window.vm.imgupload = false;
                }
                
            }else{
                console.log(readJson);
                console.log(id);                
//                base64 = "data:image/png;base64," + base64;
                base64 = "data:image/jpg;base64," + base64;
//                console.log(base64);
                window.vm.setPicture(base64);
            }
        }
        this.$store.state.auth.withMenu = true;
        this.useragent = navigator.userAgent;
        console.log("Useragent: "+this.useragent);
        if(this.useragent == 'MAPP') {
            this.maximg = 6;
            this.imgmaxwidth = 256;
            this.imgquality = 0.7;
        }
        this.date = DateTime.now().setLocale('de').toFormat("yyyy-MM-dd");
//        this.$store.dispatch("attachBusLineOnSnapshotoffline", { date: this.date} );
//        this.$store.dispatch("attachBusStationOnSnapshotoffline", { date: this.date} );
//        this.$store.dispatch("attachBusStationCheckOnSnapshotoffline", { date: this.date} );
    },
    beforeDestroy() {
        this.$store.dispatch("detachBusLineOnSnapshot");
        this.$store.dispatch("detachBusStationOnSnapshot");
        if(this.busstopcheck.ange == true) {
            this.$store.dispatch("detachBusStationCheck", { docid: this.checkdocid} );
        } else {
            this.$store.dispatch("detachBusStationCheckDelete", { docid: this.checkdocid} );
        }
        this.$route.params.date = null;
    },
    components: {},
};
</script>
<style>
    #fileInput {
        display: none;
    }
    .img-preview {
        display: none;
    }
    @media (min-width: 950px) {
        /* breite Browserfenster */
        .v-data-table .v-data-table__wrapper table tbody tr td {
            font-size: 1.3rem !important;
        }
        #imgcheck {
            width: 99%;
            max-width: 35em;
        }
        #imgrow {
            padding: 10px;
        }
    }
    @media (min-width: 550px) and 
    (max-width: 950px) {
    /* Darstellung auf Netbooks */ 
        .v-data-table .v-data-table__wrapper table tbody tr td {
            font-size: 1.1rem !important;
        }
        #imgcheck {
            width: 99%;
            max-width: 35em;
        }
        #imgrow {
            padding: 10px;
        }
    } 
    @media (max-width: 550px) {
     /* mobile Geräte */ 
        .v-data-table .v-data-table__wrapper table tbody tr td {
            font-size: 0.95rem !important;
            padding: 0 0.4rem;
        }
        .v-card__text {
            padding-left: 4px;
        }
        #imgcheck {
            width: 99%;
            max-width: 35em;
        }
        #imgrow {
            padding: 10px;
        }
    }
    @media (max-width: 430px) {
     /* mobile Geräte */ 
        .v-data-table .v-data-table__wrapper table tbody tr td {
            font-size: 0.85rem !important;
            padding: 0 0.4rem;
        }
        .v-card__text {
            padding-left: 4px;
        }
        #imgcheck {
            width: 99%;
            max-width: 35em;
        }
        #imgrow {
            padding: 10px;
        }
    }
    
</style>
