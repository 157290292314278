<template>
  <form>
    <v-container>
      <v-card v-if="$store.state.auth.isAuthenticating">
        <v-banner v-if="$store.state.busparkingspace.isOnline == false" single-line>
          <v-icon slot="icon" color="warning" size="36">
            mdi-wifi-strength-alert-outline
          </v-icon>
          Offline-Modus!
        </v-banner>
        <v-card-title>
          <v-icon>mdi-bus-clock</v-icon>
          &nbsp; Stellplatzübersicht
        </v-card-title>
        <v-card-subtitle>
          letzte Aktualisierung:
          {{ $store.state.busparkingspace.lastChange }}
        </v-card-subtitle>
      </v-card>
      <div class="map">
        <v-navigation-drawer v-model="drawer" absolute width="320" temporary>
          <v-row class="mt-5 mx-5" align="center" justify="center">
            <v-col cols="10">
              <span class="font-weight-light">NEW</span>
              <span>Maps</span>
            </v-col>
            <v-col cols="2">
              <v-btn icon @click="drawer = !drawer">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="mt-5 mx-5">
            <MapPanel @mapTypeChangeEvent="updateMapType"></MapPanel>
          </div>
        </v-navigation-drawer>
        <gmap-map
          ref="mapRef"
          @maptypeid_changed="updateMapType($event)"
          :center="mapCenter"
          :zoom="zoomLevel"
          :map-type-id="mapType"
          :options="{
            mapTypeControl: false,
            streetViewControl: false,
          }"
          style="width: 1500px; height: 1000px"
        >
          <GmapInfoWindow
            :options="infoWindowOptions"
            :position="infoWindowPosition"
            :opened="infoWindowOpened"
            @closeclick="handleInfoWindowClosed"
          >
            <v-card>
              <v-card-title>
                {{ selectedObject && selectedObject.kennzeichen }}
                {{ selectedObject && selectedObject.nummer }}
              </v-card-title>
              <v-card-subtitle>
                {{ selectedObject && selectedObject.afzs_standort }}
                {{ selectedObject && selectedObject.bezeichnung }}
                {{ selectedObject && selectedObject.typ }}
              </v-card-subtitle>
            </v-card>
          </GmapInfoWindow>
        <!--<GmapMarker
          v-for="(obj, index) in filteredObjects" 
          :key="index"
          :position="obj.vehicle.position"
          :clickable="true"
          :draggable="false"
          @click="center=obj.vehicle.position"
        />-->
          <gmap-circle
            v-for="(obj, index) in $store.state.busparkingspace.busparkingspacedata"
            :key="'circle' + index"
            :center="obj.vehicle.position"
            :radius="1"
            :visible="true"
            :options="{fillColor:'red',fillOpacity:1.0}"
            @click="handleMarkerClicked(obj)"
            @mouseover="handleMarkerClicked(obj)" 
            @mouseleave="infoWindowOpened = false"
          ></gmap-circle>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="halle1"
          ></gmap-polygon>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="halle2"
          ></gmap-polygon>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="halle3"
          ></gmap-polygon>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="strang50"
          ></gmap-polygon>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="strang51"
          ></gmap-polygon>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="zblbereich"
          ></gmap-polygon>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="nebereich"
          ></gmap-polygon>
<!--          <gmap-polygon 
            v-if="mapLoaded"
            :paths="halle2"
          ></gmap-polygon>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="getStraenge(halle1, 1, 15)"
          >
          </gmap-polygon>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="getStraenge(halle2, 27, 9)"
          >
          </gmap-polygon>
          <gmap-polygon 
            v-if="mapLoaded"
            :paths="getStraenge(halle3, 17, 9)"
          >
          </gmap-polygon>-->
        </gmap-map>
      </div>
    </v-container>
  </form>
</template>

<script>
import { DateTime } from "luxon";
import { gmapApi } from "vue2-google-maps";
import MapPanel from "@/components/app/MapPanel.vue";

export default {
  data() {
    return {
      mapType: "roadmap",
      mapLoaded: false,
      myCoordinate: {lat: 51.183997, lng: 6.450886 },
      mapCenter: {lat: 51.183997, lng: 6.450886 },
      //mapCenter: { lat: 51.18444540499487, lng: 6.45066482710535 },
      zoomLevel: 19,
      infoWindowOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      infoWindowOpened: false,
      selectedObject: null,
      halle1: [
        [ {lat: 51.18496182656393, lng: 6.451623489658238}, 
          {lat: 51.18447228678277, lng: 6.452065277820181}, 
          {lat: 51.18399535449722, lng: 6.4508104713400565}, 
          {lat: 51.184494359986296, lng: 6.450347651488931} 
        ]    
      ],
      halle2: [
        [ {lat: 51.184186866545936, lng: 6.450325084689274}, 
          {lat: 51.18385525130117, lng: 6.4506269285375675}, 
          {lat: 51.183513803553616, lng: 6.449705640653729}, 
          {lat: 51.18383463267177, lng: 6.449412098503034} 
        ]
      ],
      halle3: [
        [ {lat: 51.18449448655419, lng: 6.450020231286073}, 
          {lat: 51.184186866545936, lng: 6.450325084689274}, 
          {lat: 51.18381778482887, lng: 6.44935888511939}, 
          {lat: 51.18414146438468, lng: 6.449054292126921} 
        ]
      ],
      strang50: [
        [ {lat: 51.184828638291705, lng: 6.451950355975507},
          {lat: 51.184544508567214, lng: 6.452177002641187},
          {lat: 51.18448566491381, lng: 6.452058985446081},
          {lat: 51.18477315747678, lng: 6.451778694600809}
        ]
      ],
      strang51: [
        [ {lat: 51.18501147232707, lng: 6.451772659637087},
          {lat: 51.184828638291705, lng: 6.4519503559755070},
          {lat: 51.18477315747678, lng: 6.451778694600809},
          {lat: 51.18496328718709, lng: 6.451596350357231}
        ]
      ],
      zblbereich: [
        [ {lat: 51.18435284609495, lng: 6.452004000156251},
          {lat: 51.18418135795381, lng: 6.4521743204264580},
          {lat: 51.18402626151199, lng: 6.4517619307958350},
          {lat: 51.18420111274768, lng: 6.4515895988688925}
        ]
      ],
      nebereich: [
        [ {lat: 51.18419186582396, lng: 6.451549365734198},
          {lat: 51.18401070980747, lng: 6.451725720974619},
          {lat: 51.1838589753339, lng: 6.4513186957619455},
          {lat: 51.18403340688797, lng: 6.451144352178268}
        ]
      ],
    };
  },

  watch: {
  },

  computed: {
    google: gmapApi,

    filteredObjects() {
      if (this.$store.state.busparkingspace.busparkingspacedata.length == 0) {
        return [];
      }
      return this.$store.state.busparkingspace.busparkingspacedata
    },

    infoWindowPosition() {
      if (this.selectedObject) {
        return {
          lat: parseFloat(this.selectedObject.afzs_gpsy),
          lng: parseFloat(this.selectedObject.afzs_gpsx),
        };
      }
      return this.myCoordinate;
    },

  },

  methods: {
    handleInfoWindowClosed() {
      this.selectedObject = null
      this.infoWindowOpened = false
    },

    handleMarkerClicked(obj) {
      this.selectedObject = obj.vehicle.data
      this.infoWindowOpened = true
    },

    setMapCenter(coordinates) {
      this.mapCenter = {
        lat: coordinates[0],
        lng: coordinates[1],
      };
    },

    setZoomLevel(zoom) {
      this.zoomLevel = zoom;
    },

    updateMapType($event) {
      this.$set(this, "mapType", $event);
    },

    getPosition(obj) {
      return {
        lat: parseFloat(obj.geojson[0].coordinates[0]),
        lng: parseFloat(obj.geojson[0].coordinates[1]),
      };
    },

    getStraenge(halle, start, anz) {
      const _x = 0.00002761176012;
      const _y = 0.000030596236322;
//      console.log("Halle", halle, anz)
      if (halle.length > 0) {
        let _anfang_y = halle[0][0].lat;
        let _anfang_x = halle[0][0].lng;
        let _ende_y = halle[0][3].lat;
        let _ende_x = halle[0][3].lng;
        let koord = []
        for (let step = start + anz; step >= start; step--) {
          let _a_x = _anfang_x + _x;
          let _a_y = _anfang_y - _y;
          let _e_x = _ende_x + _x;
          let _e_y = _ende_y - _y;
          koord.push([{ lat: _anfang_y, lng: _anfang_x}, { lat: _a_y, lng: _a_x}, { lat: _e_y, lng: _e_x}, { lat: _ende_y, lng: _ende_x}])
          _ende_x = _e_x
          _ende_y = _e_y
          _anfang_x = _a_x
          _anfang_y = _a_y
        }
//        console.log("Halle", koord)
        return koord
      }
      return []
    },
    
  },

  created() {

  },

  mounted() {
    const date = DateTime.now()
          .setLocale("de")
          .plus({ days: -2 })
          .toFormat("yyyy-MM-dd 00:00:00");
    this.$store
      .dispatch("attachBusParkingSpace", { date: date })
      .then(() => {
        this.$store.dispatch("attachVehiclesPosition")
        //setMapCenter();
      });
    this.$refs.mapRef.$mapPromise.then((map) => {
      this.mapLoaded = true;
      map.panTo({lat: 51.1840, lng: 6.45075})
    });
    //mapCenter: {lat: 51.183997, lng: 6.450886 },
//    this.$refs.map.$mapPromise.then(() => {
//      this.mapLoaded = true;
//    });
  },

  beforeDestroy() {
  },

  components: {
    MapPanel,
  },
};
</script>

<style>
.map {
  width: 100%;
  height: 100%;
}

.menu-item {
  z-index: 10;
  position: absolute;
}

.v-input.v-input--selection-controls {
  margin-top: 0;
}

.gm-fullscreen-control {
  top: 4rem !important;
}
</style>
