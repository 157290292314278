<template>
  <form>
    <v-container>
      <v-card v-if="$store.state.auth.isAuthenticating">
        <v-banner v-if="$store.state.busexit.CountisOnline == false" single-line>
          <v-icon slot="icon" color="warning" size="36">
            mdi-wifi-strength-alert-outline
          </v-icon>
          Offline-Modus!
        </v-banner>
        <v-card-title>
          <v-icon>mdi-bus-clock</v-icon>
          &nbsp; Ausfahrt
          <v-spacer></v-spacer>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="exitdate"
                label="Datum"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              :max="aktdate"
              :locale="'de'"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-card-title>
        <v-card-subtitle>
          letzte Aktualisierung:
          {{ $store.state.busexit.lastChange }}
        </v-card-subtitle>
        <v-card-text> </v-card-text>
      </v-card>
      <template v-if="$store.state.busexit.CountisOnline == true">
        <v-card v-for="_bec in $store.state.busexit.countdata" :key="_bec.id">
          <v-card-title>
            {{ _bec.text }}
          </v-card-title>
          <v-card-subtitle v-if="_bec.SL > 0">
            Solobus
          </v-card-subtitle>
          <v-progress-linear v-if="_bec.SL > 0"
            :value="getValue(_bec, 'SL')"
            color="#660031"
            background-color="black"
            height="25"
            striped
          >
            <strong class="strongcolor">
              Solobus 
              {{ _bec.gSL}} von
              {{ _bec.SL }} noch
              {{ getAnz(_bec, 'SL') }} Bus(se)
              {{ getValue(_bec, 'SL') }}%
            </strong>
          </v-progress-linear>
          <v-card-subtitle v-if="_bec.GB > 0">
            Gelenkbus
          </v-card-subtitle>
          <v-progress-linear v-if="_bec.GB > 0"
            :value="getValue(_bec, 'GB')"
            color="#9b004b"
            background-color="black"
            height="25"
            striped
          >
            <strong class="strongcolor">
              Gelenkbus 
              {{ _bec.gGB}} von
              {{ _bec.GB }} noch
              {{ getAnz(_bec, 'GB') }} Bus(se)
              {{ getValue(_bec, 'GB') }}%
            </strong>
          </v-progress-linear>
          <v-card-subtitle v-if="_bec.ESLBYD > 0">
            E-Solobus BYD
          </v-card-subtitle>
          <v-progress-linear v-if="_bec.ESLBYD > 0"
            :value="getValue(_bec, 'ESLBYD')"
            color="#b80058"
            background-color="black"
            height="25"
            striped
          >
            <strong class="strongcolor">
              E-Solo BYD
              {{ _bec.gESLBYD}} von
              {{ _bec.ESLBYD }} noch
              {{ getAnz(_bec, 'ESLBYD') }} Bus(se)
              {{ getValue(_bec, 'ESLBYD') }}%
            </strong>
          </v-progress-linear>
          <v-card-subtitle v-if="_bec.ESLMB > 0">
            E-Solobus MB
          </v-card-subtitle>
          <v-progress-linear v-if="_bec.ESLMB > 0"
            :value="getValue(_bec, 'ESLMB')"
            color="#b80058"
            background-color="black"
            height="25"
            striped
          >
            <strong class="strongcolor">
              E-Solo MB
              {{ _bec.gESLMB}} von
              {{ _bec.ESLMB }} noch
              {{ getAnz(_bec, 'ESLMB') }} Bus(se)
              {{ getValue(_bec, 'ESLMB') }}%
            </strong>
          </v-progress-linear>
          <v-card-subtitle v-if="_bec.EGB > 0">
            E-Gelenkbus
          </v-card-subtitle>
          <v-progress-linear v-if="_bec.EGB > 0"
            :value="getValue(_bec, 'EGB')"
            color="green darken-2"
            background-color="black"
            height="25"
            striped
          >
            <strong class="strongcolor">
              E-Gelenkbus 
              {{ _bec.gEGB}} von
              {{ _bec.EGB }} noch
              {{ getAnz(_bec, 'EGB') }} Bus(se)
              {{ getValue(_bec, 'EGB') }}%
            </strong>
          </v-progress-linear>
        </v-card>
      </template>
    </v-container>
  </form>
</template>

<script>
import { DateTime } from "luxon";

export default {
  data() {
    return {
      monitor: false,
      exitdate: null,
      exitdata: [],
      timerInterval: null,
      date: null,
      aktdate: DateTime.now().setLocale("de").toFormat("yyyy-MM-dd"),
    };
  },

  watch: {
    date: {
      handler(newValue, oldValue) {
        if (newValue > DateTime.now().setLocale("de").toFormat("yyyy-MM-dd")) {
          newValue = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
          this.date = newValue;
        }
        this.exitdate = DateTime.fromFormat(newValue, "yyyy-MM-dd")
          .setLocale("de")
          .toFormat("dd.MM.yyyy");
//        console.log("Datumwatch: ", newValue, oldValue);
        this.$store.dispatch("detachBusexitGroupCount").then(() => {
          this.$store.dispatch("attachBusExitGroupCounts", { date: newValue });
        });
      },
      deep: true,
    },
  },

  computed: {
    scrollbarTheme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },

  methods: {
    getValue(data, bus) {
      if(this.$store.state.busexit.CountisOnline == true) {
        const soll = data[bus];
        const ist = data['g'+bus];
        if (soll > 0) {
          return Math.round(ist / soll * 100);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getAnz(data, bus) {
      if(this.$store.state.busexit.CountisOnline == true) {
        const soll = data[bus];
        const ist = data['g'+bus];
        if (soll > 0) {
          return Math.round(soll - ist);
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },

  created() {
    this.$store.dispatch("attachbusexittypes")
  },

  mounted() {
    this.date = DateTime.now().setLocale("de").toFormat("yyyy-MM-dd");
    this.exitdate = DateTime.fromFormat(this.date, "yyyy-MM-dd")
      .setLocale("de")
      .toFormat("dd.MM.yyyy");
    this.$store.dispatch("detachBusexitGroupCount").then(() => {
      this.$store.dispatch("attachBusExitGroupCounts", { date: this.date });
    });
  },

  beforeDestroy() {
    this.$store.dispatch("detachBusexitGroupCount");
    this.$store.dispatch("detachhbusexittypes");
  },

  components: {
    
  },
};
</script>

<style scoped>
.strongcolor {
  color: rgb(255,255,255)
}
</style>