<template>
  <v-card class="mt-5">
    <v-card-text class="text-right"> {{ route }} in {{ this.timer }} sec </v-card-text>
  </v-card>
</template>

<script>
export default {
    components: {
    },
    name: 'timerroute',
    props: {
      interval: {
        type: Number,
        required: true,
        default: 240
      },
      route: {
        type: String,
        required: true,
        default: "Home"
      },
      routeextern: {
        type: Boolean,
        required: true,
        default: false
      },
    },
    data() {
      return {
        timer: 0,
      };
    },
    watch: {
    },
    created() {
      this.timer = this.interval
      this.timeInterval = setInterval(() => {
        this.timer -= 1
        if (this.timer <= 0) {
          if (this.routeextern) {
            this.openExtern()
          } else {
            this.openDoc()
          }
        }
      }, 1000)
    },
    mounted() {
    },
    destroyed() {
      clearInterval(this.timeInterval);
    },
    methods: {
      openDoc() {
      this.$router.push({ name: this.route }).catch(() => {});
      },
      openExtern() {
        window.open(this.route, "_self");
      },
      Reset() {
        this.timer = this.interval
      }
    },
  };
</script>
