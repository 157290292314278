import { db } from '@/firebase/firebase';
import { doc, collection, onSnapshot, orderBy, where, query, setDoc, getDoc, updateDoc } from "firebase/firestore";
const { DateTime } = require("luxon");

const state = {
  rd: [{
    datum: DateTime.now().setLocale('de').toFormat("yyyy-MM-dd"),
    datumanzeige: DateTime.now().setLocale('de').toFormat("dd.MM.yyyy"),
    kommg: "",
    komry: "",
    frueh: [],
    mittel: [],
    spaet: [],/*{
        dienst: "",
        von: null,
        bis: null,
        name: "",
        ort: "",
        persid: "",
        ro: true,
        modal: false,
        einsatz: [{}],
    }],*/
    nb: [],
  }],
  reserveservice: [{
    datum: DateTime.now().setLocale('de').toFormat("yyyy-MM-dd"),
    datumanzeige: DateTime.now().setLocale('de').toFormat("dd.MM.yyyy"),
    kommg: "",
    komry: "",
    frueh: [],
    mittel: [],
    spaet: [],/*{
        dienst: "",
        von: null,
        bis: null,
        name: "",
        ort: "",
        persid: "",
        ro: true,
        modal: false,
        einsatz: [{}],
    }],*/
    nb: [],
  }],
  firestoreListener: null,
  isLoading: true,
  isOnline: false,
  lastChange: null,
}

const getters = {

}

const mutations = {

}

const actions = {
  async insertRESBusExit({ state }, { date, docid, docdata }) {
    const docSnap = await getDoc(doc(db, "dayroster", docid));
    if (docSnap.exists()) {
      console.log(docSnap.data())
    } else {
      await setDoc(doc(db, "dayroster", docid), docdata, { merge: true });
    }
    /*const docSnap = await getDoc(doc(db, "busexit", date, "ausfahrt", docid));
    if (docSnap.exists()) {
      console.log(docSnap.data())
    } else {
      await setDoc(doc(db, "busexit", date, "ausfahrt", docid), docdata, { merge: true });
    }*/
  },

  async addEinsatz({ commit }, { date, docid, einsatzdata, typ }) {
    console.log("ADD: ", date, docid, einsatzdata);
    const docSnap = await getDoc(doc(db, "dayroster", docid));
    if (docSnap.exists()) {
      let _einsatz = {DPfrü: [], DPmit: [], DPspä: []};
      if(docSnap.data().einsatz) {
        _einsatz = docSnap.data().einsatz;
        if(_einsatz.length === 0) {
          _einsatz = {DPfrü: [], DPmit: [], DPspä: []};
        }
      }
      _einsatz[typ].push(einsatzdata);
      setDoc(doc(db, "dayroster", docid), { einsatz: _einsatz }, { merge: true }).then(() => {
        commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
      }).catch(err => {
        commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
      });
    }
    /*const docSnap = await getDoc(doc(db, "busexit", date, "ausfahrt", docid));
    if (docSnap.exists()) {
      let _einsatz = {DPfrü: [], DPmit: [], DPspä: []};
      if(docSnap.data().einsatz) {
        _einsatz = docSnap.data().einsatz;
        if(_einsatz.length === 0) {
          _einsatz = {DPfrü: [], DPmit: [], DPspä: []};
        }
      }
      _einsatz[typ].push(einsatzdata);
      setDoc(doc(db, "busexit", date, "ausfahrt", docid), { einsatz: _einsatz }, { merge: true }).then(() => {
        commit('showSnackbar', { message: 'Eintrag gespeichert', status: 'success' }, { root: true })
      }).catch(err => {
        commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
      });
    }*/
  },

  async deleteEinsatz({ commit }, { date, docid, index, typ }) {
    //console.log("ADD: ", date, docid, index);
    const docSnap = await getDoc(doc(db, "dayroster", docid));
    if (docSnap.exists()) {
      let _einsatz = docSnap.data().einsatz;
      _einsatz[typ].splice(index, 1);
      setDoc(doc(db, "dayroster", docid), { einsatz: _einsatz }, { merge: true }).then(() => {
        commit('showSnackbar', { message: 'Eintrag gelöscht', status: 'success' }, { root: true })
      }).catch(err => {
        commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
      });
    }
/*    const docSnap = await getDoc(doc(db, "busexit", date, "ausfahrt", docid));
    if (docSnap.exists()) {
      let _einsatz = docSnap.data().einsatz;
      _einsatz[typ].splice(index, 1);
      setDoc(doc(db, "busexit", date, "ausfahrt", docid), { einsatz: _einsatz }, { merge: true }).then(() => {
        commit('showSnackbar', { message: 'Eintrag gelöscht', status: 'success' }, { root: true })
      }).catch(err => {
        commit('showSnackbar', { message: err.message, status: 'error' }, { root: true })
      });
    }*/
  },

  async attachReserveService({ state }, { date }) {
    return new Promise((resolve, reject) => {
      if (state.firestoreListener) {
          resolve(state.rd);
          return;
      }
      state.isLoading = true;
      const resserviceRef = collection(db, "dayroster");
      const q = query(resserviceRef, 
          where("Service_date", "==", date), 
          //where("linie", "array-contains", ["RES", "RAN"]),
          where("Service_nr", ">=", "5000"),
          where("Service_nr", "<=", "6999"),
          orderBy("Service_nr"));
      state.firestoreListener = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
        for (const doc of snapshot.docs) {
          if (doc.exists) {
            let _rsd = doc.data();
//            console.log("SERVICE: ", _rsd);
            _rsd.docid = doc.id;
            if(!_rsd.einsatz) {
              _rsd.einsatz = [];
            }
            switch(_rsd.Service_typ.trim()) { //_rsd.dayroster.Service_typ.trim()) {
              case 'DPfrü': {
//                                        console.log("FRÜH: ", _rsd);
                let _fr = state.rd[0].frueh.find (elem => elem.persid == _rsd.Personnel_nr && elem.dienst == _rsd.Service_nr);
                if (!_fr) {
                  state.rd[0].frueh.push({ dienst: _rsd.Service_nr, von: _rsd.service_begin, bis: _rsd.service_end, 
                      name: _rsd.Surname,ort: _rsd.begin_location, persid: _rsd.Personnel_nr, ro: true, 
                      modal: false, id: _rsd.docid, date: _rsd.datum, einsatz: _rsd.einsatz });
                } else {
                  _fr.von = _rsd.service_begin;
                  _fr.bis = _rsd.service_end;
                  _fr.name =  _rsd.Surname;
                  _fr.ort =  _rsd.begin_location;
                  _fr.persid =  _rsd.Personnel_nr;
                  _fr.id = _rsd.docid;
                  _fr.date = _rsd.datum;
                  _fr.einsatz = _rsd.einsatz;
                }
                break;
              }
              case 'DPmit': {
//                                        console.log("MITTEL: ", _rsd);
                let _mit = state.rd[0].mittel.find (elem => elem.persid == _rsd.Personnel_nr && elem.dienst == _rsd.Service_nr);
                if (!_mit) {
                  state.rd[0].mittel.push({ dienst: _rsd.Service_nr, von: _rsd.service_begin, bis: _rsd.service_end, 
                      name: _rsd.Surname,ort: _rsd.begin_location, persid: _rsd.Personnel_nr, ro: true, 
                      modal: false, id: _rsd.docid, date: _rsd.datum, einsatz: _rsd.einsatz });
                } else {
                  _mit.von = _rsd.service_begin;
                  _mit.bis = _rsd.service_end;
                  _mit.name =  _rsd.Surname;
                  _mit.ort =  _rsd.begin_location;
                  _mit.persid =  _rsd.Personnel_nr;
                  _mit.id = _rsd.docid;
                  _mit.date = _rsd.datum;
                  _mit.einsatz = _rsd.einsatz;
                }
                break;
              }
              case 'DPne':
              case 'DPspä': {
//                                        console.log("SPÄT: ", _rsd);
                let _spae = state.rd[0].spaet.find (elem => elem.persid == _rsd.Personnel_nr && elem.dienst == _rsd.Service_nr);
                if (!_spae) {
                  state.rd[0].spaet.push({ dienst: _rsd.Service_nr, von: _rsd.service_begin, bis: _rsd.service_end, 
                      name: _rsd.Surname,ort: _rsd.begin_location, persid: _rsd.Personnel_nr, ro: true, 
                      modal: false, id: _rsd.docid, date: _rsd.datum, einsatz: _rsd.einsatz });
                } else {
                  _spae.von = _rsd.service_begin;
                  _spae.bis = _rsd.service_end;
                  _spae.name =  _rsd.Surname;
                  _spae.ort =  _rsd.begin_location;
                  _spae.persid =  _rsd.Personnel_nr;
                  _spae.id = _rsd.docid;
                  _spae.date = _rsd.datum;
                  _spae.einsatz = _rsd.einsatz;
                }
                break;
              }
              case "DPget": {
                console.log("GET: ", _rsd);
                if((_rsd.pause_begin === '00:00' && _rsd.pause_end === '00:00') ||
                  (_rsd.pause_begin === '' && _rsd.pause_end === '')) {
                  let _mit = state.rd[0].mittel.find (elem => elem.persid == _rsd.Personnel_nr && elem.dienst == _rsd.Service_nr);
                  if( !_mit) {
                    state.rd[0].mittel.push({ dienst: _rsd.Service_nr, von: _rsd.service_begin, bis: _rsd.service_end, 
                        name: _rsd.fahrer,ort: _rsd.end_location, persid: _rsd.Personnel_nr, ro: true, 
                        modal: false, id: _rsd.id, date: _rsd.datum, einsatz: _rsd.einsatz });
                  } else {
                    _mit.von = _rsd.service_begin;
                    _mit.bis = _rsd.service_end;
                    _mit.name =  _rsd.fahrer;
                    _mit.ort =  _rsd.end_location;
                    _mit.persid =  _rsd.Personnel_nr;
                    _mit.id = _rsd.id;
                    _mit.date = _rsd.datum;
                    _mit.einsatz = _rsd.einsatz;
                  }
                } else {
                  let _fr = state.rd[0].frueh.find (elem => elem.persid == _rsd.Personnel_nr && elem.dienst == _rsd.Service_nr);
                  if (!_fr) {
                    state.rd[0].frueh.push({ dienst: _rsd.Service_nr, von: _rsd.service_begin, bis: _rsd.pause_begin, 
                        name: _rsd.fahrer,ort: _rsd.begin_location, persid: _rsd.Personnel_nr, ro: true, 
                        modal: false, id: _rsd.id, date: _rsd.datum, einsatz: _rsd.einsatz });
                  } else {
                    _fr.von = _rsd.service_begin;
                    _fr.bis = _rsd.pause_begin;
                    _fr.name =  _rsd.fahrer;
                    _fr.ort =  _rsd.begin_location;
                    _fr.persid =  _rsd.Personnel_nr;
                    _fr.id = _rsd.id;
                    _fr.date = _rsd.datum;
                    _fr.einsatz = _rsd.einsatz;
                  }
                  let _mit = state.rd[0].mittel.find (elem => elem.persid == _rsd.Personnel_nr && elem.dienst == _rsd.Service_nr);
                  if( !_mit) {
                    state.rd[0].mittel.push({ dienst: _rsd.Service_nr, von: _rsd.pause_end, bis: _rsd.service_end, 
                        name: _rsd.fahrer,ort: _rsd.end_location, persid: _rsd.Personnel_nr, ro: true, 
                        modal: false, id: _rsd.id, date: _rsd.datum, einsatz: _rsd.einsatz });
                  } else {
                    _mit.von = _rsd.pause_end;
                    _mit.bis = _rsd.service_end;
                    _mit.name =  _rsd.fahrer;
                    _mit.ort =  _rsd.end_location;
                    _mit.persid =  _rsd.Personnel_nr;
                    _mit.id = _rsd.id;
                    _mit.date = _rsd.datum;
                    _mit.einsatz = _rsd.einsatz;
                  }
                }
                break;
              }
            }
          }
          // const source = snapshot.metadata.fromCache ? "local cache" : "server";
          if (snapshot.metadata.fromCache) {
            state.isOnline = false;
          } else {
            state.isOnline = true;
            state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
          }
        }
        //console.log("RESERVE: ", state.rd);
        resolve(state.rd);
      }, (error) => {
        console.log(error.message);
        reject(error);
      })
    })
  },
/*
  async attachRESBusExit({ state }, { date }) {
    return new Promise((resolve, reject) => {
      if (state.firestoreListener) {
          resolve(state.rd);
          return;
      }
      state.isLoading = true;
      const resserviceRef = collection(db, "busexit", date, "ausfahrt");
      const q = query(resserviceRef, where("linie", "in", ['RES','RAN']), orderBy("meldezeit"));
      state.firestoreListener = onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
        state.rd[0].frueh.splice(0, state.rd[0].frueh.length);
        for (const doc of snapshot.docs) {
          if (doc.exists) {
              let _rsd = doc.data();
              _rsd.docid = doc.id;
              if(!_rsd.einsatz) {
                _rsd.einsatz = [];
              }
//                            console.log("1: ", doc.id, _rsd.dayroster);
              if(_rsd.dayroster) {
//                console.log("TYP: ", doc.id, _rsd.dienst,  _rsd.diensttyp.trim(), "F: ", _rsd.fahrer);
                switch(_rsd.diensttyp.trim()) { //_rsd.dayroster.Service_typ.trim()) {
                    case 'DPfrü': {
//                                        console.log("FRÜH: ", _rsd);
                      let _fr = state.rd[0].frueh.find (elem => elem.persid == _rsd.personnel_nr && elem.dienst == _rsd.dienst);
                      if (!_fr) {
                        state.rd[0].frueh.push({ dienst: _rsd.dienst, von: _rsd.anfang, bis: _rsd.ende, 
                            name: _rsd.fahrer,ort: _rsd.dayroster.begin_location, persid: _rsd.personnel_nr, ro: true, 
                            modal: false, id: _rsd.docid, date: _rsd.datum, einsatz: _rsd.einsatz });
                      } else {
                        _fr.von = _rsd.anfang;
                        _fr.bis = _rsd.ende;
                        _fr.name =  _rsd.fahrer;
                        _fr.ort =  _rsd.dayroster.begin_location;
                        _fr.persid =  _rsd.personnel_nr;
                        _fr.id = _rsd.docid;
                        _fr.date = _rsd.datum;
                        _fr.einsatz = _rsd.einsatz;
                      }
                      break;
                    }
                    case 'DPmit': {
//                                        console.log("MIT: ", _rsd);
                      let _mit = state.rd[0].mittel.find (elem => elem.persid == _rsd.personnel_nr && elem.dienst == _rsd.dienst);
                      if( !_mit) {
                        state.rd[0].mittel.push({ dienst: _rsd.dienst, von: _rsd.anfang, bis: _rsd.ende, 
                            name: _rsd.fahrer,ort: _rsd.dayroster.begin_location, persid: _rsd.personnel_nr, ro: true, 
                            modal: false, id: _rsd.docid, date: _rsd.datum, einsatz: _rsd.einsatz });
                      } else {
                        _mit.von = _rsd.anfang;
                        _mit.bis = _rsd.ende;
                        _mit.name =  _rsd.fahrer;
                        _mit.ort =  _rsd.dayroster.begin_location;
                        _mit.persid =  _rsd.personnel_nr;
                        _mit.id = _rsd.docid;
                        _mit.date = _rsd.datum;
                        _mit.einsatz = _rsd.einsatz;
                      }
                      break;
                    }
                    case 'DPne':
                    case 'DPspä': {
//                                        console.log("SPÄT: ", _rsd);
                      let _spae = state.rd[0].spaet.find (elem => elem.persid == _rsd.personnel_nr && elem.dienst == _rsd.dienst);
                      if( !_spae) {
                        state.rd[0].spaet.push({ dienst: _rsd.dienst, von: _rsd.anfang, bis: _rsd.ende, 
                            name: _rsd.fahrer,ort: _rsd.dayroster.begin_location, persid: _rsd.personnel_nr, ro: true, 
                            modal: false, id: _rsd.docid, date: _rsd.datum, einsatz: _rsd.einsatz });
                      } else {
                        _spae.von = _rsd.anfang;
                        _spae.bis = _rsd.ende;
                        _spae.name =  _rsd.fahrer;
                        _spae.ort =  _rsd.dayroster.begin_location;
                        _spae.persid =  _rsd.personnel_nr;
                        _spae.id = _rsd.docid;
                        _spae.date = _rsd.datum;
                        _spae.einsatz = _rsd.einsatz;
                      }
                      break;
                    }
                    case "DPget": {
//                                        console.log("GET: ", _rsd);
                      if((_rsd.dayroster.pause_begin === '00:00' && _rsd.dayroster.pause_end === '00:00') ||
                        (_rsd.dayroster.pause_begin === '' && _rsd.dayroster.pause_end === '')) {
                        let _mit = state.rd[0].mittel.find (elem => elem.persid == _rsd.personnel_nr && elem.dienst == _rsd.dienst);
                        if( !_mit) {
                          state.rd[0].mittel.push({ dienst: _rsd.dienst, von: _rsd.anfang, bis: _rsd.ende, 
                              name: _rsd.fahrer,ort: _rsd.dayroster.end_location, persid: _rsd.personnel_nr, ro: true, 
                              modal: false, id: _rsd.docid, date: _rsd.datum, einsatz: _rsd.einsatz });
                        } else {
                          _mit.von = _rsd.anfang;
                          _mit.bis = _rsd.ende;
                          _mit.name =  _rsd.fahrer;
                          _mit.ort =  _rsd.dayroster.end_location;
                          _mit.persid =  _rsd.personnel_nr;
                          _mit.id = _rsd.docid;
                          _mit.date = _rsd.datum;
                          _mit.einsatz = _rsd.einsatz;
                        }
                      } else {
                        let _fr = state.rd[0].frueh.find (elem => elem.persid == _rsd.personnel_nr && elem.dienst == _rsd.dienst);
                        if (!_fr) {
                          state.rd[0].frueh.push({ dienst: _rsd.dienst, von: _rsd.anfang, bis: _rsd.dayroster.pause_begin, 
                              name: _rsd.fahrer,ort: _rsd.dayroster.begin_location, persid: _rsd.personnel_nr, ro: true, 
                              modal: false, id: _rsd.docid, date: _rsd.datum, einsatz: _rsd.einsatz });
                        } else {
                          _fr.von = _rsd.anfang;
                          _fr.bis = _rsd.dayroster.pause_begin;
                          _fr.name =  _rsd.fahrer;
                          _fr.ort =  _rsd.dayroster.begin_location;
                          _fr.persid =  _rsd.personnel_nr;
                          _fr.id = _rsd.docid;
                          _fr.date = _rsd.datum;
                          _fr.einsatz = _rsd.einsatz;
                        }
                        let _mit = state.rd[0].mittel.find (elem => elem.persid == _rsd.personnel_nr && elem.dienst == _rsd.dienst);
                        if( !_mit) {
                          state.rd[0].mittel.push({ dienst: _rsd.dienst, von: _rsd.dayroster.pause_end, bis: _rsd.ende, 
                              name: _rsd.fahrer,ort: _rsd.dayroster.end_location, persid: _rsd.personnel_nr, ro: true, 
                              modal: false, id: _rsd.docid, date: _rsd.datum, einsatz: _rsd.einsatz });
                        } else {
                          _mit.von = _rsd.dayroster.pause_end;
                          _mit.bis = _rsd.ende;
                          _mit.name =  _rsd.fahrer;
                          _mit.ort =  _rsd.dayroster.end_location;
                          _mit.persid =  _rsd.personnel_nr;
                          _mit.id = _rsd.docid;
                          _mit.date = _rsd.datum;
                          _mit.einsatz = _rsd.einsatz;
                        }
                      }
                      break;
                    }
                    default: {
//                                        console.log("DEFAULT", _rsd);
                      if (!_rsd.personnel_nr) {
                        if( !state.rd[0].nb.find (elem => elem.dienst == _rsd.dienst)) {
                          state.rd[0].nb.push({ dienst: _rsd.dienst, von: _rsd.anfang, bis: _rsd.ende, 
                              name: _rsd.fahrer,ort: _rsd.dayroster.begin_location, persid: _rsd.personnel_nr, ro: true, 
                              modal: false, einsatz: [] });
                        }
                      } else {
                        let _mit = state.rd[0].mittel.find (elem => elem.persid == _rsd.personnel_nr && elem.dienst == _rsd.dienst);
                        if( !_mit) {
                          state.rd[0].mittel.push({ dienst: _rsd.dienst, von: _rsd.anfang, bis: _rsd.ende, 
                              name: _rsd.fahrer,ort: _rsd.dayroster.begin_location, persid: _rsd.personnel_nr, ro: true, 
                              modal: false, einsatz: [] });
                        } else {
                          _mit.von = _rsd.anfang;
                          _mit.bis = _rsd.ende;
                          _mit.name =  _rsd.fahrer;
                          _mit.ort =  _rsd.dayroster.begin_location;
                          _mit.persid =  _rsd.personnel_nr;
                        }
                      }
                      break;
                    }
                  }
              } else {
                if( !state.rd[0].nb.find (elem => elem.dienst == _rsd.dienst)) {
                  state.rd[0].nb.push({ dienst: _rsd.dienst, von: _rsd.anfang, bis: _rsd.ende, 
                    name: _rsd.fahrer,ort: '', persid: _rsd.personnel_nr, ro: true, 
                    modal: false, einsatz: [] });
                }
              }
            }
            // const source = snapshot.metadata.fromCache ? "local cache" : "server";
            if (snapshot.metadata.fromCache) {
                state.isOnline = false;
            } else {
                state.isOnline = true;
                state.lastChange = DateTime.now().setLocale('de').toFormat("dd.MM.yyyy' 'HH:mm");
            }
        }
//                    console.log("RD: ", state.rd);
        resolve(state.rd);
      }, (error) => {
        console.log(error.message);
        reject(error);
      })
    })
  },
*/
  detachRESBusexit({ state }) {
    if (state.firestoreListener) {
      state.firestoreListener();
      state.firestoreListener = null;
    }
  },
}

export default { state, getters, mutations, actions }
