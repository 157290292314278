/* eslint-disable */

import { register } from 'register-service-worker';

if (process.env.NODE_ENV === 'production' &&
('https:' === location.protocol || location.host.match(/(localhost)/))) {
  console.log("BASE_URL", process.env.BASE_URL);
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log('App is being served from cache by a service worker.');
    },
    registered(reg) {
      console.log('Service worker has been registered.');
      setInterval(() => {
        reg.update();
      }, 1000 * 60 * 60); // e.g. hourly checks
    },
    cached() {
      console.log('Content has been cached for offline use.');
    },
    updatefound() {
      console.log('New content is downloading.');
    },
    updated(reg) {
      console.log('New content is available; please refresh.');
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: reg.waiting })
      );
    },
    offline() {
      console.log(
        'No internet connection found. App is running in offline mode.'
      );
    },
    error(error) {
      console.error('Error during service worker registration:', error);
    }
  });
}